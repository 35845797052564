import React, { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';

const InputToast = ({ message, isSubmit, setIsSubmit }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if(isSubmit){
      setShow(true);
    }
  }, [isSubmit]);

  return (
    <Toast
      style={{
        backgroundColor: '#f4dedc',
        maxWidth: '100%',
        color: '#a03b32',
        fontSize: '17px',
        borderRadius: '5px',
        padding: '0.2rem 1rem',
        marginTop: '5px',
        boxShadow: 'none'
      }}
      onClose={() => {setShow(false);
        setIsSubmit(false);}}
      delay={3000}
      show={show}
      autohide
    >
      <div className="pts-b-17">{message}</div>
    </Toast>
  );
};

export default InputToast;
