import * as CONSTANTS from './constants';

// getting current linear channels
export const getLinearChannelsRequest = data => ({
  type: CONSTANTS.GET_LINEAR_CHANNELS_REQUEST,
  payload: data
});
export const getLinearChannelsSuccess = data => ({
  type: CONSTANTS.GET_LINEAR_CHANNELS_SUCCESS,
  payload: data
});
export const getLinearChannelsError = () => ({
  type: CONSTANTS.GET_LINEAR_CHANNELS_ERROR
});

// channel selecting
export const playLiveChannelRequest = data => ({
  type: CONSTANTS.PLAY_LIVE_CHANNEL_REQUEST,
  payload: data
});
export const playLiveChannelSuccess = data => ({
  type: CONSTANTS.PLAY_LIVE_CHANNEL_SUCCESS,
  payload: data
});
export const playLiveChannelError = data => ({
  type: CONSTANTS.PLAY_LIVE_CHANNEL_ERROR,
  payload: data
});

// getting available channels
export const getOverflowChannelsRequest = data => ({
  type: CONSTANTS.GET_OVERFLOW_CHANNELS_REQUEST,
  payload: data
});
export const getOverflowChannelsSuccess = data => ({
  type: CONSTANTS.GET_OVERFLOW_CHANNELS_SUCCESS,
  payload: data
});
export const getOverflowChannelsError = () => ({
  type: CONSTANTS.GET_OVERFLOW_CHANNELS_ERROR
});

// getting available channels
export const getTVScheduleRequest = data => ({
  type: CONSTANTS.GET_TV_SCHEDULE_REQUEST,
  payload: data
});
export const getTVScheduleSuccess = data => ({
  type: CONSTANTS.GET_TV_SCHEDULE_SUCCESS,
  payload: data
});
export const getTVScheduleError = () => ({
  type: CONSTANTS.GET_TV_SCHEDULE_ERROR
});

export const concurrencyError = () => ({
  type: CONSTANTS.CONCURRENCY_ERROR
});
export const outOfMarketPlaceError = () => ({
  type: CONSTANTS.OUT_OF_MARKETPLACE_ERROR
});

export const clearAPIError = () => ({
  type: CONSTANTS.CLEAR_ERROR
});

export const trackStreamStartRequest = data => ({
  type: CONSTANTS.TRACK_STREAM_START_REQUEST,
  payload: data
});
export const trackStreamStartSuccess = () => ({
  type: CONSTANTS.TRACK_STREAM_START_SUCCESS
});
export const trackStreamStartError = () => ({
  type: CONSTANTS.TRACK_STREAM_START_ERROR
});

export const trackStreamStopRequest = data => ({
  type: CONSTANTS.TRACK_STREAM_STOP_REQUEST,
  payload: data
});
export const trackStreamStopSuccess = () => ({
  type: CONSTANTS.TRACK_STREAM_STOP_SUCCESS
});
export const trackStreamStopError = () => ({
  type: CONSTANTS.TRACK_STREAM_STOP_ERROR
});

export const getVODListRequest = (data, offset) => ({
  type: CONSTANTS.GET_VOD_LIST_REQUEST,
  payload: data,
  offset
});
export const getVODListSuccess = data => ({
  type: CONSTANTS.GET_VOD_LIST_SUCCESS,
  payload: data
});
export const getVODListResponse = data => ({
  type: CONSTANTS.GET_VOD_LIST_RESPONSE,
  payload: data
});
export const getVODListError = () => ({
  type: CONSTANTS.GET_VOD_LIST_ERROR
});

export const getVODSectionRequest = data => ({
  type: CONSTANTS.GET_VOD_SECTION_REQUEST,
  payload: data
});
export const getVODSectionSuccess = data => ({
  type: CONSTANTS.GET_VOD_SECTION_SUCCESS,
  payload: data
});
export const getVODSectionError = () => ({
  type: CONSTANTS.GET_VOD_SECTION_ERROR
});

export const getVODItemRequest = data => ({
  type: CONSTANTS.GET_VOD_ITEM_REQUEST,
  payload: data
});
export const getVODItemSuccess = data => ({
  type: CONSTANTS.GET_VOD_ITEM_SUCCESS,
  payload: data
});

export const getVODSearchResultsRequest = data => ({
  type: CONSTANTS.GET_VOD_SEARCH_RESULTS_REQUEST,
  payload: data
});

export const getVODSearchResultsSuccess = data => ({
  type: CONSTANTS.GET_VOD_SEARCH_RESULTS_SUCCESS,
  payload: data
});

export const getVODSearchResultsError = () => ({
  type: CONSTANTS.GET_VOD_SEARCH_RESULTS_ERROR
});

export const getVODItemError = () => ({
  type: CONSTANTS.GET_VOD_ITEM_ERROR
});

export const getVODRequest = data => ({
  type: CONSTANTS.GET_URL_VOD_REQUEST,
  payload: data
});

export const getVODSuccess = data => ({
  type: CONSTANTS.GET_URL_VOD_SUCCESS,
  payload: data
});

export const getVODError = () => ({
  type: CONSTANTS.GET_URL_VOD_ERROR
});

export const setCurrentScheduleItem = data => ({
  type: CONSTANTS.SET_CURRENT_SCHEDULE_ITEM,
  payload: data
});

export const outOfUSError = () => ({
  type: CONSTANTS.OUT_OF_US_ERROR
});
export const abortNotEntitledError = () => ({
  type: CONSTANTS.ABORT_NOT_ENTITLED
});
export const abortRoamingError = () => ({
  type: CONSTANTS.ABORT_ROAMING
});
export const gracefulStopError = () => ({
  type: CONSTANTS.GRACEFUL_STOP
});
export const blackoutError = data => ({
  type: CONSTANTS.BLACK_OUT_ERROR,
  payload: data
});
export const noSubscriptionError = () => ({
  type: CONSTANTS.NO_SUBSCRIPTOIN_ERROR
});
export const setRemoteConfig = data => ({
  type: CONSTANTS.GET_REMOTE_CONFIG_SUCCESS,
  payload: data
});
export const getRemoteConfig = () => ({
  type: CONSTANTS.GET_REMOTE_CONFIG_REQUEST
});
export const getRemoteConfigError = data => ({
  type: CONSTANTS.GET_REMOTE_CONFIG_ERROR,
  payload: data
});
export const setLocalization = data => ({
  type: CONSTANTS.GET_LOCALIZATION_SUCCESS,
  payload: data
});
export const getLocalization = () => ({
  type: CONSTANTS.GET_LOCALIZATION_REQUEST
});
export const getLocalizationError = data => ({
  type: CONSTANTS.GET_LOCALIZATION_ERROR,
  payload: data
});
export const setPlayer = data => ({
  type: CONSTANTS.GET_PLAYERS_SUCCESS,
  payload: data
});
export const getPlayers = () => ({
  type: CONSTANTS.GET_PLAYERS_REQUEST
});
export const getPlayerError = data => ({
  type: CONSTANTS.GET_PLAYERS_ERROR,
  payload: data
});

export const updatePlayers = data => ({
  type: CONSTANTS.UPDATE_PLAYERS,
  payload: data
});

export const setSelectedPlan = data => ({
  type: CONSTANTS.SET_SELECTED_PLAN,
  payload: data
});

export const setSuccessfullyLinked = () => ({
  type: CONSTANTS.SET_SUCCESSFULLY_LINKED
});

export const checkCouchRights = () => ({
  type: CONSTANTS.CHECK_COUCH_RIGHTS
});

export const isCheckCouchRightsInProgress = data => ({
  type: CONSTANTS.CHECK_COUCH_RIGHTS_IN_PROGRESS,
  payload: data
});

export const couchRightsExpired = data => ({
  type: CONSTANTS.COUCH_RIGHTS_EXPIRED,
  payload: data
});

export const couchRightsExpireyError = () => ({
  type: CONSTANTS.COUCH_RIGHTS_EXPIRY_ERROR
});

export const cancelSubscriptionRequest = (data, reason) => ({
  type: CONSTANTS.CANCEL_SUBSCRIPTION_REQUEST,
  payload: data,
  reason
});
export const cancelSubscriptionSuccess = data => ({
  type: CONSTANTS.CANCEL_SUBSCRIPTION_SUCCESS,
  payload: data
});
export const cancelSubscriptionError = data => ({
  type: CONSTANTS.CANCEL_SUBSCRIPTION_ERROR,
  payload: data
});
export const setHeartbeatToken = data => ({
  type: CONSTANTS.SET_HEARTBEAT_TOKEN,
  payload: data
});
export const setErrorType = data => ({
  type: CONSTANTS.SET_ERROR_TYPE,
  payload: data
});

export const setErrorCode = data => ({
  type: CONSTANTS.SET_ERROR_CODE,
  payload: data
});
