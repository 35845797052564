import React from 'react';
import checkPassword from 'utils/password';

const PasswordStrengthMeter = ({ password }) => {
  const mark = checkPassword(password);
  const num = (mark * 100) / 4;

  const passwordLable = () => {
    switch (mark) {
      case 0:
        return 'Required';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return ' ';
    }
  };

  const progressColor = () => {
    switch (mark) {
      case 0:
        return '#828282';
      case 1:
        return '#EA1111';
      case 2:
        return '#EFAD00';
      case 3:
        return '#9B1158';
      case 4:
        return '#00B500';
      default:
        return 'none';
    }
  };

  const changePasswordColor = () => ({
    width: `${num}%`,
    background: progressColor(),
    height: '7px'
  });

  return (
    <div className="text-right">
      <p className="pts-n-grey-14 mb-1">{passwordLable()}</p>
      <div className="progress" style={{ height: '7px' }}>
        <div className="progress-bar" style={changePasswordColor()} />
      </div>
    </div>
  );
};

export default PasswordStrengthMeter;
