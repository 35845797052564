import produce from 'immer';
import * as CONSTANTS from './constants';

const initialState = {
  errorMessage: {
    type: null,
    message: null
  },
  successMessage: {
    type: null,
    message: null
  }
};

const authReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CONSTANTS.TOAST_SUCCESS_NOTIFY:
        draft.errorMessage = {
          type: null,
          message: null
        };
        draft.successMessage = action.payload;
        break;
      case CONSTANTS.TOAST_ERROR_NOTIFY:
        draft.successMessage = {
          type: null,
          message: null
        };
        draft.errorMessage = action.payload;
        break;

      default:
        break;
    }
  });

export default authReducer;
