import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlaylistCard from 'components/PlaylistCard/PlaylistCard';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'redux/configureStore';
import { useHistory } from 'react-router-dom';
import VODimage from 'assets/images/vod_item_image.png';
import axios from 'axios';
import useAnalyticsScreenTracking from 'hooks/useAnalyticsScreenTracking';
import { getVODSuccess } from '../redux/actions';
import './VODCategory.scss';

const VODCategory = ({
  mvpdObj,
  mvpdLogoClicked,
  currentSection,
  setCurrentSection,
  sectionId,
  playLiveChannel,
  setVideoName,
  setVideoTitle,
  setSeeMore,
  linearId,
  setIdVOD,
  setShowSubscribeScreen,
  containerVodURL
}) => {
  const dispatch = useDispatch();
  const historyListener = useHistory();
  const { selectedMVPD } = useSelector(state => state.app.mvpdSelect);
  const [locationKeys, setLocationKeys] = useState([]);
  const { GetActiveSubscriptionsResponseMessage } = useSelector(state => state.auth);
  const [result, setResult] = useState();
  const isSubscribe = !!GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.length;

  useAnalyticsScreenTracking('Watch - See More Content');
  const goBack = () => {
    history.push({
      pathname: `/watch/${linearId}`,
      search: window.location.search
    });
    setIdVOD(false);
    setSeeMore(false);
    setCurrentSection('');
  };
  useEffect(
    () =>
      historyListener.listen(location => {
        if (historyListener.action === 'PUSH') {
          setLocationKeys([location.key]);
        }

        if (historyListener.action === 'POP') {
          if (locationKeys[1] === location.key) {
            setLocationKeys(([_, ...keys]) => keys);

            // Handle forward event
          } else {
            setLocationKeys(keys => [location.key, ...keys]);

            // Handle back event
            goBack();
          }
        }
      }),
    // eslint-disable-next-line
    [locationKeys]
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(
        `${containerVodURL}&reg=us&dt=androidmobile&client=yesn-yesn-androidmobile&pageNumber=1&pageSize=50`
      );
      setResult(response);
    }
    fetchData();
  }, [containerVodURL]);

  const playItem = (assetId, name, duration, title, item) => {
    setSeeMore(false);
    setCurrentSection(sectionId);
    const inFreePackage = item.ent[0].sp.map(i => i.includes('Free')).includes(true);
    if (!selectedMVPD && !inFreePackage && !isSubscribe) {
      dispatch(getVODSuccess(item));
      history.push({
        pathname: `/watch/${assetId}`,
        search: window.location.search
      }); // sets url to enter mvpd select route
    } else {
      setVideoName(name);
      setVideoTitle(title);
      dispatch(getVODSuccess(item));
      playLiveChannel(assetId, duration, inFreePackage);
    }
  };

  const pusblishTime = duration => {
    const durInSec = duration / 1000;
    const hrs = Math.floor(durInSec / 3600)
      .toString()
      .padStart(2, '0');
    const mins = Math.floor((durInSec % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const secs = Math.floor(durInSec % 60)
      .toString()
      .padStart(2, '0');

    return `${hrs}:${mins}:${secs}`;
  };

  const filterimages = ITEM => {
    if (ITEM && ITEM?.ia && ITEM?.ia[0]) {
      return `${process.env.REACT_APP_QUICKPLAY_IMAGE_BASE_URL}/image/${ITEM.id}/${ITEM.ia[0]}.jpg?width=500`;
    }

    if (ITEM && ITEM?.ia && ITEM?.ia[1]) {
      return `${process.env.REACT_APP_QUICKPLAY_IMAGE_BASE_URL}/image/${ITEM.id}/${ITEM.ia[1]}.jpg?width=500`;
    }

    return VODimage;
  };

  return (
    <div className="player-container">
      <div className="video-container">
        <div className="watch-header d-flex align-items-center justify-content-between mb-2">
          <div className="d-flex flex-row">
            <div className="category-back-arrow">
              <FontAwesomeIcon
                className="category-back-button"
                icon="arrow-left"
                onClick={() => goBack()}
              />
            </div>
            <div className="back-text">
              <h3 onClick={() => goBack()} className="tglc-24 text-center back-button-text">
                Back
              </h3>
            </div>
          </div>
          <div className="d-flex align-items-center logo">
            {mvpdObj && mvpdObj.logoUrlWhite && (
              <img
                onClick={() => mvpdLogoClicked(mvpdObj)}
                className="view-all-mvpd-logo mr-4"
                src={mvpdObj.logoUrlWhite}
                alt="mvpd_logo"
              />
            )}
          </div>
        </div>
        <div>
          <h3 className="tgd1-n-base-26">{currentSection.title}</h3>
        </div>
        <div className="search-video-container d-flex flex-wrap">
          {result &&
            result?.data &&
            result?.data?.data &&
            result?.data?.data.map(item => (
              <div className="search-result-card" key={item.id}>
                <PlaylistCard
                  key={item.id}
                  title={item?.lon[0].n}
                  onClick={() => playItem(item.id, item.name, item.duration, item?.lon[0].n, item)}
                  responsive={1}
                  videolength={pusblishTime(item?.vrt)}
                  publishdate={item.st_dt}
                  image={filterimages(item)}
                  lockIcon={
                    !!(
                      !selectedMVPD &&
                      !isSubscribe &&
                      !item.ent[0].sp.map(i => i.includes('Free')).includes(true)
                    )
                  }
                  setShowSubscribeScreen={setShowSubscribeScreen}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default VODCategory;
