import axios from 'axios';
import { get } from 'lodash';
import { history } from 'redux/configureStore';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import {
  getRefreshToken,
  setRefreshToken, removeAccessToken, setAccessToken, getEvergentExpiresIn, setEvergentExpiresIn, getAccessToken
} from './localStorage';
export const baseURL = process.env.REACT_APP_EVERGENT_API;

const payload = {
  RefreshTokenRequestMessage: {
    apiUser: APIUSER,
    apiPassword: APIPASSWORD,
    channelPartnerID: CHANNELPARTNERID,
    refreshToken: `${getRefreshToken()}`
  }
}
const INSTANCE = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${getAccessToken()}`
  }
});
const createAxiosResponseInterceptor = () => {
  const interceptor = INSTANCE.interceptors.response.use(
    response => response,
    error => {
      // Reject promise if usual error
      const { data } = get(error, 'response', error);
      const expiresInDate = parseFloat(getEvergentExpiresIn());
      const currentDate = Date.now();
      const isValidToken = expiresInDate > currentDate;
      if (!(!data.errorCode && error.response.status === 403 && !isValidToken && !window.location.pathname.startsWith('/authenticate'))) {
        return Promise.reject(error);
      }
      /**
       * When response status is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response
       */
      INSTANCE.interceptors.response.eject(interceptor);

      return INSTANCE.post('/yesnetwork/refreshToken', payload)
        .then(({ data }) => {
          setAccessToken(data.RefreshTokenResponseMessage.accessToken);
          setRefreshToken(data.RefreshTokenResponseMessage.refreshToken);
          setEvergentExpiresIn(data.RefreshTokenResponseMessage.expiresIn);
          error.response.config.headers.Authorization = `Bearer ${data.RefreshTokenResponseMessage.accessToken}`;
          return INSTANCE(error.response.config);
        })
        .catch(err => {
          //  removeRefreshToken();
          removeAccessToken();
          history.push('/');
          return Promise.reject(err);
        })
        .finally(createAxiosResponseInterceptor);
    }
  );
};

createAxiosResponseInterceptor();

export default INSTANCE;
