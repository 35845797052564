import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PasswordField from 'components/PasswordField';
import './ChangePassword.scss';
import request from 'utils/api';
import { get } from 'lodash';
import PasswordStrengthMeter from 'components/PasswordStrengthMeter/PasswordStrengthMeter';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { changePasswordSchema } from 'helpers/validations';
import { toastErrorNotify } from 'pages/toast/redux/actions';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import { checkValidToken } from 'utils/CheckTokenValidation';
import PageWrapper from 'pages/layoutPage/PageWrapper';
import NewLayout from 'containers/AuthLayout/NewLayout';
import useAnalyticsScreenTracking from 'hooks/useAnalyticsScreenTracking';

const ChangePassword = ({ goBack }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { isMac } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useAnalyticsScreenTracking('Change Password');

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  const handleSubmit = async values => {
    const { currentPassword, newPassword, confirmPassword } = values;
    const params = {
      ChangePasswordRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        oldPassword: currentPassword,
        newPassword,
        confirmNewpassword: confirmPassword
      }
    };
    try {
      setIsLoading(true);
      const evergentAccessToken = await checkValidToken();
      await request('evergent', '/yesnetwork/changePassword', 'post', params, evergentAccessToken);
      setIsLoading(false);
      goBack();
    } catch (err) {
      setIsLoading(false);
      const error = get(err, 'response', err);
      dispatch(
        toastErrorNotify({
          type: 'Change Password Error',
          message: error?.data?.errorMessage
        })
      );
    }
  };
  return (
    <PageWrapper
      showHeader
      showBackIcon
      onBackClick={() => goBack()}
      showTitle
      titleText="CHANGE PASSWORD"
    >
      <NewLayout>
        <div className="changepassword-container">
          <Formik
            initialValues={initialValues}
            validationSchema={changePasswordSchema}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, isValid, values }) => (
              <Form className="d-flex flex-column justify-content-around manage-account-form">
                <div>
                  <div className="form-group">
                    <label
                      className={
                        touched.currentPassword && errors.currentPassword
                          ? 'is-invalid form-label'
                          : 'form-label'
                      }
                      htmlFor="currentPassword"
                    >
                      Current Password
                    </label>
                    <Field
                      name="currentPassword"
                      placeholder="Current Password"
                      component={PasswordField}
                      className={`form-control ${
                        touched.currentPassword && errors.currentPassword ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="currentPassword"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label
                    className={
                      touched.newPassword && errors.newPassword
                        ? 'is-invalid form-label'
                        : 'form-label'
                    }
                    htmlFor="newPassword"
                  >
                    New Password
                  </label>
                  <Field
                    name="newPassword"
                    placeholder="Enter your new password."
                    component={PasswordField}
                    className={`form-control ${
                      touched.newPassword && errors.newPassword ? 'is-invalid' : ''
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="newPassword"
                    className="invalid-feedback mb-1"
                  />
                  <label className="pts-n-db-14 float-left">Password Strength</label>
                  <PasswordStrengthMeter password={values.newPassword} />
                </div>
                <div className="form-group">
                  <label
                    className={
                      touched.confirmPassword && errors.confirmPassword
                        ? 'is-invalid form-label'
                        : 'form-label'
                    }
                    htmlFor="confirmPassword"
                  >
                    Confirm New Password
                  </label>
                  <Field
                    name="confirmPassword"
                    placeholder="Please confirm your password."
                    component={PasswordField}
                    className={`form-control ${
                      touched.confirmPassword && errors.confirmPassword ? 'is-invalid' : ''
                    }`}
                  />
                  <ErrorMessage
                    component="div"
                    name="confirmPassword"
                    className="invalid-feedback"
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    // className="btn btn-primary btn-block w-50 m-auto mb-1"
                    className={`${isMac ? 'IOS' : ''} primary__button`}
                    disabled={
                      !isValid ||
                      isLoading ||
                      !values.currentPassword ||
                      !values.newPassword ||
                      !values.confirmPassword
                    }
                  >
                    {isLoading ? 'Please wait...' : 'UPDATE'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </NewLayout>
    </PageWrapper>
  );
};

export default ChangePassword;
