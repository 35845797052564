import { combineReducers } from 'redux';

import authReducer from 'pages/auth/redux/reducers';
import appReducer from 'pages/app/redux/reducers';
import toastReducer from 'pages/toast/redux/reducers';

export default combineReducers({
  auth: authReducer,
  app: appReducer,
  toast: toastReducer
});
