import React from 'react';
import PageHeader from './PageHeader';
import PageTitle from './PageTitle';

const PageWrapper = ({ ...props }) => {
  const { children, showHeader, showTitle, titleText, titleSubText, className, titleNewSubText } = props;

  return (
    <div
      className={`page-background ${className !== undefined ? className : ''}`}
    >
      {showHeader && <PageHeader {...props} />}
      {showTitle && <PageTitle text={titleText} subText={titleSubText} newSubText={titleNewSubText} />}
      {children}
    </div>
  );
};
export default PageWrapper;
