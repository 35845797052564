import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from 'pages/layoutPage/PageWrapper';
import { getPlatform } from 'utils/localStorage';
// import { getPlatform, getTVCode } from 'utils/localStorage';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import PageLoading from 'components/PageLoading';
import Model from 'components/Model';
import { history } from 'redux/configureStore';
import useAnalyticsScreenTracking from 'hooks/useAnalyticsScreenTracking';
import NewLayout from '../../../containers/AuthLayout/NewLayout';
// import { setSuccessfullyLinked } from '../Watch/redux/actions';
import { loginDeviceRequest } from '../TeamSelect/redux/actions';
// import { onAuthenticationStatus } from '../MVPDSelect/redux/actions';
// import { getQuickPlayTVEFlatTokenRequest } from '../DeviceMVPD/redux/actions';

const RegisterSuccess = ({ activationCode }) => {
  const [addSubscriptionErrorMessage, setAddSubscriptionErrorMessage] = useState(true);
  const { isDeviceLoginSuccess, errorMessage } = useSelector(state => state.app.teamSelect);
  const { addSubscriptionError, addSubscriptionSuccess } = useSelector(
    state => state.app.deviceMVPD
  );
  const { localization } = useSelector(state => state.app.watch);
  const platform = getPlatform() === null ? '' : getPlatform();
  const dispatch = useDispatch();

  useAnalyticsScreenTracking('Login - TV Provider Confirmation');
  useEffect(() => {
    if (activationCode && addSubscriptionSuccess) {
      const data = {
        RegisterDeviceRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID,
          activationCode: activationCode.toUpperCase()
        }
      };
      dispatch(loginDeviceRequest(data));
    }
  }, [dispatch, activationCode, addSubscriptionSuccess]);

  if (addSubscriptionError) {
    const errorMsg =
      localization?.errors?.userAlreadyHasDTCSubscriptionTV?.en ||
      'Your account already has a YES subscription. Please login with your YES app account on your TV.';
    return (
      <Model
        title="Sorry"
        body={errorMsg}
        show={addSubscriptionErrorMessage}
        isAddTVEError
        setIsShow={setAddSubscriptionErrorMessage}
      />
    );
  }

  const navigate = () => {
    // dispatch(setSuccessfullyLinked());
    // const tvCode = getTVCode();
    // const isSelectProvider = true;
    // const query = {
    //   AddTVESubscriptionRequestMessage: {
    //     apiUser: APIUSER,
    //     apiPassword: APIPASSWORD,
    //     channelPartnerID: CHANNELPARTNERID,
    //     mvpdID: tvCode,
    //     encryptedZip: userMetadataZip
    //   }
    // };
    // dispatch(getQuickPlayTVEFlatTokenRequest(query, isSelectProvider));
    history.push({
      pathname: '/watch'
    });
  };

  if (!isDeviceLoginSuccess && !errorMessage.type) {
    return <PageLoading />;
  }

  return (
    !errorMessage.type &&
    activationCode && (
      <PageWrapper titleHeader={`Connect Your ${platform} TV`} showHeader>
        <NewLayout>
          <div className="confirmation">
            <div className="confirmation-text">{`You’ve successfully linked your ${platform} TV`}</div>
            <div className="confirmation-link" onClick={navigate}>
              If you want to continue on this website instead of your TV, log in here.
            </div>
          </div>
        </NewLayout>
      </PageWrapper>
    )
  );
};

export default RegisterSuccess;
