export const GET_LINEAR_CHANNELS_REQUEST = 'GET_LINEAR_CHANNELS_REQUEST';
export const GET_LINEAR_CHANNELS_SUCCESS = 'GET_LINEAR_CHANNELS_SUCCESS';
export const GET_LINEAR_CHANNELS_ERROR = 'GET_LINEAR_CHANNELS_ERROR';

export const PLAY_LIVE_CHANNEL_REQUEST = 'PLAY_LIVE_CHANNEL_REQUEST';
export const PLAY_LIVE_CHANNEL_SUCCESS = 'PLAY_LIVE_CHANNEL_SUCCESS';
export const PLAY_LIVE_CHANNEL_ERROR = 'PLAY_LIVE_CHANNEL_ERROR';

export const GET_OVERFLOW_CHANNELS_REQUEST = 'GET_OVERFLOW_CHANNELS_REQUEST';
export const GET_OVERFLOW_CHANNELS_SUCCESS = 'GET_OVERFLOW_CHANNELS_SUCCESS';
export const GET_OVERFLOW_CHANNELS_ERROR = 'GET_OVERFLOW_CHANNELS_ERROR';

export const GET_TV_SCHEDULE_REQUEST = 'GET_TV_SCHEDULE_REQUEST';
export const GET_TV_SCHEDULE_SUCCESS = 'GET_TV_SCHEDULE_SUCCESS';
export const GET_TV_SCHEDULE_ERROR = 'GET_TV_SCHEDULE_ERROR';

export const SET_CURRENT_SCHEDULE_ITEM = 'SET_CURRENT_SCHEDULE_ITEM';
export const CONCURRENCY_ERROR = 'CONCURRENCY_ERROR';
export const OUT_OF_MARKETPLACE_ERROR = 'OUT_OF_MARKETPLACE_ERROR';

export const TRACK_STREAM_START_REQUEST = 'TRACK_STREAM_START_REQUEST';
export const TRACK_STREAM_START_SUCCESS = 'TRACK_STREAM_START_SUCCESS';
export const TRACK_STREAM_START_ERROR = 'TRACK_STREAM_START_ERROR';

export const TRACK_STREAM_STOP_REQUEST = 'TRACK_STREAM_STOP_REQUEST';
export const TRACK_STREAM_STOP_SUCCESS = 'TRACK_STREAM_STOP_SUCCESS';
export const TRACK_STREAM_STOP_ERROR = 'TRACK_STREAM_STOP_ERROR';

export const TRACK_STREAM_CREATE_REQUEST = 'TRACK_STREAM_CREATE_REQUEST';

export const GET_VOD_LIST_REQUEST = 'GET_VOD_LIST_REQUEST';
export const GET_VOD_LIST_SUCCESS = 'GET_VOD_LIST_SUCCESS';
export const GET_VOD_LIST_RESPONSE = 'GET_VOD_LIST_RESPONSE';
export const GET_VOD_LIST_ERROR = 'GET_VOD_LIST_ERROR';

export const GET_VOD_SECTION_REQUEST = 'GET_VOD_SECTION_REQUEST';
export const GET_VOD_SECTION_SUCCESS = 'GET_VOD_SECTION_SUCCESS';
export const GET_VOD_SECTION_ERROR = 'GET_VOD_SECTION_ERROR';

export const GET_VOD_ITEM_REQUEST = 'GET_VOD_ITEM_REQUEST';
export const GET_VOD_ITEM_SUCCESS = 'GET_VOD_ITEM_SUCCESS';
export const GET_VOD_ITEM_ERROR = 'GET_VOD_ITEM_ERROR';

export const GET_URL_VOD_REQUEST = 'GET_URL_VOD_REQUEST';
export const GET_URL_VOD_SUCCESS = 'GET_URL_VOD_SUCCESS';
export const GET_URL_VOD_ERROR = 'GET_URL_VOD_ERROR';

export const GET_VOD_SEARCH_RESULTS_REQUEST = 'GET_VOD_SEARCH_RESULTS_REQUEST';
export const GET_VOD_SEARCH_RESULTS_SUCCESS = 'GET_VOD_SEARCH_RESULTS_SUCCESS';
export const GET_VOD_SEARCH_RESULTS_ERROR = 'GET_VOD_SEARCH_RESULTS_ERROR';

export const OUT_OF_US_ERROR = 'OUT_OF_US_ERROR';
export const BLACK_OUT_ERROR = 'BLACK_OUT_ERROR';
export const ABORT_ROAMING = 'OUT_OF_US_ERROR';
export const ABORT_NOT_ENTITLED = 'BLACK_OUT_ERROR';
export const GRACEFUL_STOP = 'GRACEFUL_STOP';

export const CLEAR_ERROR = 'CLEAR_ERROR';
export const NO_SUBSCRIPTOIN_ERROR = 'NO_SUBSCRIPTOIN_ERROR';
export const GET_REMOTE_CONFIG_SUCCESS = 'GET_REMOTE_CONFIG_SUCCESS';
export const GET_REMOTE_CONFIG_REQUEST = 'GET_REMOTE_CONFIG_REQUEST';
export const GET_REMOTE_CONFIG_ERROR = 'GET_REMOTE_CONFIG_ERROR';
export const GET_LOCALIZATION_SUCCESS = 'GET_LOCALIZATION_SUCCESS';
export const GET_LOCALIZATION_REQUEST = 'GET_LOCALIZATION_REQUEST';
export const GET_LOCALIZATION_ERROR = 'GET_LOCALIZATION_ERROR';
export const GET_PLAYERS_SUCCESS = 'GET_PLAYERS_SUCCESS';
export const GET_PLAYERS_REQUEST = 'GET_PLAYERS_REQUEST';
export const GET_PLAYERS_ERROR = 'GET_PLAYERS_ERROR';

export const UPDATE_PLAYERS = 'UPDATE_PLAYERS';

export const SET_SELECTED_PLAN = 'SET_SELECTED_PLAN';

export const SET_SUCCESSFULLY_LINKED = 'SET_SUCCESSFULLY_LINKED';

export const CHECK_COUCH_RIGHTS_IN_PROGRESS = 'CHECK_COUCH_RIGHTS_IN_PROGRESS';
export const CHECK_COUCH_RIGHTS = 'CHECK_COUCH_RIGHTS';
export const COUCH_RIGHTS_EXPIRED = 'COUCH_RIGHTS_EXPIRED';
export const COUCH_RIGHTS_EXPIRY_ERROR = 'COUCH_RIGHTS_EXPIRY_ERROR';

export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_ERROR = 'CANCEL_SUBSCRIPTION_ERROR';

export const SET_HEARTBEAT_TOKEN = 'SET_HEARTBEAT_TOKEN';
export const SET_ERROR_TYPE = 'SET_ERROR_TYPE';
export const SET_ERROR_CODE = 'SET_ERROR_CODE';
