import React, { useState } from 'react';

import YesBackground from 'components/YesBackground';
import YesCard from 'components/YesCard';
import ErrorModal from 'components/ErrorModal';
import ScreenImage from 'assets/images/Screens_icon.png';
import { background } from 'helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { emailConfirmSchema } from 'helpers/validations';
import { Link, useLocation } from 'react-router-dom';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import { signout } from 'pages/auth/redux/actions';
import { emailConfirmRequest } from '../redux/actions';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';

const EmailConfirm = () => {
  const [showErrorModel, setShowErrorModel] = useState(false);
  const { isEmailConfirmLoading } = useSelector(state => state.auth);
  const query = new URLSearchParams(useLocation().search);
  const isEmailURL = query.get('email');
  const dispatch = useDispatch();

  const initialValues = {
    email: isEmailURL || ''
  };

  useAnalyticsScreenTracking('Forget Password', 'Email Confirm ', '/emailConfirm');

  const handleSubmit = values => {
    const { email } = values;
    const data = {
      ForgotContactPasswordRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        email: isEmailURL || email
      }
    };
    dispatch(emailConfirmRequest(data, () => setShowErrorModel(true)));
    dispatch(signout());
  };

  return (
    <>
      <ErrorModal show={showErrorModel} setIsShow={setShowErrorModel} />
      <YesBackground
        logoIcon={ScreenImage}
        bgClassName={background.auth}
        title="Watch Yes"
        description="<span>Catch your favorite games live on your <b>phone</b>, <b>computer</b>, <b>tablet</b>, or <b>TV</b>.</span>"
        subDescription="Available to customers on participating TV providers."
      />
      <YesCard title="Forgot Password">
        <p className="pts-n-db-20 mb-5">
          Enter the email associated with this account to receive an email with a link to reset
          your password <b>(please check your spam folder as well for our email). </b>
        </p>
        <div className="pt-4">
          <Formik
            initialValues={initialValues}
            validationSchema={emailConfirmSchema}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, isValid }) => (
              <Form>
                <div className="form-group">
                  <label
                    htmlFor="email"
                    className={
                      touched.email && errors.email
                        ? 'is-invalid form-label'
                        : 'color-dark form-label'
                    }
                  >
                    Email Address
                  </label>
                  <Field
                    type="text"
                    name="email"
                    disabled={!!isEmailURL}
                    placeholder={isEmailURL}
                    className="form-control-lg w-100 italic-placeholder"
                  />
                </div>
                <div className="text-center mt-5">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block w-50 m-auto"
                    disabled={!isValid || isEmailConfirmLoading}
                  >
                    {isEmailConfirmLoading ? 'Please wait...' : 'SUBMIT'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className="text-center mt-4">
          <Link to="/" className="link pts-b-db-16">
            Back to login
          </Link>
        </div>
      </YesCard>
    </>
  );
};

export default EmailConfirm;
