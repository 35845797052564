import React from 'react';
import './SubscribeToGotham.scss';
import { useSelector } from 'react-redux';
import { history } from '../../../redux/configureStore';
import BackArrow from '../../../../src/assets/svgs/Back_Arrow.svg';

const SubscribeToGotham = () => {
  const { remoteConfig, localization } = useSelector(state => state.app.watch);

  return (
    <div className="subscribe-to-gotham">
      <div className="header">
        <button
          className="back-button"
          onClick={() => {
            history.push({
              pathname: '/',
              search: window.location.search
            });
          }}
        >
          <img src={BackArrow} alt="Back" className="back-button-image" />
          Back
        </button>
      </div>

      <div className="content">
        <div className="title" style={{ marginTop: '30px' }}>
          {localization?.subscribeNewScreen?.subscribeToYes.en}
        </div>

        <div className="logo-container">
          <img
            src={remoteConfig?.appSunset?.yesGothamLogoUrl}
            alt="YES Network"
            className="yes-logo"
          />
        </div>

        <p className="description">
          <span className="bold-text">
            {localization?.subscribeNewScreen?.phase2SubscribeDescText.en}
          </span>
          <br />
          {localization?.subscribeNewScreen?.phase2DownloadDescText.en}
          <br />
          {localization?.subscribeNewScreen?.SeeText.en}{' '}
          {remoteConfig?.contactLinks?.sunsetFAQURL ? (
            <a href={remoteConfig.contactLinks.sunsetFAQURL} className="faq-link">
              {localization?.subscribeNewScreen?.FAQText.en}
            </a>
          ) : (
            <span className="faq-link">{localization?.subscribeNewScreen?.FAQText.en}</span>
          )}{' '}
          {localization?.subscribeNewScreen?.detailedDescText.en}
        </p>

        <button
          className="download-button"
          onClick={() => {
            window.open(remoteConfig?.contactLinks?.sunsetStoreRedirectURL, '_blank');
          }}
        >
          {localization?.subscribeNewScreen?.btnText.en}
        </button>
      </div>
    </div>
  );
};

export default SubscribeToGotham;
