import { get } from 'lodash';
import { put, takeLatest, call } from 'redux-saga/effects';
import request from 'utils/api';
// import { toastErrorNotify } from 'pages/toast/redux/actions';
import { getSessionToken, setSessionToken } from 'utils/localStorage';
import * as CONSTANTS from './constants';
import { updateUserMetadataSuccess, updateUserMetadataError } from './actions';

function* updateUserMetadata(action) {
  try {
    const { sessionToken: localSessionToken } = yield call(
      request,
      'istreamPlant',
      '/v2/accounts/me/mvpd_user_metadata',
      'post',
      action.payload,
      getSessionToken()
    );
    setSessionToken(localSessionToken);
    yield put(updateUserMetadataSuccess());
  } catch (err) {
    const error = get(err, 'response', err);
    console.log(error);
    yield put(updateUserMetadataError());
  }
}

export default function* mvpdSelectSaga() {
  yield takeLatest(CONSTANTS.UPDATE_USER_METADATA_REQUEST, updateUserMetadata);
}
