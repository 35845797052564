import React from 'react';

const PageLoading = () => (
  <div>
    <div className="full-spinner-container">
      <div className="spinner-border text-primary" role="status" />
    </div>
  </div>
);

export default PageLoading;
