import * as CONSTANTS from './constants';

/**
 * User Register
 * @param {object} data
 * @param {boolean} authFlow
 */
export const toastSuccessNotify = message => ({
  type: CONSTANTS.TOAST_SUCCESS_NOTIFY,
  payload: message
});

export const toastErrorNotify = message => ({
  type: CONSTANTS.TOAST_ERROR_NOTIFY,
  payload: message
});
