import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './BrowserNotificationChecker.scss';
import { checkBrowserNotifications } from '../../utils/checkBrowserNotifications';

function BrowserNotificationChecker() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  useEffect(() => {
    const bnStatus = checkBrowserNotifications();
    if (bnStatus.status !== 'granted') setShow(true);
  }, []);

  return (
    <div id="browserNotificationWarning">
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>Your browser notifications are currently turned off.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            To receive the latest game, Favorite Player and Favorite Team news, you must first
            enable your browser to display these notifications. Once you have enabled browser
            notifications please refresh the page.
          </p>
          {checkBrowserNotifications().instructions && (
            <Button
              href={checkBrowserNotifications().instructions}
              target="_blank"
              variant="primary"
            >
              Take me to the instructions
            </Button>
          )}

          <br />
          <Button variant="secondary" onClick={handleClose}>
            I&apos;ll do this later
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default BrowserNotificationChecker;
