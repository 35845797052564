import React, { useEffect, useRef } from 'react';
import './LandingPage.scss';
import '../../styles/_buttons.scss';
import { useSelector, useDispatch } from 'react-redux';
import { setRegCode, setUser } from 'utils/localStorage';
import { subscribePrice } from 'utils/consts';
import useAnalyticsScreenTracking from 'hooks/useAnalyticsScreenTracking';
import { segmentEvent } from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import ResetPasswordNotification from '../../pages/auth/Signin/ResetPasswordNotiification';
import { onAuthenticationStatus } from '../app/MVPDSelect/redux/actions';
import { history } from '../../redux/configureStore';

const LandingPage = () => {
  const videoRef = useRef(null);
  const baseUrl = process.env.PUBLIC_URL;
  const { isMac } = useSelector(state => state.auth);
  const { remoteConfig = {}, localization = {} } = useSelector(state => state.app.watch || {});
  const dispatch = useDispatch();
  const userHasBeenNotified = localStorage.getItem('reset_password_notified');
  const isMonthlyPromo = remoteConfig?.subscriptionScreens?.promoPricing?.monthly;
  const isYearlyPromo = remoteConfig?.subscriptionScreens?.promoPricing?.annually;
  const promoMonthlyPrice = remoteConfig?.subscriptionScreens?.promoPricing?.monthlyPromo;
  const promoYearlyPrice = remoteConfig?.subscriptionScreens?.promoPricing?.annualPromo;
  const regularMonthlyPrice = remoteConfig?.subscriptionScreens?.promoPricing?.monthlyRegular;
  const regularYearlyPrice = remoteConfig?.subscriptionScreens?.promoPricing?.annualRegular;

  let monthlyPrice = subscribePrice;
  let annuallyPrice;
  if (isMonthlyPromo) {
    monthlyPrice = promoMonthlyPrice;
  }
  if (!isMonthlyPromo && regularMonthlyPrice) {
    monthlyPrice = regularMonthlyPrice;
  }
  if (isYearlyPromo) {
    annuallyPrice = promoYearlyPrice;
  }
  if (!isYearlyPromo && regularYearlyPrice) {
    annuallyPrice = regularYearlyPrice;
  }

  useAnalyticsScreenTracking('Lanuch Options', 'Main Screen', '/');

  const tvProviderSelect = () => {
    setUser('tve');
    setRegCode('undefined');
    dispatch(onAuthenticationStatus(0));
    history.push({
      pathname: '/watch',
      search: window.location.search
    });
    segmentEvent('Login Button Clicked', {});
  };

  console.log('remoteConfig', remoteConfig?.isPhase1Enabled);

  const onSubscribeSelect = () => {
    setUser('dtc');
    setRegCode('undefined');
    history.push({
      pathname: remoteConfig?.isPhase1Enabled ? '/subscribeToGotham' : '/register',
      search: window.location.search
    });
    segmentEvent('Subscription Button Clicked', {});
  };

  const onLoginSelect = () => {
    setUser('dtc');
    setRegCode('undefined');
    history.push({
      pathname: '/login',
      search: window.location.search
    });
    segmentEvent('Login Button Clicked', {});
  };

  useEffect(() => {
    if (remoteConfig && videoRef.current) {
      videoRef.current.load(); // Load the new video source from config
      videoRef.current
        .play()
        .catch(error => console.error('Error attempting to play video:', error));
    }
  }, [remoteConfig]);

  return (
    <>
      {remoteConfig?.resetEmailToggle &&
        (!userHasBeenNotified || userHasBeenNotified === null) && <ResetPasswordNotification />}
      <div className="landing_page">
        <video
          autoPlay
          muted
          id="landingPageVideo"
          poster={remoteConfig?.dtcLaunchScreen?.fallbackURL}
          ref={videoRef}
          onEnded={() => {
            if (videoRef.current) {
              videoRef.current.play();
            }
          }}
        >
          <source src={remoteConfig?.dtcLaunchScreen?.videoURL} type="video/mp4" />
        </video>
        <img className="darkMode" src={`${baseUrl}/Gradient.png`} alt="DarkMode" />
        <img className="landingPageImage" src={`${baseUrl}/Side_gradient.png`} alt="gradient-bg" />
        <div className="login_section">
          <div className="TV_login">
            <div className="TV_login_desc">
              {remoteConfig?.isPhase2Enabled ? (
                <div className="logo-container">
                  <img
                    src={remoteConfig?.appSunset?.yesGothamLogoUrl}
                    alt="YES Network"
                    className="yes-logo"
                  />
                </div>
              ) : (
                'Already subscribe to YES Network?'
              )}
            </div>
            {remoteConfig?.isPhase2Enabled ? (
              <div className="phase2-desc">
                <div className="phase2-desc-1">GOTHAM SPORTS is now available!</div>
                <div className="phase2-desc-2">
                  <span className="bold">
                    {localization?.subscribeNewScreen?.phase2SubscribeDescText.en}
                  </span>
                  <br />
                  <span className="regular">
                    {' '}
                    {localization?.subscribeNewScreen?.phase2DownloadDescText.en}
                  </span>
                  <br />
                  <span className="regular">
                    {localization?.subscribeNewScreen?.SeeText?.en}
                    {/* See */}
                    <a href="/faq" className="faq-link" style={{ color: '#fff' }}>
                      {localization?.subscribeNewScreen?.FAQText?.en}
                    </a>{' '}
                    {/* FAQ as a link */}
                    {localization?.subscribeNewScreen?.detailedDescText?.en}{' '}
                    {/* for more information */}
                  </span>
                </div>
              </div>
            ) : (
              <>
                <button
                  className={`${isMac ? 'IOS' : ''} secondary__button`}
                  onClick={tvProviderSelect}
                >
                  LOGIN WITH TV PROVIDER
                </button>
                <div>
                  <button
                    onClick={onLoginSelect}
                    className={`${isMac ? 'IOS' : ''} secondary__button`}
                  >
                    LOGIN WITH YES APP ACCOUNT
                  </button>
                </div>
              </>
            )}
          </div>
          {!remoteConfig?.isPhase2Enabled && <hr />}
          <div className="subscribe_section">
            {!remoteConfig?.isPhase2Enabled && (
              <>
                <div className="subscriber_section_desc">
                  {remoteConfig?.isPhase1Enabled
                    ? 'Subscribe to Yes Network'
                    : `Subscribe now for just $${annuallyPrice}/yr or $${monthlyPrice}/mo`}
                </div>
                <div className="subscriber_section_sub_desc">
                  {!remoteConfig?.isPhase1Enabled && 'Introductory offer.'} Subject to territorial
                  restrictions.
                </div>
              </>
            )}

            <div>
              {!remoteConfig?.isPhase2Enabled ? (
                <button
                  className={`${isMac ? 'IOS' : ''} primary__button`}
                  onClick={onSubscribeSelect}
                >
                  SUBSCRIBE NOW
                </button>
              ) : (
                <button
                  className={`${isMac ? 'IOS' : ''} primary__button uppercase-text`}
                  onClick={() => {
                    window.open(remoteConfig?.contactLinks?.sunsetStoreRedirectURL, '_blank');
                  }}
                >
                  {localization?.subscribeNewScreen?.btnText?.en}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
