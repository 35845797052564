import moment from 'moment';

const isAnyActivePromotions = (plan) => {
  const currentDate = moment().format('MM/DD/YYYY');

  return plan?.[0]?.promoMsg?.some(item => {
    const startDate = item?.startDate?.slice(0, 10);
    const endDate = item?.endDate?.slice(0, 10);
    return currentDate >= startDate && currentDate <= endDate;
  }) || false;
};

export default isAnyActivePromotions;