import React, { useEffect, useState } from 'react';
import Toast from 'react-bootstrap/Toast';
import { useSelector } from 'react-redux';

const ToastNotification = () => {
  const [show, setShow] = useState(false);
  const { errorMessage, successMessage } = useSelector(state => state.toast);

  useEffect(() => {
    if (errorMessage.message || successMessage.message) {
      setShow(true);
    }
  }, [errorMessage, successMessage]);

  return (
    <Toast
      style={{
        position: 'fixed',
        zIndex: '1000',
        right: '6%',
        top: '7%'
      }}
      onClose={() => setShow(false)}
      show={show}
      delay={5000}
      autohide
    >
      <div className="toast-body" data-dismiss="toast">
        {errorMessage.type && (
          <p className="text-danger mb-0">
            <strong className="mr-auto">{errorMessage.type}: </strong>
            {errorMessage.message}
          </p>
        )}
        {successMessage.type && (
          <p className="text-success mb-0">
            <strong className="mr-auto">{successMessage.type}: </strong>
            {successMessage.message}
          </p>
        )}
      </div>
    </Toast>
  );
};

export default ToastNotification;
