import { getUser } from 'utils/localStorage';
import addPropertyIfTruthy from 'utils/addPropertyIfTruthy';

/* eslint-disable max-len */
const VideoPlaybackStarted = 'Video Playback Started';
const VideoPlaybackPaused = 'Video Playback Paused';
const VideoPlaybackResumed = 'Video Playback Resumed';
const VideoPlaybackBufferStarted = 'Video Playback Buffer Started';
const VideoPlaybackBufferCompleted = 'Video Playback Buffer Completed';
const VideoPlaybackSeekStarted = 'Video Playback Seek Started';
const VideoPlaybackSeekCompleted = 'Video Playback Seek Completed';
const VideoPlaybackCompleted = 'Video Playback Completed';
const VideoAdBreakStarted = 'Video Ad Break Started';
const VideoAdBreakCompleted = 'Video Ad Break Completed';
const VideoAdStarted = 'Video Ad Started';
const VideoAdSkipped = 'Video Ad Skipped';
const VideoAdCompleted = 'Video Ad Completed';
const VideoPlaybackError = 'Video Playback Error';
const VideoContentStarted = 'Video Content Started';
const VideoContentCompleted = 'Video Content Completed';
const VideoContentPlaying = 'Video Content Playing';
const VideoQualityUpdated = 'Video Quality Updated';
const VideoTimeChanged = 'Video Time Changed';
const ContentLoadError = 'Content Load Error';
const StatsClicked = 'Stats Started';
const WatchPartyClicked = 'Watch Party Started';
const PickNPlayLiveClicked = 'Pick N Play Live Started';
const VideoStartSession = 'Video Start Session';
const Platform = 'web';

const formatSegmentIdentifyData = (userId, contact, subscription) => {
  const subscriptionData = {};

  if (subscription) {
    subscriptionData.subscription_type = subscription?.subscriptionType || '';
    subscriptionData.subscription_start_date = subscription?.startDate
      ? subscription.startDate
      : '';
    subscriptionData.subscription_period_unit = subscription?.subscriptionType || '';
    subscriptionData.subscription_status = subscription?.status || '';

    subscriptionData.subscription_end_date = subscription?.validityTill
      ? subscription.validityTill
      : '';
    subscriptionData.subscription_billing_platform = subscription?.paymentMethod || '';
    subscriptionData.subscription_date_cancelled = subscription?.subscriptionDateCancelled || '';
  }
  return {
    user_id: userId,
    username: contact?.userName,
    email: contact?.email,
    first_name: contact?.firstName,
    last_name: contact?.lastName,
    birthdate: contact?.dateOfBirth ? new Date(contact?.dateOfBirth).toISOString() : '',
    gender: contact?.gender || '',
    registration_date: contact?.contactCreateDate
      ? new Date(contact?.contactCreateDate).toISOString()
      : '',
    signed_up_via: contact?.socialLoginType ? contact?.socialLoginType : 'email',
    ...subscriptionData
  };
};

const SubscriptionCancelled = reason => {
  window.analytics.track('Subscription Cancelled', {
    reason_given: reason
  });
};

const identify = (userId, traits) => {
  window.analytics.identify(userId, traits);
};

const SubscriptionStarted = (
  billingPlatForm,
  price,
  type,
  startDate,
  endDate,
  serviceType,
  subscriptionId,
  referralCode
) => {
  const properties = {
    path: getUser(),
    platform: Platform,
    billing_platform: billingPlatForm || '',
    end_date: endDate || '',
    price: price || '',
    start_date: startDate || '',
    subscription_id: subscriptionId || '',
    type: type || '',
    winback: '',
    service_type: serviceType || ''
  };
  // Use the helper function to conditionally add the referral_code
  addPropertyIfTruthy(properties, 'referral_code', referralCode);
  window.analytics.track('Subscription Started', properties);

};

const segmentEvent = (eventName, properties) => {
  // Define default properties
  const defaultProperties = { path: getUser(), platform: Platform };
  const eventProperties = { ...defaultProperties, ...properties };

  window.analytics.track(eventName, eventProperties);
  console.info(eventName, eventProperties);
};

export {
  formatSegmentIdentifyData,
  SubscriptionCancelled,
  SubscriptionStarted,
  segmentEvent,
  identify,
  VideoPlaybackResumed,
  VideoPlaybackPaused,
  VideoPlaybackStarted,
  VideoPlaybackBufferStarted,
  VideoPlaybackBufferCompleted,
  VideoPlaybackSeekStarted,
  VideoPlaybackSeekCompleted,
  VideoPlaybackCompleted,
  VideoAdBreakStarted,
  VideoAdBreakCompleted,
  VideoAdStarted,
  VideoAdSkipped,
  VideoAdCompleted,
  VideoPlaybackError,
  VideoContentStarted,
  VideoContentCompleted,
  VideoContentPlaying,
  VideoQualityUpdated,
  ContentLoadError,
  StatsClicked,
  WatchPartyClicked,
  PickNPlayLiveClicked,
  VideoTimeChanged,
  VideoStartSession
};
