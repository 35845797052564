import { get } from 'lodash';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { history } from 'redux/configureStore';
import request from 'utils/api';
import eventAnalytics from 'utils/eventAnalytics';
import * as braze from '@braze/web-sdk';
import {
  MinLimitAge,
  APIUSER,
  APIPASSWORD,
  CHANNELPARTNERID,
  getWebDeviceId,
  deviceType,
  deviceName
} from 'config';
import log from 'utils/log';
import moment from 'moment-timezone';
import { checkValidToken } from 'utils/CheckTokenValidation';
import {
  getTVCode,
  getRegCode,
  getPlatform,
  getActivationCode,
  getUser
} from 'utils/localStorage';
import {
  formatSegmentIdentifyData,
  segmentEvent
} from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import * as CONSTANTS from './constants';
import { toastErrorNotify, toastSuccessNotify } from '../../toast/redux/actions';
import { onAuthenticationStatus } from '../../app/MVPDSelect/redux/actions';
import {
  registerSuccess,
  updateProfileError,
  signinSuccess,
  signinError,
  getAccountRequest,
  getAccountSuccess,
  getAccountError,
  socialSigninSuccess,
  emailConfirmSuccess,
  emailConfirmError,
  passwordResetSuccess,
  passwordResetError,
  updateProfileSuccess,
  getSubscriptionsSuccess,
  getSubscriptionsError,
  getFavoriteTeamRequest,
  getFavoriteError,
  getFavoriteSuccess,
  getNotificationError,
  getNotificationSuccess,
  getPlayerRequest,
  getPlayerSuccess,
  getPlayerError,
  getQuickplayOAuthTokenRequest,
  registerError,
  setSearchAccountV2ResponseMessage,
  setSearchAccountV2Error,
  setActiveSubscriptionsSuccess,
  setActiveSubscriptionsError,
  updateAccountDetailsSuccess,
  getPackagesSuccess,
  getPackagesError,
  setLinkToTVSucess,
  getPaymentsSuccess,
  getPaymentsError,
  changeServiceSuccess,
  changeServiceError,
  getContinueFromThePlayers,
  setSubscriptionType
} from './actions';
import {
  getGeoLocationRequest,
  getQuickPlayTVEFlatTokenRequest
} from '../../app/DeviceMVPD/redux/actions';
import { notificationSelectRequest } from '../../app/TeamSelect/redux/actions';

let isServiceUpdated = false;
let isNewRegisteredUserAnalyticVar = false;
function* register(action) {
  try {
    const {
      isAllowNewsletter,
      authFlow,
      urlVODId,
      search,
      callback,
      payload: { dob, gender, customerPassword, ...userData }
    } = action;
    const { selectedMVPD, userMetadata } = yield select(state => state.app.mvpdSelect);
    const { userMetadataZip } = yield select(state => state.app.deviceMVPD);
    const tvCode = getTVCode();
    const regCode = getRegCode();
    const platform = getPlatform();
    const activationCode = getActivationCode();

    const data = yield call(request, 'evergent', '/yesnetwork/createUser', 'post', userData);
    const getAccountData = {
      GetContactRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID
      }
    };
    eventAnalytics('event', 'Success');
    yield put(getGeoLocationRequest());
    if (data.CreateUserResponseMessage.responseCode === '1') {
      yield put(registerSuccess(data));
      const segmentEventData = {
        opted_in: isAllowNewsletter,
        referral_code: userData?.CreateUserRequestMessage?.referralCode
      };
      if (getUser() === 'dtc') {
        segmentEventData.signed_up_via = 'email';
      }
      window.analytics.identify(
        data.CreateUserResponseMessage.spAccountID,
        formatSegmentIdentifyData(
          data.CreateUserResponseMessage.spAccountID,
          userData.email,
          userData.email,
          userData.firstName,
          userData.lastName,
          userData.dateOfBirth,
          userData.gender,
          '',
          segmentEventData.signed_up_via
        )
      );
      setTimeout(segmentEvent('Signed Up', segmentEventData), 2000);
      yield put(getContinueFromThePlayers(false));
      isNewRegisteredUserAnalyticVar = true;
      localStorage.setItem('evergantAccessToken', data.CreateUserResponseMessage.accessToken);
      localStorage.setItem('refreshToken', data.CreateUserResponseMessage.refreshToken);
      localStorage.setItem('evergentExpiresIn', data.CreateUserResponseMessage.expiresIn);
      const authTokenRequestData = `client_id=${process.env.REACT_APP_QUICKPLAY_CLIENT_ID}&client_secret=${process.env.REACT_APP_QUICKPLAY_CLIENT_SECRET}&grant_type=client_credentials&audience=edge-service&scope=openid`;
      yield put(getQuickplayOAuthTokenRequest(authTokenRequestData));
      yield put(getAccountRequest(getAccountData, true));
      const query = {
        AddTVESubscriptionRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID,
          mvpdID: selectedMVPD || tvCode,
          encryptedZip: selectedMVPD
            ? selectedMVPD === 'Comcast_SSO'
              ? userMetadata?.encryptedZip
              : userMetadata?.zip
            : userMetadataZip
        }
      };
      if (selectedMVPD || tvCode) {
        yield put(getQuickPlayTVEFlatTokenRequest(query, true));
      }

      // Braze sdk
      braze.changeUser(data.CreateUserResponseMessage.spAccountID);
      braze.openSession();

      if (isAllowNewsletter) {
        yield put(
          notificationSelectRequest({
            AddOrRemoveFavouritesRequestMessage: {
              apiUser: APIUSER,
              apiPassword: APIPASSWORD,
              channelPartnerID: CHANNELPARTNERID,
              favouriteType: 'notificationTags',
              notificationTagsToAdd: ['NEWSLETTER'],
              notificationTagsToRemove: []
            }
          })
        );
      }
      // set linktoTV as sucess
      yield put(setLinkToTVSucess());
      // this condition is put to check all value of regCode as regCode value change due to cacheing.
      if (
        authFlow === 'user' &&
        (regCode === null ||
          regCode === 'undefined' ||
          regCode === 'null' ||
          regCode === undefined)
      ) {
        if (localStorage.getItem('userType') === 'tve') {
          history.push({
            pathname: urlVODId ? `/watch/${urlVODId}` : '/watch',
            search
          });
        } else {
          history.push({
            pathname: '/CreditCardPayment',
            search
          });
        }
      } else {
        history.push({
          pathname: `/authenticate/${regCode}/${platform}/${activationCode}`,
          search
        });
      }
    } else if (data.CreateUserResponseMessage.failureMessage[0].errorCode === 'eV1062') {
      eventAnalytics('error', 'This email belongs to an existing account.');
      yield put(
        registerError({
          type: 'Register Error',
          message: 'This email belongs to an existing account.'
        })
      );
      yield callback();
    } else {
      eventAnalytics('error', data?.CreateUserResponseMessage.failureMessage[0].errorMessage);
      yield put(
        registerError({
          type: 'Register Error',
          message: data?.CreateUserResponseMessage.failureMessage[0].errorMessage
        })
      );
      yield put(
        toastErrorNotify({
          type: 'Register Error:',
          message: data?.CreateUserResponseMessage.failureMessage[0].errorMessage
        })
      );
    }
  } catch (err) {
    console.log('Registration api ', err);
  }
}

function* updateProfile(action) {
  try {
    const {
      isChangePassword,
      changePasswordRequest,
      deviceId,
      authFlow,
      urlVODId,
      search,
      isAllowNewsletter,
      callback
    } = action;
    const evergentAccessToken = yield checkValidToken();
    if (isChangePassword) {
      const data = yield call(
        request,
        'evergent',
        '/yesnetwork/changePassword',
        'post',
        changePasswordRequest,
        evergentAccessToken
      );
      if (data.ChangePasswordResponseMessage.responseCode !== '1') {
        yield put(
          toastErrorNotify({
            type: 'Change Password Error:',
            message: data?.ChangePasswordResponseMessage.failureMessage[0].errorMessage
          })
        );
      }
    }
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/updateProfile',
      'post',
      action.data,
      evergentAccessToken
    );

    if (data.UpdateProfileResponseMessage.responseCode === '1') {
      yield put(updateProfileSuccess(data));
      const getAccountData = {
        GetContactRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID
        }
      };
      const getContactResponseData = yield call(
        request,
        'evergent',
        '/yesnetwork/getContact',
        'post',
        getAccountData,
        evergentAccessToken
      );
      yield put(getAccountSuccess(getContactResponseData.GetContactResponseMessage));
      if (getContactResponseData.GetContactResponseMessage.responseCode === '1') {
        window.analytics.identify(
          getContactResponseData.GetContactResponseMessage.spAccountID,
          formatSegmentIdentifyData(
            getContactResponseData.GetContactResponseMessage.spAccountID,
            getContactResponseData.GetContactResponseMessage.contactMessage[0],
            null
          )
        );
      }
      eventAnalytics('event', 'Success');
      if (isAllowNewsletter) {
        yield put(
          notificationSelectRequest({
            AddOrRemoveFavouritesRequestMessage: {
              apiUser: APIUSER,
              apiPassword: APIPASSWORD,
              channelPartnerID: CHANNELPARTNERID,
              favouriteType: 'notificationTags',
              notificationTagsToAdd: ['NEWSLETTER'],
              notificationTagsToRemove: []
            }
          })
        );
      }
      if (action?.data?.UpdateProfileRequestMessage?.password && callback) {
        yield callback();
      } else if (authFlow === 'user') {
        history.push({
          pathname: urlVODId ? `/watch/${urlVODId}` : '/watch',
          search
        });
      } else {
        history.push({
          pathname: `/authenticate/${deviceId}`,
          search
        });
      }
    } else if (data.UpdateProfileResponseMessage.failureMessage[0].errorCode === 'eV1062') {
      eventAnalytics('error', 'This email belongs to an existing account. Please go to sign in.');
      yield put(
        updateProfileError({
          type: 'UpdateProfile Error',
          message: 'This email belongs to an existing account. Please  go to sign in.'
        })
      );
    } else {
      eventAnalytics('error', data?.UpdateProfileResponseMessage.failureMessage[0].errorMessage);
      yield put(
        toastErrorNotify({
          type: 'UpdateProfile Error:',
          message: data?.UpdateProfileResponseMessage.failureMessage[0].errorMessage
        })
      );
    }
  } catch (err) {
    console.log('UpdateProfile error', err);
  }
}

export function* signin(action) {
  const { selectedMVPD, userMetadata } = yield select(state => state.app.mvpdSelect);
  const { userMetadataZip } = yield select(state => state.app.deviceMVPD);
  const tvCode = getTVCode();
  try {
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/getOAuthAccessTokenv2',
      'post',
      action.data
    );
    const { deviceId, authFlow, urlVODId, search, callback } = action;
    if (data.GetOAuthAccessTokenv2ResponseMessage.responseCode === '1') {
      yield put(signinSuccess(data));
      localStorage.setItem(
        'evergantAccessToken',
        data.GetOAuthAccessTokenv2ResponseMessage.accessToken
      );
      localStorage.setItem('refreshToken', data.GetOAuthAccessTokenv2ResponseMessage.refreshToken);
      localStorage.setItem(
        'evergentExpiresIn',
        data.GetOAuthAccessTokenv2ResponseMessage.expiresIn
      );
      const authTokenRequestData = `client_id=${process.env.REACT_APP_QUICKPLAY_CLIENT_ID}&client_secret=${process.env.REACT_APP_QUICKPLAY_CLIENT_SECRET}&grant_type=client_credentials&audience=edge-service&scope=openid`;

      yield put(getQuickplayOAuthTokenRequest(authTokenRequestData));
      const query = {
        AddTVESubscriptionRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID,
          mvpdID: selectedMVPD || tvCode,
          encryptedZip: selectedMVPD
            ? selectedMVPD === 'Comcast_SSO'
              ? userMetadata?.encryptedZip
              : userMetadata?.zip
            : userMetadataZip
        }
      };
      if (selectedMVPD || tvCode) {
        const isSelectProvider = true;
        yield put(getQuickPlayTVEFlatTokenRequest(query, isSelectProvider));
      } else {
        const isSelectProvider = false;
        yield put(getQuickPlayTVEFlatTokenRequest('', isSelectProvider));
      }
      const getAccountData = {
        GetContactRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID
        }
      };
      const evergentAccessToken = yield checkValidToken();
      const getContactResponseData = yield call(
        request,
        'evergent',
        '/yesnetwork/getContact',
        'post',
        getAccountData,
        evergentAccessToken
      );
      yield put(getAccountSuccess(getContactResponseData.GetContactResponseMessage));
      // segment analytics for logged in user
      if (getContactResponseData.GetContactResponseMessage.responseCode === '1') {
        window.analytics.identify(
          getContactResponseData.GetContactResponseMessage.spAccountID,
          formatSegmentIdentifyData(
            getContactResponseData.GetContactResponseMessage.spAccountID,
            getContactResponseData.GetContactResponseMessage.contactMessage[0],
            null
          )
        );
        const segmentEventData = {};
        if (getUser() === 'dtc') {
          segmentEventData.signed_up_via = 'email';
        }
        setTimeout(segmentEvent('Signed In', segmentEventData), 2000);
      }
      yield put(
        getFavoriteTeamRequest({
          GetFavouritesRequestMessage: {
            apiUser: APIUSER,
            apiPassword: APIPASSWORD,
            channelPartnerID: CHANNELPARTNERID
          }
        })
      );
      yield put(
        getPlayerRequest({
          GetFavouritesRequestMessage: {
            apiUser: APIUSER,
            apiPassword: APIPASSWORD,
            channelPartnerID: CHANNELPARTNERID
          }
        })
      );

      const getActiveSubscriptionData = {
        GetActiveSubscriptionsRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID
        }
      };

      const subscriptionResponse = yield call(
        request,
        'evergent',
        '/yesnetwork/getActiveSubscriptions',
        'post',
        getActiveSubscriptionData,
        evergentAccessToken
      );

      // Braze sdk
      if (getContactResponseData.GetContactResponseMessage.responseCode === '1') {
        braze.changeUser(getContactResponseData.GetContactResponseMessage.spAccountID);
      }
      braze.openSession();

      const hasActiveSubscription = () =>
        !!subscriptionResponse.GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.filter(
          sub => sub.status === 'Active' || sub.status === 'Final Bill'
        ).length;
      const subscriptionType = subscriptionResponse?.GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.[0]?.subscriptionType;
      yield put(setActiveSubscriptionsSuccess(subscriptionResponse?.GetActiveSubscriptionsResponseMessage));
      yield put(setSubscriptionType(subscriptionType));
      const maxYear = parseInt(moment().subtract(MinLimitAge, 'y').format('YYYY'), 10);
      const userDOB =
        getContactResponseData.GetContactResponseMessage.contactMessage[0].dateOfBirth;
      const validDOB = moment(new Date().setFullYear(maxYear));
      const convertUserDOB = moment(userDOB).format('DD MMM YYYY');
      if (convertUserDOB && validDOB.diff(userDOB) < 0) {
        log('logout');
        yield put(
          signinError({
            type: 'Invalid age',
            message: 'You must be at least 13 to login to YES'
          })
        );
        yield put(
          toastErrorNotify({
            type: 'Invalid age',
            message: 'You must be at least 13 to login to YES'
          })
        );
        return;
      }
      if (callback) {
        yield callback();
      }
      if (authFlow === 'user' && action.winback === false) {
        history.push({
          pathname: urlVODId ? `/watch/${urlVODId}` : '/watch',
          search
        });
      } else if (
        authFlow === 'user' &&
        action.winback === true &&
        subscriptionResponse.GetActiveSubscriptionsResponseMessage
      ) {
        if (hasActiveSubscription()) {
          history.push({
            pathname: urlVODId ? `/watch/${urlVODId}` : '/watch',
            search
          });
        } else {
          history.push({
            pathname: '/CreditCardPayment',
            search: '?reload=true'
          });
        }
      } else {
        history.push({
          pathname: `/authenticate/${deviceId}`,
          search
        });
      }
    } else {
      if (callback) {
        yield callback();
      }
      switch (data.GetOAuthAccessTokenv2ResponseMessage.failureMessage[0].errorCode) {
        case 'eV2134':
          eventAnalytics('error', 'Email or password is incorrect.');
          yield put(
            signinError({
              type: 'Signin Error',
              message: 'Email or password is incorrect.'
            })
          );
          break;
        case 'eV4492':
          eventAnalytics('error', 'Your login has been blocked.');
          yield put(
            signinError({
              type: 'Signin Error',
              message: 'Your login has been blocked.'
            })
          );
          break;
        case 'eV2327':
          eventAnalytics('error', 'No account found with the given details.');
          yield put(
            signinError({
              type: 'Signin Error',
              message: 'No account found with the given details.'
            })
          );

          if (getUser() === 'tve') {
            history.push({
              pathname: '/register'
            });
          }
          break;
        default:
          eventAnalytics(
            'error',
            data.GetOAuthAccessTokenv2ResponseMessage.failureMessage[0].errorMessage
          );
          yield put(
            signinError({
              type: 'Signin Error',
              message: data.GetOAuthAccessTokenv2ResponseMessage.failureMessage[0].errorMessage
            })
          );
          if (getUser() === 'tve') {
            history.push({
              pathname: '/register'
            });
          }
          break;
      }
    }
  } catch (err) {
    console.log('SignIn err', err);
  }
}

function* getOAuthToken(action) {
  try {
    const authTokenRespData = yield call(
      request,
      'quickplay',
      '/oauth2/token',
      'post',
      action.data,
      '',
      true
    );

    if (authTokenRespData.access_token) {
      localStorage.setItem('quickPlayOAuthToken', authTokenRespData.access_token);
      localStorage.setItem('quickplayExpiresIn', authTokenRespData.expires_in);
      yield put(getGeoLocationRequest());
    } else {
      console.log('quickplay oauth token error');
      console.log('quickplay oauth token error ', authTokenRespData.error);
      console.log('quickplay oauth token error description', authTokenRespData.error_description);
    }
  } catch (err) {
    console.log('quickplay OAuth Token error', err);
  }
}

function* getAccount(action) {
  try {
    const evergentAccessToken = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/getContact',
      'post',
      action.data,
      evergentAccessToken
    );
    if (data.GetContactResponseMessage.responseCode === '1') {
      yield put(getAccountSuccess(data.GetContactResponseMessage));
      if (isNewRegisteredUserAnalyticVar === true) {
        window.analytics.identify(
          data.GetContactResponseMessage.spAccountID,
          formatSegmentIdentifyData(
            data.GetContactResponseMessage.spAccountID,
            data.GetContactResponseMessage.contactMessage[0],
            null
          )
        );
        if (action.socialSignInProvider) {
          setTimeout(
            segmentEvent('Signed In', { signed_up_via: action.socialSignInProvider }),
            2000
          );
        }
        isNewRegisteredUserAnalyticVar = false;
      }
    } else {
      yield put(getAccountError());
    }
  } catch (err) {
    yield put(getAccountError());
  }
}

function* getFavorite(action) {
  try {
    const evergentAccessToken = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/getFavourites',
      'post',
      action.data,
      evergentAccessToken
    );
    if (data.GetFavouritesResponseMessage.responseCode === '1') {
      yield put(getFavoriteSuccess(data.GetFavouritesResponseMessage?.favourites));
      if (typeof data.GetFavouritesResponseMessage?.favourites?.notificationTags !== 'undefined') {
        const optedIn =
          data.GetFavouritesResponseMessage?.favourites?.notificationTags.includes('NEWSLETTER');
        if (action.isUpdatedTeam) {
          window.analytics.identify({
            favorite_team: data.GetFavouritesResponseMessage?.favourites?.favoriteTeams,
            marketing_opt_in: optedIn ? 'opted_in' : 'opted_out'
          });
        }
      }
    } else {
      eventAnalytics('error', 'Authentication Failed');
      yield put(
        getFavoriteError({
          type: 'Get Favorite Error',
          message: 'Authentication Failed'
        })
      );
    }
  } catch (err) {
    console.log('Get Favorite api error', err);
  }
}

function* getNotification(action) {
  try {
    const evergentAccessToken = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/getFavourites',
      'post',
      action.data,
      evergentAccessToken
    );
    if (data.GetFavouritesResponseMessage.responseCode === '1') {
      yield put(
        getNotificationSuccess(data.GetFavouritesResponseMessage?.favourites?.notificationTags)
      );
    } else {
      eventAnalytics('error', 'notification Tags error');
      yield put(
        getNotificationError({
          type: 'Get Notification Error',
          message: 'Notification Tag Error'
        })
      );
    }
  } catch (err) {
    console.log('Get Notification api error', err);
  }
}

function* getFavPlayer(action) {
  try {
    const evergentAccessToken = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/getFavourites',
      'post',
      action.data,
      evergentAccessToken
    );
    if (data.GetFavouritesResponseMessage.responseCode === '1') {
      yield put(getPlayerSuccess(data.GetFavouritesResponseMessage?.favourites?.favoritePlayers));
      if (action.isUpdatedPlayer) {
        window.analytics.identify({
          favorite_players: data.GetFavouritesResponseMessage?.favourites?.favoritePlayers
        });
      }
      if (
        data.GetFavouritesResponseMessage?.favourites?.favoritePlayers &&
        data.GetFavouritesResponseMessage?.favourites?.favoritePlayers.length < 0
      ) {
        yield put(getSubscriptionsSuccess());
      }
    } else {
      eventAnalytics('error', 'Player error');
      yield put(
        getPlayerError({
          type: 'Get Player Error',
          message: 'Player Tag Error'
        })
      );
    }
  } catch (err) {
    console.log('Get Player api error', err);
  }
}

function* socalSignin(action) {
  const { authFlow, deviceId, urlVODId, search, provider, callback } = action;

  const data = yield call(
    request,
    'evergent',
    '/yesnetwork/getOAuthAccessTokenv2',
    'post',
    action.data
  );
  // evergent api implementation

  if (data.GetOAuthAccessTokenv2ResponseMessage.responseCode === '1') {
    eventAnalytics('event', 'Success');

    localStorage.setItem(
      'evergantAccessToken',
      data.GetOAuthAccessTokenv2ResponseMessage.accessToken
    );
    localStorage.setItem('refreshToken', data.GetOAuthAccessTokenv2ResponseMessage.refreshToken);
    localStorage.setItem('evergentExpiresIn', data.GetOAuthAccessTokenv2ResponseMessage.expiresIn);
    yield put(socialSigninSuccess(data));

    const authTokenRequestData = `client_id=${process.env.REACT_APP_QUICKPLAY_CLIENT_ID}&client_secret=${process.env.REACT_APP_QUICKPLAY_CLIENT_SECRET}&grant_type=client_credentials&audience=edge-service&scope=openid`;

    yield put(getQuickplayOAuthTokenRequest(authTokenRequestData));
    isNewRegisteredUserAnalyticVar = true;
    const getAccountData = {
      GetContactRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID
      }
    };
    yield put(getAccountRequest(getAccountData, false, provider));
    yield put(
      getFavoriteTeamRequest({
        GetFavouritesRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID
        }
      })
    );
    yield put(
      getPlayerRequest({
        GetFavouritesRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID
        }
      })
    );
    if (authFlow === 'user') {
      history.push({
        pathname: urlVODId ? `/watch/${urlVODId}` : '/watch',
        search
      });
    } else {
      history.push({
        pathname: `/authenticate/${deviceId}`,
        search
      });
    }
  } else {
    yield callback();
    log('No user attached with this profile');
  }
}

function* emailConfirm(action) {
  const { payload, errorCallback } = action;
  try {
    const result = yield call(
      request,
      'evergent',
      '/yesnetwork/forgotContactPassword',
      'post',
      payload
    );
    if (result?.ForgotContactPasswordResponseMessage?.responseCode === '1') {
      const successMessage = result?.ForgotContactPasswordResponseMessage?.message;
      yield put(toastSuccessNotify({ type: 'Password Reset', message: successMessage }));
      yield put(
        emailConfirmSuccess({ email: payload?.ForgotContactPasswordRequestMessage?.email })
      );
      history.push('/email-sent');
    } else {
      yield put(
        toastErrorNotify({
          type: 'Error',
          message:
            result?.ForgotContactPasswordResponseMessage?.failureMessage[0]?.errorMessage ||
            'Unknown Error'
        })
      );
      yield put(emailConfirmError());
      errorCallback();
    }
  } catch (err) {
    const error = get(err, 'response', err);
    yield put(toastErrorNotify({ type: 'Error', message: error?.data?.errorMessage }));
    yield put(emailConfirmError());
  }
}

function* passwordReset(action) {
  const { payload, errorCallback } = action;
  try {
    const result = yield call(request, 'evergent', '/yesnetwork/resetPassword', 'post', payload);
    if (result?.ResetPasswordResponseMessage?.responseCode === '1') {
      yield put(
        toastSuccessNotify({
          type: 'Password Reset',
          message: result?.ResetPasswordResponseMessage?.message
        })
      );
      yield put(passwordResetSuccess());
      history.push('/password-reset-success');
    } else {
      yield put(passwordResetError());
      yield put(
        toastErrorNotify({
          type: 'Reset Password Error',
          message:
            result?.ResetPasswordResponseMessage?.failureMessage[0]?.errorMessage ||
            'Unknown Error'
        })
      );
      errorCallback();
    }
  } catch (err) {
    const error = get(err, 'response', err);
    yield put(
      toastErrorNotify({ type: 'Reset Password Error', message: error?.data?.errorMessage })
    );
    yield put(passwordResetError());
  }
}

function* signout() {
  try {
    localStorage.removeItem('evergantAccessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('quickPlayOAuthToken');
    localStorage.removeItem('evergentExpiresIn');
    localStorage.removeItem('quickplayExpiresIn');
    localStorage.removeItem('quickPlayTVEFlatTokenExpiresIn');
    localStorage.removeItem('quickPlayTVEFlatToken');
    localStorage.removeItem('regCode');
    localStorage.removeItem('userType');
    yield put(onAuthenticationStatus(0));
  } catch (err) {
    yield put(toastErrorNotify({ type: 'Signout Error', message: 'Unexpected Error' }));
  }
}

function* getSubscriptions(action) {
  try {
    const sdk = yield window.UA;
    if (!sdk?.channel?.optedIn) yield sdk?.register();
    const channel = yield sdk?.getChannel();
    // console.log('channel');
    yield channel?.namedUser?.set(action.payload);
    const list = yield channel?.subscriptions?.list();

    if (list?.length) yield put(getSubscriptionsSuccess());
    // console.log('Subscription Error: Missing List');
    yield put(getSubscriptionsError());
  } catch (err) {
    // console.log('Subscription Error: ', err);
    yield put(getSubscriptionsError());
  }
}

function* searchAccount(action) {
  const { userMetadata } = yield select(state => state.app.mvpdSelect);
  const { isLinkedToTV } = yield select(state => state.auth);
  const { data, deviceId, authFlow, urlVODId, search, callback } = action;
  try {
    const { SearchAccountV2ResponseMessage } = yield call(
      request,
      'evergent',
      '/yesnetwork/searchAccountV2',
      'post',
      data
    );
    yield put(setSearchAccountV2ResponseMessage(SearchAccountV2ResponseMessage));
    if (SearchAccountV2ResponseMessage?.responseCode !== '1' && callback) {
      yield callback();
    }
    yield signin({
      type: CONSTANTS.SIGNIN_REQUEST,
      data: {
        GetOAuthAccessTokenv2RequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID,
          cpCustomerID: decodeURIComponent(SearchAccountV2ResponseMessage?.cpCustomerID),
          sessionExpiryPeriod: '',
          deviceMessage: {
            deviceType,
            deviceName,
            modelNo: '04ries',
            serialNo: getWebDeviceId(),
            userAgent: 'USA'
          }
        }
      },
      authFlow,
      deviceId,
      urlVODId,
      search,
      callback
    });
    if (!isLinkedToTV) {
      yield updateAccountDetails({
        type: CONSTANTS.UPDATE_ACCOUNT_DETAILS,
        data: {
          UpdateAccountDetailsRequestMessage: {
            apiUser: APIUSER,
            apiPassword: APIPASSWORD,
            channelPartnerID: CHANNELPARTNERID,
            parameterMesgs: [
              {
                paramName: 'CPCUSTOMERID',
                paramValue: userMetadata?.userID
              }
            ]
          }
        },
        authFlow,
        deviceId,
        urlVODId,
        search
      });
    }
    yield put(setLinkToTVSucess());
  } catch (err) {
    yield put(setSearchAccountV2Error());
  }
}

function* activeSubscriptions(action) {
  const { callback } = action;
  const getActiveSubscriptionData = {
    GetActiveSubscriptionsRequestMessage: {
      apiUser: APIUSER,
      apiPassword: APIPASSWORD,
      channelPartnerID: CHANNELPARTNERID
    }
  };
  try {
    const evergentAccessToken = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/getActiveSubscriptions',
      'post',
      getActiveSubscriptionData,
      evergentAccessToken
    );
    yield put(setActiveSubscriptionsSuccess(data.GetActiveSubscriptionsResponseMessage));
    yield put(onAuthenticationStatus(1));
    if (isServiceUpdated) {
      const currentActiveSubscription =
        data.GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.filter(
          sub => sub.status === 'Active'
        );
      const segmentIdentity = {
        subscriptionType: currentActiveSubscription[0]?.subscriptionType,
        startDate: new Date(currentActiveSubscription[0]?.startDate).toISOString(),
        status: currentActiveSubscription[0]?.status,
        validityTill: new Date(currentActiveSubscription[0]?.validityTill).toISOString(),
        paymentMethod: currentActiveSubscription[0]?.paymentMethod,
        subscriptionDateCancelled: currentActiveSubscription[0]?.cancellationDate || ''
      };
      window.analytics.identify(formatSegmentIdentifyData(null, null, segmentIdentity));
      isServiceUpdated = false;
    }
    const isSelectProvider = false;
    yield put(getQuickPlayTVEFlatTokenRequest('', isSelectProvider));
    if (callback) {
      yield callback();
    }
  } catch (err) {
    yield put(setActiveSubscriptionsError());
  }
}

function* getPaymentsRequest() {
  const getPaymentsData = {
    GetPaymentMethodsRequestMessage: {
      apiUser: APIUSER,
      apiPassword: APIPASSWORD,
      channelPartnerID: CHANNELPARTNERID
    }
  };
  try {
    const evergentAccessToken = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/getPaymentMethods',
      'post',
      getPaymentsData,
      evergentAccessToken
    );
    if (data.GetPaymentMethodsResponseMessage?.responseCode === '1') {
      yield put(
        getPaymentsSuccess(
          data.GetPaymentMethodsResponseMessage?.PaymentMethods?.filter(
            card => card.isAutoPayment === true
          )[0]
        )
      );
    } else {
      yield put(getPaymentsError());
    }
  } catch (err) {
    yield put(getPaymentsError());
  }
}

function* changeServiceRequest(action) {
  const { oldServiceID, newServiceID } = action.payload;
  const dataPayload = {
    ChangeServiceRequestMessage: {
      apiUser: APIUSER,
      apiPassword: APIPASSWORD,
      channelPartnerID: CHANNELPARTNERID,
      oldServiceID,
      newServiceID
    }
  };
  try {
    const evergentAccessToken = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/changeService',
      'post',
      dataPayload,
      evergentAccessToken
    );
    if (data.ChangeServiceResponseMessage.responseCode === '1') {
      isServiceUpdated = true;
      yield put(changeServiceSuccess(data.ChangeServiceResponseMessage));
    } else {
      yield put(changeServiceError());
    }
  } catch (err) {
    yield put(changeServiceError());
  }
}

function* updateAccountDetails(action) {
  try {
    const token = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/updateAccountDetails',
      'post',
      action.data,
      token
      // getAccessToken()
    );

    const { deviceId, authFlow, urlVODId, search } = action;
    if (data?.UpdateAccountDetailResponseMessage?.responseCode === '1') {
      yield put(updateAccountDetailsSuccess(data));
      const getAccountData = {
        GetContactRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID
        }
      };
      const getContactResponseData = yield call(
        request,
        'evergent',
        '/yesnetwork/getContact',
        'post',
        getAccountData,
        token
        // getAccessToken()
      );
      yield put(getAccountSuccess(getContactResponseData?.GetContactResponseMessage));
      eventAnalytics('event', 'Success');
      if (authFlow === 'user') {
        history.push({
          pathname: urlVODId ? `/watch/${urlVODId}` : '/watch',
          search
        });
      } else {
        history.push({
          pathname: `/authenticate/${deviceId}`,
          search
        });
      }
    }
  } catch (err) {
    console.log('updateAccountDetails error', err);
  }
}

function* getPackages(action) {
  const zones = action?.payload?.zone && action.payload.zone.toLowerCase() !== 'unavailable' ? action?.payload?.zone.split(',') : ['yankees', 'nets'];
  const getPackagesData = {
    GetPkgsAndProdsWithPromosRequestMessage: {
      apiUser: APIUSER,
      apiPassword: APIPASSWORD,
      channelPartnerID: CHANNELPARTNERID,
      dmaID: '001',
      returnPkgAppChannels: true,
      returnPackagesHavingProduct: 'T',
      packageItems: zones
    }
  };

  try {
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/getPkgsAndProdsWithPromos',
      'post',
      getPackagesData
    );

    yield put(getPackagesSuccess(data));
  } catch (err) {
    yield put(
      toastErrorNotify({
        type: 'getPackages Error',
        message:
          'Oops, there was a problem accessing the subscription purchase environment. Please try again later.'
      })
    );
    yield put(getPackagesError());
  }
}

export default function* authSaga() {
  yield takeLatest(CONSTANTS.REGISTER_REQUEST, register);
  yield takeLatest(CONSTANTS.GET_QUICKPLAY_OAUTH_TOKEN_REQUEST, getOAuthToken);
  yield takeLatest(CONSTANTS.UPDATE_PROFILE_REQUEST, updateProfile);
  yield takeLatest(CONSTANTS.SIGNIN_REQUEST, signin);
  yield takeLatest(CONSTANTS.GET_ACCOUNT_REQUEST, getAccount);
  yield takeLatest(CONSTANTS.GET_FAVORITE_REQUEST, getFavorite);
  yield takeLatest(CONSTANTS.GET_NOTIFICATION_REQUEST, getNotification);
  yield takeLatest(CONSTANTS.GET_PLAYER_REQUEST, getFavPlayer);
  yield takeLatest(CONSTANTS.SIGN_OUT, signout);
  yield takeLatest(CONSTANTS.SOCIAL_SIGNIN_REQUEST, socalSignin);
  yield takeLatest(CONSTANTS.EMAIL_CONFIRM_REQUEST, emailConfirm);
  yield takeLatest(CONSTANTS.PASSWORD_RESET_REQUEST, passwordReset);
  yield takeLatest(CONSTANTS.GET_SUBSCRIPTIONS_REQUEST, getSubscriptions);
  yield takeLatest(CONSTANTS.SEARCH_ACCOUNT_REQUEST, searchAccount);
  yield takeLatest(CONSTANTS.GET_ACTIVE_SUBSCRIPTIONS, activeSubscriptions);
  yield takeLatest(CONSTANTS.UPDATE_ACCOUNT_DETAILS, updateAccountDetails);
  yield takeLatest(CONSTANTS.GET_PACKAGES, getPackages);
  yield takeLatest(CONSTANTS.GET_PAYMENTS_REQUEST, getPaymentsRequest);
  yield takeLatest(CONSTANTS.CHANGE_SERVICE_REQUEST, changeServiceRequest);
}
