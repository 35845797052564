import React, { useState, useEffect } from 'react';
import request from 'utils/api';
import { getAccessToken } from 'utils/localStorage';
import yankeesLogo from 'assets/svgs/NYY_Logo.svg';
import netsLogo from 'assets/images/NETS_Logo.png';
import nycfcLogo from 'assets/images/NYCFC_Logo.png';
import libertyLogo from 'assets/svgs/LIBERTY_Logo.svg';
import yankeesLogoActive from 'assets/svgs/NYY_Logo_Active.svg';
import getPlayerHeadshot from 'utils/getPlayerHeadshot';
import { useDispatch, useSelector } from 'react-redux';
import { getContinueFromThePlayers } from '../../../auth/redux/actions';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import { playerSelectRequest } from '../../TeamSelect/redux/actions';
import UserSettings from 'services/UserSettings';
import handlePlayerSelection from '../../../../utils/playersFunctionality';
import { segmentEvent } from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import { getPlayerSuccess } from '../../../auth/redux/actions';

const SelectFavouritePlayers = ({ favoriteTeams, setIsEditingPlayers, isOnboarding }) => {
  const { remoteConfig, players } = useSelector(state => state.app.watch);
  const { isMac } = useSelector(state => state.auth);
  const [selectedTeam, setSelectedTeam] = useState(
    remoteConfig?.yesTeams?.find(team => team.id === favoriteTeams[0])?.airshipName
  );
  const [apiFavPlayers, setApiFavPlayers] = useState([]);
  const [playersToAdd, setPlayersToAdd] = useState([]);
  const [playersToRemove, setPlayersToRemove] = useState([]);
  const [followedPlayers, setFollowedPlayers] = useState([]);

  const dispatch = useDispatch();

  const getLogo = id => {
    switch (id) {
      case 1:
        return selectedTeam === 'NYY' ? yankeesLogoActive : yankeesLogo;
      case 2:
        return netsLogo;
      case 3:
        return nycfcLogo;
      default:
        return libertyLogo;
    }
  };

  const getFavoritePlayerData = async () => {
    const data = {
      GetFavouritesRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID
      }
    };
    try {
      const getFavoritePlayer = await request(
        'evergent',
        '/yesnetwork/getFavourites',
        'post',
        data,
        getAccessToken()
      );
      if (getFavoritePlayer.GetFavouritesResponseMessage.favourites.favoritePlayers) {
        setApiFavPlayers(
          getFavoritePlayer.GetFavouritesResponseMessage.favourites.favoritePlayers
        );
        setFollowedPlayers(
          getFavoritePlayer.GetFavouritesResponseMessage.favourites.favoritePlayers
        );
      }
    } catch (err) {
      console.log('getFavouriteTeam api error', err);
    }
  };

  useEffect(() => {
    getFavoritePlayerData();
  }, []);

  const teamPlayers = players.teams.map(team => {
    switch (selectedTeam) {
      case 'NETS':
        return team.nets;
      case 'LIBERTY':
        return team.liberty;
      case 'NYY':
        return team.yankees;
      default:
        return '';
    }
  });

  useEffect(() => {
    setPlayersToAdd(
      followedPlayers.filter(
        player => !apiFavPlayers.some(apiPlayer => apiPlayer.playerId === player.playerId)
      )
    );
    setPlayersToRemove(
      apiFavPlayers.filter(
        apiPlayer => !followedPlayers.some(player => player.playerId === apiPlayer.playerId)
      )
    );
  }, [followedPlayers, apiFavPlayers]);

  const TeamPlayerList = () => {
    const followedTeamPlayers = [];
    for (const team of players.teams) {
      for (const [, players] of Object.entries(team)) {
        const filterFollowedTeamPlayersList = players
          .filter(player =>
            followedPlayers.some(followedPlayer => followedPlayer.playerId === player.player_id)
          )
          .map(player => player.player_name);
        followedTeamPlayers.push(...filterFollowedTeamPlayersList);
      }
    }
    UserSettings.addFavPlayers(followedTeamPlayers);
    segmentEvent('Favorite Players Picked', { players_selected: followedTeamPlayers });
  };

  const submitPlayers = () => {
    const data = {
      AddOrRemoveFavouritesRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        favouriteType: 'favoritePlayers',
        favoritePlayersToAdd: playersToAdd,
        favoritePlayersToRemove: playersToRemove
      }
    };
    dispatch(playerSelectRequest(data));
    if (data.GetFavouritesResponseMessage) {
      dispatch(getPlayerSuccess(data.GetFavouritesResponseMessage.favourites.favoritePlayers));
    }
    dispatch(getContinueFromThePlayers(true));
    TeamPlayerList();
  };
  return (
    <>
      <div className="d-flex justify-content-center">
        {remoteConfig?.yesTeams?.map(
          team =>
            favoriteTeams.includes(team.id) &&
            team.id !== 3 &&
            team.id !== 4 && (
              <div
                className={`${
                  team.airshipName === selectedTeam ? 'selected-team' : 'team'
                } d-flex justify-content-center align-items-center`}
                onClick={() => {
                  setSelectedTeam(team.airshipName);
                }}
                key={team.id}
              >
                <img className="team-logo" src={getLogo(team.id)} alt="team-logo" />
                {team?.name}
              </div>
            )
        )}
      </div>
      <div className="favourite-player-card d-flex flex-column align-items-center">
        <div className="player-list-select">
          {teamPlayers?.map(players =>
            players?.map(player => {
              return (
                <div
                  className="d-flex justify-content-between player-item align-items-center"
                  key={player.player_id}
                >
                  <div className="d-flex align-items-center">
                    <img
                      src={getPlayerHeadshot(player)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.className = 'player-picture-none';
                      }}
                      onLoad={({ currentTarget }) => {
                        currentTarget.className = 'player-picture';
                      }}
                      className="player-picture-none"
                      alt="player-pictures"
                    />
                    <div className="d-flex flex-column">
                      <p className="player-name">{player.player_name.replace(',', ' ')}</p>
                    </div>
                  </div>

                  <div
                    className={`${
                      followedPlayers.some(p => p.playerId === player.player_id)
                        ? 'subscribe-button-active'
                        : 'subscribe-button'
                    } subscribe-button d-flex justify-content-center align-items-center`}
                    onClick={() => {
                      handlePlayerSelection(player, setFollowedPlayers, followedPlayers);
                    }}
                  >
                    {followedPlayers.some(p => p.playerId === player.player_id)
                      ? 'Unfollow'
                      : 'Follow'}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      <div
        className={
          isOnboarding
            ? 'btn-done-section-onboarding d-flex justify-content-around align-items-center flex-column'
            : 'btn-done-section  d-flex justify-content-around align-items-center flex-column'
        }
      >
        <button
          onClick={() => {
            submitPlayers();
            if (typeof setIsEditingPlayers === 'function') setIsEditingPlayers(false);
          }}
          // disabled={playersToAdd.length === 0 || playersToRemove.length === 0}
          className={`${isMac ? 'IOS' : ''} primary__button`}
        >
          {true ? 'LET’S GO' : 'DONE'}
        </button>
        {isOnboarding && (
          <span
            onClick={() => {
              dispatch(getContinueFromThePlayers(true));
            }}
            className="link-blue pts-b-db-20"
          >
            MAYBE LATER
          </span>
        )}
      </div>
    </>
  );
};

export default SelectFavouritePlayers;
