/* eslint-disable import/no-extraneous-dependencies */
import { getConnectionIp } from 'utils/video';
import { v4 as uuidv4 } from 'uuid';

const APP_NAME = process.env.REACT_APP_NAME;
const APP_BUNDLE_ID = process.env.REACT_APP_CORE_BUNDLE_ID;
const APP_ENV = process.env.REACT_APP_ENV;
const DEVICE_ID_TYPE = '';
const connectionIp = getConnectionIp();

const channelUpdate = {
  linear: '/22157414155/OTTA/tl_live',
  VOD: '/22157414155/OTTA/tl_vod',
  OVERFLOW: '/22157414155/OTTA/tl_live'
};

const generateAdURL = ({ userContact, video, adInfo }) => {
  const adUrl = new URL('https://pubads.g.doubleclick.net/gampad/ads');

  // Init static params
  const adSearchParams = new URLSearchParams();

  const params = {
    env: 'vp',
    gdfp_req: '1',
    unviewed_position_start: '1',
    iu: channelUpdate[video.type],
    tfcd: '0',
    output: 'vast',
    plcmt: '1',
    vpmute: '0',
    sz: '640x480'
  };

  // Append first set of params
  Object.entries(params).forEach(([key, value]) => {
    adSearchParams.append(key, value);
  });

  // Append vid_d after sz
  if (video && video.type === 'VOD') {
    adSearchParams.append('vid_d', video.duration);
  }

  // Append the remaining parameters
  const remainingParams = {
    vpos: 'preroll',
    ad_rule: '0',
    cmsid: '2663951',
    hl: 'en',
    npa: '0',
    pod: '',
    pmnd: '0',
    pmad: '1',
    pmxd: '16000',
    impl: 's',
    wta: '1'
  };

  Object.entries(remainingParams).forEach(([key, value]) => {
    adSearchParams.append(key, value);
  });

  // Dynamic params
  const coorelator = new Date().getTime().toString();
  adSearchParams.set('correlator', coorelator);
  adSearchParams.set('description_url', window.location.href);
  adSearchParams.set('url', window.location.href);
  adSearchParams.set('vid', video.id);
  adSearchParams.set('msid', APP_BUNDLE_ID);
  adSearchParams.set('an', APP_NAME);
  const { rdid } = adInfo;
  adSearchParams.set('rdid', rdid);
  // Publisher provided identifier
  const ppid = userContact.spAccountID || userContact.cpCustomerID;
  adSearchParams.set('ppid', ppid);
  adSearchParams.set('idtype', DEVICE_ID_TYPE);
  // Limit Ad Tracking
  const dnt = adInfo.isLAT;
  adSearchParams.set('is_lat', dnt);
  adSearchParams.set('scor', coorelator);
  const vip = connectionIp;
  adSearchParams.set('vip', vip);

  // custom params
  const customParams = new URLSearchParams();
  customParams.set('stage', APP_ENV === 'DEVELOP' ? 'true' : '');
  customParams.set(
    'channel',
    video.type === 'linear' ? 'primary' : video.type === 'OVERFLOW' ? 'overflow' : ''
  );
  customParams.set('mode', '');
  customParams.set('contentid', video.id);
  customParams.set('sessionid', '');
  customParams.set('device', DEVICE_ID_TYPE);
  customParams.set('rdevid', rdid);
  customParams.set('uservip', vip);
  customParams.set('vid_id', video.id);
  customParams.set('app_bundle', APP_BUNDLE_ID);
  customParams.set('app_name', APP_NAME);
  customParams.set('duration', video.duration);
  customParams.set('pos', 'pre');
  adSearchParams.set('cust_params', customParams.toString());

  adUrl.search = adSearchParams.toString();
  return adUrl;
};

const getAdvertisingConfig = ({
  userContact,
  video,
  adInfo,
  isPreRollVODAdsEnabled,
  isPreRollOverflowAdsEnabled,
  isPreRollLinearAdsEnabled
}) => {
  const checkVODOrOverflow =
    (video.type === 'VOD' && isPreRollVODAdsEnabled) ||
    (video.type === 'OVERFLOW' && isPreRollOverflowAdsEnabled) ||
    (video.type === 'linear' && isPreRollLinearAdsEnabled);
  if (!userContact || !video || !checkVODOrOverflow) return {};

  const adURL = generateAdURL({ userContact, video, adInfo });
  return {
    adBreaks: [
      {
        tag: {
          url: adURL.href,
          type: 'vast'
        }
      }
    ]
  };
};

const getAd = ({ userContact, video, adInfo }) => {
  if (!userContact) return null; // pre-roll only for live or overflow
  const adURL = generateAdURL({ userContact, video, adInfo });
  return {
    id: uuidv4(),
    position: 'pre',
    tag: {
      url: adURL.href,
      type: 'vast'
    }
  };
};

export { getAdvertisingConfig, getAd };
