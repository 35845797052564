import React from 'react';
import { Modal } from 'react-bootstrap';
import './Model.scss';
import { useSelector, useDispatch } from 'react-redux';
import { history } from 'redux/configureStore';
import { setUser, getTVCode, setTVCode } from 'utils/localStorage';
import { onAuthenticationStatus, selectedMvpdUpdated } from 'pages/app/MVPDSelect/redux/actions';
import { checkAuthenticationError, getGeoLocationSuccess, addSubscriptionError } from 'pages/app/DeviceMVPD/redux/actions';
import { signout } from 'pages/auth/redux/actions';

function Model({ show, setIsShow, title, body, isSubscribe, isAddTVEError, subscriptionSuccess }) {
  const dispatch = useDispatch();
  const { isMac } = useSelector(state => state.auth);
  const { selectedMVPD } = useSelector(state => state.app.mvpdSelect);

  const handleClose = () => {
    if (isAddTVEError) {
      dispatch(signout());
      if (selectedMVPD) {
        dispatch(selectedMvpdUpdated(null));
        dispatch(onAuthenticationStatus(false));
        dispatch(addSubscriptionError(false));
      }
      else if (getTVCode()) {
        setTVCode(null);
        dispatch(checkAuthenticationError());
      }
      dispatch(getGeoLocationSuccess(null));
      setUser('dtc');
      history.push('/');
    }
    if (isSubscribe) {
      subscriptionSuccess();
    }
    setIsShow(false);
  };
  return (
    <div id="model">
      <Modal className="model-container" show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {body}
          </p>
          <button
            onClick={handleClose}
            className={`${isMac ? 'IOS' : ''} primary__button`}
          >
            {!isSubscribe ? 'OK' : ' CONTINUE'}
          </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Model;
