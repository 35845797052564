import { Component } from 'bitmovin-player-ui';
import { PlayerEvent, TimeMode } from 'bitmovin-player';
import EaseLive from '@ease-live/ease-live-bridge-web';
import { easeLiveConstants } from 'helpers/constants';
import simpleBets from './easeliveSimpleBetsPlugin';

// Bitmovin UI Component that loads EaseLive web SDK
export default class EaseLiveComponent extends Component {
  setEaseLiveConfig(config) {
    this.destroyEaseLive();
    this.createEaseLive(config, this.player, this.uimanager);
  }

  setControlsVisible(visible) {
    if (visible) {
      // show the bitmovin controls
      if (!this.isControlsVisible) {
        const container =
          this.uimanager.ui.element.get(0) ||
          document.getElementsByClassName('bmpui-ui-uicontainer')[0];
        let event = document.createEvent('MouseEvent');
        event.initEvent('mousemove', true, true);
        container.dispatchEvent(event);
      }
    } else {
      this.uimanager.ui.uiHideTimeout.clear();
      this.uimanager.ui.uiHideTimeout.callback();
    }
  }

  getPlayerState() {
    if (this.player.isPlaying()) { return 'playing'; }
    if (this.player.isPaused()) { return 'paused'; }
    if (this.player.isStalled()) { return 'buffering'; }
    return 'stopped';
  }

  configure(player, uimanager) {
    super.configure(player, uimanager);

    this._createdTime = new Date();

    console.log('EaseLiveComponent: create component', this._createdTime);

    const container = this.getDomElement();
    container.addClass('easelive-container');

    this.player = player;
    this.uimanager = uimanager;

    this.isControlsVisible = true;

    this.getDomElement().on('mouseenter', e => {
      if (this.easeLive && !this.hidden) {
        e.preventDefault();
      }
    });

    this.getDomElement().on('click', e => {
      if (!this.easeLive) {
        this.setControlsVisible(!this.isControlsVisible);
      }
    });

    uimanager.onControlsShow.subscribe(() => {
      this.isControlsVisible = true;

      if (this.easeLive) {
        this.easeLive.emit(easeLiveConstants.STAGE_CLICKED, {
          controls: 'visible'
        });
      }

      if (window.bitmovin.customMessageHandler) {
        window.bitmovin.customMessageHandler.sendSynchronous('onControlsShow');
      }
    });

    uimanager.onControlsHide.subscribe(() => {
      this.isControlsVisible = false;

      if (this.easeLive) {
        this.easeLive.emit(easeLiveConstants.STAGE_CLICKED, {
          controls: 'hidden'
        });
      }

      if (window.bitmovin.customMessageHandler) {
        window.bitmovin.customMessageHandler.sendSynchronous('onControlsHide');
      }
    });

    this._onPlay = () => {
      this.updateEaseLivePlayerState('playing');
    };
    this._onPause = () => {
      this.updateEaseLivePlayerState('paused');
    };
    this._onBuffering = () => {
      this.updateEaseLivePlayerState('buffering');
    };
    this._onSeeking = () => {
      this.updateEaseLivePlayerState('seeking');
    };
    this._onStop = () => {
      this.updateEaseLivePlayerState('stopped');
    };
    this._onTimeChanged = () => {
      this._lastTimeChange = Date.now();
    };
    this._onSpeedChanged = e => {
      if (this.easeLive) {
        const speed = e.to;
        this.easeLive.emit(easeLiveConstants.PLAYER_SPEED, {
          speed
        });
      }
    };

    this._onVolumeChanged = e => {
      if (this.easeLive) {
        const volume = e.targetVolume;
        this.easeLive.emit(easeLiveConstants.PLAYER_VOLUME, {
          volume
        });
      }
    };

    player.on(PlayerEvent.Play, this._onPlay);
    player.on(PlayerEvent.Paused, this._onPause);
    player.on(PlayerEvent.StallStarted, this._onBuffering);
    player.on(PlayerEvent.StallEnded, this._onPlay);
    player.on(PlayerEvent.TimeShift, this._onSeeking);
    player.on(PlayerEvent.TimeShifted, this._onPlay);
    player.on(PlayerEvent.PlaybackFinished, this._onStop);
    player.on(PlayerEvent.TimeChanged, this._onTimeChanged);
    player.on(PlayerEvent.PlaybackSpeedChanged, this._onSpeedChanged);
    player.on(PlayerEvent.VolumeChanged, this._onVolumeChanged);

    let config;

    if (window.bitmovin.customMessageHandler) {
      // when used in android/ios app load config using custom message handler

      window.bitmovin.customMessageHandler.on('setControlsVisible', data => {
        this.setControlsVisible(data);
      });

      try {
        config = JSON.parse(
          window.bitmovin.customMessageHandler.sendSynchronous('getEaseLiveConfig')
        );
      } catch (error) {
        console.log('error', error);
      }

      if (!config) {
        console.log('EaseLiveComponent: no config');
      } else {
        this.createEaseLive(config, player, uimanager);
      }

      window.bitmovin.customMessageHandler.on('updateEaseLiveTimecode', data => {
        try {
          const t = JSON.parse(data);
          this.updateEaseLiveTimecode(t.timecode, t.position, t.duration);
        } catch (e) {
          console.log('EaseLiveComponent: Error in updateEaseLiveTimecode', e);
        }
      });

      window.bitmovin.customMessageHandler.on('easeLiveEmit', data => {
        try {
          const event = JSON.parse(data);
          this.easeLive.emit(event.name, event.metadata);
        } catch (e) {
          console.log('EaseLiveComponent: Error in easeLiveEmit', e);
        }
      });

      window.bitmovin.customMessageHandler.on('setEaseLiveConfig', data => {
        try {
          const config = JSON.parse(data);
          this.setEaseLiveConfig(config);
        } catch (e) {
          console.log('EaseLiveComponent: Error in setEaseLiveConfig', e);
        }
      });

      window.bitmovin.customMessageHandler.on('hideEaseLive', data => {
        try {
          this.hideEaseLive();
        } catch (e) {
          console.log('EaseLiveComponent: Error in hideEaseLive', e);
        }
      });

      window.bitmovin.customMessageHandler.on('showEaseLive', data => {
        try {
          this.showEaseLive();
        } catch (e) {
          console.log('EaseLiveComponent: Error in showEaseLive', e);
        }
      });
    } else {
      // when used in web page get config from the object passed to buildDefaultUI
      config = uimanager.getConfig().easeLiveConfig;

      if (!config && uimanager.getConfig().getEaseLiveConfig) {
        config = uimanager.getConfig().getEaseLiveConfig();
      }

      if (!config) {
        console.log('EaseLiveComponent: no config');
      } else {
        this.createEaseLive(config, player, uimanager);
      }
    }

    if (uimanager.getConfig().onEaseLiveComponentCreated) {
      uimanager.getConfig().onEaseLiveComponentCreated(this);
    }
  }

  updateEaseLivePlayerState(state) {
    this.playerState = state;
    if (this.easeLive) {
      this.easeLive.emit(easeLiveConstants.PLAYER_STATE, {
        state
      });
    }
  }

  // send new timecode to EaseLive
  updateEaseLiveTimecode(time, position, duration) {
    if (this.easeLive) {
      let max = 0;
      let initial = 0;

      if (isFinite(duration)) {
        const msFromEnd = duration - position;

        // calculate timecode at the end of the seekable buffer
        max = time + msFromEnd;
        if (max < time) {
          max = time;
        }

        // calculate timecode at the start of the seekable buffer
        initial = max - duration;
        if (initial < 0) {
          initial = 0;
        }
      }

      this._currentTimecode = time;

      this.easeLive.emit(easeLiveConstants.PLAYER_TIME, {
        timecode: time,
        initialTimecode: initial,
        maxTimecode: max
      });
    }
  }



  createEaseLive(config, player, uimanager) {
    if (this.easeLive) { return; }
    console.log(config, 'EaseLiveComponent: Config values passess to easeLive');
    if (!config || !(config.url || (config.accountId))) {
      return;
    }

    const container = this.getDomElement();

    const viewContainer = container.get(0);
    viewContainer.innerHTML = '';

    let easeLive = new EaseLive(
      Object.assign(
        {
          viewContainer: viewContainer,
          playerPlugin: function () { }
        },
        config
      )
    );
    console.log('allConfig', config);

    if (config.simpleBetsEnabled) {
      const configCache = JSON.parse(localStorage.getItem('elc'));
      if (configCache && configCache?.expiration > new Date().getTime()) {
        easeLive.use(simpleBets, configCache);
      } else {
        const startTime = new Date(config.match.start_time).getTime();
        config.expiration = startTime + (60 * 60 * 4 * 1000);
        localStorage.setItem('elc', JSON.stringify(config));
        easeLive.use(simpleBets, config);
      }
    }

    if (!this._easeLiveCount) {
      this._easeLiveCount = 0;
    }

    this._easeLiveCount += 1;

    easeLive._uniqueInstanceId = this._easeLiveCount;

    this.easeLive = easeLive;

    const onBridgeReady = e => {
      const state = this.playerState || this.getPlayerState();

      console.log('EaseLiveComponent: bridge.ready state', state);

      easeLive.emit(easeLiveConstants.PLAYER_STATE, {
        state: state
      });
    };

    easeLive.on(easeLiveConstants.BRIDGE_READY, onBridgeReady);

    const onAppReady = e => {
      if (window.bitmovin.customMessageHandler) {
        window.bitmovin.customMessageHandler.sendSynchronous(
          'onEaseLiveAppReady',
          JSON.stringify(e)
        );
      } else if (uimanager.getConfig().onEaseLiveAppReady) {
        uimanager.getConfig().onEaseLiveAppReady(e);
      }

      const state = this.playerState || this.getPlayerState();

      easeLive.emit(easeLiveConstants.PLAYER_STATE, {
        state
      });

      // sometimes has wrong state, check again
      if (state === 'stopped') {
        this._pollForPlayerStateTimeout = setTimeout(() => {
          let state = this.getPlayerState();
          let timeSinceTimeChange = Date.now() - this._lastTimeChange;
          console.log(
            'EaseLiveComponent: checked for player state again',
            state,
            timeSinceTimeChange
          );

          if (state === 'stopped' && timeSinceTimeChange < 2000) {
            // if time is changing it is probably playing even if Bitmovin says no
            state = 'playing';
          }

          if (this.easeLive) {
            this.easeLive.emit(easeLiveConstants.PLAYER_STATE, {
              state
            });
          }
        }, 5000);
      }
    };

    easeLive.on(easeLiveConstants.APP_READY, onAppReady);

    const onViewError = () => {
      easeLive.destroy();
      if (
        this.easeLive &&
        easeLive &&
        easeLive._uniqueInstanceId === this.easeLive._uniqueInstanceId
      ) {
        this.easeLive = null;
      }
    };

    easeLive.on(easeLiveConstants.VIEW_ERROR, onViewError);

    const onError = e => {
      e = e || {};
      console.log('EaseLiveComponent: error', e);

      if (window.bitmovin.customMessageHandler) {
        window.bitmovin.customMessageHandler.sendSynchronous(
          'onEaseLiveError',
          JSON.stringify(e)
        );
      } else if (uimanager.getConfig().onEaseLiveError) {
        uimanager.getConfig().onEaseLiveError(e);
      }
    };

    easeLive.on(easeLiveConstants.ANY_ERROR, onError);

    const onBridgeMessage = e => {
      e = e || {};
      console.log('EaseLiveComponent: Bridge Message', e);

      if (window.bitmovin.customMessageHandler) {
        window.bitmovin.customMessageHandler.sendSynchronous(
          'onEaseLiveMessage',
          JSON.stringify(e)
        );
      } else if (uimanager.getConfig().onEaseLiveMessage) {
        uimanager.getConfig().onEaseLiveMessage(e);
      }
    };

    easeLive.on(easeLiveConstants.BRIDGE_MESSAGE, onBridgeMessage);

    const isEventSentByOverlay = sender => {
      return sender && sender.id === 'web-app';
    };

    const setControlsVisible = (data, sender) => {
      if (!isEventSentByOverlay(sender)) return;

      this.setControlsVisible(data.controls === 'visible');
    };

    easeLive.on(easeLiveConstants.STAGE_CLICKED, setControlsVisible);

    const setPlayerState = (data, sender) => {
      if (!isEventSentByOverlay(sender)) return;

      switch (data.state) {
        case 'playing': {
          this.player.play();
          break;
        }
        case 'paused': {
          this.player.pause();
          break;
        }
        default: {
          break;
        }
      }
    };

    easeLive.on(easeLiveConstants.PLAYER_STATE, setPlayerState);

    const setPlayerTime = (data, sender) => {
      if (!isEventSentByOverlay(sender)) return;

      const time = data.timecode;

      const currentTimecode = this._currentTimecode;

      if (!currentTimecode) return;

      // seek length is the difference between the wanted UTC and the current UTC
      const diff = (time - currentTimecode) / 1000;

      // seek position relative to the current timeline position

      const maxTimeshift = this.player.getMaxTimeShift();
      if (maxTimeshift < 0) {
        const currentTimeshift = this.player.getTimeShift();

        let timeshift = currentTimeshift + diff;

        if (timeshift < maxTimeshift) {
          timeshift = maxTimeshift;
        } else if (timeshift > 0) {
          timeshift = 0;
        }

        this.player.timeShift(timeshift);
      } else {
        const currentPosition = this.player.getCurrentTime(TimeMode.RelativeTime);
        const position = currentPosition + diff;
        this.player.seek(position);
      }
    };

    easeLive.on(easeLiveConstants.PLAYER_TIME, setPlayerTime);

    const setPlayerSpeed = (data, sender) => {
      if (!isEventSentByOverlay(sender)) return;
      const { speed } = data;
      this.player.setPlaybackSpeed(speed);
    };

    easeLive.on(easeLiveConstants.PLAYER_SPEED, setPlayerSpeed);

    const setPlayerVolume = (data, sender) => {
      if (!isEventSentByOverlay(sender)) return;
      const { volume } = data;
      this.player.setVolume(volume);
    };

    easeLive.on(easeLiveConstants.PLAYER_VOLUME, setPlayerVolume);

    const onDestroy = () => {
      easeLive.off(easeLiveConstants.DESTROY, onDestroy);
      easeLive.off(easeLiveConstants.PLAYER_TIME, setPlayerTime);
      easeLive.off(easeLiveConstants.PLAYER_STATE, setPlayerState);
      easeLive.off(easeLiveConstants.PLAYER_SPEED, setPlayerSpeed);
      easeLive.off(easeLiveConstants.PLAYER_VOLUME, setPlayerVolume);
      easeLive.off(easeLiveConstants.STAGE_CLICKED, setControlsVisible);
      easeLive.off(easeLiveConstants.APP_READY, onAppReady);
      easeLive.off(easeLiveConstants.BRIDGE_READY, onBridgeReady);
      easeLive.off(easeLiveConstants.VIEW_ERROR, onViewError);
      easeLive.off(easeLiveConstants.ANY_ERROR, onError);
      easeLive = null;
    };
    easeLive.on(easeLiveConstants.DESTROY, onDestroy);
    easeLive.init();

    if (uimanager.getConfig().onEaseLiveCreated) {
      uimanager.getConfig().onEaseLiveCreated(easeLive);
    }

    easeLive.emit(easeLiveConstants.PLAYER_READY, {
      player: this.player
    });
  }

  destroyEaseLive() {
    clearTimeout(this._pollForPlayerStateTimeout);

    if (this.easeLive) {
      this.easeLive.destroy();
      this.easeLive = null;
    }
  }

  release() {
    this.player.off(PlayerEvent.Play, this._onPlay);
    this.player.off(PlayerEvent.Paused, this._onPause);
    this.player.off(PlayerEvent.StallStarted, this._onBuffering);
    this.player.off(PlayerEvent.StallEnded, this._onPlay);
    this.player.off(PlayerEvent.TimeShift, this._onSeeking);
    this.player.off(PlayerEvent.TimeShifted, this._onPlay);
    this.player.off(PlayerEvent.PlaybackFinished, this._onStop);
    this.player.off(PlayerEvent.TimeChanged, this._onTimeChanged);

    super.release();

    this.destroyEaseLive();
  }

  hideEaseLive() {
    this.hide();
  }

  showEaseLive() {
    this.show();
  }
}
