import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import {
  getRegCode,
  getAccessToken,
  getTVCode,
  getActivationCode,
  setActivationCode,
  setPlatform
} from 'utils/localStorage';
import { getFavoriteTeamRequest } from 'pages/auth/redux/actions';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import DeviceMVPD from '../DeviceMVPD';
import TeamSelect from '../TeamSelect';
import RegisterSuccess from '../RegisterSuccess';
import Register from '../../auth/Register';
import FavouritePlayer from '../FavouritePlayer';
import SecondScreen from '../SecondScreen';
// import Watch from '../Watch';

const platformDisplayNameMap = new Map();
platformDisplayNameMap.set('samsung', 'Samsung');
platformDisplayNameMap.set('lg', 'LG');
platformDisplayNameMap.set('vizio', 'VIZIO');

const DeviceRoutes = () => {

  const dispatch = useDispatch();
  const match = useRouteMatch('/authenticate/:deviceId/:platform?/:activationCode?');
  const deviceId = match?.params?.deviceId;

  if (deviceId !== 'watch' && deviceId !== 'register-success') {
    localStorage.setItem('regCode', deviceId);
  }
  if (match?.params?.platform !== 'watch' && match?.params?.platform !== undefined) {
    const platform = platformDisplayNameMap.get(match?.params?.platform) ||
      (match?.params?.platform.charAt(0).toUpperCase() + match?.params?.platform.slice(1));
    setPlatform(platform);
  }
  if (match?.params?.activationCode !== 'watch' && match?.params?.activationCode !== undefined) {
    setActivationCode(match?.params?.activationCode);
  }

  const { isDeviceAuthorized } = useSelector(state => state.app.deviceMVPD);
  const { account, hasFavoritePlayers, continueFromThePlayers } = useSelector(state => state.auth);
  const { successfullyLinked } = useSelector(state => state.app.watch);
  const { errorMessage } = useSelector(state => state.app.teamSelect);
  // const { search } = useLocation();
  const activationCode = getActivationCode();


  useEffect(() => {
    if (getAccessToken() && !account.favoriteTeams) {
      dispatch(getFavoriteTeamRequest({
        GetFavouritesRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID,
        }
      }));
    }
    // eslint-disable-next-line
  }, [dispatch, account.favoriteTeams]);
  const hasFavoriteTeam = !!(
    account &&
    account.favoriteTeams &&
    account.favoriteTeams.length
  );
  // const isReturningUser = GetOAuthAccessTokenv2ResponseMessage?.responseCode === '1';
  if ((!isDeviceAuthorized || !getTVCode()) && activationCode !== null) {
    return <DeviceMVPD deviceId={getRegCode()} />;
  }
  if (!account.profile && activationCode !== null) {
    return <Register deviceId={match?.params?.deviceId} />;
  }
  if (!hasFavoriteTeam && activationCode !== null) {
    return <TeamSelect isOnboarding />;
  }
  if (!hasFavoritePlayers && !continueFromThePlayers && activationCode !== null) {
    return <FavouritePlayer isOnboarding />;
  }
  if (activationCode === null || errorMessage.type) {
    return <SecondScreen />;
  }
  if ((isDeviceAuthorized && hasFavoriteTeam) || !successfullyLinked || getAccessToken()) {
    return <RegisterSuccess activationCode={activationCode} />;
  }
  // if (successfullyLinked) {
  //   return <Watch search={search} />;
  // }
};
export default DeviceRoutes;
