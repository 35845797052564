import * as CONSTANTS from './constants';

// User actions
export const authButtonSelected = () => ({
  type: CONSTANTS.AUTH_BTN_SELECTED
});

export const selectedMvpdUpdated = data => ({
  type: CONSTANTS.SELECTED_MVPD_UPDATED,
  payload: data
});

export const signInButtonSelected = () => ({
  type: CONSTANTS.SIGN_IN_BTN_SELECTED
});

export const watchButtonSelected = data => ({
  type: CONSTANTS.WATCH_BTN_SELECTED,
  payload: data
});

// Adob pass actions
export const onAuthenticationStatus = data => ({
  type: CONSTANTS.ON_AUTHENTICATION_STATUS,
  payload: data
});

export const setAuthLoading = data => ({
  type: CONSTANTS.AUTH_LOADING,
  payload: data
});

export const setMVPDToken = data => ({
  type: CONSTANTS.SET_TOKEN,
  payload: data
});

export const setMetadata = data => ({
  type: CONSTANTS.SET_METADATA,
  payload: data
});

export const onEntitlementLoaded = data => ({
  type: CONSTANTS.ENTITLEMENT_LOADED,
  data
});

export const onSetConfig = data => ({
  type: CONSTANTS.ON_SET_CONFIG,
  data
});

export const onSetSelectedProvider = data => ({
  type: CONSTANTS.ON_SET_SELECTED_PROVIDER,
  data
});

// update user zipcode
export const updateUserMetadataRequest = data => ({
  type: CONSTANTS.UPDATE_USER_METADATA_REQUEST,
  payload: data
});
export const updateUserMetadataSuccess = data => ({
  type: CONSTANTS.UPDATE_USER_METADATA_SUCCESS,
  payload: data
});
export const updateUserMetadataError = () => ({
  type: CONSTANTS.UPDATE_USER_METADATA_ERROR
});

// select no provider
export const selectNoProviderRequest = () => ({
  type: CONSTANTS.SELECT_NO_PROVIDER
});
