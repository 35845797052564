export const ENTITLEMENT_LOADED = 'ENTITLEMENT_LOADED';
export const ON_AUTHENTICATION_STATUS = 'ON_AUTHENTICATION_STATUS';
export const ON_SET_CONFIG = 'ON_SET_CONFIG';
export const ON_SET_SELECTED_PROVIDER = 'ON_SET_SELECTED_PROVIDER';
export const ON_SET_TOKEN = 'ON_SET_TOKEN';

export const AUTHENTICATED = 'AUTHENTICATED';
export const AUTH_LOADING = 'AUTH_LOADING';
export const SET_TOKEN = 'SET_TOKEN';

export const LOGGING_OUT_ADOBE_PASS = 'LOGGING_OUT_ADOBE_PASS';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';
export const TEMP_PASS = 'TEMP_PASS';
export const SET_METADATA = 'SET_METADATA';

// User
export const AUTH_BTN_SELECTED = 'AUTH_BTN_SELECTED';
export const SELECTED_MVPD_UPDATED = 'SELECTED_MVPD_UPDATED';
export const SIGN_IN_BTN_SELECTED = 'SIGN_IN_BTN_SELECTED';
export const WATCH_BTN_SELECTED = 'WATCH_BTN_SELECTED';

export const UPDATE_USER_METADATA_REQUEST = 'UPDATE_USER_METADATA_REQUEST';
export const UPDATE_USER_METADATA_SUCCESS = 'UPDATE_USER_METADATA_SUCCESS';
export const UPDATE_USER_METADATA_ERROR = 'UPDATE_USER_METADATA_ERROR';

export const SELECT_NO_PROVIDER = 'SELECT_NO_PROVIDER';
export const NO_PROFILE_FOUND_FOR_USER = "NO_PROFILE_FOUND_FOR_USER";
