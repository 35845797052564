import request from 'utils/api';
import { get } from 'lodash';
import { put, takeLatest, call } from 'redux-saga/effects';
import { adobeToken, getWebDeviceId, APIUSER, APIPASSWORD, CHANNELPARTNERID, deviceType, deviceName } from 'config';
import { saveDeivceInfo } from 'utils/localStorage';
import jwt from 'jsonwebtoken';
import requestHeaderOption from 'utils/apiHeaderOption';
import { checkQuickPlayOAuthValidToken, checkQuickPlayTVEFlatToken, checkValidToken } from 'utils/CheckTokenValidation';
import * as CONSTANTS from './constants';
import { setMetadata } from '../../MVPDSelect/redux/actions';
import { signin } from '../../../auth/redux/sagas';
import { SIGNIN_REQUEST } from '../../../auth/redux/constants';
import {
  mvpdListSuccess,
  mvpdListError,
  checkAuthenticationError,
  checkAuthenticationSuccess,
  getUserMetadataError,
  regCodeError,
  registerDeviceSuccess,
  getQuickPlayTVEFlatTokenSuccess,
  setXDeviceIDToken,
  getGeoLocationSuccess,
  getGeoLocationError,
  getUserMetadataZipSuccess,
  addSubscriptionSuccess,
  addSubscriptionError
} from './actions';

let analyticFlag = true;
function* getMVPDList() {
  try {
    const {
      requestor: { mvpds }
    } = yield call(request, 'adobeAuth', '/api/v1/config/YESN', 'get');
    yield put(mvpdListSuccess(mvpds.mvpd));
  } catch (err) {
    const error = get(err, 'response', err);
    console.log('error:', error);
    yield put(mvpdListError());
  }
}

function* checkAuthentication(action) {
  try {
    const { deviceId: regCode, requestor, search, match, authFlow, deviceCode } = action.payload;
    let deviceInfo = {};
    try {
      deviceInfo = yield call(
        request,
        'adobeAuth',
        `/reggie/v1/YESN/regcode/${regCode}`,
        'get',
        null,
        adobeToken
      );
    } catch (err) {
      const error = get(err, 'response', err);
      console.log(err, 'reg err');
      console.log(error, 'reg code error');
      if (error.status === 404) {
        yield put(regCodeError());
      }
    }

    yield call(
      request,
      'adobeAuth',
      `/api/v1/checkauthn/${regCode}`,
      'get',
      { requestor },
      adobeToken
    );
    yield put(checkAuthenticationSuccess());

    const {
      info: { deviceId, deviceInfo: device }
    } = deviceInfo;
    saveDeivceInfo(atob(device));

    const userMetadata = yield call(
      request,
      'adobeAuth',
      '/api/v1/tokens/usermetadata',
      'get',
      { deviceId: window.atob(deviceId), requestor },
      adobeToken
    );
    yield put(getUserMetadataZipSuccess(userMetadata.data.mvpd === 'Comcast_SSO'
      ? userMetadata.data.encryptedZip
      : userMetadata.data.zip));

    const query = {
      mvpd_id: userMetadata.data.mvpd,
      key: userMetadata.data.mvpd === 'Comcast_SSO' ? 'encryptedZip' : 'zip',
      encrypted: true,
      data:
        userMetadata.data.mvpd === 'Comcast_SSO'
          ? userMetadata.data.encryptedZip
          : userMetadata.data.zip
    };
    console.log('query ', query);

    // yield put(updateUserMetadataRequest(query));
    yield put(setMetadata({ key: 'userID', data: userMetadata.data.userID }));

    yield signin({
      type: SIGNIN_REQUEST,
      data: {
        GetOAuthAccessTokenv2RequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID,
          cpCustomerID: decodeURIComponent(userMetadata?.data.userID),
          sessionExpiryPeriod: '',
          deviceMessage: {
            deviceType,
            deviceName,
            modelNo: '04ries',
            serialNo: getWebDeviceId(),
            userAgent: 'USA'
          }
        }
      },
      authFlow,
      deviceId: deviceCode,
      urlVODId: match?.params?.vodId,
      search
    });

  } catch (err) {
    const error = get(err, 'response', err);
    console.log('this device is not authenticated!:', error);
    yield put(checkAuthenticationError());
  }
}

function* getUserMetadata(action) {
  try {
    const data = yield call(
      request,
      'adobeAuth',
      '/api/v1/tokens/usermetadata',
      'get',
      action.payload,
      adobeToken
    );
    console.log('ignore', data);
  } catch (err) {
    const error = get(err, 'response', err);
    console.log('error:', error);
    yield put(getUserMetadataError());
  }
}

function* getGeoLocation() {
  try {
    const reqHeader = {
      'X-Client-Id': 'yesn-yesn-web'
    };
    const quickplayOauthToken = yield checkQuickPlayOAuthValidToken();
    const data = yield call(
      requestHeaderOption,
      'quickplayGeoLocation',
      '/device/location/lookup',
      'get',
      reqHeader,
      '',
      quickplayOauthToken
    );
    yield put(getGeoLocationSuccess(data.data));
    if (analyticFlag) {
      window.analytics.identify({
        zip_code: data.data?.zip,
      });
      analyticFlag = false;
    }

  } catch (err) {
    yield put(getGeoLocationError());
    console.log('getGeoLocation error', err);
  }
}

function* getQuickplayTVEFlatToken(action) {
  let cancelTask = false;
  const deviceId = getWebDeviceId();
  const evergentAccessToken = yield checkValidToken();
  if (action.isSelectProvider) {
    const addSubscriptionResponse = yield call(
      request,
      'evergent',
      '/yesnetwork/addTVESubscription',
      'post',
      action.data,
      evergentAccessToken
    );
    if (addSubscriptionResponse.AddTVESubscriptionResponseMessage.responseCode === '0' &&
      addSubscriptionResponse.AddTVESubscriptionResponseMessage.failureMessage.find(errItem => errItem.errorCode === 'eV5883')) {
      yield put(addSubscriptionError(true));
      cancelTask = true;
    }
    if (addSubscriptionResponse.AddTVESubscriptionResponseMessage.responseCode === '1') {
      yield put(addSubscriptionSuccess(true));
    }
  }
  if (!cancelTask) {
    const requestBodyForGetEntitlements = {
      GetEntitlementsRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
      }
    };
    const getFlatToken = yield call(
      request,
      'evergent',
      '/yesnetwork/getEntitlements',
      'post',
      requestBodyForGetEntitlements,
      evergentAccessToken
    );
    if (getFlatToken.GetEntitlementsResponseMessage.responseCode === '1') {
      yield put(getQuickPlayTVEFlatTokenSuccess(getFlatToken.GetEntitlementsResponseMessage));
      localStorage.setItem('quickPlayTVEFlatToken', getFlatToken.GetEntitlementsResponseMessage.ovatToken);
      localStorage.setItem('quickPlayTVEFlatTokenExpiresIn', getFlatToken.GetEntitlementsResponseMessage.ovatTokenExpiry);
    }
    else {
      console.log('QuickPlay TVE FlatToken ', getFlatToken.GetEntitlementsResponseMessage);
    }
    const quickPlayTVEFlatToken = yield checkQuickPlayTVEFlatToken();
    const registerDeviceHeader = {
      'X-Authorization': quickPlayTVEFlatToken,
      'X-Client-Id': 'yesn-yesn-web'
    };
    const quickplayOauthToken = yield checkQuickPlayOAuthValidToken();
    const registerDevice = yield call(
      requestHeaderOption,
      'quickplayRegisterDevice',
      '/device/app/register',
      'post',
      registerDeviceHeader,
      { uniqueId: deviceId },
      quickplayOauthToken
    );
    yield put(registerDeviceSuccess(registerDevice));
    const responseSecretKey = registerDevice.data.secret;
    const responseSecretExpiry = registerDevice.data.expiry;
    const currentTime = Date.now() / 1000;
    const expTime = currentTime + responseSecretExpiry;
    const payload = {
      deviceId,
      aud: 'playback-auth-service',
      iat: currentTime,
      exp: expTime,
    };
    const token = jwt.sign(payload, Buffer.from(responseSecretKey, 'base64'));
    yield put(setXDeviceIDToken(token));
  }
}


export default function* deviceMVPDSaga() {
  yield takeLatest(CONSTANTS.MVPD_LIST_REQUEST, getMVPDList);
  yield takeLatest(CONSTANTS.CHECK_AUTHENTICATION_REQUEST, checkAuthentication);
  yield takeLatest(CONSTANTS.GET_USER_METADATA_REQUEST, getUserMetadata);
  yield takeLatest(CONSTANTS.GET_QUICKPLAY_GEO_LOCATION_REQUEST, getGeoLocation);
  // yield takeLatest(CONSTANTS.GET_QUICKPLAY_ANONYMOUS_FLATTOKEN_REQUEST, getQuickPlayAnonymousFlatToken);
  yield takeLatest(CONSTANTS.GET_QUICKPLAY_TVE_FLATTOKEN_REQUEST, getQuickplayTVEFlatToken);
}
