import ip from 'ip';

const getConnectionIp = () => {
  const address = ip.address();
  return address || '0.0.0.0';
};

const getChannel = (video) => {
  switch (video.catalogType) {
    case 'channel':
      return 'primary';
    case 'liveevent':
      return 'overflow';
    default:
      return 'channel';
  }
};
export { getChannel, getConnectionIp };
