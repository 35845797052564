/* eslint-disable no-else-return */
import axios from 'axios';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import { getAccessToken, setAccessToken, setEvergentExpiresIn, setRefreshToken, getRefreshToken, getEvergentExpiresIn, getQuickPlayExpiresIn, setQuickPlayExpiresIn, setOAuthToken, getOAuthToken, setTVEFlatToken, setTVEFlatTokenExpiresIn, getTVEFlatToken, getQuickPlayTVETokenExpiresIn, setQuickPlayTVETokenExpiresIn } from './localStorage';

const checkValidToken = async () => {
  const expiresInDate = parseFloat(getEvergentExpiresIn());
  const currentDate = Date.now();
  const isValidToken = expiresInDate > currentDate;
  if (!isValidToken) {
    const headers = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const payload = {
      RefreshTokenRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        refreshToken: `${getRefreshToken()}`
      }
    };
    try {
      const data = await axios.post(`${process.env.REACT_APP_EVERGENT_API}/yesnetwork/refreshToken`, payload, headers);
      setAccessToken(data.data.RefreshTokenResponseMessage.accessToken);
      setRefreshToken(data.data.RefreshTokenResponseMessage.refreshToken);
      setEvergentExpiresIn(Date.now() / 1000 + data.data.RefreshTokenResponseMessage.expiresIn);
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }
  return getAccessToken();
};

const checkQuickPlayOAuthValidToken = async () => {

  const expiresIn = parseFloat(getQuickPlayExpiresIn());
  const currentTimeInSec = Date.now() / 1000;
  const isValidToken = expiresIn > currentTimeInSec;
  if (!isValidToken) {
    const headers = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    };
    const authTokenRequestData = `client_id=${process.env.REACT_APP_QUICKPLAY_CLIENT_ID}&client_secret=${process.env.REACT_APP_QUICKPLAY_CLIENT_SECRET}&grant_type=client_credentials&audience=edge-service&scope=openid`;
    try {
      const data = await axios.post(`${process.env.REACT_APP_QUICKPLAY_API}/oauth2/token`, authTokenRequestData, headers);
      setOAuthToken(data.data.access_token);
      setQuickPlayExpiresIn(Date.now() / 1000 + data.data.expires_in);
      return data.data.access_token;
    }
    catch (error) {
      console.log(error);
      return undefined;
    }
  }
  return getOAuthToken();
};

const checkQuickPlayTVEFlatToken = async () => {
  const expiresIn = parseFloat(getQuickPlayTVETokenExpiresIn());
  const currentTimeInSec = new Date() / 1000;
  const isValidToken = expiresIn > currentTimeInSec;
  if (!isValidToken) {
    const requestBodyForGetEntitlements = {
      GetEntitlementsRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
      }
    };
    const evergentAccessToken = await checkValidToken();
    const headers = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': `Bearer ${evergentAccessToken}`
      }
    };

    try {
      const data = await axios.post(`${process.env.REACT_APP_EVERGENT_API}/yesnetwork/getEntitlements`, requestBodyForGetEntitlements, headers);
      setTVEFlatToken(data.data.GetEntitlementsResponseMessage.ovatToken);
      setTVEFlatTokenExpiresIn(Date.now() / 1000 + data.data.GetEntitlementsResponseMessage.ovatTokenExpiry);
      setQuickPlayTVETokenExpiresIn((Date.now() / 1000) + data.data.GetEntitlementsResponseMessage.ovatTokenExpiry);
    }
    catch (error) {
      console.log(error);
      return undefined;
    }
  }
  return getTVEFlatToken();
};
export {
  checkValidToken,
  checkQuickPlayOAuthValidToken,
  checkQuickPlayTVEFlatToken
};