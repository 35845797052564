import produce from 'immer';
import * as CONSTANTS from './constants';

const initialState = {
  selectedMVPD: null,
  mvpdAuthenticated: false,
  authLoading: false,
  mvpdSelectLoading: false,
  mvpdToken: null,
  isUpdatingUserMetadata: false,
  userMetadataUpdated: false,
  userMetadata: {},
  currentVodId: ''
};

const mvpdSelectReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {      
      case CONSTANTS.ON_AUTHENTICATION_STATUS:
        draft.mvpdAuthenticated = action.payload;
        break;
      case CONSTANTS.AUTH_LOADING:
        draft.authLoading = action.payload;
        break;

      case CONSTANTS.SET_TOKEN:
        draft.mvpdToken = action.payload;
        break;

      case CONSTANTS.SELECTED_MVPD_UPDATED:
        draft.selectedMVPD = action.payload;
        break;

      case CONSTANTS.SET_METADATA:
        // eslint-disable-next-line no-case-declarations
        const { key, data } = action.payload;
        draft.userMetadata[key] = key === 'userID' ? decodeURIComponent(data) : data;
        break;

      case CONSTANTS.UPDATE_USER_METADATA_REQUEST:
        draft.isUpdatingUserMetadata = true;
        draft.userMetadataUpdated = false;
        break;
      case CONSTANTS.UPDATE_USER_METADATA_SUCCESS:
        draft.userMetadataUpdated = true;
        draft.isUpdatingUserMetadata = false;
        break;
      case CONSTANTS.UPDATE_USER_METADATA_ERROR:
        draft.isUpdatingUserMetadata = false;
        draft.userMetadataUpdated = false;
        break;

      case CONSTANTS.SELECT_NO_PROVIDER:
        draft.selectedMVPD = 'no_provider';
        break;

      default:
        break;
    }
  });

export default mvpdSelectReducer;
