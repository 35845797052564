const { getDeviceInfo } = require('./localStorage');

const getDeviceType = () => {
  const deviceInfo = getDeviceInfo();
  if (deviceInfo) {
    return deviceInfo.model || 'device';
  }
  return 'device';
};

export default getDeviceType;
