import React, { useEffect, useState } from 'react';
import closeIcon from 'assets/images/close_icon.png';

const UpNextVideo = ({
  assetId,
  name,
  duration,
  title,
  image,
  date,
  playItem,
  inFreePackage,
  setNextVideo,
  setIsVODReplayed
}) => {
  const [nextVideoTimer, setNextVideoTimer] = useState(10);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setNextVideoTimer(t => t - 1);
    }, 1000);
    setIntervalId(interval);
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (nextVideoTimer <= 0) {
      playItem(assetId, name, duration / 1000, title, inFreePackage);
      if (intervalId) clearInterval(intervalId);
    }
    // eslint-disable-next-line
  }, [nextVideoTimer]);

  const cancel = () => {
    setNextVideo(null);
    setIsVODReplayed(true);
    if (intervalId) clearInterval(intervalId);
  };

  const publishTime = duration => {
    const durInSec = duration / 1000;
    const hrs = Math.floor(durInSec / 3600)
      .toString()
      .padStart(2, '0');
    const mins = Math.floor((durInSec % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const secs = Math.floor(durInSec % 60)
      .toString()
      .padStart(2, '0');
    return `${hrs}:${mins}:${secs}`;
  };

  return (
    <div className="up-next">
      <div className="error-screen_title up-next-content d-flex">
        <div className="close-button d-flex">
          <img className="close-icon" src={closeIcon} alt="Close_Icon" onClick={() => cancel()} />
        </div>
        <div className="d-flex flex-column next-video">
          <div className="space-left tglc-24 d-flex">
            Up next in <p className="next-video-timer">{nextVideoTimer}</p>
          </div>
          <div className="d-flex justify-content-between up-next-card-height">
            <div className="up-next-card-container">
              <div className="float-layout">
                <div className="up-next-card-image">
                  <img src={image} alt="VOD_Image" />
                  <div className="up-next-card d-flex flex-column justify-content-between">
                    <div className="up-next-card-desc tglc-24">{name}</div>
                    <div className="d-flex justify-content-between">
                      <div className="tglc-16">{date}</div>
                      <div className="tglc-16 up-next-duration">{publishTime(duration)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex up-next-buttons">
            <div
              className="cancel-button tglc-16 d-flex justify-content-center align-items-center"
              onClick={() => cancel()}
            >
              Cancel
            </div>
            <div
              className="play-button tglc-16 d-flex justify-content-center align-items-center"
              onClick={() => setNextVideoTimer(0)}
            >
              Play Now
            </div>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
};

export default UpNextVideo;
