import React from 'react';

const YesBackground = ({ logoIcon, bgClassName, title, description, subDescription }) => (
  <div className={bgClassName}>
    <div className="yes-background__header">
      {logoIcon && <div className="logo">
        <img src={logoIcon} alt="screen_icon" />
      </div>}
      {title ? (
        <h1 className="tgd1-base-76">{title}</h1>
      ) : (
        <h1 className="tgd1-base-76"> </h1>
      )}
      {description ? (
        <h2 className="description" dangerouslySetInnerHTML={{ __html: description }} />
      ) : (
        <h2 className="description"> </h2>
      )}
      {subDescription ? <p>{subDescription}</p> : <p> </p>}
    </div>
  </div>
);

export default YesBackground;
