import React from 'react';
import Iframe from 'react-iframe';
import './Header.scss';

const Header = () => (
  <Iframe
    url="https://www.yesnetwork.com/thirdparty/header?watchLive=true"
    className="iframe-header"
    width="100%"
    height="173px"
    id="yes-header"
    name="yes-header"
    display="initial"
    position="relative"
  />
);

export default Header;
