import React from 'react';

const ComponentLoading = () => (
  <div>
    <div className="w-100 text-center">
      <div className="spinner-border text-dark m-auto" role="status" />
    </div>
  </div>
);

export default ComponentLoading;
