import React from 'react';
import { Modal } from 'react-bootstrap';
import './YoungAgeModel.scss';
import { useSelector } from 'react-redux';

function YoungAgeModel({show, setIsShow }) {
  const { isMac } = useSelector(state => state.auth);
  const handleClose = () => setIsShow(true);
  return (
    <div id="YoungAgeModel">
      <Modal className="young-age-modal" show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header>
            <Modal.Title>Sorry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p>
              {`you do not meet the age requirements. Users must be 13 years or older.`}
            </p> 
            <button
              onClick={handleClose}
              className={`${isMac ? 'IOS' : ''} primary__button`}
            >
              OK
            </button>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default YoungAgeModel;