/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
import { UIFactory, UIManager, PlaybackToggleOverlay } from 'bitmovin-player-ui';
import EaseLiveComponent from './easelivecomponent'

const EaseLiveUiFactory = {
  buildDefaultUI: (player, config) => EaseLiveUiFactory.buildModernUI(player, config),

  buildDefaultSmallScreenUI: (player, config) => EaseLiveUiFactory.buildModernSmallScreenUI(player, config),

  buildDefaultCastReceiverUI: (player, config) => UIFactory.buildModernCastReceiverUI(player, config),

  buildModernUI: (player, config) => {
    // show smallScreen UI only on mobile/handheld devices
    const smallScreenSwitchWidth = 600;

    return new UIManager(player, [{
      ui: EaseLiveUiFactory.modernSmallScreenAdsUI(),
      condition: (context) => context.isMobile && context.documentWidth < smallScreenSwitchWidth && context.isAd
                    && context.adRequiresUi,
    }, {
      ui: EaseLiveUiFactory.modernAdsUI(),
      condition: (context) => context.isAd && context.adRequiresUi,
    }, {
      ui: EaseLiveUiFactory.modernSmallScreenUI(),
      condition: (context) => !context.isAd && !context.adRequiresUi && context.isMobile
                    && context.documentWidth < smallScreenSwitchWidth,
    }, {
      ui: EaseLiveUiFactory.modernUI(),
      condition: (context) => !context.isAd && !context.adRequiresUi,
    }], config);
  },

  buildModernSmallScreenUI: (player, config) => new UIManager(player, [{
    ui: EaseLiveUiFactory.modernSmallScreenAdsUI(),
    condition: (context) => {
      return context.isAd && context.adRequiresUi;
    },
  }, {
    ui: EaseLiveUiFactory.modernSmallScreenUI(),
    condition: (context) => {
      return !context.isAd && !context.adRequiresUi;
    },
  }], config),

  modernSmallScreenAdsUI: () => UIFactory.modernSmallScreenAdsUI(),

  modernAdsUI: () => UIFactory.modernAdsUI(),

  modernSmallScreenUI: () => {
    let ui = UIFactory.modernSmallScreenUI();
    EaseLiveUiFactory._insertEaseLive(ui);
    return ui;
  },

  modernUI: () => {
    let ui = UIFactory.modernUI();
    EaseLiveUiFactory._insertEaseLive(ui);
    return ui;
  },

  // insert EaseLive component below player controls
  _insertEaseLive: (ui) => {
    // ui.hideDelay = -1
    const components = ui.getComponents();
    let index = 0;
    for (let i = 0; i < components.length; i++) {
      let component = components[i];
      if (component instanceof PlaybackToggleOverlay) {
        index = i;
      }
    }

    const elContainer = new EaseLiveComponent();
    ui.config.components.splice(index, 0, elContainer);

    return ui;
  }
};

export default EaseLiveUiFactory;