import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFavoriteTeamRequest } from 'pages/auth/redux/actions';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import { getAccessToken, getUser } from 'utils/localStorage';
import ComponentLoading from 'components/ComponentLoading/ComponentLoading';
import FavouritePlayer from '../FavouritePlayer';
import MVPDSelect from '../MVPDSelect';
import TeamSelect from '../TeamSelect';
import Register from '../../auth/Register';
import Watch from '../Watch';


const WebRoutes = ({ location, match }) => {
  const { mvpdAuthenticated, selectedMVPD } = useSelector(state => state.app.mvpdSelect);
  const { account, GetOAuthAccessTokenv2ResponseMessage, continueFromThePlayers, loading: isLoading } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getAccessToken() && !account.favoriteTeams) {
      dispatch(getFavoriteTeamRequest({
        GetFavouritesRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID,
        }
      }));
    }
    // eslint-disable-next-line
  }, [dispatch, account?.favoriteTeams]);


  const hasFavoriteTeam = !!(
    account &&
    account.favoriteTeams &&
    account.favoriteTeams.length
  );
  const isReturningUser = GetOAuthAccessTokenv2ResponseMessage?.responseCode === '1';
  const userType = getUser();
  if (!mvpdAuthenticated && !selectedMVPD && (userType === 'tve' || userType === 'undefined' || userType === null)) {
    return <MVPDSelect match={match} />;
  }
  if (!account?.profile && !isReturningUser) {
    return <Register deviceId={match?.params?.deviceId} />;
  } if (!hasFavoriteTeam && !isReturningUser) {
    return <TeamSelect isOnboarding />;
  } if (!continueFromThePlayers) {
    if (isLoading) {
      return <ComponentLoading />;
    }
    return <FavouritePlayer isOnboarding />;
  } if (selectedMVPD === 'no_provider' || (mvpdAuthenticated && isReturningUser) || getAccessToken()) {
    return <Watch vodId={match?.params?.vodId} search={location.search} />;
  }
};

export default WebRoutes;
