/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useLocation, withRouter, useRouteMatch } from 'react-router-dom';
import {
  isMaintenance,
  deivceAllowed,
  webAllowed,
  APIUSER,
  APIPASSWORD,
  CHANNELPARTNERID
} from 'config';
import Footer from 'components/Footer';
import Header from 'components/Header';
import PageLoading from 'components/PageLoading';
import { getAccessToken } from 'utils/localStorage';
import { getAccountRequest, setIsMac } from './auth/redux/actions';
import AppRouter from './app';
import AuthRouter from './auth';
import WinbackRouter from './auth/winback/index';
import LandingPage from './landing-page';
import Maintenance from './global/Maintenance';
import EmailSent from './auth/EmailSent';
import ToastNotification from './toast/Toast';
import EmailConfirm from './auth/EamilConfirm';
import PasswordReset from './auth/PasswordReset';
import PasswordResetSuccess from './auth/PasswordResetSuccess';
import {
  getVODRequest,
  getRemoteConfig,
  getLocalization,
  getPlayers
} from './app/Watch/redux/actions';
import DeleteMyData from './app/DeleteMyData';
import Register from './auth/Register';
import SubscribeToGotham from './auth/SubscribeToGotham';

// import Authentication from './app/SecondScreen';
import RegisterSuccess from './app/RegisterSuccess';

const Routes = () => {
  const { account, isGettingAccount, resetEmail, hasResetPassword, isLinkedToTV } = useSelector(
    state => state.auth
  );
  const { selectedMVPD, mvpdAuthenticated } = useSelector(state => state.app.mvpdSelect);
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);
  const { urlVOD } = useSelector(state => state.app.watch);
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const match = useRouteMatch('/watch/:vodId?');
  useEffect(() => {
    dispatch(getRemoteConfig());
    dispatch(getLocalization());
    dispatch(getPlayers());
    // eslint-disable-next-line
  }, []);

  const userAgent = window.navigator.userAgent.toLowerCase();
  const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
  useEffect(() => {
    if (macosPlatforms.test(userAgent)) {
      dispatch(setIsMac(true));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (match?.params?.vodId && !urlVOD) {
      dispatch(getVODRequest(match?.params?.vodId));
    }
    // eslint-disable-next-line
  }, [match, urlVOD]);
  const hasDob = account?.profile?.contactMessage?.[0]?.dateOfBirth;
  const hasEmail = account?.profile?.contactMessage[0]?.email;

  useEffect(() => {
    if (getAccessToken() && !account.profile?.contactMessage[0]) {
      dispatch(
        getAccountRequest({
          GetContactRequestMessage: {
            apiUser: APIUSER,
            apiPassword: APIPASSWORD,
            channelPartnerID: CHANNELPARTNERID
          }
        })
      );
    }
    // eslint-disable-next-line
  }, [dispatch, account?.profile]);

  if (isGettingAccount) {
    return <PageLoading />;
  }

  if (isMaintenance) {
    return (
      <>
        <Header />
        <Maintenance />
        <Footer />
      </>
    );
  }

  return (
    <>
      <ToastNotification />
      {!hideHeaderFooter && <Header />}
      {(mvpdAuthenticated && selectedMVPD && isLinkedToTV) || (hasDob && hasEmail) ? (
        <AppRouter setHideHeaderFooter={setHideHeaderFooter} />
      ) : (
        <AuthenticateRoute
          pathname={pathname}
          resetEmail={resetEmail}
          hasDob={hasDob}
          hasResetPassword={hasResetPassword}
          search={search}
          setHideHeaderFooter={setHideHeaderFooter}
          selectedMVPD={selectedMVPD}
        />
      )}
      {!hideHeaderFooter && <Footer />}
    </>
  );
};

const AuthenticateRoute = ({
  pathname,
  resetEmail,
  hasResetPassword,
  hasDob,
  search,
  setHideHeaderFooter,
  selectedMVPD,
  mvpdAuthenticated
}) =>
  pathname.startsWith('/authenticate') ? (
    <DeviceAuthentication setHideHeaderFooter={setHideHeaderFooter} />
  ) : (
    <WebAuthentication
      resetEmail={resetEmail}
      hasResetPassword={hasResetPassword}
      search={search}
      setHideHeaderFooter={setHideHeaderFooter}
      selectedMVPD={selectedMVPD}
      mvpdAuthenticated={mvpdAuthenticated}
    />
  );

const DeviceAuthentication = ({ setHideHeaderFooter }) =>
  deivceAllowed ? (
    <Switch>
      {/* <Route exact path="/authenticate" component={Authentication} /> */}
      <Route
        exact
        path="/authenticate/:deviceId/:platform?/:activationCode?"
        render={props => <AppRouter setHideHeaderFooter={setHideHeaderFooter} {...props} />}
      />
      {/* <Route exact path="/authenticate/:deviceId?" component={AuthRouter} /> */}
      <Route exact path="/authenticate/register-success" component={RegisterSuccess} />
      <Route exact path="/register" component={Register} />
      <Redirect to="/authenticate" />
    </Switch>
  ) : (
    <Maintenance />
  );

const WebAuthentication = ({
  resetEmail,
  hasResetPassword,
  setHideHeaderFooter,
  selectedMVPD,
  mvpdAuthenticated
}) =>
  webAllowed ? (
    <Switch>
      {/* <Route exact path="/" component={AuthRouter} /> */}
      <Route
        exact
        path="/delete"
        render={props => <DeleteMyData setHideHeaderFooter={setHideHeaderFooter} {...props} />}
      />
      <Route exact path="/login" component={AuthRouter} />
      <Route exact path="/winback" component={WinbackRouter} />
      <Route exact path="/email-confirm" component={EmailConfirm} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/SubscribeToGotham" component={SubscribeToGotham} />
      <Route
        exact
        path="/watch/:vodId?"
        render={props => <AppRouter setHideHeaderFooter={setHideHeaderFooter} {...props} />}
      />
      <Route exact path="/process/reset" component={PasswordReset} />
      {resetEmail && <Route exact path="/email-sent" component={EmailSent} />}
      {hasResetPassword && (
        <Route exact path="/password-reset-success" component={PasswordResetSuccess} />
      )}
      {!mvpdAuthenticated && !selectedMVPD && <Route exact path="/" component={LandingPage} />}
    </Switch>
  ) : (
    <Maintenance />
  );

export default withRouter(Routes);
