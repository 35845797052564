const handlePlayerSelection = (player, setFollowedPlayers, followedPlayers) => {
  const isAlreadyInList = followedPlayers.some(p => p.playerId === player.player_id);

  if (isAlreadyInList) {
    setFollowedPlayers(prev => prev.filter(p => p.playerId !== player.player_id));
  } else {
    setFollowedPlayers(prev => [
      ...prev,
      { playerId: player.player_id, playerName: player.player_name }
    ]);
  }
};

export default handlePlayerSelection;
