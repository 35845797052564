/* eslint-disable max-len */
import axios from 'axios';
import { toastErrorNotify } from 'pages/toast/redux/actions';
import getGameIdentifierId from 'components/BitmovinPlayer/EaseLive/easeLive';

// Utility functions
const getEPGTricodes = (teams, type) => teams?.find(team => team.ty === type)?.c;
const getLiveTricodes = (teams, type) => teams?.find(team => team.teamLocationType === type)?.abbreviation;

const isNotEmpty = (str) => Boolean(str && str.length);
const leagueMappings = {
  basketball: 'NBA',
  baseball: 'MLB',
};
const getLeague = (sportsName) => leagueMappings[sportsName] || 'UNKNOWN';
const handleGameIdentifierError = (gameId, dispatch) => {
  if (typeof gameId !== 'string' && gameId?.status !== 200) {
    dispatch(toastErrorNotify({
      type: 'Ease Live Game Identifier Error',
      message: gameId?.data?.error_description,
    }));
  }
};

const getProgramID = async (currentEPG, tricode, currentChannel, setProgramId, setAwayTeam, setHomeTeam, setStartTime, dispatch, setLiveEvent) => {
  try {
    const liveData = tricode?.liveEvent ? await axios.get(tricode.liveEvent).then(res => res.data) : null;
    setLiveEvent(liveData?.eventTime && liveData?.teams?.length === 2 ? liveData : null);

    if (tricode?.easeLive?.programID) {
      // Directly setting values if programID exists
      setProgramId(tricode.easeLive.programID);
      setAwayTeam(tricode.easeLive.awayTeam);
      setHomeTeam(tricode.easeLive.homeTeam);
      setStartTime(tricode.easeLive.startTime);
      return;
    }

    let gameIdentifierId = 'invalid';
    if (currentEPG.ev_live === 'true') {
      const homeTricode = getEPGTricodes(currentEPG?.pgm?.tm, 'home');
      const awayTricode = getEPGTricodes(currentEPG?.pgm?.tm, 'away');
      if (isNotEmpty(homeTricode) && isNotEmpty(awayTricode) && homeTricode !== awayTricode) {
        const league = getLeague(currentEPG?.pgm?.spt_ty);
        setHomeTeam(homeTricode.toUpperCase());
        setAwayTeam(awayTricode.toUpperCase());
        setStartTime(currentEPG?.sc_st_dt);
        gameIdentifierId = await getGameIdentifierId(tricode, currentEPG.sc_st_dt, homeTricode, awayTricode, league);
      }
    } else if (currentChannel === 'linear' && liveData) {
      const eventTime = `${liveData.eventTime.replace(/[+-]\d{2}:\d{2}$/, '')}${liveData.eventTime.includes('Z') ? '' : 'Z'}`;
      const homeTricode = getLiveTricodes(liveData.teams, 'home').toUpperCase();
      const awayTricode = getLiveTricodes(liveData.teams, 'away').toUpperCase();
      const league = getLeague(liveData.sportsName);
      setHomeTeam(homeTricode);
      setAwayTeam(awayTricode);
      setStartTime(eventTime);
      gameIdentifierId = await getGameIdentifierId(tricode, eventTime, homeTricode, awayTricode, league);
    }
    handleGameIdentifierError(gameIdentifierId, dispatch);
    console.log('EaseLive Setting ProgramId to:', gameIdentifierId);
    setProgramId(gameIdentifierId);
  } catch (error) {
    console.log(error, 'Live endpoint error');
    dispatch(toastErrorNotify({
      type: 'Ease Live Game Identifier Error',
      message: 'Error fetching live event data',
    }));
  }
};

export default getProgramID;
