const checkIsUrlValid = url => {
  if (url === null || url === undefined || url === '') {
    console.log('emptyField');
    return false;
  }
  if (typeof url !== 'string') {
    console.log('invalidUrl');
    return false;
  }
  // check if url is absolute
  if (!/^https?:\/\/|^\/\//i.test(url)) {
    console.log('invalidUrl');
    return false;
  }
  if (window.location.protocol === 'https:' && /^http:\/\/|^\/\//i.test(url)) {
    console.log('mixedContentError');
    return false;
  }
  return true;
};

export default checkIsUrlValid;
