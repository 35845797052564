/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeServiceRequest,
  getActiveSubscriptions,
  getPackages
} from 'pages/auth/redux/actions';
import { maxBy } from 'lodash';
import moment from 'moment-timezone';
import { history } from '../../../redux/configureStore';

import './CreditCardPayment.scss';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';

const ChangePlan = () => {
  const [selectValue, setSelectValue] = useState(null);
  const dispatch = useDispatch();
  const {
    isMac,
    GetPkgsAndProdsWithPromosResponseMessage,
    GetActiveSubscriptionsResponseMessage
  } = useSelector(state => state.auth);
  const { geoLocation } = useSelector(state => state.app.deviceMVPD);

  useAnalyticsScreenTracking('Manage Subscription - Change Subscription');

  const activeSub =
    GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.filter(
      sub => sub.status === 'Active'
    )[0] || null;
  const finalBillSub =
    GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.filter(
      sub => sub.status === 'Final Bill'
    )[0] || null;

  let allowChange = false;
  let currentlyActiveId = null;

  const startDate1 = finalBillSub ? activeSub?.startDate : finalBillSub?.startDate;
  const startDate = startDate1 ? moment(startDate1).format('MMMM DD, YYYY') : null;

  const maxObject = maxBy(
    GetActiveSubscriptionsResponseMessage?.AccountServiceMessage,
    'validityTill'
  );
  const RenewalDate = GetActiveSubscriptionsResponseMessage
    ? moment(maxObject.validityTill).format('MMMM DD, YYYY')
    : null;

  if (!finalBillSub && activeSub) {
    allowChange = true;
    currentlyActiveId = activeSub.serviceID;
  }

  if (selectValue?.period === activeSub?.serviceID.split('.').at(-1)) {
    allowChange = false;
  }

  if (
    finalBillSub?.subscriptionStatus === 'Active - Cancelled' &&
    activeSub?.status === 'Active'
  ) {
    allowChange = false;
    currentlyActiveId = finalBillSub.serviceID;
  }

  const currentPlanName = activeSub?.displayName;
  const renewalMessage =
    finalBillSub?.subscriptionStatus === 'Active - Cancelled' && activeSub?.status === 'Active'
      ? `Your ${currentPlanName} plan will start on ${startDate}`
      : activeSub
      ? `Renews on ${RenewalDate}`
      : `Your plan is scheduled to be cancelled on ${RenewalDate}`;

  useEffect(() => {
    if (geoLocation !== 'undefined') {
      const zones = geoLocation;
      console.log('zones', zones);
      dispatch(getPackages(zones));
    }
  }, [geoLocation, dispatch]);

  useEffect(() => {
    dispatch(getActiveSubscriptions());
  }, [dispatch]);

  const UpdateSelectPlan = () => {
    const currentServiceID = activeSub?.serviceID;
    const newServiceID = selectValue?.packageID;
    if (currentServiceID !== newServiceID) {
      dispatch(
        changeServiceRequest({
          oldServiceID: currentServiceID,
          newServiceID
        })
      );
    }
    history.push({
      pathname: '/watch/?settings=true',
      search: window.location.search
    });
  };

  const cancel = () => {
    history.push({
      pathname: '/CancelSubscription',
      search: window.location.search
    });
  };

  const changeSelectedPlan = planData => {
    setSelectValue(planData);
  };

  // const isAnyActivePromotions = plan => {
  //   const currentDate = new Date();
  //   const result = plan?.map((item) => {
  //     if (currentDate >= item.startDate && currentDate <= item.endDate) {
  //       return true;
  //     }
  //     return false;
  //   });
  //   const isActivePromtion = result?.includes(true);
  //   return isActivePromtion;
  // };

  return (
    <div>
      <div className="plans">
        {GetPkgsAndProdsWithPromosResponseMessage?.pkgWithPromos?.map(plan => (
          <button
            disabled={
              finalBillSub?.subscriptionStatus === 'Active - Cancelled' &&
              activeSub?.status === 'Active' &&
              finalBillSub?.serviceID !== plan?.pkgMsg?.packageID
            }
            className={classNames('plan-card', {
              planCardSelected:
                (currentlyActiveId === plan?.pkgMsg?.packageID && !selectValue) ||
                selectValue?.period === plan?.pkgMsg?.packageID.split('.').at(-1)
            })}
            onClick={() =>
              changeSelectedPlan({
                period: plan?.pkgMsg?.packageID.split('.').at(-1),
                price: plan?.pkgMsg?.retailPrice,
                packageID: plan?.pkgMsg?.packageID
              })
            }
          >
            <div className="plan-name">{plan?.pkgMsg?.displayName}</div>
            <div className="plan-price">
              {plan.promoMsg === null
                ? plan.pkgMsg.retailPrice
                : plan?.promoMsg[0]?.promotionPrice}{' '}
              / {plan.pkgMsg.period === 'NoOfYears' ? 'year' : 'month'}
            </div>
            <div className="plan-plus-tax">Plus applicable taxes</div>
            <br />
            {currentlyActiveId !== plan?.pkgMsg?.packageID && (
              <div className="plan-description">Cancel anytime</div>
            )}
            {currentlyActiveId === plan?.pkgMsg?.packageID && <p>CURRENT PLAN</p>}
          </button>
        ))}
      </div>
      <br />

      <div className="change-your-plan">
        <div>{renewalMessage}</div>
        <button
          disabled={!allowChange || !selectValue}
          className={`${isMac ? 'IOS' : ''} primary__button`}
          onClick={() => UpdateSelectPlan()}
        >
          CHANGE PLAN
        </button>
        <br />
        {activeSub && (
          <span className="link-blue" onClick={cancel}>
            CANCEL SUBSCRIPTION
          </span>
        )}
      </div>
    </div>
  );
};

export default ChangePlan;
