import React from 'react';
import ReactDOM from 'react-dom';
import './styles/_global.scss';
import * as braze from '@braze/web-sdk';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BRAZE_API_KEY } from './config';

braze.initialize(BRAZE_API_KEY, {
  baseUrl: 'sdk.iad-05.braze.com',
  enableLogging: true
});

braze.isPushSupported();
braze.requestPushPermission();
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
