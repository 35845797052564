import { combineReducers } from 'redux';
import deviceMVPDReducer from '../DeviceMVPD/redux/reducers';

import mvpdSelectReducer from '../MVPDSelect/redux/reducers';
import teamSelectReducer from '../TeamSelect/redux/reducers';
import WatchReducer from '../Watch/redux/reducers';

export default combineReducers({
  mvpdSelect: mvpdSelectReducer,
  deviceMVPD: deviceMVPDReducer,
  teamSelect: teamSelectReducer,
  watch: WatchReducer
});
