export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

export const GET_QUICKPLAY_OAUTH_TOKEN_REQUEST = 'GET_QUICKPLAY_OAUTH_TOKEN_REQUEST';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS';
export const UPDATE_ACCOUNT_DETAILS_SUCCESS = 'UPDATE_ACCOUNT_DETAILS_SUCCESS';
export const UPDATE_ACCOUNT_DETAILS_ERROR = 'UPDATE_ACCOUNT_DETAILS_ERROR';

export const SIGNIN_REQUEST = 'SIGNIN_REQUEST';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNIN_ERROR = 'SIGNIN_ERROR';

export const GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR';

export const GET_FAVORITE_REQUEST = 'GET_FAVORITE_REQUEST';
export const GET_FAVORITE_SUCCESS = 'GET_FAVORITE_SUCCESS';
export const GET_FAVORITE_ERROR = 'GET_FAVORITE_ERROR';

export const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR';

export const GET_PLAYER_REQUEST = 'GET_PLAYER_REQUEST';
export const GET_PLAYER_SUCCESS = 'GET_PLAYER_SUCCESS';
export const GET_PLAYER_ERROR = 'GET_PLAYER_ERROR';

export const SOCIAL_SIGNIN_REQUEST = 'SOCIAL_SIGNIN_REQUEST';
export const SOCIAL_SIGNIN_SUCCESS = 'SOCIAL_SIGNIN_SUCCESS';
export const SOCIAL_SIGNIN_ERROR = 'SOCIAL_SIGNIN_ERROR';

export const EMAIL_CONFIRM_REQUEST = 'EMAIL_CONFIRM_REQUEST';
export const EMAIL_CONFIRM_SUCCESS = 'EMAIL_CONFIRM_SUCCESS';
export const EMAIL_CONFIRM_ERROR = 'EMAIL_CONFIRM_ERROR';

export const PASSWORD_RESET_REQUEST = 'PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR';

export const GET_SUBSCRIPTIONS_REQUEST = 'GET_SUBSCRIPTIONS_REQUEST';
export const GET_SUBSCRIPTIONS_SUCCESS = 'GET_SUBSCRIPTIONS_SUCCESS';
export const GET_SUBSCRIPTIONS_ERROR = 'GET_SUBSCRIPTIONS_ERROR';
export const GET_CONTINUE_FROM_THE_PLAYERS = 'GET_CONTINUE_FROM_THE_PLAYERS';

export const SIGN_OUT = 'SIGN_OUT';

export const DELETE_DATA_REQUEST = 'DELETE_DATA_REQUEST';
export const DELETE_DATA_SUCCESS = 'DELETE_DATA_SUCCESS';
export const DELETE_DATA_ERROR = 'DELETE_DATA_ERROR';

export const SEARCH_ACCOUNT_REQUEST = 'SEARCH_ACCOUNT_REQUEST';
export const SEARCH_ACCOUNT_SUCCESS = 'SEARCH_ACCOUNT_SUCCESS';
export const SEARCH_ACCOUNT_ERROR = 'SEARCH_ACCOUNT_ERROR';

export const GET_ACTIVE_SUBSCRIPTIONS = 'GET_ACTIVE_SUBSCRIPTIONS';
export const SET_ACTIVE_SUBSCRIPTIONS_SUCCESS = 'SET_ACTIVE_SUBSCRIPTIONS_SUCCESS';
export const SET_ACTIVE_SUBSCRIPTIONS_ERROR = 'SET_ACTIVE_SUBSCRIPTIONS_ERROR';

export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS';
export const GET_PACKAGES_ERROR = 'GET_PACKAGES_ERROR';

export const SET_IS_MAC = 'SET_IS_MAC';
export const LINK_TO_TV_SUCESS = "LINK_TO_TV_SUCESS";

export const GET_PAYMENTS_REQUEST = 'GET_PAYMENTS_REQUEST';
export const GET_PAYMENTS_SUCCESS = 'GET_PAYMENTS_SUCCESS';
export const GET_PAYMENTS_ERROR = 'GET_PAYMENTS_ERROR';

export const CHANGE_SERVICE_REQUEST = 'CHANGE_SERVICE_REQUEST';
export const CHANGE_SERVICE_SUCCESS = 'CHANGE_SERVICE_SUCCESS';
export const CHANGE_SERVICE_ERROR = 'CHANGE_SERVICE_ERROR';

export const SET_SUBSCRIPTION_TYPE = 'SET_SUBSCRIPTION_TYPE';
