import React from 'react';
import ArrowIcon from '../../../assets/icons/ArrowIcon';
import { history } from '../../../redux/configureStore';
import getDeviceType from '../../../utils/device';
import AccountIcon from '../../../assets/svgs/account_icon.svg';

const PageHeader = ({ ...props }) => {
  const { showBackIcon, backUrl, backText = 'Back', onBackClick, showStepper, activeStep, stepperText, titleHeader, lengthStepper, showAccountIcon, setIsSettingsOpen, isSettingsOpen } = props;
  return (
    <div className={`header${!showBackIcon ? ' just-stepper' : showStepper ? ' with-stepper' : ''} ${backText === 'Account & Settings' || 'Manage subscription' ? 'long-text' :''}`}>
      {showBackIcon && (
        <div className={`back-section ${showStepper ? 'with-stepper' : ''}`}>
          <button
            onClick={backUrl ? () => history.push({
              pathname: `${backUrl}`,
              search: window.location.search
            }) : onBackClick}
          >
            {backText}
            <ArrowIcon color="#FFFFFF" />
          </button>
        </div>
      )}
      {showStepper ? (
        <div className="stepper-section">
          {lengthStepper === 2 ? (
            activeStep === 1 ? <img src={`${process.env.PUBLIC_URL}/stepper 1.png`} alt="stepper" /> :
            <img src={`${process.env.PUBLIC_URL}/stepper 2.png`} alt="stepper" />) : (
            activeStep === 1 ? <img src={`${process.env.PUBLIC_URL}/Step1of3.png`} alt="stepper" /> :
              activeStep === 2 ? <img src={`${process.env.PUBLIC_URL}/Step2of3.png`} alt="stepper" /> :
              <img src={`${process.env.PUBLIC_URL}/Step3of3.png`} alt="stepper" />
          )}
          <div className="stepper-text">{stepperText}</div>
        </div>
      ) : (
        titleHeader && (
          <div
            className='header-title'
            onClick={() => history.push({
              pathname: `${getDeviceType()}`,
              search: window.location.search
            })}
          >
            {titleHeader}
          </div>
        ))}
      {showAccountIcon && (
        <div
          className="settings-icon"
          onClick={() => {
            setIsSettingsOpen(!isSettingsOpen);
            window.location.reload();
          }}
        >
          <img src={AccountIcon} alt="Settings" />
        </div>
      )}
    </div>
  );
};
export default PageHeader;
