import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import MVPDPicker from './MVPDPicker';
import './MVPDSelect.scss';
import PageWrapper from '../../layoutPage';
import NewLayout from '../../../containers/AuthLayout/NewLayout';

const MVPDSelect = (match) => {
  const { url } = useRouteMatch();
  return (
    <PageWrapper
      showHeader
      showBackIcon
      backUrl="/"
    >
      <NewLayout url={url}>
        <h2>Select your Television Service Provider</h2>
        <p>I subscribe to YES Network with a cable or satellite TV provider</p>
        <MVPDPicker isOnBoarding={true} match={match} />
      </NewLayout>
    </PageWrapper>
  );
};

export default MVPDSelect;
