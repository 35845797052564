import React from 'react';
import './SubscriptionUnavailable.scss';
import NewLayout from 'containers/AuthLayout/NewLayout';
import PageWrapper from 'pages/layoutPage/PageWrapper';
import { segmentEvent } from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import { history } from '../../../redux/configureStore';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';

const SubscriptionUnavailable = ({ isReload }) => {
  const baseUrl = process.env.PUBLIC_URL;

  useAnalyticsScreenTracking(
    'Subscribe - Subscription Unavailable',
    'SubscriptionUnavailable',
    '/subscriptionUnavailable'
  );

  const continueButton = () => {
    segmentEvent('Continued Without Subscription', {});
    if (isReload) {
      window.location.reload();
    } else {
      history.push({
        pathname: '/watch',
        search: window.location.search
      });
    }
  };

  return (
    <PageWrapper
      showHeader
      showTitle
      titleText="PURCHASE A PLAN"
      activeStep={2}
      stepperText="Step 2 of 3: Purchase a Plan"
      showStepper
      lengthStepper={3}
    >
      <NewLayout>
        <div className="all">
          <div className="message">Subscription Unavailable</div>
          <img className="location" src={`${baseUrl}/location.png`} alt="location" />
          <div className="second-message">
            You appear to be outside YES Network’s core viewing territory. YES Network is a
            regional sports network servicing New York, Connecticut and portions of New Jersey and
            Pennsylvania.
          </div>

          <div className="more">
            Please see our &nbsp;
            <a className="link-blue" href='https://yesnetworkhelp.zendesk.com/hc/en-us' target="_blank" rel="noreferrer noopener">YES FAQ</a>
            &nbsp; for additional information and assistance.
          </div>

          <span className="link-blue" onClick={continueButton}>
            CONTINUE WITHOUT SUBSCRIPTION
          </span>
        </div>
      </NewLayout>
    </PageWrapper>
  );
};

export default SubscriptionUnavailable;
