import React from 'react';

const YesCard = ({ children, hint, title, subTitle }) => (
  <div className="yes-card">
    <div className="yes-card__header">
      {hint && <p className="hint">{hint}</p>}
      <h2 className="pts-b-db-30">{title}</h2>
      {subTitle ? (
        <p className="sub-title">{subTitle}</p>
      ) : (
        <p className="sub-title"> </p>
      )}
    </div>
    <div className="yes-card__body">{children}</div>
  </div>
);

export default YesCard;




