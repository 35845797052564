import React from 'react';
import Iframe from 'react-iframe';
import './Footer.scss';

const windowInnerWidth  = window.innerWidth;
const isMobile = windowInnerWidth < 1024;

const Footer = () => (
  <div className='footer-container'>
    <Iframe
      url="https://www.yesnetwork.com/thirdparty/footer"
      width="100%"
      height="185px"
      id="myId"
      display="initial"
      position="relative"
      className="iframe-footer"
      scrolling={isMobile ? 'yes' : 'no'}
    />
  </div>
);

export default Footer;
