import React, { useEffect } from 'react';

import './TeamSelect.scss';
import eventAnalytics from 'utils/eventAnalytics';
import NewLayout from 'containers/AuthLayout/NewLayout';
import PageWrapper from 'pages/layoutPage/PageWrapper';
import TeamPicker from './TeamPicker';
import { useSelector } from 'react-redux';
import PageLoading from '../../../components/PageLoading';

const TeamSelect = ({ isOnboarding, goBack }) => {
  useEffect(() => {
    eventAnalytics('page', 'Registration - Select Team');
  }, []);
  const { isDeviceAuthorized } = useSelector(state => state.app.deviceMVPD);
  const { selectedMVPD } = useSelector(state => state.app.mvpdSelect);
  const { isGettingFavorite } = useSelector(state => state.auth);

  if (isGettingFavorite) {
    return <PageLoading />;
  }

  return (
    <PageWrapper
      showHeader
      showTitle
      showBackIcon={!isOnboarding}
      onBackClick={() => goBack()}
      titleText="PICK & FOLLOW"
      titleSubText="YOUR FAVORITE TEAMS"
      activeStep={3}
      stepperText={!selectedMVPD && !isDeviceAuthorized ? "Step 3 of 3: Pick Your Favorites" : "Step 2 of 2: Pick Your Favorites"}
      showStepper={isOnboarding}
      lengthStepper={!selectedMVPD && !isDeviceAuthorized ? 3 : 2}
    >
      <NewLayout>
        <TeamPicker isOnboarding={isOnboarding}  goBack={goBack} />
      </NewLayout>
    </PageWrapper>
  );
};

export default TeamSelect;
