import HmacSHA1 from 'crypto-js/hmac-sha1';
import EncBase64 from 'crypto-js/enc-base64';
import { v1 as uuidv1 } from 'uuid';
import { getBrowserName } from 'utils/checkBrowserNotifications';

const ADOBE_REQUEST_URI = '/reggie/v1/YESN/regcode';
const ADOBE_SIGNATURE_METHOD = 'HMAC-SHA1';
const MinLimitAge = 13;
const MaxLimitAge = 121;
const MaxVOD = 15;
const APIUSER = process.env.REACT_APP_API_APIUSER;
const CHANNELPARTNERID = process.env.REACT_APP_API_CHANNELPARTNERID;
const US = 'us';
const deviceType = 'Web';
const deviceName = getBrowserName();
const modelNo = '04ries';
const userAgent = 'USA';
const APIPASSWORD = process.env.REACT_APP_API_PASSWORD;
const YES_CLIENT_ID = 7777;
const CONVIVA_CUSTOMER_KEY = process.env.REACT_APP_CONVIVA_CUSTOMER_KEY ?? '';
const CONVIVA_PLAYER_NAME = process.env.REACT_APP_CONVIVA_PLAYER_NAME ?? '';
const CHANNEL_NAME = process.env.REACT_APP_CONVIVA_CHANNEL_NAME ?? '';
const APP_ENV = process.env.REACT_APP_ENV;
const CONVIVA_GATEWAY_URL = process.env.REACT_APP_CONVIVA_GATEWAY_URL;
const CONVIVA_FRAMEWORK = 'Bitmovin';
const BRAZE_API_KEY = process.env.REACT_APP_BRAZE_API_KEY;

const baseUrl = {
  quickplay: process.env.REACT_APP_QUICKPLAY_API,
  quickplayFlatToken: process.env.REACT_APP_QUICKPLAY_FLATTOKEN_API,
  quickplayRegisterDevice: process.env.REACT_APP_QUICKPLAY_REGISTER_DEVICE_API,
  quickplayDataStores: process.env.REACT_APP_QUICKPLAY_DATA_STORES,
  evergent: process.env.REACT_APP_EVERGENT_API,
  istreamPlant: process.env.REACT_APP_ISTREAMPLANT_API,
  adobeAuth: process.env.REACT_APP_ADOBE_AUTH_API,
  remoteConfig: process.env.REACT_APP_REMOTE_CONFIG,
  localization: process.env.REACT_APP_LOCALIZATION,
  players: process.env.REACT_APP_PLAYER,
  easeliveProgram: process.env.REACT_EASELIVE,
  quickPlayAuthorization: process.env.REACT_APP_QUICKPLAY_AUTHORIZATION,
  quickPlayStorefrontCatalogue: process.env.REACT_APP_STOREFRONT_CATALOGUE,
  quickPlayStream: process.env.REACT_APP_QUICKPLAY_STREAM_URL,
  quickPlayHeartbeat: process.env.REACT_APP_QUICKPLAY_HEARTBEAT_URL,
  quickplayGeoLocation: process.env.REACT_APP_QUICKPLAY_GEO_LOCATION,
  quickplayOverflow: process.env.REACT_APP_QUICKPLAY_OVERFLOW,
  evergentIframe: process.env.REACT_APP_EVERGENT_SUBSCRIPTION_IFRAME
};

const apiToken = process.env.REACT_APP_ISTREAMPLANT_API_TOKEN;
const getWebDeviceId = () => {
  let deviceId = localStorage.getItem('deviceId');
  if (!deviceId) {
    deviceId = uuidv1();
    localStorage.setItem('deviceId', deviceId);
  }
  return deviceId;
};
const VODPageID = process.env.REACT_APP_VOD_PAGE_ID;

const VODFreePackage = ['urn:package:tve:free', 'urn:package:Free_Pkg'];

/**
 * Adobe pass
 */
const adobeAuthConfig = `GET requestor_id=${process.env.REACT_APP_ADOBE_REQUESTOR_ID}, nonce=${process.env.REACT_APP_ADOBE_NONCE
  }, signature_method=${ADOBE_SIGNATURE_METHOD}, request_time=${new Date().getTime()}, request_uri=${ADOBE_REQUEST_URI}`;
const securityKeyHash = HmacSHA1(adobeAuthConfig, process.env.REACT_APP_ADOBE_SECURITY_KEY);
const securityKeyInBase64 = EncBase64.stringify(securityKeyHash);
const adobeToken = `${adobeAuthConfig}, public_key=${process.env.REACT_APP_ADOBE_PUBLIC_KEY}, signature=${securityKeyInBase64}`;

const adobeSDKUrl = process.env.REACT_APP_ADOBE_SDK;
const adobePassRequestorId = process.env.REACT_APP_ADOBE_REQUESTOR_ID;
const softwareStatement = process.env.REACT_APP_ADOBE_SOFTWARE_STATEMENT;
const setRequestorEndPoint = process.env.REACT_APP_ADOBE_SETREQUESTOR_ENDPOINT;
const bitmovincdnProviderConfig = 'akamai';

/** App setting */
const deivceAllowed = process.env.REACT_APP_DEVICE_ALLOWED === 'true';
const webAllowed = process.env.REACT_APP_WEB_ALLOWED === 'true';
const isMaintenance = process.env.REACT_APP_IS_MAINTENANCE === 'true';
const easeLive = process.env.REACT_APP_EASELIVE;

export {
  baseUrl,
  apiToken,
  getWebDeviceId,
  adobeToken,
  MinLimitAge,
  MaxLimitAge,
  adobeSDKUrl,
  adobePassRequestorId,
  softwareStatement,
  setRequestorEndPoint,
  VODPageID,
  deivceAllowed,
  webAllowed,
  isMaintenance,
  easeLive,
  MaxVOD,
  APIUSER,
  APIPASSWORD,
  CHANNELPARTNERID,
  US,
  deviceType,
  deviceName,
  modelNo,
  userAgent,
  VODFreePackage,
  YES_CLIENT_ID,
  bitmovincdnProviderConfig,
  CONVIVA_CUSTOMER_KEY,
  CONVIVA_PLAYER_NAME,
  CHANNEL_NAME,
  APP_ENV,
  CONVIVA_GATEWAY_URL,
  CONVIVA_FRAMEWORK,
  BRAZE_API_KEY
};
