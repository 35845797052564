import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import './CreditCardPayment.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { getPackages } from 'pages/auth/redux/actions';
import PageLoading from 'components/PageLoading';
import PageWrapper from '../../layoutPage/PageWrapper';
import SelectPlan from './SelectPlan';
import Payment from './Payment';
import SubscriptionUnavailable from '../SubscriptionUnavailable';
import { getGeoLocationRequest } from '../DeviceMVPD/redux/actions';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';

const CreditCardPayment = ({ goBack, isReload = false }) => {
  const { account } = useSelector(state => state.auth);
  const { geoLocation, isGettingGeoLocation } = useSelector(state => state.app.deviceMVPD);
  const queryParams = queryString.parse(useLocation().search);
  const isSocial =
    ['Google', 'Facebook', 'Apple'].indexOf(account?.profile?.socialLoginType) !== -1;
  const dispatch = useDispatch();

  useAnalyticsScreenTracking(
    'Subscribe - Payment Gateway',
    'CreditCardPayment',
    '/creditCardPayment'
  );

  // winback flag
  const winbackReloadFlag = queryParams.reload;

  useEffect(() => {
    if (geoLocation !== 'undefined') {
      const zones = geoLocation;
      dispatch(getPackages(zones));
    }
    if (geoLocation === null) dispatch(getGeoLocationRequest());
  }, [geoLocation, dispatch]);

  if (isGettingGeoLocation) {
    return <PageLoading />;
  }

  if (geoLocation?.zone === 'unavailable' || geoLocation === null) {
    return <SubscriptionUnavailable isReload={isReload} />;
  }

  return (
    <PageWrapper
      showHeader
      showBackIcon={goBack}
      onBackClick={() => goBack()}
      showTitle
      titleText="PURCHASE A PLAN"
      activeStep={2}
      stepperText={`Step 2 of ${isSocial ? 2 : 3}: Purchase a Plan`}
      showStepper={isSocial || !goBack}
      lengthStepper={isSocial ? 2 : 3}
    >
      <Row className="credit-card-screen">
        <Col className="plans-section">
          <SelectPlan isUpdateMode={false} />
        </Col>
        <Col className="center-border" />
        <Col className="payment-section">
          <Payment
            isUpdateMode={false}
            queryParams={queryParams}
            isReload={isReload}
            winbackReloadFlag={winbackReloadFlag}
          />
        </Col>
      </Row>
    </PageWrapper>
  );
};

export default CreditCardPayment;
