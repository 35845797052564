const getUserType = (user, numberOfActivePackage) => {
  let convivaUserType = '';
  let userType = '';
  switch (user) {
    case 'dtc':
      userType = 'dtc';
      convivaUserType = 'DTC';
      break;
    case 'tve':
      userType = numberOfActivePackage;
      convivaUserType = numberOfActivePackage;
      break;
    default:
      userType = 'dtc';
      convivaUserType = 'DTC';
      break;
  }
  return { userType, convivaUserType };
};

export default getUserType;
