/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable max-len */
/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Player, PlayerEvent, TimeMode } from 'bitmovin-player';
import {
  segmentEvent,
  VideoPlaybackPaused,
  VideoPlaybackBufferStarted,
  VideoPlaybackBufferCompleted,
  VideoPlaybackSeekStarted,
  VideoPlaybackSeekCompleted,
  VideoPlaybackCompleted,
  VideoAdBreakStarted,
  VideoAdBreakCompleted,
  VideoAdStarted,
  VideoAdSkipped,
  VideoAdCompleted,
  VideoPlaybackError,
  VideoContentCompleted,
  VideoContentPlaying,
  VideoQualityUpdated,
  VideoPlaybackResumed,
  VideoPlaybackStarted,
  VideoTimeChanged,
  ContentLoadError,
  VideoStartSession
} from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import checkIsUrlValid from 'helpers/urlValidation';
import 'bitmovin-player/bitmovinplayer-ui.css';
import './BitmovinPlayer.scss';
import moment, { duration } from 'moment-timezone';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectionIp } from 'utils/video';
import { getUser } from 'utils/localStorage';
import { videoPlayer, ovp, playerName } from 'utils/consts';
import { trackStreamStartRequest } from 'pages/app/Watch/redux/actions';
import axios from 'axios';
import getProgramID from 'components/BitmovinPlayer/EaseLive/easeLiveProgramId';
import getEaseLiveConfig from 'components/BitmovinPlayer/EaseLive/getEaseLiveConfig';
import { UIFactory } from '../ease-live-bitmovin-player-ui/src/index';
import '../ease-live-bitmovin-player-ui/src/style.css';
import { getWebDeviceId, bitmovincdnProviderConfig } from '../../config';
import playerReadyEventBinder from './AdobeAnalytics/PlayerReadyEventBinder';
import ConvivaProxy from '../../services/ConvivaProxy';
import { getAdvertisingConfig } from './Advertising/Advertising';

let player = null;
let playerUi = null;
let easeLiveComponent = null;
let videoStreamingTracker = null;
let VideoSegmentTracker = null;
let hasId3Timecode = false;
let hasPdtTimecode = false;
let timeCode = 0;
let propertiesContent = {};
// let userType = '';
let videoSegmentPaused = false;
const LIVE_STARTS = 'liveStats';
const WATCH_PARTY = 'watchParty';
const PLAY = 'play';

const BitmovinPlayer = ({
  source,
  entityId,
  orbisId,
  entityDuration = {},
  entityType = {},
  autoPlay = false,
  videoName = '',
  setVideoEnd = '',
  mvpd = '',
  startAnalytics,
  vod = null,
  currentChannel,
  sceenicId,
  selectedMVPD,
  signedUrls,
  startDate,
  convivaSessionStart,
  isVODReplayed,
  setIsVODReplayed,
  convivaUserType,
  userType
}) => {
  const playerEl = useRef(null);
  const [adsObj, setAdsObj] = useState(null);
  const [adsSecond, setAdsSecond] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [adsUrl, setAdsUrl] = useState(null);
  const dispatch = useDispatch();
  const account = useSelector(state => state.auth.account);
  const [time, setTime] = useState(0);
  const [programId, setProgramId] = useState('');
  const [liveEvent, setLiveEvent] = useState(null);
  const { currentScheduleItem, remoteConfig, heartbeatToken, overflowChannels, linearChannels } =
    useSelector(state => state.app.watch);
  const [homeTeam, setHomeTeam] = useState('');
  const [awayTeam, setAwayTeam] = useState('');
  const [startTime, setStartTime] = useState('');
  const connectionIp = getConnectionIp();
  const isAdsDisplayable = remoteConfig?.isAdsEnabled && connectionIp;

  const requiredEntityType = (entityType && entityType[entityId]) ?? '';

  let videoNameMetaData;
  let videoDescription;
  let videoIdValue;
  let seriesNameConvivaMetaData;

  if (requiredEntityType === 'VOD') {
    videoNameMetaData = videoName;
    videoDescription = videoName;
    videoIdValue = entityId || '';
    seriesNameConvivaMetaData = null;
  } else if (requiredEntityType === 'linear') {
    videoNameMetaData = linearChannels[0]?.pgm?.lon
      ? linearChannels[0]?.pgm?.lon[0].n
      : linearChannels[0]?.pgm?.loepn[0].n;
    videoDescription = linearChannels[0]?.pgm?.lod
      ? linearChannels[0]?.pgm?.lod[0]?.n
      : linearChannels[0]?.pgm?.lostl[0]?.n;
    videoIdValue = linearChannels[0]?.pgm_id || '';
    seriesNameConvivaMetaData = linearChannels[0]?.pgm?.lostl
      ? linearChannels[0]?.pgm?.lostl[0].n
      : null;
  } else {
    videoNameMetaData = overflowChannels.length ? overflowChannels[0]?.lon[0]?.n : 'Overflow';
    videoDescription = overflowChannels.length ? overflowChannels[0]?.lon[0]?.n : 'Overflow';
    videoIdValue = overflowChannels.length ? overflowChannels[0]?.id : '';
    seriesNameConvivaMetaData = null;
  }

  const convivaLiveFlag = currentScheduleItem?.ev_live === 'true' && !!currentScheduleItem.pgm?.tm;

  const userId = account?.profile?.spAccountID || '';

  const wasPaused = useRef(false);

  const addPlayerEventListeners = () => {
    player.on(PlayerEvent.Play, () => {
      if (wasPaused.current) {
        segmentEvent(VideoPlaybackResumed, propertiesContent);
        ConvivaProxy.track(VideoPlaybackResumed);
        wasPaused.current = false;
      }
    });

    player.on(PlayerEvent.Paused, () => {
      videoSegmentPaused = true;
      clearInterval(VideoSegmentTracker);
      segmentEvent(VideoPlaybackPaused, propertiesContent);
      ConvivaProxy.track(VideoPlaybackPaused);
      wasPaused.current = true;
    });

    player.on(PlayerEvent.PlaybackFinished, () => {
      segmentEvent(VideoContentCompleted, propertiesContent);
      ConvivaProxy.track(VideoContentCompleted);
      if (requiredEntityType === 'VOD') {
        ConvivaProxy.track(VideoPlaybackCompleted);
      }
      player.setViewMode('inline');
      clearInterval(VideoSegmentTracker);
      setVideoEnd(true);
    });

    player.on(PlayerEvent.TimeChanged, () => {
      if (player.isPlaying()) {
        ConvivaProxy.track(VideoTimeChanged, {
          time: player.getCurrentTime(TimeMode.RelativeTime) || 0
        });
      }
    });

    player.on(PlayerEvent.Destroy, () => {
      segmentEvent(VideoPlaybackCompleted, propertiesContent);
      ConvivaProxy.track(VideoPlaybackCompleted);
      ConvivaProxy.endSession();
    });

    player.on(PlayerEvent.SourceUnloaded, () => {
      segmentEvent(VideoPlaybackCompleted, propertiesContent);
      ConvivaProxy.track(VideoPlaybackCompleted);
      ConvivaProxy.endSession();
    });

    player.on(PlayerEvent.Seek, () => {
      segmentEvent(VideoPlaybackSeekStarted, propertiesContent);
      const currentTime = player.getCurrentTime(TimeMode.RelativeTime);
      ConvivaProxy.track(VideoPlaybackSeekStarted, { seekPosition: currentTime });
    });

    player.on(PlayerEvent.Seeked, () => {
      const currentTime = player.getCurrentTime(TimeMode.RelativeTime);
      segmentEvent(VideoPlaybackSeekCompleted, propertiesContent);
      ConvivaProxy.track(VideoPlaybackSeekCompleted, { seekPosition: currentTime });
    });

    player.on(PlayerEvent.StallStarted, () => {
      segmentEvent(VideoPlaybackBufferStarted, propertiesContent);
      ConvivaProxy.track(VideoPlaybackBufferStarted);
    });

    player.on(PlayerEvent.StallEnded, () => {
      segmentEvent(VideoPlaybackBufferCompleted, propertiesContent);
      ConvivaProxy.track(VideoPlaybackBufferCompleted);
    });

    player.on(PlayerEvent.Playing, () => {
      ConvivaProxy.track(VideoPlaybackStarted);
    });

    player.on(PlayerEvent.TimeShift, () => {
      const currentTime = player.getCurrentTime(TimeMode.RelativeTime);
      segmentEvent(VideoPlaybackSeekStarted, propertiesContent);
      ConvivaProxy.track(VideoPlaybackSeekStarted, { seekPosition: currentTime });
    });

    player.on(PlayerEvent.TimeShifted, () => {
      ConvivaProxy.track(VideoPlaybackSeekCompleted);
    });

    player.on(PlayerEvent.AdBreakStarted, event => {
      segmentEvent(VideoAdBreakStarted, propertiesContent);
    });
    player.on(PlayerEvent.AdStarted, event => {
      segmentEvent(VideoAdStarted, propertiesContent);
    });
    player.on(PlayerEvent.AdBreakFinished, event => {
      segmentEvent(VideoAdBreakCompleted, propertiesContent);
    });
    player.on(PlayerEvent.AdSkipped, event => {
      segmentEvent(VideoAdSkipped, propertiesContent);
    });
    player.on(PlayerEvent.AdFinished, event => {
      segmentEvent(VideoAdCompleted, propertiesContent);
    });

    // Track playback Error
    player.on(PlayerEvent.Error, error => {
      if (convivaSessionStart === undefined || convivaSessionStart === true) {
        ConvivaProxy.setAssetContext({
          id: videoIdValue,
          assetName: videoNameMetaData,
          type: requiredEntityType ?? '',
          duration: entityDuration[entityId],
          source,
          seriesName: seriesNameConvivaMetaData
        });
        ConvivaProxy.track(VideoStartSession);
        const errorMessage = error.data
          ? error.data['error-message'] ?? error.message
          : error.data;
        ConvivaProxy.track(ContentLoadError, { error: errorMessage });
      }
      videoSegmentPaused = false;
      segmentEvent(VideoPlaybackError, propertiesContent);
      clearInterval(VideoSegmentTracker);
    });

    // Quality of Experience
    player.on(PlayerEvent.VideoPlaybackQualityChanged, event => {
      const videoEvent = event;
      const {
        targetQuality: { bitrate = 0, frameRate = 0 }
      } = videoEvent;
      const droppedFrames = player?.getDroppedVideoFrames() || 0;
      const startupTime = player?.getCurrentTime(TimeMode.RelativeTime) || 0;
      const qoeInfo = {
        'qoe.bitrate': bitrate,
        'qoe.droppedFrames': droppedFrames,
        'qoe.fps': frameRate,
        'qoe.startupTime': startupTime
      };
      segmentEvent(VideoQualityUpdated, qoeInfo);
      ConvivaProxy.track(VideoQualityUpdated, {
        bitrate,
        droppedFrames,
        framerate: frameRate,
        startupTime
      });
    });
  };

  useEffect(() => {
    if (currentScheduleItem?.cid)
      getProgramID(
        currentScheduleItem,
        remoteConfig,
        currentChannel,
        setProgramId,
        setAwayTeam,
        setHomeTeam,
        setStartTime,
        dispatch,
        setLiveEvent
      );
  }, [currentScheduleItem, remoteConfig, currentChannel]);
  const pollEaseLive = async programId => {
    try {
      const program = await axios.get(
        `https://yes-cdn.cloud.easelive.tv/api/v1/programs/live/${programId}`
      );
      console.log('EaseLive ProgramId for Polling: ', programId);
      console.log('EaseLive Polling Response: ', program);
      const { scheduledEndTime } = program.data;
      const diff = moment().diff(scheduledEndTime);
      if (diff >= 0) {
        setProgramId('invalid');
      }
    } catch (err) {
      setProgramId('invalid');
    }
  };
  // check for gameidentifier id of ease live to change in every one minute
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        programId &&
        programId !== 'invalid' &&
        remoteConfig?.easeLive?.programID === '' &&
        programId !== ''
      ) {
        pollEaseLive(programId);
      }
    }, 60000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [programId]);

  useEffect(() => {
    if (easeLiveComponent && programId && source) {
      if (programId === 'invalid') {
        setTimeout(() => {
          easeLiveComponent.hideEaseLive();
          easeLiveComponent.setEaseLiveConfig({});
        }, 5000);
      } else {
        setTimeout(() => {
          easeLiveComponent.showEaseLive();
          easeLiveComponent.setEaseLiveConfig(
            getEaseLiveConfig(
              programId,
              currentScheduleItem,
              remoteConfig,
              awayTeam,
              homeTeam,
              startTime,
              account,
              sceenicId
            )
          );
        }, 5000);
      }
    }
  }, [easeLiveComponent, programId, entityType[entityId], source]);

  const buildUI = () => {
    if (playerUi) {
      playerUi.release();
    }
    if (
      entityType[entityId]?.toUpperCase() === 'LINEAR' ||
      entityType[entityId]?.toUpperCase() === 'OVERFLOW'
    ) {
      console.log(
        `[Ease Live Retrieve Game Identifier] status: Success, program ID: ${programId}`
      );
      playerUi = UIFactory.buildDefaultUI(player, {
        // config passed to EaseLive constructor
        easeLiveConfig:
          programId && programId !== 'invalid'
            ? getEaseLiveConfig(
                programId,
                currentScheduleItem,
                remoteConfig,
                awayTeam,
                homeTeam,
                startTime,
                account,
                sceenicId
              )
            : {},
        onEaseLiveAppReady: e => {
          console.log('Ease Live overlay ready');
        },

        onEaseLiveError: e => {
          console.log('Ease Live error handler', e);
          if (e.code === 404 && e.programId) {
            // failed to load program, destroy EaseLive
            easeLiveComponent.setEaseLiveConfig({});
            easeLiveComponent.hideEaseLive();
          }
        },
        onEaseLiveMessage: e => {
          console.log('Ease Live Message handler', e.item);
          let action = '';
          switch (e.item) {
            case LIVE_STARTS:
              action = 'Stats Started';
              break;
            case WATCH_PARTY:
              action = 'Watch Party Started';
              break;
            case PLAY:
              action = 'Pick N Play Live Started';
              break;
            default:
              action = '';
          }
          // Passing bridge message to Conviva analytics
          ConvivaProxy.track(action);
          ConvivaProxy.screenTracking(action);
        },

        onEaseLiveComponentCreated: c => {
          console.log('Ease Live component was created in Bitmovin UI', c);
          easeLiveComponent = c;
          hasId3Timecode = false;
          hasPdtTimecode = false;
        }
      });

      player.on(PlayerEvent.SegmentPlayback, event => {
        // EXT-X-PROGRAM-DATE-TIME at the segment
        if (event.dateTime) {
          let date;
          if (event.dateTime.getTime) {
            date = event.dateTime;
          } else {
            date = new Date(event.dateTime);
          }
          if (date) {
            if (date.getTime() < 100000000000) {
              return;
            }
            if (!hasId3Timecode && !hasPdtTimecode) {
              const duration = Math.floor(player.getDuration() * 1000);
              const position = Math.floor(player.getCurrentTime(TimeMode.RelativeTime) * 1000);
              easeLiveComponent.updateEaseLiveTimecode(date.getTime(), position, duration);
            }
          }
        }
      });

      // timecode from timed ID3 metadata
      player.on(PlayerEvent.Metadata, event => {
        if (!easeLiveComponent) {
          return;
        }
        try {
          let data;
          for (let i = 0; i < event?.metadata?.frames?.length; i++) {
            const d = event.metadata.frames[i];

            if (typeof d.data === 'string') {
              data = d.data;
            } else if (Array.isArray(d.data)) {
              data = '';
              for (let j = 0; j < d.data.length; j++) {
                data += String.fromCharCode(d.data[j]);
              }
            }
          }
          if (!data || data.length === 0) {
            return;
          }
          data = data.substring(data.indexOf('{'), 1 + data.lastIndexOf('}'));
          const json = JSON.parse(data);
          const time = parseInt(json.ut || json.utc);
          if (timeCode === 0) timeCode = time;
          const diffMs = time - timeCode;
          if (diffMs < 500) return;
          timeCode = time;
          if (!isNaN(timeCode)) {
            hasId3Timecode = true;
            const position = Math.floor(player.getCurrentTime(TimeMode.RelativeTime) * 1000);
            const duration = Math.floor(player.getDuration() * 1000);
            easeLiveComponent.updateEaseLiveTimecode(timeCode, position, duration);
          }
        } catch (e) {
          console.error('error parsing timecode', e);
        }
      });
    } else {
      console.log(`[Ease Live Retrieve Game Identifier] status: N/A, program ID: ${programId}`);
      if (playerUi) {
        playerUi.release();
      }
      UIFactory.buildDefaultUI(player);
    }
  };

  const getSubtitleLabels = data => {
    let label = `${data.label.toLowerCase()} (SubtitleID: ${data.id})`;
    if (data.label === 'en') {
      label += ' (original)';
    }
    return label;
  };

  const videoStartDate = linearChannels[0]?.sc_st_dt;
  const isVideoLive = linearChannels[0]?.ev_live === 'true' ? 'LIVE' : 'RECORDED';
  const videoTitleValue =
    requiredEntityType === 'VOD'
      ? videoName
      : requiredEntityType === 'linear'
      ? `${videoNameMetaData}|${videoStartDate}|${isVideoLive}`
      : overflowChannels.length
      ? overflowChannels[0]?.lon[0]?.n
      : 'Overflow';

  const isLiveValue =
    requiredEntityType === 'VOD' ? false : requiredEntityType === 'linear' ? true : true;

  const programAirType =
    requiredEntityType === 'overflow'
      ? 'Live'
      : requiredEntityType === 'linear'
      ? linearChannels[0]?.ev_live === 'true' && linearChannels[0]?.ev_replay === 'false'
        ? 'Live'
        : linearChannels[0]?.ev_live === 'false' && linearChannels[0]?.ev_replay === 'true'
        ? 'Replay'
        : null
      : null;

  const isGame =
    requiredEntityType === 'overflow'
      ? 'Game'
      : requiredEntityType === 'linear'
      ? linearChannels[0]?.isGame === 'true'
        ? 'Game'
        : 'Other'
      : undefined;

  const customData6Value =
    requiredEntityType === 'overflow'
      ? overflowChannels[0]?.pgm?.spt_lg
      : requiredEntityType === 'linear'
      ? linearChannels[0]?.pgm?.spt_lg || ''
      : null;

  const getConfig = () => ({
    style: {
      width: '100%',
      aspectratio: '16:9'
    },
    cast: {
      enable: true
    },
    remotecontrol:
      process.env.REACT_APP_ENABLE_CUSTOM_RECEIVER ?? false
        ? process.env.REACT_APP_ENABLE_CAF_RECEIVER === 'true'
          ? {
              receiverApplicationId: process.env.REACT_APP_CUSTOM_RECEIVER_APP_ID,
              receiverVersion: 'v3',
              type: 'googlecast'
            }
          : {
              receiverApplicationId: process.env.REACT_APP_CUSTOM_RECEIVER_APP_ID,
              type: 'googlecast'
            }
        : null,
    key: process.env.REACT_APP_BITMOVIE_LICENSE,
    skin: {
      screenLogoImage: ''
    },
    ui: false,
    autoplay: true,
    // temporary changes [buffer and adaptation]
    buffer: {
      video: {
        forwardduration: 16,
        backwardduration: 8
      },
      audio: {
        forwardduration: 16,
        backwardduration: 8
      }
    },
    tweaks: {
      // this seems to be needed to get time for VOD HLS on Safari
      native_hls_parsing: !vod,
      BUFFER_LOGIC_ACTIVATION_THRESHOLD: 50
    },
    labeling: {
      subTitles: getSubtitleLabels
    },
    analytics: {
      key: process.env.REACT_APP_BITMOVIN_ANALYTICS_LICENSE,
      videoId: videoIdValue,
      title: videoTitleValue || '',
      userId,
      cdnProvider: bitmovincdnProviderConfig,
      isLive: isLiveValue,
      customData1: process.env.REACT_APP_ANALYTICS_PLAYER_NAME,
      customData2: userType,
      customData3: mvpd || null,
      customData4: programAirType,
      customData5: isGame,
      customData6: customData6Value,
      customData7: account?.profile?.cpCustomerID || '',
      customData8: signedUrls?.header?.tracking_id || '',
      customData9: signedUrls?.data?.streamId || ''
    },
    advertising: true
      ? getAdvertisingConfig({
          userContact: account?.profile?.spAccountID || account?.profile?.cpCustomerID,
          video: {
            id: entityId,
            type: requiredEntityType,
            catalogType: requiredEntityType === 'linear' ? 'primary' : 'liveevent',
            duration: entityDuration[entityId]
          },
          adInfo: { rdid: '', isLAT: '0' },
          isPreRollVODAdsEnabled: remoteConfig?.preRollVODAdsFeatureEnabled,
          isPreRollOverflowAdsEnabled: remoteConfig?.preRollOverflowAdsFeatureEnabled,
          isPreRollLinearAdsEnabled: remoteConfig?.preRollLinearAdsFeatureEnabled ?? false
        })
      : undefined
  });

  useEffect(() => {
    if (isVODReplayed) {
      ConvivaProxy.setAssetContext({
        id: videoIdValue,
        assetName: videoNameMetaData,
        type: requiredEntityType ?? '',
        duration: entityDuration[entityId],
        source,
        seriesName: seriesNameConvivaMetaData
      });
      ConvivaProxy.track(VideoStartSession);
      setIsVODReplayed(false);
    }
  }, [isVODReplayed]);

  const buildPlayer = () => {
    if (player) player.unload();
    const config = getConfig();
    config.events = {
      [PlayerEvent.Playing]: () => {
        playerReadyEventBinder(
          entityType,
          entityId,
          orbisId,
          videoName,
          mvpd,
          currentScheduleItem,
          remoteConfig,
          liveEvent,
          userType,
          player,
          videoSegmentPaused,
          linearChannels[0],
          startDate,
          entityDuration,
          source,
          convivaSessionStart
        );
      }
    };
    player = new Player(playerEl.current, config);
    addPlayerEventListeners();
    buildUI();
    ConvivaProxy.setPlayerInfo('Bitmovin', player.version);
    ConvivaProxy.setUserContext({
      userType: convivaUserType,
      spAccountID: userId,
      mvpdName: mvpd
    });
  };

  const loadSource = useCallback(
    sourceLink => {
      (async link => {
        if (link && !checkIsUrlValid(link)) {
          return;
        }
        await player.load({
          hls: link,
          startOffset: remoteConfig?.startOffset,
          startOffsetTimelineReference: 'TimelineReferencePoint.End'
        });
        ConvivaProxy.setAssetContext({
          id: videoIdValue,
          assetName: videoNameMetaData,
          type: requiredEntityType ?? '',
          duration: entityDuration[entityId],
          source,
          seriesName: seriesNameConvivaMetaData
        });
        if (convivaSessionStart === undefined || convivaSessionStart === true) {
          ConvivaProxy.track(VideoStartSession);
        }

        player.setVolume(0);
        if (autoPlay) {
          player.play();
        }
      })(sourceLink);
    },
    [autoPlay]
  );

  useEffect(() => {
    buildPlayer();
    // eslint-disable-next-line
  }, [entityType[entityId]]);

  useEffect(() => {
    if (time === 0) {
      setTime(1);
    } else if (currentScheduleItem?.sc_st_dt && remoteConfig?.analytics) {
      // eslint-disable-next-line no-lonely-if
      if (entityType[entityId].toUpperCase() === 'LINEAR') {
        playerReadyEventBinder(
          entityType,
          entityId,
          orbisId,
          videoName,
          mvpd,
          currentScheduleItem,
          remoteConfig,
          liveEvent,
          userType,
          player,
          videoSegmentPaused,
          linearChannels[0],
          startDate,
          entityDuration,
          source,
          convivaSessionStart
        );
      }
    }
    // eslint-disable-next-line
  }, [startAnalytics, currentScheduleItem, remoteConfig, liveEvent]);

  useEffect(() => {
    if (entityId && propertiesContent) {
      // Video Streaming Tracker
      if (videoStreamingTracker) {
        clearInterval(videoStreamingTracker);
      }
      videoStreamingTracker = setInterval(() => {
        dispatch(trackStreamStartRequest({ selectedMVPD, entityId, heartbeatToken }));
      }, 1 * 60 * 1000);
    }
    return () => {
      if (videoStreamingTracker) {
        clearInterval(videoStreamingTracker);
      }
    };
    // eslint-disable-next-line
  }, [entityId, dispatch, heartbeatToken, currentScheduleItem]);

  useEffect(() => {
    if (entityId && propertiesContent) {
      // Video Segment Tracker
      if (VideoSegmentTracker) {
        clearInterval(VideoSegmentTracker);
      }
      VideoSegmentTracker = setInterval(() => {
        if (propertiesContent && currentScheduleItem) {
          propertiesContent = {
            asset_id: videoIdValue,
            program: videoNameMetaData,
            title: videoNameMetaData,
            description: videoDescription,
            session_id: getWebDeviceId(),
            total_length: entityDuration[entityId],
            livestream: isLiveValue,
            sound: player?.getVolume(),
            video_player: videoPlayer,
            player_name: playerName,
            stream_type: entityType[entityId],
            position: player?.getCurrentTime(TimeMode.RelativeTime),
            ovp,
            liveFlag: convivaLiveFlag.toString()
          };
          segmentEvent(VideoContentPlaying, propertiesContent);
          if (player.isPlaying()) {
            ConvivaProxy.track(VideoContentPlaying);
          }
        }
      }, 10000);
    }
    return () => {
      if (VideoSegmentTracker) {
        clearInterval(VideoSegmentTracker);
      }
    };
  }, [entityId, propertiesContent, currentScheduleItem]);

  useEffect(() => {
    if (player && source) {
      loadSource(source);
      wasPaused.current = false;
    }
  }, [source, loadSource]);

  useEffect(() => {
    if (adsSecond < 0) {
      // show easeLive
      setAdsSecond(0);
      clearInterval(intervalId);
    }
    // eslint-disable-next-line
  }, [adsSecond]);

  const playAds = URL => {
    window.open(URL, '_blank');
  };

  return (
    <div className="bitmovin-player-container">
      <div className="bitmovin-player bitmovinplayer-container aspect-6x9" ref={playerEl} />
      {adsObj && adsObj.beaconUrls && adsSecond > 0 ? (
        <>
          <button
            className="learn-more btn btn-outline-dark btn-lg"
            rel="noopener noreferrer"
            onClick={() => playAds(adsObj.beaconUrls[0])}
          >
            Learn More
          </button>
        </>
      ) : null}
    </div>
  );
};

export default BitmovinPlayer;
