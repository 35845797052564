const eventAnalytics = (type, action) => {
  if (window.s) {
    switch (type) {
      case 'error':
        window.s.eVar5 = action;
        break;
      case 'page':
        window.s.pageName = action;
        break;
      case 'event':
        window.s.eVar6 = action;
        break;
      default:
        break;
    }
    window.s.t();
  } else {
    console.log('Analytics not found');
  }
};

export default eventAnalytics;
