import React, { useState } from 'react';
import FacebookIcon from 'assets/images/FacebookLogo.png';
import GoogleIcon from 'assets/images/GoogleLogo.png';
import { ReactComponent as AppleIcon } from 'assets/svgs/AppleIcon.svg';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useDispatch } from 'react-redux';
import {
  getWebDeviceId,
  APIUSER,
  APIPASSWORD,
  CHANNELPARTNERID,
  deviceType,
  deviceName,
  modelNo,
  userAgent
} from 'config';
import { useRouteMatch, useLocation } from 'react-router-dom';
import eventAnalytics from 'utils/eventAnalytics';
import Model from 'components/Model';
import { socialSigninRequest } from '../redux/actions';
import ConvivaProxy from 'services/ConvivaProxy';

const SocialSignin = ({ deviceId }) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const match = useRouteMatch('/watch/:vodId?');
  const { search } = useLocation();
  const authFlow = url.startsWith('/authenticate') ? 'device' : 'user';
  const [showUserModel, setShowUserModel] = useState(false);

  const responseGoogle = response => {
    const googleResponse = response;
    const provider = 'google';
    const data = {
      GetOAuthAccessTokenv2RequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        socialLoginID: googleResponse.googleId,
        socialLoginType: 'google',
        contactUsername: '',
        spAccountID: '',
        cpCustomerID: '',
        sessionExpiryPeriod: '',
        deviceMessage: {
          deviceType,
          deviceName,
          modelNo,
          serialNo: getWebDeviceId(),
          userAgent
        }
      }
    };

    dispatch(
      socialSigninRequest(
        data,
        authFlow,
        deviceId,
        match?.params?.vodId,
        search,
        googleResponse,
        provider,
        () => setShowUserModel(true)
      )
    );
  };

  const responseFacebook = response => {
    const facebookResponse = response;
    const provider = 'facebook';
    const data = {
      GetOAuthAccessTokenv2RequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        socialLoginID: facebookResponse.userID,
        socialLoginType: 'facebook',
        contactUsername: '',
        spAccountID: '',
        cpCustomerID: '',
        sessionExpiryPeriod: '',
        deviceMessage: {
          deviceType,
          deviceName,
          modelNo: '04ries',
          serialNo: getWebDeviceId(),
          userAgent: 'USA'
        }
      }
    };
    dispatch(
      socialSigninRequest(
        data,
        authFlow,
        deviceId,
        match?.params?.vodId,
        search,
        facebookResponse,
        provider,
        () => setShowUserModel(true)
      )
    );
  };

  const failGoogle = response => {
    console.log('Google login error', response);
  };

  const failFacebook = response => {
    console.log('Facebook login error', response);
  };

  const failApple = response => {
    console.log('Apple login error', response);
  };

  const initApple = async () => {
    window.AppleID.auth.init({
      clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
      scope: 'name email', // To tell apple we want the user name and emails fields in the response it sends us.
      redirectURI: `https://${window.location.hostname}`, // As registered along with our service ID
      state: 'origin:web', // Any string of your choice that you may use for some logic. It's optional and you may omit it.
      usePopup: true // Important if we want to capture the data apple sends on the client side.
    });

    try {
      const response = await window.AppleID.auth.signIn();
      const {
        authorization: { id_token: jwtToken }
      } = response;
      function decodeJwt(token) {
        const base64Payload = token.split('.')[1];
        const payloadBuffer = Buffer.from(base64Payload, 'base64');
        return JSON.parse(payloadBuffer.toString());
      }
      const jwt = JSON.parse(Buffer.from(response.authorization.id_token.split('.')[1], 'base64'));
      const userID = jwt.sub.split('.')[1]; // extract the second part of the sub field
      const payload = decodeJwt(jwtToken);
      const socialLoginID = userID;
      const provider = 'apple';
      const data = {
        GetOAuthAccessTokenv2RequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID,
          socialLoginID,
          socialLoginType: 'apple',
          contactUsername: '',
          spAccountID: '',
          cpCustomerID: '',
          sessionExpiryPeriod: '',
          deviceMessage: {
            deviceType,
            deviceName,
            modelNo,
            serialNo: getWebDeviceId(),
            userAgent
          }
        }
      };
      dispatch(
        socialSigninRequest(
          data,
          authFlow,
          deviceId,
          match?.params?.vodId,
          search,
          payload,
          provider,
          () => setShowUserModel(true)
        )
      );
    } catch (error) {
      failApple(error);
    }
  };

  const socialLogin = (renderProps, type) => {
    eventAnalytics('event', type);
    renderProps.onClick();
  };

  const getRedirectURL = () => {
    console.log(`${window.location.origin + window.location.pathname}${search || ''}`);
    return `${window.location.origin + window.location.pathname}${search || ''}`;
  };

  const appleLogin = type => {
    eventAnalytics('event', type);
    initApple();
  };

  return (
    <div className="text-center">
      {showUserModel && (
        <Model
          title="Sorry"
          body="No YES App profile exists"
          show={showUserModel}
          setIsShow={setShowUserModel}
        />
      )}
      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
        buttonText="Login"
        autoLoad={false}
        onSuccess={responseGoogle}
        render={renderProps => (
          <button
            className="secondary__button--lg-icon mb-4"
            onClick={() => {
              socialLogin(renderProps, 'Google');
              ConvivaProxy.actionTracking('Login - Google');
            }}
          >
            <img src={GoogleIcon} alt="Google_Icon" />
            Continue with Google
          </button>
        )}
        onFailure={failGoogle}
        cookiePolicy="single_host_origin"
      />

      <FacebookLogin
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        autoLoad={false}
        callback={responseFacebook}
        redirectUri={getRedirectURL}
        isDisabled={false}
        isMobile={false}
        render={renderProps => (
          <button
            className="secondary__button--lg-icon mb-4"
            onClick={() => {
              socialLogin(renderProps, 'Facebook');
              ConvivaProxy.actionTracking('Login - Facebook');
            }}
          >
            <img src={FacebookIcon} alt="Facebook_Icon" />
            Continue with Facebook
          </button>
        )}
        onFailure={failFacebook}
      />

      <button
        className="secondary__button--lg-icon mb-4"
        onClick={() => {
          appleLogin('Apple');
          ConvivaProxy.actionTracking('Login - Apple');
        }}
      >
        <AppleIcon />
        Continue with Apple
      </button>
    </div>
  );
};

export default SocialSignin;
