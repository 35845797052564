import React from 'react';
import { Modal } from 'react-bootstrap';
import './ExistUserModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from 'utils/localStorage';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { searchAccountV2Request } from '../redux/actions';
import { history } from '../../../redux/configureStore';

function ExistUserModal({ email, show, setIsShow, deviceId }) {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const match = useRouteMatch('/watch/:vodId?');
  const { search } = useLocation();
  let authFlow = url.startsWith('/authenticate') ? 'device' : 'user';
  const { selectedMVPD } = useSelector(state => state.app.mvpdSelect);
  const { isMac } = useSelector(state => state.auth);
  const { isDeviceAuthorized } = useSelector(state => state.app.deviceMVPD);
  const { isLinkedToTV } = useSelector(state => state.auth);
  const handleClose = () => {
    setIsShow(false);
  };
  const navigateToLogin = () => {
    setIsShow(false);
    history.push({
      pathname: '/login',
      search: window.location.search
    });
  };
  const handleSignIn = () => {
    if (selectedMVPD || getUser() === 'dtc') {
      authFlow = 'user';
    } else {
      authFlow = 'device';
    }
    dispatch(searchAccountV2Request({
      SearchAccountV2RequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        email
      }
    },
    authFlow,
    deviceId,
      match?.params?.vodId,
      search,
      () => handleClose())
    );
  };
  const directLoginFlow = () => (!selectedMVPD && !isDeviceAuthorized && isLinkedToTV);
  return (
    <div id="existUserModal">
      <Modal className="exist-user-modal" show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
        <Modal.Header>
          {directLoginFlow() ?
            <Modal.Title>Profile already exists</Modal.Title> :
            <Modal.Title>Profile Exists</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          {directLoginFlow() ? (
            <p>
              We already have a profile associated with this email address. Login with this email address to continue.
            </p>
          )
            :
            <p>{`A profile already exists for the email address ${email}. Would you like to continue and use the existing profile?`}</p>}
          {directLoginFlow() ? (
            <div style={{ display: 'flex' }}>
              <button
                style={{ marginRight: '10px' }}
                onClick={navigateToLogin}
                className={`${isMac ? 'IOS' : ''} primary__button`}
              >
                Login
              </button>
              <button
                onClick={handleClose}
                className={`${isMac ? 'IOS' : ''} primary__button`}
              >
                Cancel
              </button>
            </div>
          ) : (
            <>
              <button
                onClick={() => handleSignIn()}
                className={`${isMac ? 'IOS' : ''} primary__button`}
              >
                CONTINUE
              </button>
              <br />
              <button className="link-blue" onClick={handleClose}>
                CANCEL
              </button>
            </>
          )}

        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ExistUserModal;
