/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BrowserNotificationChecker from 'components/BrowserNotificationChecker';
import './FavouritePlayer.scss';
import request from 'utils/api';
import { getAccessToken } from 'utils/localStorage';
import getPlayerHeadshot from 'utils/getPlayerHeadshot';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import PageWrapper from 'pages/layoutPage/PageWrapper';
import NewLayout from 'containers/AuthLayout/NewLayout';
import handlePlayerSelection from 'utils/playersFunctionality';
import SelectFavouritePlayer from './SelectFavouritePlayers';
import { checkBrowserNotifications } from '../../../utils/checkBrowserNotifications';
import { getContinueFromThePlayers } from '../../auth/redux/actions';
import { playerSelectRequest } from '../TeamSelect/redux/actions';
import PageLoading from '../../../components/PageLoading';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';
import UserSettings from '../../../services/UserSettings';

const FavouritePlayer = ({ isOnboarding, isEditingPlayers, setIsEditingPlayers, goBack }) => {
  // const { account } = useSelector(state => state.auth);
  const { isGettingFavorite, account } = useSelector(state => state.auth);
  const { players } = useSelector(state => state.app.watch);
  const { isMac } = useSelector(state => state.auth);
  const favoriteTeams = [1, 2, 3, 4]; // all teams are favorite teams.
  const [apiFavPlayers, setApiFavPlayers] = useState([]);
  const [bnModal, setBnModal] = useState(false);
  const [currentFollowedPlayers, setCurrentFollowedPlayers] = useState([]);
  const dispatch = useDispatch();
  const { isDeviceAuthorized } = useSelector(state => state.app.deviceMVPD);
  const { selectedMVPD } = useSelector(state => state.app.mvpdSelect);

  const [playersToAdd, setPlayersToAdd] = useState([]);
  const [playersToRemove, setPlayersToRemove] = useState([]);
  const [followedPlayers, setFollowedPlayers] = useState([]);

  useAnalyticsScreenTracking(
    isOnboarding ? 'Registration - Select Players' : 'Account - Update Players'
  );

  useEffect(() => {
    if (checkBrowserNotifications().status !== 'granted') {
      setBnModal(true);
    }
    // eslint-disable-next-line
  }, []);

  const getFavoritePlayerData = async () => {
    const data = {
      GetFavouritesRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID
      }
    };
    try {
      const getFavoritePlayer = await request(
        'evergent',
        '/yesnetwork/getFavourites',
        'post',
        data,
        getAccessToken()
      );
      if (getFavoritePlayer.GetFavouritesResponseMessage.favourites.favoritePlayers) {
        setApiFavPlayers(
          getFavoritePlayer.GetFavouritesResponseMessage.favourites.favoritePlayers
        );
      }
    } catch (err) {
      console.log('getFavouriteTeam api error', err);
    }
  };

  useEffect(() => {
    setFollowedPlayers(account?.favoritePlayers);
  }, [account]);

  useEffect(() => {
    getFavoritePlayerData();
    // eslint-disable-next-line
  }, [account]);

  useEffect(() => {
    if (followedPlayers?.length) {
      setPlayersToAdd(
        followedPlayers.filter(
          player => !apiFavPlayers.some(apiPlayer => apiPlayer.playerId === player.playerId)
        )
      );
    }
    setPlayersToRemove(
      apiFavPlayers.filter(
        apiPlayer => !followedPlayers.some(player => player.playerId === apiPlayer.playerId)
      )
    );
  }, [followedPlayers, apiFavPlayers]);

  const PlayerListForBraze = () => {
    const playerList = [];
    for (const team of players.teams) {
      for (const [, selectedPlayersList] of Object.entries(team)) {
        const filteredPlayers = selectedPlayersList
          .filter(player =>
            followedPlayers.some(followedPlayer => followedPlayer.playerId === player.player_id)
          )
          .map(player => player.player_name);
        playerList.push(...filteredPlayers);
      }
    }
    UserSettings.addFavPlayers(playerList);
  };
  const submitPlayers = async () => {
    const data = {
      AddOrRemoveFavouritesRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        favouriteType: 'favoritePlayers',
        favoritePlayersToAdd: playersToAdd,
        favoritePlayersToRemove: playersToRemove
      }
    };
    dispatch(playerSelectRequest(data));
    PlayerListForBraze();
    await getFavoritePlayerData();
    dispatch(getContinueFromThePlayers(true));
    goBack();
  };

  useEffect(() => {
    const followedTeamPlayers = {};
    // Convert favoritePlayers to a Set for O(1) lookup times
    const favoritePlayerIds = new Set(account?.favoritePlayers?.map(fp => fp.playerId));
    for (const team of players.teams) {
      for (const [teamkey, teamPlayers] of Object.entries(team)) {
        // Filter and check if there are any favorite players using the Set
        const favoritePlayers = teamPlayers.filter(player =>
          favoritePlayerIds.has(player.player_id)
        );
        // Only add to followedTeamPlayers if there are favorite players on this team
        if (favoritePlayers.length > 0) {
          followedTeamPlayers[teamkey] = favoritePlayers;
        }
      }
    }
    setCurrentFollowedPlayers(followedTeamPlayers);
  }, [players.teams, account.favoritePlayers]);

  const hasSubscriptions = () => {
    let showSubscriptions = false;
    if (account?.favoritePlayers && account?.favoritePlayers.length > 0) {
      showSubscriptions = true;
    }
    return showSubscriptions;
  };

  if (isGettingFavorite) {
    return <PageLoading />;
  }

  if (isOnboarding) {
    return (
      <PageWrapper
        showHeader
        showBackIcon
        backUrl="/"
        showTitle
        titleText="PICK & FOLLOW"
        titleSubText="YOUR FAVORITE PLAYERS"
        activeStep={3}
        stepperText={
          !selectedMVPD && !isDeviceAuthorized
            ? 'Step 3 of 3: Pick Your Favorites'
            : 'Step 2 of 2: Pick Your Favorites'
        }
        showStepper
        lengthStepper={!selectedMVPD && !isDeviceAuthorized ? 3 : 2}
      >
        <NewLayout>
          <div>
            {bnModal ? <BrowserNotificationChecker /> : null}
            <div className="onboarding-fav-players col-md-14">
              <SelectFavouritePlayer
                favoriteTeams={favoriteTeams}
                setIsEditingPlayers={setIsEditingPlayers}
                isOnboarding={isOnboarding}
              />
            </div>
          </div>
        </NewLayout>
      </PageWrapper>
    );
  }
  return (
    <>
      {bnModal ? <BrowserNotificationChecker /> : null}
      {!isEditingPlayers ? (
        hasSubscriptions() ? (
          <>
            <PageWrapper
              showHeader
              showBackIcon
              onBackClick={() => goBack()}
              showTitle
              titleText="PICK & FOLLOW"
              titleSubText="YOUR FAVORITE PLAYERS"
            >
              <NewLayout>
                <div className="player-list-container d-flex flex-column align-items-center">
                  <div className="player-list">
                    {currentFollowedPlayers &&
                      Object.entries(currentFollowedPlayers).map(([team, displayPlayers]) => (
                        <div className="team-section" key={team}>
                          <div className="team-name">
                            {/* <img
                                className="team-logo"
                                src={getLogo(team.team)}
                                alt="team-logo"
                              /> */}
                            {team}
                          </div>
                          {displayPlayers.map(player => (
                            <div
                              className="d-flex justify-content-between player-item align-items-center"
                              key={player.player_id}
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  src={getPlayerHeadshot(player)}
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.className = 'player-picture-none';
                                  }}
                                  onLoad={({ currentTarget }) => {
                                    currentTarget.className = 'player-picture';
                                  }}
                                  className="player-picture-none"
                                  alt="player-pictures"
                                />
                                <div className="d-flex flex-column">
                                  <p className="player-first-name">
                                    {player.player_name.replace(',', ' ')}
                                  </p>
                                </div>
                              </div>

                              <div
                                className={`${followedPlayers.some(p => p.playerId === player.player_id)
                                  ? 'subscribe-button-active'
                                  : 'subscribe-button'
                                  } subscribe-button d-flex justify-content-center align-items-center`}
                                onClick={() => {
                                  handlePlayerSelection(
                                    player,
                                    setFollowedPlayers,
                                    followedPlayers
                                  );
                                }}
                              >
                                {followedPlayers.some(p => p.playerId === player.player_id)
                                  ? 'Unfollow'
                                  : 'Follow'}
                              </div>
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </div>

                <div className="btn-done-section d-flex flex-column justify-content-around align-items-center">
                  <div
                    className="btn-add-more-players"
                    onClick={() => {
                      setIsEditingPlayers(true);
                    }}
                  >
                    + Add More Players
                  </div>
                  <button onClick={() => submitPlayers()} className={`${isMac ? 'IOS' : ''}`}>
                    {' '}
                    UPDATE{' '}
                  </button>
                </div>
              </NewLayout>
            </PageWrapper>
          </>
        ) : (
          <>
            <PageWrapper
              showHeader
              showBackIcon
              onBackClick={() => goBack()}
              showTitle
              titleText="PICK & FOLLOW"
              titleSubText="YOUR FAVORITE PLAYERS"
            >
              <NewLayout>
                <div className="fav-container d-flex flex-column  align-items-center">
                  <div>
                    Selecting a favorite player allows you to receive notifications to keep you up
                    to date on all the latest news.
                  </div>

                  <div
                    className="btn-add-fav-players pts-n-db-17"
                    onClick={() => setIsEditingPlayers(true)}
                  >
                    + Add Favorite Players
                  </div>
                </div>
              </NewLayout>
            </PageWrapper>
          </>
        )
      ) : (
        <PageWrapper
          showHeader
          showBackIcon
          onBackClick={() => goBack()}
          showTitle
          titleText="PICK & FOLLOW"
          titleSubText="YOUR FAVORITE PLAYERS"
        >
          <NewLayout>
            <SelectFavouritePlayer
              favoriteTeams={favoriteTeams}
              setIsEditingPlayers={setIsEditingPlayers}
              isOnboarding={isOnboarding}
            />
          </NewLayout>
        </PageWrapper>
      )}
    </>
  );
};

export default FavouritePlayer;
