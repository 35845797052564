import BrazeProxy from './BrazeProxy';

const enableGameAlerts = enable => {
  BrazeProxy.brazeCustomUserAttribute('game_alerts', enable);
};

const enableTeamAlerts = enable => {
  BrazeProxy.brazeCustomUserAttribute('team_news', enable);
};

const enableNewsletterAlerts = enable => {
  BrazeProxy.brazeCustomUserAttribute('news_letter', enable);
};

// Teams
const addFavTeamsTag = teamTags => {
  BrazeProxy.brazeCustomUserAttribute('favorite_teams_push', teamTags);
};

// Players
const addFavPlayers = players => {
  BrazeProxy.brazeCustomUserAttribute('player_notifications', players);
};

const UserSettings = {
  enableGameAlerts,
  enableTeamAlerts,
  enableNewsletterAlerts,
  addFavTeamsTag,
  addFavPlayers
};

export default UserSettings;
