import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Carousel from 'react-multi-carousel';
import PlaylistCard from 'components/PlaylistCard/PlaylistCard';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import VODimage from 'assets/images/vod_item_image.png';
import viewAll from 'assets/images/view_all.png';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { history } from 'redux/configureStore';
import { onAuthenticationStatus } from 'pages/app/MVPDSelect/redux/actions';
import { MaxVOD } from 'config';
import { getVODSuccess } from '../redux/actions';



const CustomLeftArrow = ({ onClick }) => (
  <Button className="left-arrow-button" onClick={() => onClick()}>
    <FontAwesomeIcon className="mr-2 left-arrow" icon={faChevronLeft} />
  </Button>
);
const CustomRightArrow = ({ onClick }) => (
  <Button className="right-arrow-button" onClick={() => onClick()}>
    <FontAwesomeIcon className="mr-2 right-arrow" icon={faChevronRight} />
  </Button>
);

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5.5,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3.5,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1
  }
};

const featuredResponsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4.5,
    slidesToSlide: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2.5,
    slidesToSlide: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.25,
    slidesToSlide: 1
  }
};


const VODSection = ({
  sectionName,
  detailSections,
  sectionId,
  listLength,
  playLiveChannel,
  setDetailedSections,
  setSeeMore,
  setVideoName,
  setVideoTitle,
  setCurrentSection,
  setSectionId,
  position,
  setShowSubscribeScreen,
  containerUrl,
  setContainerVodURL
}) => {
  const [isMoving, setIsMoving] = useState(false);
  const dispatch = useDispatch();
  const { selectedMVPD } = useSelector(state => state.app.mvpdSelect);
  const { GetActiveSubscriptionsResponseMessage } = useSelector(state => state.auth);
  const isSubscribe = !!GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.length;

  const isContentAvailable = (item) =>
    // Check if the item is available to the user (e.g., user has access or it's free)
    isSubscribe || selectedMVPD !== null || item.ent.some((en) => en.sp.includes('urn:package:Free_Pkg'));

  const playItem = (assetId, name, duration, title, item) => {
    setCurrentSection(sectionId);
    const inFreePackage = item.ent.some((en) => en.sp.includes('urn:package:Free_Pkg'));
    if (!isContentAvailable(item)) {
      dispatch(onAuthenticationStatus(1)); // needed to avoid auto redirect
      dispatch(getVODSuccess(item));
      history.push({
        pathname: `/watch/${assetId}`,
        search: window.location.search
      }); // sets url to enter mvpd select route
    } else {
      setVideoName(name);
      setVideoTitle(title);
      dispatch(getVODSuccess(item));
      playLiveChannel(assetId, duration, inFreePackage);
    }
    setDetailedSections(detailSections);

  };

  const publishTime = duration => {
    const durInSec = duration / 1000;
    const hrs = Math.floor(durInSec / 3600).toString().padStart(2, '0');
    const mins = Math.floor(durInSec % 3600 / 60).toString().padStart(2, '0');
    const secs = Math.floor(durInSec % 60).toString().padStart(2, '0');
    return `${hrs}:${mins}:${secs}`;
  };

  const filterimages = ITEM => {
    if (position === 0) {
      if (ITEM && ITEM?.ia && ITEM?.ia[0]) {
        return `${process.env.REACT_APP_QUICKPLAY_IMAGE_BASE_URL}/image/${ITEM.id}/${ITEM.ia[0]}.jpg?width=500`;
      }
      if (ITEM && ITEM?.ia && ITEM?.ia[1]) {
        return `${process.env.REACT_APP_QUICKPLAY_IMAGE_BASE_URL}/image/${ITEM.id}/${ITEM.ia[1]}.jpg?width=500`;
      }
    }
    else if (ITEM?.ia?.indexOf('0-3x4') > 0) {
      const index = ITEM?.ia.indexOf('0-3x4');
      return `${process.env.REACT_APP_QUICKPLAY_IMAGE_BASE_URL}/image/${ITEM.id}/${ITEM?.ia[index]}.jpg?width=500`;
    }
    else {
      const index = ITEM?.ia.indexOf('0-16x9');
      return `${process.env.REACT_APP_QUICKPLAY_IMAGE_BASE_URL}/image/${ITEM.id}/${ITEM?.ia[index]}.jpg?width=500`;
    }
    return VODimage;
  };

  return detailSections ? (
    <div key={sectionId}>
      <h3 className="tgd1-n-base-20 float-left">{sectionName}</h3>
      <br />
      <Carousel
        ssr
        responsive={listLength === 0 ? featuredResponsive : responsive}
        infinite={false}
        beforeChange={() => setIsMoving(true)}
        afterChange={() => setIsMoving(false)}
        containerClass="first-carousel-container"
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {
          detailSections?.map((item) => {
            // locked vod for non-subscribe user 
            const isLockedVOD = !!(!selectedMVPD && !isSubscribe && !item.ent[0].sp.map((i) => i.includes('Free')).includes(true));
            return (
              <>
                <PlaylistCard
                  key={item.id}
                  title={item?.lon[0].n}
                  onClick={isLockedVOD ? undefined : () =>
                    playItem(item.id, item.name, (item?.vrt / 1000), item?.lon[0].n, item)}
                  isMoving={isMoving}
                  responsive={listLength}
                  videolength={publishTime(item?.vrt)}
                  publishdate={item.st_dt}
                  image={filterimages(item)}
                  lockIcon={!isContentAvailable(item)}
                  setShowSubscribeScreen={setShowSubscribeScreen}
                />
              </>
            );
          })
        }
        {detailSections?.length > MaxVOD && (
          <PlaylistCard
            onClick={() => {
              setContainerVodURL(containerUrl);
              setSeeMore(true);
              setDetailedSections(detailSections);
              setSectionId(sectionId);
            }}
            image={viewAll}
          />
        )}
      </Carousel>
      <hr />
    </div>
  ) : (
    <div />
  );
};

export default VODSection;
