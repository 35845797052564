import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toastErrorNotify } from 'pages/toast/redux/actions';
import NewLayout from 'containers/AuthLayout/NewLayout';
import BrowserNotificationChecker from 'components/BrowserNotificationChecker';
import './Notifications.scss';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import PageWrapper from 'pages/layoutPage/PageWrapper';
import { checkBrowserNotifications } from '../../../utils/checkBrowserNotifications';
import { notificationSelectRequest } from '../TeamSelect/redux/actions';
import UserSettings from '../../../services/UserSettings';

const Notifications = ({ goBack }) => {
  const [yesNotification, setYesNotification] = useState(false);
  const [gameAlert, setGameAlert] = useState(false);
  const [teamAlert, setTeamAlert] = useState(false);
  const [bnModal, setBnModal] = useState(false);
  const [playerNews, setPlayerNews] = useState(false);
  const [NewsletterCommunication, setNewsletterCommunication] = useState(false);
  const dispatch = useDispatch();
  const listNotification = ['TEAM-NEWS', 'GAME-ALERTS', 'PLAYER-NEWS', 'NEWSLETTER'];

  useEffect(() => {
    window.UA.then(sdk => {
      sdk.getChannel().then(channel => {
        if (channel.optedIn) {
          setYesNotification(true);
          const savedPreferences = channel.tags.tagMap.get('notifications_preference');
          if (savedPreferences && savedPreferences.has('TEAM-NEWS')) setTeamAlert(true);
          if (savedPreferences && savedPreferences.has('GAME-ALERTS')) setGameAlert(true);
          if (savedPreferences && savedPreferences.has('PLAYER-NEWS')) setPlayerNews(true);
          if (savedPreferences && savedPreferences.has('NEWSLETTER'))
            setNewsletterCommunication(true);
        }
      });
    });
  }, []);

  const handleOrbis = () => {
    window.UA.then(sdk => {
      sdk.getChannel().then(channel => {
        const selectedNotification = channel.tags.list.notifications_preference;
        const removeNotification = listNotification.filter(
          tag => selectedNotification.indexOf(tag) === -1
        );
        const data = {
          AddOrRemoveFavouritesRequestMessage: {
            apiUser: APIUSER,
            apiPassword: APIPASSWORD,
            channelPartnerID: CHANNELPARTNERID,
            favouriteType: 'notificationTags',
            notificationTagsToAdd: selectedNotification,
            notificationTagsToRemove: removeNotification
          }
        };
        dispatch(notificationSelectRequest(data));
      });
    });
  };

  const handleYesNotifications = () => {
    if (yesNotification) {
      window.UA.then(sdk => {
        sdk.getChannel().then(channel => {
          channel.tags.set([], 'notifications_preference').then(() => handleOrbis());
        });
        sdk.channel.optOut();
      });
      setYesNotification(false);
      setGameAlert(false);
      setTeamAlert(false);
      setPlayerNews(false);
    } else if (!yesNotification && checkBrowserNotifications().status === 'granted') {
      setYesNotification(true);
    }
    handleOrbis();
  };

  const handleGameAlerts = () => {
    if (gameAlert) {
      UserSettings.enableGameAlerts(false);
      window.UA.then(sdk => {
        sdk.getChannel().then(channel => {
          channel.tags.remove('GAME-ALERTS', 'notifications_preference').then(() => handleOrbis());
        });
      });
    } else {
      if (!yesNotification) {
        handleYesNotifications();
      }
      UserSettings.enableGameAlerts(true);
      window.UA.then(sdk => {
        sdk.getChannel().then(channel => {
          channel.tags.add('GAME-ALERTS', 'notifications_preference').then(() => handleOrbis());
        });
      });
    }
    setGameAlert(!gameAlert);
  };

  const handleTeamAlerts = () => {
    if (teamAlert) {
      UserSettings.enableTeamAlerts(false);
      window.UA.then(sdk => {
        sdk.getChannel().then(channel => {
          channel.tags.remove('TEAM-NEWS', 'notifications_preference').then(() => handleOrbis());
        });
      });
    } else {
      if (!yesNotification) {
        handleYesNotifications();
      }
      UserSettings.enableTeamAlerts(true);
      window.UA.then(sdk => {
        sdk.getChannel().then(channel => {
          channel.tags.add('TEAM-NEWS', 'notifications_preference').then(() => handleOrbis());
        });
      });
    }
    setTeamAlert(!teamAlert);
  };

  const handleSetPlayerNews = () => {
    window.UA.then(sdk => {
      sdk.getChannel().then(channel => {
        channel.subscriptions.list().then(list1 => {
          if (list1.length > 0) {
            if (playerNews) {
              channel.tags
                .remove('PLAYER-NEWS', 'notifications_preference')
                .then(() => handleOrbis());
            } else {
              if (!yesNotification) {
                handleYesNotifications();
              }
              channel.tags
                .add('PLAYER-NEWS', 'notifications_preference')
                .then(() => handleOrbis());
            }
            setPlayerNews(!playerNews);
            if (!yesNotification) {
              handleYesNotifications();
            }
          } else {
            dispatch(
              toastErrorNotify({
                type: 'Player News Notifications Error:',
                message:
                  'You must select at least one favorite player on your Account to receive player news notifications'
              })
            );
          }
        });
      });
    });
    setPlayerNews(!playerNews);
  };

  const handleNewsletter = () => {
    if (NewsletterCommunication) {
      UserSettings.enableNewsletterAlerts(false);
      window.UA.then(sdk => {
        sdk.getChannel().then(channel => {
          channel.tags.remove('NEWSLETTER', 'notifications_preference').then(() => handleOrbis());
        });
      });
    } else {
      if (!yesNotification) {
        handleYesNotifications();
      }
      UserSettings.enableNewsletterAlerts(true);
      window.UA.then(sdk => {
        sdk.getChannel().then(channel => {
          channel.tags.add('NEWSLETTER', 'notifications_preference').then(() => handleOrbis());
        });
      });
    }
    setNewsletterCommunication(!NewsletterCommunication);
  };

  const handleYesNotificationClick = () => {
    if (checkBrowserNotifications().status !== 'granted') {
      setBnModal(true);
    } else if (!yesNotification) {
      window.UA.then(sdk => {
        sdk.getChannel().then(channel => {
          channel.tags.add('GAME-ALERTS', 'notifications_preference').then(() => {
            handleOrbis();
            setGameAlert(true);
          });
          channel.tags.add('TEAM-NEWS', 'notifications_preference').then(() => {
            handleOrbis();
            setTeamAlert(true);
          });
          channel.subscriptions.list().then(list1 => {
            if (list1.length > 0) {
              channel.tags.add('PLAYER-NEWS', 'notifications_preference').then(() => {
                handleOrbis();
                setPlayerNews(true);
              });
            }
          });
        });
      });
    }
  };

  return (
    <PageWrapper
      className="background-light"
      showHeader
      showBackIcon
      onBackClick={() => goBack()}
      backText="Account & Settings"
    >
      <NewLayout>
        {bnModal ? <BrowserNotificationChecker /> : null}
        <div className="notification-container">
          <div className="notification-title">NOTIFICATIONS</div>
          <div className="notification-body">
            <div className="d-flex justify-content-between notication-items mt-4  ">
              <div className="pts-n-db-16 item">Allow YES Notifications</div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={yesNotification}
                  onClick={handleYesNotificationClick}
                  onChange={handleYesNotifications}
                />
                <span className="slider" />
              </label>
            </div>

            <div
              className={`${
                !yesNotification ? 'disabled-item' : ''
              } d-flex justify-content-between notication-items mt-4  `}
            >
              <div className="pts-n-db-16 item">Game Alerts</div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={gameAlert}
                  className={!yesNotification ? 'disabled' : ''}
                  onChange={handleGameAlerts}
                />
                <span className={`${!yesNotification ? 'disabled-slider ' : ''}slider`} />
              </label>
            </div>
            <div
              className={`${
                !yesNotification ? 'disabled-text' : ''
              } pts-n-lb-14 mt-1 notification-text`}
            >
              {yesNotification
                ? `Stay closer to the action through real-time notifications of starting lineups,
              scoring plays, close games, in-progress and final scores, and more.`
                : `Game Alerts will allow you to stay closer to the action through real-time notifications of
               starting lineups, scoring plays, close games, in-progress and final scores, and more.`}
            </div>

            <div
              className={`${
                !yesNotification ? 'disabled-item' : ''
              } d-flex justify-content-between notication-items mt-4`}
            >
              <div className="pts-n-db-16 item">Team News</div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={teamAlert}
                  className={!yesNotification ? 'disabled' : ''}
                  onChange={handleTeamAlerts}
                />
                <span className={`${!yesNotification ? 'disabled-slider ' : ''}slider`} />
              </label>
            </div>
            <div
              className={`${
                !yesNotification ? 'disabled-text' : ''
              } pts-n-lb-14 mt-1 notification-text`}
            >
              {yesNotification
                ? 'Keeps you up to date on all the latest news surrounding your favorite team.'
                : 'Team News alerts keep you up to date on all the latest news surrounding your favorite team.'}
            </div>

            <div
              className={`${
                !yesNotification ? 'disabled-item' : ''
              } d-flex justify-content-between notication-items mt-4 `}
            >
              <div className="pts-n-db-16 item">Player News</div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={playerNews}
                  className={!yesNotification ? 'disabled' : ''}
                  onChange={handleSetPlayerNews}
                />
                <span className={`${!yesNotification ? 'disabled-slider ' : ''}slider`} />
              </label>
            </div>
            <div
              className={`${
                !yesNotification ? 'disabled-text' : ''
              } pts-n-lb-14 mt-1 notification-text`}
            >
              {yesNotification
                ? `Keeps you up to date on all the latest news surrounding your favorite player. 
          (NOTE: At least one Favorite Player must be selected to enable this feature).`
                : 'Player News Alerts keep you up to date on all the latest news surrounding your favorite player.'}
            </div>
            <div className="d-flex justify-content-between notication-items  ">
              <div className="pts-n-db-16 item">Allow YES News & Communication</div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={NewsletterCommunication}
                  onChange={handleNewsletter}
                />
                <span className="slider" />
              </label>
            </div>
          </div>
        </div>
      </NewLayout>
    </PageWrapper>
  );
};

export default Notifications;
