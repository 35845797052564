import React from 'react';
import Moment from 'react-moment';
import Live from 'assets/svgs/live.svg';
import './Channel.scss';

const Channel = ({ disabled, title, startTime, onClick, image, ...props }) => (
  <div className="channel mb-4 " {...props} onClick={onClick}>
    <div className="d-flex channel--content ">
      <div className="d-flex align-items-center">
        <img className="align-self-center channel--image" src={image} height="100px" width="100px" alt="Channel-img" />
      </div>
      <div className="d-flex ml-4 flex-column">
        <h5 className="tglbc-b-db-19">{title}</h5>
        <p className="tglbc-b-db-20 channel--time">
          <Moment format="hh:mm A">{startTime}</Moment>
        </p>
      </div>
    </div>
    <div>
      <img className="channel--live-btn" src={Live} alt="live-btn" />
    </div>
  </div>
);

export default Channel;
