import * as CONSTANTS from './constants';

export const teamSelectRequest = (data, authFlow, search, urlVODId,deviceId) => ({
  type: CONSTANTS.TEAM_SELECT_REQUEST,
  data,
  authFlow,
  search,
  urlVODId,
  deviceId
});

export const teamSelectSuccess = data => ({
  type: CONSTANTS.TEAM_SELECT_SUCCESS,
  payload: data
});

export const teamSelectError = () => ({
  type: CONSTANTS.TEAM_SELECT_ERROR
});

export const notificationSelectRequest  = data => ({
  type: CONSTANTS.NOTIFICATION_SELECT_REQUEST,
  data
})

export const notificationSelectSuccess = data => ({
  type: CONSTANTS.NOTIFICATION_SELECT_SUCCESS,
  payload: data
})
export const notificationSelectError = data => ({
  type: CONSTANTS.NOTIFICATION_SELECT_ERROR
})

export const playerSelectRequest  = data => ({
  type: CONSTANTS.PLAYER_SELECT_REQUEST,
  data
})

export const playerSelectSuccess = data => ({
  type: CONSTANTS.PLAYER_SELECT_SUCCESS,
  payload: data
})
export const playerSelectError = data => ({
  type: CONSTANTS.PLAYER_SELECT_ERROR
})

export const loginDeviceRequest = data => ({
  type: CONSTANTS.LOGIN_DEVICE_REQUEST,
  data
});

export const loginDeviceSuccess = data => ({
  type: CONSTANTS.LOGIN_DEVICE_SUCCESS,
  payload: data
});

export const loginDeviceError = data => ({
  type: CONSTANTS.LOGIN_DEVICE_ERROR,
  payload: data
});
