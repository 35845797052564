import React, { useEffect, useState } from 'react';
import queryString from 'qs';
import { useDispatch, useSelector } from 'react-redux';

import PageLoading from 'components/PageLoading';
import ComponentLoading from 'components/ComponentLoading/ComponentLoading';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import { adobePassRequestorId, baseUrl } from 'config';
import { toastErrorNotify } from 'pages/toast/redux/actions';
import eventAnalytics from 'utils/eventAnalytics';
import { getRegCode, setTVCode, getPlatform } from 'utils/localStorage';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { checkAuthenticationRequest, mvpdListRequest } from './redux/actions';
import PageWrapper from '../../layoutPage/PageWrapper';
import NewLayout from '../../../containers/AuthLayout/NewLayout';
import { setUser } from '../../../utils/localStorage';


const DeviceMVPD = ({ deviceId }) => {
  eventAnalytics('page', 'Registration - MVPD Select');
  const dispatch = useDispatch();
  const match = useRouteMatch('/authenticate/:deviceId/:platform?/:activationCode?');
  const deviceCode = match?.params?.deviceId;
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const authFlow = url.startsWith('/authenticate') ? 'device' : 'user';
  const [isRedirectLoading, setIsRedirectLoading] = useState(false);
  const { activationError } = useSelector(state => state.app.deviceMVPD);
  const { remoteConfig } = useSelector(state => state.app.watch);
  const platform = getPlatform() === null ? '' : getPlatform();

  const restProviders = remoteConfig?.tvProviders?.filter(
    provider => !provider.isPremium
  );

  useEffect(() => {
    if (deviceId) {
      setUser('tve');
      dispatch(
        checkAuthenticationRequest({
          requestor: process.env.REACT_APP_ADOBE_REQUESTOR_ID,
          deviceId: getRegCode(),
          search,
          deviceCode,
          authFlow,
          match
        })
      );
    }
    //  eslint-disable-next-line
  }, [dispatch, deviceId]);

  const onMVPDChange = mvpdId => {
    if (!deviceId) {
      dispatch(
        toastErrorNotify({
          type: 'TV provider Login',
          message: 'Please add your device ID'
        })
      );
    } else if (activationError) {
      dispatch(
        toastErrorNotify({
          type: 'Expired Activation Code',
          message: 'Activation code is expired, please generate a new code from your TV'
        })
      );
    } else {
      setIsRedirectLoading(true);
      const data = {
        reg_code: deviceId,
        requestor_id: adobePassRequestorId,
        mso_id: mvpdId,
        domain_name: 'adobe.com',
        noflash: true,
        no_iframe: false,
        redirect_url: window.location.href
      };
      setTVCode(mvpdId);
      window.analytics.identify({
        adobe_pass_id: mvpdId
      });
      const qs = `?${queryString.stringify(data, { arrayFormat: 'bracket' })}`;
      const url = `${baseUrl.adobeAuth}/api/v1/authenticate${qs}`;
      window.open(url, '_parent');
    }
  };

  useEffect(() => {
    if (activationError) {
      dispatch(
        toastErrorNotify({
          type: 'Expired Activation Code',
          message: 'Activation code is expired, please generate a new code from your TV'
        })
      );
    }
    // eslint-disable-next-line
  }, [activationError]);

  useEffect(() => {
    dispatch(mvpdListRequest());
  }, [dispatch]);


  if (isRedirectLoading) {
    return <PageLoading />;
  }

  return (
    <PageWrapper
      showHeader
      titleHeader={`Connect Your ${platform} TV`}
    >
      <NewLayout>
        <h2>Select your Television Service Provider</h2>
        <p>I subscribe to YES Network with a cable or satellite TV provider</p>
        <Row className="suggested-mvpds">
          {remoteConfig?.tvProviders?.filter(mvpd => mvpd.isPremium)
            .map(mvpd => (
              <Col md={4} sm={6} key={mvpd.id} onClick={() => onMVPDChange(mvpd.id)}>
                <img src={mvpd.logoUrl} alt={mvpd.displayName} />
              </Col>
            ))}
          <div className="border-none left-border" />
          <div className="border-none right-border" />
          <div className="border-none top-border" />
          <div className="border-none bottom-border" />
        </Row>
        <p className="text-center" htmlFor="mvpdList">
          Can’t find the service provider you are subscribed to? Search below -
        </p>
        {restProviders?.length ? (
          <Form.Control className="service-provider-list" as="select" onChange={e => onMVPDChange(e.target.value)}>
            <option value="">Search Service Provider</option>
            {restProviders.map(mvpd => (
              <option key={mvpd.id} value={mvpd.id}>
                {mvpd.displayName}
              </option>
            ))}
          </Form.Control>
        ) : (
          <ComponentLoading />
        )}
      </NewLayout>
    </PageWrapper>
  );
};

export default DeviceMVPD;
