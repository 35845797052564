/* eslint-disable import/prefer-default-export */
import EaseLiveUiFactory from './easeliveuifactory';

import 'bitmovin-player-ui/dist/css/bitmovinplayer-ui.css';
import './style.css';

window.bitmovin = window.bitmovin || {};
window.bitmovin.playerui = window.bitmovin.playerui || {};
window.bitmovin.playerui.UIFactory = EaseLiveUiFactory;

export { EaseLiveUiFactory as UIFactory };
