import { watchErrorTypeConstants } from 'helpers/constants';
import produce from 'immer';
import * as CONSTANTS from './constants';

const initialState = {
  account: null,
  isGettingAccount: false,
  isLoading: false,
  signedUrls: {},
  isSelectedChannelLoading: false,
  linearChannels: [],
  checkingBlackOutTime: null,
  islieanerChannelsLoading: false,
  overflowChannels: [], // [{name, shortName, startTime, source}, ...]
  isLiveCompetitionsLoading: false,
  upcomingSchedule: null,
  isUpcomingScheduleLoading: false,
  vodList: [],
  vodSections: {}, // {'FEATURED': {id: 'FEATURED', items:[], title: '', ...}, 'TEAM_1_yankees_hot_stove': {...}}
  vodItem: null,
  errorType: null,
  errorCode: '',
  vodSearchResults: {},
  isVODSearchLoading: false,
  urlVOD: [],
  currentScheduleItem: [],
  remoteConfig: {},
  localization: {},
  players: {},
  liveEvent: null,
  currentSubscriptions: [],
  selectedPlan: {},
  successfullyLinked: false,
  couchRightsDate: null,
  couchRightsCheckInProgress: false,
  couchRightsExpired: false,
  isCancelSubscriptionLoading: false,
  cancelSubscription: null,
  heartbeatToken: null
};

const WatchReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CONSTANTS.GET_LINEAR_CHANNELS_REQUEST:
        draft.islieanerChannelsLoading = true;
        break;
      case CONSTANTS.GET_LINEAR_CHANNELS_SUCCESS:
        draft.islieanerChannelsLoading = false;
        draft.linearChannels = action.payload;
        break;
      case CONSTANTS.GET_LINEAR_CHANNELS_ERROR:
        draft.islieanerChannelsLoading = false;
        draft.linearChannels = [];
        break;

      case CONSTANTS.PLAY_LIVE_CHANNEL_REQUEST:
        if (!action.payload.isBlackoutChecking) {
          draft.isSelectedChannelLoading = true;
          draft.signedUrls = null;
        }
        break;
      case CONSTANTS.PLAY_LIVE_CHANNEL_SUCCESS:
        draft.isSelectedChannelLoading = false;

        if (action.payload.isBlackoutChecking) {
          if (draft.errorType === 'BLACK_OUT_ERROR') {
            draft.errorType = null;
          }
          draft.checkingBlackOutTime = action.payload.timeSchedule;
        } else {
          draft.errorType = null;
          draft.signedUrls = { ...draft.signedUrls, ...action.payload };
        }
        break;
      case CONSTANTS.PLAY_LIVE_CHANNEL_ERROR:
        draft.isSelectedChannelLoading = false;
        draft.signedUrls = null;
        break;

      case CONSTANTS.GET_OVERFLOW_CHANNELS_REQUEST:
        draft.isLiveCompetitionsLoading = true;
        break;
      case CONSTANTS.GET_OVERFLOW_CHANNELS_SUCCESS:
        draft.isLiveCompetitionsLoading = false;
        draft.overflowChannels = action.payload;
        break;
      case CONSTANTS.GET_OVERFLOW_CHANNELS_ERROR:
        draft.isLiveCompetitionsLoading = false;
        draft.overflowChannels = [];
        break;

      case CONSTANTS.GET_TV_SCHEDULE_REQUEST:
        draft.isUpcomingScheduleLoading = true;
        break;
      case CONSTANTS.GET_TV_SCHEDULE_SUCCESS:
        draft.isUpcomingScheduleLoading = false;
        draft.upcomingSchedule = action.payload;
        break;
      case CONSTANTS.GET_TV_SCHEDULE_ERROR:
        draft.isUpcomingScheduleLoading = false;
        draft.upcomingSchedule = null;
        break;

      case CONSTANTS.GET_VOD_LIST_REQUEST:
        draft.isLoading = true;
        break;
      case CONSTANTS.GET_VOD_LIST_SUCCESS:
        draft.isLoading = false;
        draft.vodList = action.payload;
        break;
      case CONSTANTS.GET_VOD_LIST_RESPONSE:
        draft.isLoading = false;
        draft.vodListResponse = action.payload;
        break;
      case CONSTANTS.GET_VOD_LIST_ERROR:
        draft.isLoading = false;
        draft.vodList = [];
        break;

      case CONSTANTS.GET_VOD_SECTION_REQUEST:
        draft.isLoading = true;
        break;
      case CONSTANTS.GET_VOD_SECTION_SUCCESS:
        draft.isLoading = false;
        draft.vodSections[action.payload.id] = action.payload || [];
        break;
      case CONSTANTS.GET_VOD_SECTION_ERROR:
        draft.isLoading = false;
        draft.vodSections = {};
        break;

      case CONSTANTS.GET_VOD_ITEM_REQUEST:
        draft.isLoading = true;
        break;
      case CONSTANTS.GET_VOD_ITEM_SUCCESS:
        draft.isLoading = false;
        draft.vodItem = action.payload;
        break;
      case CONSTANTS.GET_VOD_ITEM_ERROR:
        draft.isLoading = false;
        draft.vodItem = null;
        break;
      case CONSTANTS.GET_VOD_SEARCH_RESULTS_REQUEST:
        draft.isVODSearchLoading = true;
        break;
      case CONSTANTS.GET_VOD_SEARCH_RESULTS_SUCCESS:
        draft.isVODSearchLoading = false;
        draft.vodSearchResults = action.payload;
        break;
      case CONSTANTS.GET_VOD_SEARCH_RESULTS_ERROR:
        draft.isVODSearchLoading = false;
        draft.vodSearchResults = {};
        break;

      case CONSTANTS.GET_URL_VOD_REQUEST:
        draft.urlVOD = {};
        draft.signedUrls = {};
        break;

      case CONSTANTS.GET_URL_VOD_SUCCESS:
        draft.urlVOD = action.payload;
        break;

      case CONSTANTS.GET_URL_VOD_ERROR:
        draft.urlVOD = {};
        break;

      case CONSTANTS.OUT_OF_MARKETPLACE_ERROR:
        draft.errorType = watchErrorTypeConstants.OUT_OF_MARKETPLACE_ERROR;
        draft.isSelectedChannelLoading = false;
        draft.signedUrls = null;
        break;
      case CONSTANTS.CONCURRENCY_ERROR:
        draft.errorType = watchErrorTypeConstants.CONCURRENCY_ERROR;
        draft.isSelectedChannelLoading = false;
        draft.signedUrls = null;
        break;

      case CONSTANTS.BLACK_OUT_ERROR:
        draft.errorType = watchErrorTypeConstants.BLACKOUT_ERROR;
        draft.errorCode = action.payload;
        draft.isSelectedChannelLoading = false;
        draft.signedUrls = null;
        break;
      case CONSTANTS.COUCH_RIGHTS_EXPIRY_ERROR:
        draft.errorType = watchErrorTypeConstants.COUCH_RIGHTS_EXPIRY_ERROR;
        draft.isSelectedChannelLoading = false;
        draft.signedUrls = null;
        draft.couchRightsExpired = true;
        break;
      case CONSTANTS.OUT_OF_US_ERROR:
        draft.errorType = watchErrorTypeConstants.OUT_OF_US_ERROR;
        draft.isSelectedChannelLoading = false;
        draft.signedUrls = null;
        break;
      case CONSTANTS.ABORT_NOT_ENTITLED:
        draft.errorType = watchErrorTypeConstants.ABORT_NOT_ENTITLED;
        draft.isSelectedChannelLoading = false;
        draft.signedUrls = null;
        break;
      case CONSTANTS.ABORT_ROAMING:
        draft.errorType = watchErrorTypeConstants.ABORT_ROAMING;
        draft.isSelectedChannelLoading = false;
        draft.signedUrls = null;
        break;
      case CONSTANTS.GRACEFUL_STOP:
        draft.errorType = watchErrorTypeConstants.GRACEFUL_STOP;
        draft.isSelectedChannelLoading = false;
        draft.signedUrls = null;
        break;
      case CONSTANTS.CLEAR_ERROR:
        draft.errorType = null;
        draft.isSelectedChannelLoading = false;
        draft.signedUrls = null;
        break;

      case CONSTANTS.NO_SUBSCRIPTOIN_ERROR:
        draft.errorType = watchErrorTypeConstants.NO_SUBSCRIPTION_ERROR;
        draft.isSelectedChannelLoading = false;
        draft.signedUrls = null;
        break;
      case CONSTANTS.SET_CURRENT_SCHEDULE_ITEM:
        draft.currentScheduleItem = null;
        draft.currentScheduleItem = action.payload;
        break;
      case CONSTANTS.GET_REMOTE_CONFIG_SUCCESS:
        draft.remoteConfig = action.payload;
        draft.errorType = null;
        break;
      case CONSTANTS.GET_REMOTE_CONFIG_ERROR:
        draft.errorType = action.payload;
        break;
      case CONSTANTS.GET_REMOTE_CONFIG_REQUEST:
        draft.remoteConfig = null;
        break;
      case CONSTANTS.GET_LOCALIZATION_SUCCESS:
        draft.localization = action.payload;
        draft.errorType = null;
        break;
      case CONSTANTS.GET_LOCALIZATION_ERROR:
        draft.errorType = action.payload;
        break;
      case CONSTANTS.GET_LOCALIZATION_REQUEST:
        draft.localization = null;
        break;
      case CONSTANTS.GET_PLAYERS_SUCCESS:
        draft.players = action.payload;
        draft.errorType = null;
        break;
      case CONSTANTS.GET_PLAYERS_ERROR:
        draft.errorType = action.payload;
        break;
      case CONSTANTS.GET_PLAYERS_REQUEST:
        draft.players = null;
        break;
      case CONSTANTS.UPDATE_PLAYERS:
        draft.currentSubscriptions = action.payload;
        break;
      case CONSTANTS.SET_SELECTED_PLAN:
        draft.selectedPlan = action.payload;
        break;
      case CONSTANTS.SET_SUCCESSFULLY_LINKED:
        draft.successfullyLinked = true;
        break;
      case CONSTANTS.CHECK_COUCH_RIGHTS_IN_PROGRESS:
        draft.couchRightsCheckInProgress = action.payload;
        break;
      case CONSTANTS.COUCH_RIGHTS_EXPIRED:
        draft.couchRightsExpired = action.payload;
        break;
      case CONSTANTS.CANCEL_SUBSCRIPTION_REQUEST:
        draft.isCancelSubscriptionLoading = true;
        break;
      case CONSTANTS.CANCEL_SUBSCRIPTION_SUCCESS:
        draft.isCancelSubscriptionLoading = false;
        draft.cancelSubscription = action.payload;
        break;
      case CONSTANTS.CANCEL_SUBSCRIPTION_ERROR:
        draft.isCancelSubscriptionLoading = false;
        draft.cancelSubscription = null;
        break;
      case CONSTANTS.SET_HEARTBEAT_TOKEN:
        draft.heartbeatToken = action.payload;
        break;
      case CONSTANTS.SET_ERROR_TYPE:
        draft.errorType = action.payload;
        break;
      case CONSTANTS.SET_ERROR_CODE:
        draft.errorCode = action.payload;
        break;

      default:
        break;
    }
  });

export default WatchReducer;
