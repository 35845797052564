import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import rootReducer from './reducers';
import sagas from './sagas';

export const history = createBrowserHistory();

function configureStore(initialState = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [];
  const enhancers = [];

  middleware.push(sagaMiddleware);
  middleware.push(routerMiddleware(history));

  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === 'development') {
    const { __REDUX_DEVTOOLS_EXTENSION__ } = window;

    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
      enhancers.push(__REDUX_DEVTOOLS_EXTENSION__());
    }
  }

  const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

  const store = createStore(rootReducer, initialState, composedEnhancers);

  sagaMiddleware.run(sagas);
  return store;
}

const initialState = {};
export default configureStore(initialState);
