import React from 'react';

export default function ArrowIcon({ color, ...props }) {

  return (
    <div {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <defs />
        <path
          fill={color}
          d="M22,11H8.275l6.3-5.6L13,4,4,12l9,8,1.575-1.4L8.275,13H22Z"
        />
      </svg>
    </div>
  );
}
