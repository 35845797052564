import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
// import { Offline, Online } from 'react-detect-offline';

// import OfflineContent from 'pages/global/OfflineContent';
import Routes from './pages';
import store, { history } from './redux/configureStore';

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Routes />
      </Router>
    </Provider>
  );
}

export default App;
