
function getEaseLiveConfig(programId, currentScheduleItem = null, remoteConfig,
  awayTeam, homeTeam, startTime, account, sceenicId) {
  return {
    accountId: remoteConfig?.easeLive?.accountId,
    env: remoteConfig?.easeLive?.env || 'prod',
    programId,
    params: {
      streamId: remoteConfig?.easeLive?.streamId,
      together: sceenicId,
      invite: remoteConfig?.easeLive?.deepLinkURL,
    },
    logLevel: remoteConfig?.easeLive?.logLevel || 'off',
    blackout: currentScheduleItem.blackout || false,
    uid: account.profile.spAccountID,
    nickname: account.profile.nickname,
    match: {
      'away_team': awayTeam,
      'home_team': homeTeam,
      'start_time': startTime
    },
    simpleBetSupportedLeagues: remoteConfig?.simpleBetSupportedLeagues,
    simpleBetsEnabled: remoteConfig?.simpleBetEnabled,
  };
}

export default getEaseLiveConfig;
