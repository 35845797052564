import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';

const CancelSubscriptionInstruction = ({ platform }) => {

  const { localization } = useSelector(state => state.app.watch);

  const [isOpen, setIsOpen] = useState(false);
  const handleFilterOpening = () => {
    setIsOpen((prev) => !prev);
  };
  const displayCancelSubscriptionInstructionBasedOnPlatform = () => {
    switch (platform) {
      case 'Android':
        return { __html: localization.cancelSubscription?.cancel_instructions_android?.en };
      case 'Roku':
        return { __html: localization.cancelSubscription?.cancel_instructions_roku?.en };
      case 'Web':
        return { __html: localization.cancelSubscription?.cancel_instructions_web?.en };
      case 'Apple':
        return { __html: localization.cancelSubscription?.cancel_instructions_apple?.en };
      case 'FireTV':
        return { __html: localization.cancelSubscription?.cancel_instructions_firetv?.en };
      case 'WebSplitit':
        return { __html: localization.cancelSubscription?.cancel_instructions_web_splitit?.en };
      default:
        break;
    }
  };


  return (
    <>
      <div onClick={handleFilterOpening}>
        {isOpen ? (
          <FontAwesomeIcon className="mr-2 minus-square" icon={faMinusSquare} />

        ) : (
          <FontAwesomeIcon className="mr-2 plus-square" icon={faPlusSquare} />
        )}
        {localization.cancelSubscription?.cancel_subscription_title?.en}
      </div>
      {isOpen && (
        <div dangerouslySetInnerHTML={displayCancelSubscriptionInstructionBasedOnPlatform()} />
      )}
    </>
  );

};

export default CancelSubscriptionInstruction;