import axios from 'axios';
import moment from 'moment-timezone';
import { get } from 'lodash';

async function getGameIdentifierId(
  tricode,
  date,
  homeTeam,
  awayTeam,
  league
) {
  let gameIdentifierId = 'invalid';
  const startTime = moment(date).tz(tricode?.easeLiveTimezone).format('YYYYMMDD');
  if (league === 'MLB') {
    gameIdentifierId = `${startTime}-${awayTeam}-${homeTeam}`;
    try {
      const program = await axios.get(
        `https://yes-cdn.cloud.easelive.tv/api/v1/programs/live/${gameIdentifierId}-2`
      );
      const { scheduledStartTime } = program.data;
      const mins = Math.abs(new Date(date) - new Date(scheduledStartTime)) / 1000 / 60;
      if (mins < 30) {
        gameIdentifierId = `${gameIdentifierId}-2`;
      } else {
        gameIdentifierId = `${gameIdentifierId}-1`;
      }
    } catch (err) {
      if (err.toString().includes('404')) {
        gameIdentifierId = `${gameIdentifierId}-1`;
      } else {
        const error = get(err, 'response', err);
        return error;
      }
    }
  } else if (league === 'NBA') {
    gameIdentifierId = `${startTime}-${league}-${awayTeam}-${homeTeam}`;
  }
  return gameIdentifierId;
}

export default getGameIdentifierId;
