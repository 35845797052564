import YesCardResetPage from 'components/YesCard/YesCardResetPage';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { passwordConfirmSchema } from 'helpers/validations';
import { useDispatch, useSelector } from 'react-redux';
import PasswordField from 'components/PasswordField';
import { useLocation } from 'react-router-dom';
import PasswordStrengthMeter from 'components/PasswordStrengthMeter/PasswordStrengthMeter';
import ErrorModal from 'components/ErrorModal';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import { passwordResetRequest } from '../redux/actions';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';

const initialValues = {
  newPassword: '',
  confirmPassword: ''
};

const PasswordReset = () => {
  const query = new URLSearchParams(useLocation().search);
  const resetPasswordToken = query.get('resetPasswordToken');
  const resetEmail = query.get('email');
  const { isPasswordResetLoading, isMac } = useSelector(state => state.auth);
  const [showErrorModel, setShowErrorModel] = useState(false);
  const dispatch = useDispatch();

  useAnalyticsScreenTracking(null, 'PasswordReset', '/passwordReset');

  const handleSubmit = values => {
    const { newPassword } = values;
    const data = {
      ResetPasswordRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        email: resetEmail,
        contactPassword: newPassword,
        userToken: resetPasswordToken
      }
    };
    if (resetPasswordToken) {
      dispatch(passwordResetRequest(data, () => setShowErrorModel(true)));
    }
  };

  return (
    <div
      style={{
        background:
          'transparent linear-gradient(135.06deg, #061525 0%, #0A233F 22.98%, #0A233F 71.13%, #061525 99.9%)'
      }}
    >
      <ErrorModal show={showErrorModel} setIsShow={setShowErrorModel} />
      <div style={{ marginTop: '200px' }}>
        <YesCardResetPage title="Reset your password">
          <div className="pt-5">
            <p className="pts-n-white-20 mb-3">Create your new password.</p>
            <Formik
              initialValues={initialValues}
              validationSchema={passwordConfirmSchema}
              onSubmit={handleSubmit}
            >
              {({ touched, errors, isValid, values }) => (
                <Form>
                  <div className="form-group mb-4">
                    <label
                      style={{ width: 'auto' }}
                      className={
                        touched.newPassword && errors.newPassword
                          ? 'is-invalid form-label'
                          : 'form-label'
                      }
                      htmlFor="newPassword"
                    >
                      New Password
                    </label>
                    <Field
                      name="newPassword"
                      placeholder="Enter your new password."
                      component={PasswordField}
                      className={`form-control ${
                        touched.newPassword && errors.newPassword ? 'is-invalid' : ''
                      }`}
                    />
                    {!(touched.newPassword && errors.newPassword) && (
                      <p className="hint-msg">Password must be at least 8 characters.</p>
                    )}
                    <ErrorMessage
                      component="div"
                      name="newPassword"
                      className="invalid-feedback mb-2"
                    />
                    <label className="pts-n-white-14 float-left">Password Strength</label>
                    <PasswordStrengthMeter password={values.newPassword} />
                  </div>

                  <div className="form-group">
                    <label
                      style={{ width: 'auto' }}
                      className={
                        touched.confirmPassword && errors.confirmPassword
                          ? 'is-invalid form-label'
                          : 'form-label'
                      }
                      htmlFor="confirmPassword"
                    >
                      Confirm New Password
                    </label>
                    <Field
                      name="confirmPassword"
                      placeholder="Please confirm your password."
                      component={PasswordField}
                      className={`form-control ${
                        touched.confirmPassword && errors.confirmPassword ? 'is-invalid' : ''
                      }`}
                    />
                    <ErrorMessage
                      component="div"
                      name="confirmPassword"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="text-center mt-5">
                    <button
                      type="submit"
                      className={`${isMac ? 'IOS' : ''} primary__button`}
                      disabled={!isValid || isPasswordResetLoading}
                    >
                      {isPasswordResetLoading ? 'Please wait...' : 'UPDATE'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </YesCardResetPage>
      </div>
    </div>
  );
};

export default PasswordReset;
