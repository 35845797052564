import React from 'react';
import NewLayout from 'containers/AuthLayout/NewLayout';
import PageWrapper from 'pages/layoutPage/PageWrapper';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Row } from 'react-bootstrap';
import '../CreditCardPayment/CreditCardPayment.scss';

import Payment from '../CreditCardPayment/Payment';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';

const SubscriptionPayment = () => {
  const queryParams = queryString.parse(useLocation().search);

  useAnalyticsScreenTracking(
    'Manage Subscription - Update Credit Card',
    'SubscriptionPayment',
    '/subscriptionPayment'
  );

  return (
    <PageWrapper
      className="background-light"
      showHeader
      backUrl="/ManageSubscription"
      showBackIcon
      backText="Manage subscription"
      showTitle
      titleText="UPDATE CREDIT CARD"
    >
      <NewLayout>
        <Row className="credit-card-screen">
          <div className="payment-section">
            <Payment isUpdateMode queryParams={queryParams} />
          </div>
        </Row>
      </NewLayout>
    </PageWrapper>
  );
};
export default SubscriptionPayment;
