import produce from 'immer';
import { removeAccessToken, removeRefreshToken, removeSessionToken } from 'utils/localStorage';
import * as CONSTANTS from './constants';

const initialState = {
  account: {
    favoriteTeams: null,
    favoritePlayers: null,
    profile: null,
    notificationTags: null,
    registerSuccess: null
  },
  GetContactResponseMessage: null,
  GetFavouritesResponseMessage: null,
  SearchAccountV2ResponseMessage: null,
  GetActiveSubscriptionsResponseMessage: null,
  subscriptionType: null,
  isSubscriptionRequestLoading: false,
  UpdateProfileResponseMessage: null,
  GetOAuthAccessTokenv2ResponseMessage: null,
  CreateUserResponseMessage: null,
  errorMessage: {
    type: null,
    message: null
  },
  loading: false,
  isGettingAccount: false,
  isGetOAuthToken: false,
  isGettingFavorite: false,
  isGettingNotification: false,
  isGettingPlayer: false,
  isDeviceLogingin: false,
  isSocialSigningin: false,
  isEmailConfirmLoading: false,
  isPasswordResetLoading: false,
  resetEmail: null,
  hasResetPassword: false,
  hasFavoritePlayers: false,
  isMac: false,
  isLinkedToTV: true,
  updateAccountDetailsResponseMessage: null,
  continueFromThePlayers: true,
  GetPkgsAndProdsWithPromosResponseMessage: null,
  regularCardImages: false,
  promoCardImages: true,
  getPayment: null,
  isGetPaymentLoading: false,
  changeService: null,
  isChangeSubscriptionLoading: false
};

const authReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CONSTANTS.REGISTER_REQUEST:
        draft.loading = true;
        break;
      case CONSTANTS.REGISTER_SUCCESS:
        draft.loading = false;
        draft.CreateUserResponseMessage = action.payload.CreateUserResponseMessage;
        break;
      case CONSTANTS.REGISTER_ERROR:
        draft.loading = false;
        draft.errorMessage = action.payload;
        break;

      case CONSTANTS.UPDATE_PROFILE_REQUEST:
        draft.loading = true;
        break;
      case CONSTANTS.UPDATE_PROFILE_SUCCESS:
        draft.loading = false;
        draft.UpdateProfileResponseMessage = action.payload.UpdateProfileResponseMessage;
        break;
      case CONSTANTS.UPDATE_PROFILE_ERROR:
        draft.loading = false;
        draft.errorMessage = action.payload;
        break;
      case CONSTANTS.SIGNIN_REQUEST:
        draft.loading = true;
        draft.errorMessage.message = null;
        draft.errorMessage.type = null;
        break;
      case CONSTANTS.SIGNIN_SUCCESS:
        draft.loading = false;
        draft.GetOAuthAccessTokenv2ResponseMessage =
          action.payload.GetOAuthAccessTokenv2ResponseMessage;
        draft.errorMessage.message = null;
        draft.errorMessage.type = null;
        break;
      case CONSTANTS.SIGNIN_ERROR:
        draft.loading = false;
        draft.errorMessage = action.payload;
        if (action?.payload?.message === 'No account found with the given details.') {
          draft.isLinkedToTV = false;
        }
        break;
      case CONSTANTS.GET_QUICKPLAY_OAUTH_TOKEN_REQUEST:
        draft.isGetOAuthToken = true;
        break;
      case CONSTANTS.GET_ACCOUNT_REQUEST:
        draft.isGettingAccount = true;
        break;
      case CONSTANTS.GET_ACCOUNT_SUCCESS:
        draft.isGettingAccount = false;
        draft.account = { ...state.account, profile: action.payload };
        break;
      case CONSTANTS.GET_ACCOUNT_ERROR:
        draft.isGettingAccount = false;
        break;

      case CONSTANTS.GET_FAVORITE_REQUEST:
        draft.isGettingFavorite = true;
        break;
      case CONSTANTS.GET_FAVORITE_SUCCESS:
        draft.isGettingFavorite = false;
        draft.account = {
          ...state.account,
          favoriteTeams: action.payload?.favoriteTeams,
          favoritePlayers: action.payload?.favoritePlayers
        };

        break;
      case CONSTANTS.GET_FAVORITE_ERROR:
        draft.isGettingFavorite = false;
        break;
      case CONSTANTS.GET_NOTIFICATION_REQUEST:
        draft.isGettingNotification = true;
        break;
      case CONSTANTS.GET_NOTIFICATION_SUCCESS:
        draft.isGettingNotification = false;
        draft.account = { ...state.account, notificationTags: action.payload };
        break;
      case CONSTANTS.GET_NOTIFICATION_ERROR:
        draft.isGettingNotification = false;
        break;
      case CONSTANTS.GET_PLAYER_REQUEST:
        draft.isGettingPlayer = true;
        break;
      case CONSTANTS.GET_PLAYER_SUCCESS:
        draft.isGettingPlayer = false;
        draft.account = { ...state.account, favoritePlayers: action.payload };
        break;
      case CONSTANTS.GET_PLAYER_ERROR:
        draft.isGettingPlayer = false;
        break;
      case CONSTANTS.SOCIAL_SIGNIN_REQUEST:
        draft.isSocialSigningin = true;
        break;
      case CONSTANTS.SOCIAL_SIGNIN_SUCCESS:
        draft.isSocialSigningin = false;
        draft.GetOAuthAccessTokenv2ResponseMessage =
          action.payload.GetOAuthAccessTokenv2ResponseMessage;
        break;
      case CONSTANTS.SOCIAL_SIGNIN_ERROR:
        draft.isSocialSigningin = false;
        break;
      case CONSTANTS.EMAIL_CONFIRM_REQUEST:
        draft.isEmailConfirmLoading = true;
        break;
      case CONSTANTS.EMAIL_CONFIRM_SUCCESS:
        draft.isEmailConfirmLoading = false;
        draft.resetEmail = action.payload.email;
        break;
      case CONSTANTS.EMAIL_CONFIRM_ERROR:
        draft.isEmailConfirmLoading = false;
        break;
      case CONSTANTS.PASSWORD_RESET_REQUEST:
        draft.isPasswordResetLoading = true;
        break;
      case CONSTANTS.PASSWORD_RESET_SUCCESS:
        draft.isPasswordResetLoading = false;
        draft.hasResetPassword = true;
        break;
      case CONSTANTS.PASSWORD_RESET_ERROR:
        draft.isPasswordResetLoading = false;
        draft.hasResetPassword = false;
        break;
      case CONSTANTS.GET_SUBSCRIPTIONS_REQUEST:
        draft.loading = true;
        break;
      case CONSTANTS.GET_SUBSCRIPTIONS_SUCCESS:
        draft.hasFavoritePlayers = true;
        draft.loading = false;
        break;
      case CONSTANTS.GET_SUBSCRIPTIONS_ERROR:
        draft.hasFavoritePlayers = true;
        draft.loading = false;
        break;
      case CONSTANTS.GET_CONTINUE_FROM_THE_PLAYERS:
        draft.continueFromThePlayers = action.payload;
        break;
      case CONSTANTS.SIGN_OUT:
        draft.account = null;
        removeAccessToken();
        removeRefreshToken();
        removeSessionToken();
        break;
      case CONSTANTS.SEARCH_ACCOUNT_REQUEST:
        draft.loading = true;
        break;
      case CONSTANTS.SEARCH_ACCOUNT_SUCCESS:
        draft.SearchAccountV2ResponseMessage = action.payload;
        draft.loading = false;
        break;
      case CONSTANTS.SEARCH_ACCOUNT_ERROR:
        draft.loading = false;
        break;
      case CONSTANTS.GET_ACTIVE_SUBSCRIPTIONS:
        draft.loading = true;
        draft.isSubscriptionRequestLoading = true;
        break;
      case CONSTANTS.SET_ACTIVE_SUBSCRIPTIONS_SUCCESS:
        draft.GetActiveSubscriptionsResponseMessage = action.payload;
        draft.isSubscriptionRequestLoading = false;
        draft.loading = false;
        break;
      case CONSTANTS.SET_ACTIVE_SUBSCRIPTIONS_ERROR:
        draft.loading = false;
        draft.isSubscriptionRequestLoading = false;
        break;
      case CONSTANTS.SET_IS_MAC:
        draft.isMac = action.payload;
        break;
      case CONSTANTS.UPDATE_ACCOUNT_DETAILS:
        draft.loading = true;
        break;
      case CONSTANTS.UPDATE_ACCOUNT_DETAILS_SUCCESS:
        draft.loading = false;
        draft.updateAccountDetailsResponseMessage =
          action.payload.updateAccountDetailsResponseMessage;
        break;
      case CONSTANTS.UPDATE_ACCOUNT_DETAILS_ERROR:
        draft.loading = false;
        draft.errorMessage = action.payload;
        break;
      case CONSTANTS.GET_PACKAGES:
        draft.loading = true;
        break;
      case CONSTANTS.GET_PACKAGES_SUCCESS:
        draft.loading = false;
        draft.GetPkgsAndProdsWithPromosResponseMessage =
          action.payload.GetPkgsAndProdsWithPromosResponseMessage;
        break;
      case CONSTANTS.GET_PACKAGES_ERROR:
        draft.loading = false;
        break;
      case CONSTANTS.LINK_TO_TV_SUCESS:
        draft.isLinkedToTV = true;
        break;
      case CONSTANTS.GET_PAYMENTS_REQUEST:
        draft.isGetPaymentLoading = true;
        break;
      case CONSTANTS.GET_PAYMENTS_SUCCESS:
        draft.isGetPaymentLoading = false;
        draft.getPayment = action.payload;
        break;
      case CONSTANTS.GET_PAYMENTS_ERROR:
        draft.isGetPaymentLoading = false;
        break;
      case CONSTANTS.CHANGE_SERVICE_REQUEST:
        draft.isChangeSubscriptionLoading = true;
        break;
      case CONSTANTS.CHANGE_SERVICE_SUCCESS:
        draft.isChangeSubscriptionLoading = false;
        draft.changeService = action.payload;
        break;
      case CONSTANTS.CHANGE_SERVICE_ERROR:
        draft.isChangeSubscriptionLoading = false;
        break;
      case CONSTANTS.SET_SUBSCRIPTION_TYPE:
        draft.subscriptionType = action.payload;
        break;
      default:
        break;
    }
  });

export default authReducer;
