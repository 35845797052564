
const getSessionToken = () => localStorage.getItem('sessionToken');
const setSessionToken = token => localStorage.setItem('sessionToken', token);
const removeSessionToken = () => localStorage.removeItem('sessionToken');

// evergent token & expiresIn
const getAccessToken = () => localStorage.getItem('evergantAccessToken');
const setAccessToken = token => localStorage.setItem('evergantAccessToken', token);
const removeAccessToken = () => localStorage.removeItem('evergantAccessToken');
const getRefreshToken = () => localStorage.getItem('refreshToken');
const setRefreshToken = token => localStorage.setItem('refreshToken', token)
const removeRefreshToken = () => localStorage.removeItem('refreshToken');
const setEvergentExpiresIn = expiresIn => localStorage.setItem('evergentExpiresIn', expiresIn)
const getEvergentExpiresIn = () => localStorage.getItem('evergentExpiresIn')

//quickplay token & expiresIn
const getOAuthToken = () => localStorage.getItem('quickPlayOAuthToken');
const setOAuthToken = token => localStorage.setItem('quickPlayOAuthToken', token);
const removeOAuthToken = () => localStorage.removeItem('quickPlayOAuthToken');
const setQuickPlayExpiresIn = expiresIn => localStorage.setItem('quickplayExpiresIn', expiresIn)
const getQuickPlayExpiresIn = () => localStorage.getItem('quickplayExpiresIn')

//quickplay flattoken 
const getAnonymousFlatToken = () => localStorage.getItem('quickPlayAnonymousFlatToken');
const setAnonymousFlatToken = token => localStorage.setItem('quickPlayAnonymousFlatToken', token);
const getTVEFlatToken = () => localStorage.getItem('quickPlayTVEFlatToken');
const setTVEFlatToken = token => localStorage.setItem('quickPlayTVEFlatToken', token);
const removeTVEFlatToken = () => localStorage.removeItem('quickPlayTVEFlatToken');
const setTVEFlatTokenExpiresIn = expiresIn => localStorage.setItem('quickPlayTVEFlatTokenExpiresIn', expiresIn);
const getTVEFlatTokenExpiresIn = () => localStorage.getItem('quickPlayTVEFlatTokenExpiresIn');
const setQuickPlayTVETokenExpiresIn = expiresIn => localStorage.setItem('quickplatTVEToken', expiresIn);
const getQuickPlayTVETokenExpiresIn = () => localStorage.getItem('quickplatTVEToken');

//device
const saveDeivceInfo = deviceInfo => localStorage.setItem('deviceInfo', deviceInfo);
const getDeviceInfo = () => JSON.parse(localStorage.getItem('deviceInfo'));
const removeDeviceInfo = () => localStorage.removeItem('deviceInfo');
const getRegCode = () => localStorage.getItem('regCode');
const setRegCode = ID => localStorage.setItem('regCode', ID);

const getPlatform = () => localStorage.getItem('platform');
const setPlatform = platform => localStorage.setItem('platform', platform);

const getTVCode = () => localStorage.getItem('TVCode');
const setTVCode = ID => localStorage.setItem('TVCode', ID);

const getActivationCode = () => localStorage.getItem('ActivationCode');
const setActivationCode = ActivationCode => localStorage.setItem('ActivationCode', ActivationCode);
const setCouchRightsDate = CouchRightsDate => localStorage.setItem('CouchRightsDate', CouchRightsDate);
const getCouchRightsDate = () => localStorage.getItem('CouchRightsDate');

const getUser = () => localStorage.getItem('userType');
const setUser = userType => localStorage.setItem('userType', userType);

const setMvpdProviders = mvpds => localStorage.setItem('mvpdsProviders', JSON.stringify(mvpds));
const getMvpdsProviders = () => localStorage.getItem('mvpdsProviders');

export {
  getSessionToken,
  setSessionToken,
  getRefreshToken,
  setRefreshToken,
  removeRefreshToken,
  removeSessionToken,
  saveDeivceInfo,
  getDeviceInfo,
  removeDeviceInfo,
  getAccessToken,
  setAccessToken,
  removeAccessToken,
  setEvergentExpiresIn,
  getEvergentExpiresIn,
  getOAuthToken,
  setOAuthToken,
  removeOAuthToken,
  setQuickPlayExpiresIn,
  getQuickPlayExpiresIn,
  getRegCode,
  setRegCode,
  getAnonymousFlatToken,
  setAnonymousFlatToken,
  getTVEFlatToken,
  setTVEFlatToken,
  removeTVEFlatToken,
  setTVEFlatTokenExpiresIn,
  getTVEFlatTokenExpiresIn,
  setQuickPlayTVETokenExpiresIn,
  getQuickPlayTVETokenExpiresIn,
  setTVCode,
  getTVCode,
  setPlatform,
  getPlatform,
  setActivationCode,
  getActivationCode,
  setCouchRightsDate,
  getCouchRightsDate,
  getUser,
  setUser,
  setMvpdProviders,
  getMvpdsProviders
};
