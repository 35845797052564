import * as Yup from 'yup';
import moment from 'moment-timezone';

// import { gender } from './constants';

const signinSchema = Yup.object({
  email: Yup.string()
    .email('Please enter an email')
    .required('Please enter an email'),
  password: Yup.string().required('Please enter a password')
});

const registerSchema = Yup.object({
  name: Yup.string().required('Please enter your name'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address'),
  confirmEmail: Yup.string()
    .email('Please enter a valid email address')
    .oneOf([Yup.ref('email'), null], 'The email you have entered doesnt match')
    .required('Please enter a valid email address'),
  password: Yup.string().min(8, 'Your password must contain at least 8 characters')
  // dob: Yup.string()
  //   .required('Please enter a valid Date of Birth')
  //   .test('dob', "User's age must be 13+", value => {
  //     if (value === undefined) {
  //       return true;
  //     }

  //     const minYear = parseInt(moment().subtract(MaxLimitAge, 'y').format('YYYY'), 10);
  //     const maxYear = parseInt(moment().subtract(MinLimitAge, 'y').format('YYYY'), 10);

  //     return moment(value).year() >= minYear && moment(value).year() <= maxYear;
  //   })
});

const secondRegisterSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address')
    .nullable(),
  password: Yup.string()
    .min(8, 'Your password must contain at least 8 characters')
    .required('Your password must contain at least 8 characters')
});

const authenticationSchema = Yup.object({
  activationCode: Yup.string().required('Please enter the code')
});

const manageAccountSchema = Yup.object({
  name: Yup.string().required('Please enter your name')
});

const deleteAccountSchema = Yup.object({
  comment: Yup.string()
});

const emailConfirmSchema = Yup.object({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Please enter a valid email address')
});

const passwordConfirmSchema = Yup.object({
  newPassword: Yup.string()
    .min(8, 'Password is too short')
    .required('Your password must contain at least 8 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Password does not match')
    .required('Your password must contain at least 8 characters')
});
// change password schema

const changePasswordSchema = Yup.object({
  currentPassword: Yup.string()
    .min(8, 'Your password must contain at least 8 characters')
    .required('Your password must contain at least 8 characters'),
  newPassword: Yup.string()
    .min(8, 'Password is too short')
    .required('Your password must contain at least 8 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Password does not match')
    .required('Your password must contain at least 8 characters')

});

const PaymentSchema = Yup.object({
  name: Yup.string()
    .required('Please enter a name'),
  billingAddress: Yup.string()
    .required('Please enter an address'),
  city: Yup.string()
    .required('Please enter a city'),
  state: Yup.string()
    .required('Please enter a state'),
  code: Yup.string()
    .required('Please enter a zip code'),
  cardNumber: Yup.string()
    .required('Please enter a card number'),
  date: Yup.string()
    .required('Please enter a date')
    .test('date', 'Please enter a date greater than today ', value => {
      if (value === undefined) {
        return true;
      }

      const isValid = moment(value, 'MM/YY').isValid();
      return isValid && moment().format('MM/YY') < value;

    }),
  cvv: Yup.string()
    .required('Please enter a numbrt'),
});

export {
  signinSchema,
  registerSchema,
  secondRegisterSchema,
  authenticationSchema,
  emailConfirmSchema,
  passwordConfirmSchema,
  manageAccountSchema,
  changePasswordSchema,
  deleteAccountSchema,
  PaymentSchema
};
