import React, { useState } from 'react';
import './DropDown.scss';

const DropDown = ({ options, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSelectChange = (event) => {
    const { value } = event.target;
    setSelectedOption(value);
    onChange(value);
  };

  return (
    <div className="custom-select-wrapper">
      <select
        className="custom-select"
        value={selectedOption}
        onChange={handleSelectChange}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropDown;