import React from 'react';
import Moment from 'react-moment';
import Card from 'react-bootstrap/Card';
import { ReactComponent as LockIcon } from 'assets/svgs/lock_icon.svg';
import './PlaylistCard.scss';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

// eslint-disable-next-line max-len
const PlaylistCard = ({
  isMoving,
  image,
  title,
  publishdate,
  videolength,
  responsive,
  lockIcon,
  setShowSubscribeScreen,
  ...props
}) => {
  // eslint-disable-next-line no-shadow
  const renderTooltip = props => (
    <Tooltip className="title-tooltip" {...props}>
      {title}
    </Tooltip>
  );

  return (
    <div {...props}>
      <Card className="playlistcard">
        <div className={responsive === 0 ? 'cardimage-a' : 'cardimage-b'}>
          <Card.Img className="card_image" variant="top" src={image} />
          <Card.ImgOverlay className="d-flex justify-content-end">
            {lockIcon ? <LockIcon onClick={() => setShowSubscribeScreen(true)} /> : <></>}
          </Card.ImgOverlay>
        </div>
        {publishdate && videolength &&(
          <Card.Body className="cardbody">
            <OverlayTrigger placement="top" overlay={renderTooltip}>
              <Card.Title
                className={responsive === 0 ? 'tglc-32 cardtitle-a' : 'tglc-16 cardtitle-b'}
              >
                {title}
              </Card.Title>
            </OverlayTrigger>
            <div className="d-flex justify-content-between">
              <span className="tglc-11 publishdate">
                <Moment format="LL">{publishdate}</Moment>
              </span>
              <div className="tglb-13 vidlen">{videolength}</div>
            </div>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

export default PlaylistCard;
