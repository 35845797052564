export const MVPD_LIST_REQUEST = 'MVPD_LIST_REQUEST';
export const MVPD_LIST_SUCCESS = 'MVPD_LIST_SUCCESS';
export const MVPD_LIST_ERROR = 'MVPD_LIST_ERROR';

export const CHECK_AUTHENTICATION_REQUEST = 'CHECK_AUTHENTICATION_REQUEST';
export const CHECK_AUTHENTICATION_SUCCESS = 'CHECK_AUTHENTICATION_SUCCESS';
export const CHECK_AUTHENTICATION_ERROR = 'CHECK_AUTHENTICATION_ERROR';
export const CHECK_REG_CODE_ERROR = 'CHECK_REG_CODE_ERROR';
export const SELECTED_MVPD_DEVICE_UPDATED = 'SELECTED_MVPD_DEVICE_UPDATED';

export const GET_USER_METADATA_REQUEST = 'GET_USER_METADATA_REQUEST';
export const GET_USER_METADATA_SUCCESS = 'GET_USER_METADATA_SUCCESS';
export const GET_USER_METADATA_ERROR = 'GET_USER_METADATA_ERROR';

// usermetadata zip
export const GET_USERMETADATA_ZIP_SUCCESS = 'GET_USERMETADATA_ZIP_SUCCESS';

// geoLoction
export const GET_QUICKPLAY_GEO_LOCATION_REQUEST = 'GET_QUICKPLAY_GEO_LOCATION_REQUEST';
export const GET_QUICKPLAY_GEO_LOCATION_SUCCESS = 'GET_QUICKPLAY_GEO_LOCATION_SUCCESS';
export const GET_QUICKPLAY_GEO_LOCATION_ERROR = 'GET_QUICKPLAY_GEO_LOCATION_ERROR';

// quickplay TVE flattoken
export const GET_QUICKPLAY_TVE_FLATTOKEN_REQUEST = 'GET_QUICKPLAY_TVE_FLATTOKEN_REQUEST';
export const GET_QUICKPLAY_TVE_FLATTOKEN_SUCCESS = 'GET_QUICKPLAY_TVE_FLATTOKEN_SUCCESS';
export const GET_QUICKPLAY_TVE_FLATTOKEN_ERROR = 'GET_QUICKPLAY_TVE_FLATTOKEN_ERROR';
export const SET_XDEVICEID_TOKEN = 'SET_XDEVICEID_TOKEN';

// registerDevice
export const REGISTER_DEVICE_SUCCESS = 'REGISTER_DEVICE_SUCCESS';
export const REGISTER_DEVICE_ERROR = 'REGISTER_DEVICE_ERROR';

export const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS';
export const ADD_SUBSCRIPTION_ERROR = 'ADD_SUBSCRIPTION_ERROR';
