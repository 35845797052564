import React, { useState, useEffect, useCallback } from 'react';
import { generateReferralCode } from 'services/api/EvergentApi';
import PageWrapper from 'pages/layoutPage/PageWrapper';
import NewLayout from 'containers/AuthLayout/NewLayout';
import useAnalyticsScreenTracking from 'hooks/useAnalyticsScreenTracking';
import { useSelector } from 'react-redux';
import {
  segmentEvent,
  identify
} from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import Copy from '../../../assets/images/copy.png';
import './ReferAFriend.scss';

const ReferAFriend = ({ goBack }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [copyText, setCopyText] = useState('');
  const [error, setError] = useState(false);
  const { remoteConfig } = useSelector(state => state.app.watch);
  const account = useSelector(state => state.auth.account);
  const userId = account?.profile?.spAccountID || '';
  useAnalyticsScreenTracking('Refer A Friend', '', '', 'Referred Friend Clicked');

  const fetchReferalCode = useCallback(async () => {
    if (!remoteConfig?.referralProgram) {
      return;
    }
    try {
      const response = await generateReferralCode(remoteConfig?.referralProgram);
      setCopyText(response);
      identify(userId, { referrer_id: response });
    } catch (err) {
      setError(true);
      setCopyText(err.message);
    }
  }, [remoteConfig.referralProgram, userId]);

  useEffect(() => {
    fetchReferalCode();
  }, [fetchReferalCode]);

  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(copyText)
      .then(() => {
        setIsCopied(true);
        segmentEvent('Referral Code Copied', { referral_code: copyText });
        setTimeout(() => {
          setIsCopied(false); // Remove "Copied!" message after a few seconds
        }, 2000);
      })
      .catch(err => {
        console.error('Could not copy text: ', err);
      });
  };

  return (
    <PageWrapper
      showHeader
      showBackIcon
      onBackClick={goBack}
      showTitle
      titleText="Refer A Friend,"
      titleNewSubText="Earn YES Rewards Points!"
    >
      <NewLayout>
        <div className="refer-a-friend">
          <div className="refer-a-friend__text">
            Share your code with friends and family and earn YES Reward points when they subscribe!
            <div className="refer-a-friend__desc">
              Refer a monthly subscriber: 60,000 YES Rewards points
            </div>
            <div className="refer-a-friend__desc mb-0">
              Refer an annual subscriber: 100,000 YES Rewards points
            </div>
            <div className="refer-a-friend__subtext">
              Copy this code into an email or messaging app.
            </div>
            <div className="refer-a-friend__copied-message">
              {isCopied && <span>COPIED!</span>}
            </div>
            <div className="refer-a-friend__textbox">
              <span className="refer-a-friend__textbox-text">{copyText}</span>
              {!error && (
                <span className="refer-a-friend__textbox-icon">
                  <img src={Copy} alt="copy-icon" onClick={handleCopyClick} />
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="refer-a-freind__footer" />
      </NewLayout>
    </PageWrapper>
  );
};

export default ReferAFriend;
