/* eslint-disable no-use-before-define */
/* eslint-disable no-plusplus */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BitmovinPlayer from 'components/BitmovinPlayer';
import React, { useState, useEffect, useCallback } from 'react';
import './FullScreenPlayer.scss';
import { history } from 'redux/configureStore';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import VODimage from 'assets/images/vod_item_image.png';
import moment from 'moment-timezone';
import UpNextVideo from './UpNextVideo';
import { getVODRequest } from '../redux/actions';
import ErrorContent from '../ErrorContent';

const FullScreenPlayer = props => {
  const {
    errorType,
    videoName,
    videoTitle,
    entityDuration,
    entityType,
    entityId,
    mvpd,
    source,
    autoPlay,
    linearId,
    setIdVOD,
    setVideoName,
    setVideoTitle,
    playLiveChannel,
    setCurrentSection,
    isSubscribe,
    detailedSections,
    selectedMVPD,
    setIsVODReplayed,
    isVODReplayed
  } = props;
  const dispatch = useDispatch();
  const historyListener = useHistory();
  const [locationKeys, setLocationKeys] = useState([]);
  const [nextVideo, setNextVideo] = useState(null);
  const [videoEnd, setVideoEnd] = useState(false);
  const getNextItemInListCallback = useCallback(getNextItem, [detailedSections]);

  const goBack = () => {
    dispatch(getVODRequest(linearId));
    history.push({
      pathname: `/watch/${linearId}`,
      search: window.location.search
    });
    setIdVOD(false);
    setCurrentSection('');
  };

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  useEffect(
    () =>
      historyListener.listen(location => {
        if (historyListener.action === 'PUSH') {
          setLocationKeys([location.key]);
        }

        if (historyListener.action === 'POP') {
          if (locationKeys[1] === location.key) {
            setLocationKeys(([_, ...keys]) => keys);

            // Handle forward event
          } else {
            setLocationKeys(keys => [location.key, ...keys]);

            // Handle back event
            goBack();
          }
        }
      }),
    // eslint-disable-next-line
    [locationKeys]
  );

  function getNextItem(id, list, predicate) {
    if (!list?.length) return null;
    const currentIndex = list?.findIndex(item => item.id === id);
    if (currentIndex === -1) {
      return null;
    }
    for (let i = currentIndex + 1; i < list.length; i++) {
      const item = list[i];
      if (predicate(item)) {
        return item;
      }
    }
    return null;
  }

  const playItem = (assetId, name, duration, title, inFreePackage) => {
    setVideoName(name);
    setVideoTitle(title);
    dispatch(getVODRequest(assetId));
    playLiveChannel(assetId, duration, inFreePackage);
    setVideoEnd(false);
  };

  const filterimages = ITEM => {
    if (ITEM && ITEM?.ia && ITEM?.ia[0]) {
      return `${process.env.REACT_APP_QUICKPLAY_IMAGE_BASE_URL}/image/${ITEM.id}/${ITEM.ia[0]}.jpg?width=500`;
    }
    return VODimage;
  };

  const formatDate = date => {
    const formattedDate = moment(date).format('LL');
    return formattedDate;
  };

  useEffect(() => {
    console.log('NEXT VIDEO: ', nextVideo);
  }, [nextVideo]);

  useEffect(() => {
    const ac = new AbortController();
    const isContentAvailable = item =>
      // Check if the item is available to the user (e.g., user has access or it's free)
      isSubscribe ||
      selectedMVPD !== null ||
      item.ent.some(en => en.sp.includes('urn:package:Free_Pkg'));
    const suggestion = getNextItemInListCallback(entityId, detailedSections, isContentAvailable);
    setNextVideo(suggestion);
    return () => ac.abort();
  }, [entityId, detailedSections, getNextItemInListCallback, isSubscribe, selectedMVPD]);

  useEffect(
    () =>
      historyListener.listen(location => {
        if (historyListener.action === 'PUSH') {
          setLocationKeys([location.key]);
        }

        if (historyListener.action === 'POP') {
          if (locationKeys[1] === location.key) {
            setLocationKeys(([_, ...keys]) => keys);

            // Handle forward event
          } else {
            setLocationKeys(keys => [location.key, ...keys]);

            // Handle back event
            goBack();
          }
        }
      }),
    // eslint-disable-next-line
    [locationKeys]
  );

  return (
    <div className="full-screen">
      <div className="d-flex flex-row">
        <div className="p-2">
          <FontAwesomeIcon className="back-button" icon="arrow-left" onClick={() => goBack()} />
        </div>
        <div className="p-2">
          <h3 className="tgd1-n-base-20 text-center">{videoName}</h3>
        </div>
      </div>
      {errorType ? (
        ErrorContent(errorType)
      ) : (
        <>
          {nextVideo && videoEnd ? (
            <UpNextVideo
              assetId={nextVideo.id}
              name={nextVideo?.lon[0].n}
              duration={nextVideo?.vrt}
              title={videoTitle}
              image={filterimages(nextVideo)}
              date={formatDate(nextVideo?.st_dt)}
              playItem={playItem}
              setNextVideo={setNextVideo}
              inFreePackage={nextVideo?.ent[0]?.sp?.map(i => i.includes('Free')).includes(true)}
              setIsVODReplayed={setIsVODReplayed}
            />
          ) : null}
          <BitmovinPlayer
            vod={entityId}
            videoName={videoName}
            videoTitle={videoTitle}
            entityDuration={entityDuration}
            entityType={entityType}
            entityId={entityId}
            source={source}
            autoPlay={autoPlay}
            setVideoEnd={value => setVideoEnd(value)}
            mvpd={mvpd}
            isSubscribe={isSubscribe}
            isVODReplayed={isVODReplayed}
            setIsVODReplayed={setIsVODReplayed}
          />
        </>
      )}
    </div>
  );
};

export default FullScreenPlayer;
