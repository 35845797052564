/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import Carousel from 'react-multi-carousel';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'redux/configureStore';
import { toastErrorNotify } from 'pages/toast/redux/actions';
import log from 'utils/log';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import BitmovinPlayer from 'components/BitmovinPlayer';
import Channel from 'components/Channel/Channel';
import PageLoading from 'components/PageLoading';
import Moment from 'react-moment';
import overFlowImage from 'assets/Thumbnail/Thumbnail.png';
import eventAnalytics from 'utils/eventAnalytics';
import { signout } from 'pages/auth/redux/actions';
import { watchErrorTypeConstants } from 'helpers/constants';
import ConvivaProxy from 'services/ConvivaProxy';
import debounce from 'lodash.debounce';
import useQueryString from 'utils/useQueryString';
import queryString from 'query-string';
import AccountIcon from 'assets/svgs/account_icon.svg';
import { softwareStatement } from 'config';
import { getUser } from 'utils/localStorage';
import Model from 'components/Model';
import {
  getLinearChannelsRequest,
  getOverflowChannelsRequest,
  getTVScheduleRequest,
  playLiveChannelRequest,
  clearAPIError,
  noSubscriptionError,
  getVODRequest,
  setCurrentScheduleItem,
  setErrorType,
  setErrorCode
} from './redux/actions';
import './Watch.scss';
import VODList from './VODList';
import VODSearchResults from './VODSearchResults';
import { getWebDeviceId } from '../../../config';
import FullScreenPlayer from './FullScreenPlayer';
import ErrorContent from './ErrorContent';
import '../../../../node_modules/font-awesome/css/font-awesome.min.css';
import Account from '../Account';
import VODCategory from './VODCategory';
import Subscribe from '../Subscribe';
import { getActiveSubscriptions, getPackages } from '../../auth/redux/actions';
import { ovp } from '../../../utils/consts';
import {
  VideoPlaybackCompleted,
  VideoContentCompleted,
  VideoStartSession,
  segmentEvent
} from '../../../components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';
import getUserType from '../../../utils/userType';
import SubscribeToGotham from 'pages/auth/SubscribeToGotham';

const Watch = ({ vodId, search }) => {
  eventAnalytics('page', 'Watch');
  const dispatch = useDispatch();
  const [refreshListing, setRefreshListing] = useState(false);
  const [entityId, setEntityId] = useState(null);
  const [sectionId, setSectionId] = useState(null);
  const [entityType, setEntityType] = useState({});
  const [detailedSections, setDetailedSections] = useState({});
  const [entityDuration, setEntityDuration] = useState({});
  const [mvpdToken, setMVPDToken] = useState(null);
  const [videoName, setVideoName] = useState('');
  const [videoTitle, setVideoTitle] = useState('');
  const [authLoading, setAuthLoading] = useState(false);
  const [trackerId, setTrackerId] = useState(null);
  const [accessEnabler, setAccessEnabler] = useState(null);
  const [time, setTime] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [entityIdChanges, setEntityIdChanges] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [orbisId, setOrbisId] = useState(null);
  const [currentChannel, setCurrentChannel] = useState('linear');
  const [value, setValue] = useState('');
  const [hasClicked, setHasClicked] = useState(false);
  const [onSearch, setOnSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [prevQuery, setPrevQuery] = useQueryString('vodSearch', '');
  const [isVod, setIsVod] = useState(false);
  const [isFreePackage, setIsFreePackage] = useState(false);
  const [showSubscribeScreen, setShowSubscribeScreen] = useState(false);
  const [sceenicId, setSceenicId] = useState('');
  const [currentSection, setCurrentSection] = useQueryString('currentSection', '');
  const [width, setWidth] = useState(window.innerWidth);
  const [isSettingsOpen, setIsSettingsOpen] = useQueryString('settings', '');
  const [catalogType, setCatalog] = useState('');
  const [addSubscriptionErrorMessage, setAddSubscriptionErrorMessage] = useState(true);
  const [contentTypeId, setContentTypeId] = useState('');
  const [containerVodURL, setContainerVodURL] = useState('');
  const [convivaSessionStart, setConvivaSessionStart] = useState(true);
  const userType = getUser();
  const { geoLocation } = useSelector(state => state.app.deviceMVPD);
  const [isVODReplayed, setIsVODReplayed] = useState(false);

  const {
    linearChannels,
    signedUrls,
    checkingBlackOutTime,
    overflowChannels,
    upcomingSchedule,
    errorType,
    errorCode,
    islieanerChannelsLoading,
    isSelectedChannelLoading,
    urlVOD,
    remoteConfig,
    localization,
    currentScheduleItem
  } = useSelector(state => state.app.watch);
  const { XDeviceIDToken, registerDevice, addSubscriptionError } = useSelector(
    state => state.app.deviceMVPD
  );
  const { account, GetActiveSubscriptionsResponseMessage, subscriptionType, loading } =
    useSelector(state => state.auth);

  // winback reload
  if (localStorage.getItem('winbackReloadFlag') === 'true') {
    window.location.reload();
    localStorage.removeItem('winbackReloadFlag');
  }

  const isSubscribe =
    GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.length &&
    GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.filter(
      sub => sub.status === 'Active' || 'Final Bill'
    )[0]?.paymentMethod !== 'Operator Billing';

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5.5,
      slidesToSlide: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1.5,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  window.addEventListener('beforeunload', () => {
    ConvivaProxy.exit();
  });
  const CustomLeftArrow = ({ onClick }) => (
    <Button className="left-arrow-button" onClick={() => onClick()}>
      <FontAwesomeIcon className="mr-2 left-arrow" icon={faChevronLeft} />
    </Button>
  );
  const CustomRightArrow = ({ onClick, length }) => (
    <Button className={length > 1 ? 'right-arrow-button' : 'disable'} onClick={() => onClick()}>
      <FontAwesomeIcon className="mr-2 right-arrow" icon={faChevronRight} />
    </Button>
  );

  const toSubscriptionType = subType => {
    switch (subType) {
      case 'Monthly':
        return 'monthly';
      case 'Yearly':
        return 'annual';
      default:
        return '';
    }
  };

  let analyticFlag = true;
  useEffect(() => {
    if (analyticFlag === true) {
      window.analytics.track('Application Opened');
      analyticFlag = false;
    }
  }, [analyticFlag]);
  useAnalyticsScreenTracking('Watch', 'Watch', '/watch');

  useEffect(() => {
    if (subscriptionType !== null && (userType === 'dtc' || userType === 'tve')) {
      let serviceType = '';
      switch (userType) {
        case 'dtc':
          serviceType = 'dtc';
          break;
        case 'tve':
          serviceType = 'tve';
          break;
        default:
          // Consolidate free users to dtc for analytics purposes.
          serviceType = 'dtc';
          break;
      }
      window.analytics.identify({
        service_type: serviceType,
        subscription_type: toSubscriptionType(subscriptionType)
      });
    }
  }, [isSubscribe, subscriptionType, userType]);

  const { mvpdAuthenticated, selectedMVPD } = useSelector(state => state.app.mvpdSelect);
  const { quickplayTVEFlatToken } = useSelector(state => state.app.deviceMVPD);
  const overflowCarousel = 767;

  const selectedMVPDObj = remoteConfig?.tvProviders?.filter(mvpd => mvpd.id === selectedMVPD)[0];

  const liveSource = signedUrls && signedUrls.data ? signedUrls?.data?.contentUrl : null;
  const vodSource = signedUrls && signedUrls.data ? signedUrls?.data?.contentUrl : null;
  const isChannelDisabled =
    !mvpdToken &&
    !!errorType &&
    errorType !== watchErrorTypeConstants.OUT_OF_US_ERROR &&
    errorType !== watchErrorTypeConstants.BLACKOUT_ERROR &&
    errorType !== watchErrorTypeConstants.OUT_OF_MARKETPLACE_ERROR &&
    errorType !== watchErrorTypeConstants.COUCH_RIGHTS_EXPIRY_ERROR;

  const activePackage = quickplayTVEFlatToken?.AccountServiceMessage?.filter(
    i => i.validityTill > new Date().getMilliseconds()
  );
  const numberOfActivePackage = activePackage?.length > 1 ? 'MVPD' : 'MVPD OOM';

  useEffect(() => {
    if (window.performance.timing.navigationType === window.performance.timing.TYPE_RELOAD) {
      // Remove the search query parameters from the URL
      window.history.replaceState({}, document.title, window.location.pathname);
    }
    if (userType === 'dtc') {
      dispatch(getActiveSubscriptions());
    }

    window.scroll(0, 0);
    if (typeof window?.Adobe?.AccessEnabler !== 'undefined')
      setAccessEnabler(new window.Adobe.AccessEnabler(softwareStatement));
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    ConvivaProxy.setUserContext({
      spAccountID: account?.profile?.spAccountID,
      userType: getUserType(getUser(), numberOfActivePackage).convivaUserType,
      mvpdName: selectedMVPDObj?.displayName ?? ''
    });
  }, [account]);

  useEffect(() => {
    if (search) {
      setValue(queryString.parse(search)?.vodSearch);
      setSearchQuery(queryString.parse(search)?.vodSearch);
      setSceenicId(queryString.parse(search)?.together);
      setCurrentSection(queryString.parse(search)?.currentSection);
      setIsSettingsOpen(queryString.parse(search)?.settings);
    }
    // eslint-disable-next-line
  }, [search]);

  const getMrss = (title, entityIdMrss, mvpdId = null) => {
    const mvpdObj = remoteConfig?.tvProviders?.find(mvpd => mvpd.id === mvpdId);
    if (mvpdObj && mvpdObj.mrss) {
      return `<rss xmlns:media="http://search.yahoo.com/mrss/" version="2.0">
          <channel>
            <title>${process.env.REACT_APP_ADOBE_REQUESTOR_ID}</title>
            <item>
              <title>${title}</title>
              <guid>${entityIdMrss}</guid>
            </item>
          </channel>
        </rss>`;
    }
    return process.env.REACT_APP_ADOBE_REQUESTOR_ID;
  };

  const startAuthZ = (selectedEntityId, catalog, contentId) => {
    window.tokenRequestFailed = (
      inRequestedResourceID,
      inRequestErrorCode,
      inRequestDetailedErrorMessage
    ) => {
      log('tokenRequestFailed', inRequestDetailedErrorMessage);
      console.log(
        'tokenRequestFailed',
        inRequestDetailedErrorMessage,
        inRequestErrorCode,
        inRequestedResourceID
      );
      setAuthLoading(false);
      dispatch(
        toastErrorNotify({
          type: 'Subscription Error',
          message: 'You are not subscribed to YES Networks!'
        })
      );
      dispatch(noSubscriptionError());
    };

    /**
     * trigger: checkAuthorization() and getAuthorization() after a successful
     * authorization to view a resource.
     *
     * @param {type} resource - the content that the user is authorized to view.
     * @param {type} token - the short-lived media token
     */
    window.setToken = (resource, token) => {
      log('setToken');
      const parsedToken = window.btoa(token);
      setMVPDToken(parsedToken);
      setAuthLoading(false);
      if (XDeviceIDToken) {
        dispatch(
          playLiveChannelRequest({
            contentId: selectedEntityId,
            XDeviceIDToken,
            registerDevice,
            selectedMVPD,
            catalogType: catalog,
            contentTypeId: contentId,
            mvpdToken: parsedToken,
            ppid: account?.profile?.spAccountID || account?.profile?.cpCustomerID
          })
        );
      }
    };

    const mrss = getMrss('title', selectedEntityId, selectedMVPD);
    if (accessEnabler) {
      accessEnabler.getAuthorization(mrss, null);
    }
    log('getAuthorization');
  };
  const getLiveDuration = () => {
    if (upcomingSchedule?.sc_ed_dt && upcomingSchedule?.sc_st_dt) {
      const endTime = new Date(upcomingSchedule.sc_ed_dt).getTime();
      const startTime = new Date(upcomingSchedule.sc_st_dt).getTime();
      return (endTime - startTime) / 1000;
    }
    return 0;
  };

  // called to play any resource (linear/live/vod)
  const playLiveChannel = (
    selectedEntityId,
    duration = 0,
    videoType = 'linear',
    isFreeVod = false,
    catalog = 'channel',
    contentId = 'live'
  ) => {
    dispatch(setErrorType(null));
    dispatch(setErrorCode(null));
    setCatalog(catalog);
    setContentTypeId(contentId);
    setCurrentChannel(
      videoType.toUpperCase() === 'LINEAR'
        ? 'linear'
        : videoType.toUpperCase() === 'OVERFLOW'
        ? `overflow_${selectedEntityId}`
        : ''
    );
    setIsFreePackage(isFreeVod);
    history.push({
      pathname: `/watch/${selectedEntityId}`,
      search: window.location.search
    });
    setEntityId(selectedEntityId);
    if (entityDuration[selectedEntityId] === undefined || entityDuration[selectedEntityId] === 0) {
      entityDuration[selectedEntityId] = duration;
      setEntityDuration(entityDuration);
    }

    if (
      entityType[selectedEntityId] === undefined ||
      (entityType[selectedEntityId] !== videoType && videoType.toUpperCase() !== 'LINEAR')
    ) {
      setEntityType({ ...entityType, [selectedEntityId]: videoType });
    }
    if (isFreeVod && XDeviceIDToken) {
      dispatch(
        playLiveChannelRequest({
          contentId: selectedEntityId,
          XDeviceIDToken,
          selectedMVPD,
          catalogType: catalog,
          contentTypeId: contentId,
          registerDevice,
          ppid: account?.profile?.spAccountID || account?.profile?.cpCustomerID,
          isSubscribe
        })
      );
    } else if (XDeviceIDToken && isSubscribe !== null) {
      if (isSubscribe) {
        setAuthLoading(false);
        dispatch(
          playLiveChannelRequest({
            contentId: selectedEntityId,
            XDeviceIDToken,
            registerDevice,
            selectedMVPD,
            catalogType: catalog,
            contentTypeId: contentId,
            ppid: account?.profile?.spAccountID || account?.profile?.cpCustomerID,
            isSubscribe
          })
        );
      } else {
        setAuthLoading(true);
        startAuthZ(selectedEntityId, catalog, contentId);
      }
    }
  };

  // play live channel on the watch page on the watch screen page.
  useEffect(() => {
    if (entityId && mvpdAuthenticated && time === 0) {
      setTime(1);
      playLiveChannel(entityId, getLiveDuration());
    }
    // eslint-disable-next-line
  }, [entityId, mvpdAuthenticated, XDeviceIDToken, GetActiveSubscriptionsResponseMessage]);

  useEffect(() => {
    setEntityIdChanges(true);
  }, [signedUrls]);

  // play live channel when go back to watch screen from full screen
  useEffect(() => {
    if (linearChannels.length && !isVod && !isSettingsOpen) {
      if (prevQuery) {
        setValue(prevQuery);
        setSearchQuery(prevQuery);
      }
      if (XDeviceIDToken) {
        dispatch(clearAPIError());
        playLiveChannel(linearChannels[0].cid, getLiveDuration());
        setRefreshListing(!refreshListing);
        setCurrentChannel('linear');
      }
    }
    // eslint-disable-next-line
  }, [isVod, XDeviceIDToken, GetActiveSubscriptionsResponseMessage, isSettingsOpen]);

  // play VOD from url and setIsVOD depending on resource type
  useEffect(() => {
    if (vodId && urlVOD && urlVOD.id === vodId) {
      if (selectedMVPD || isFreePackage || isSubscribe) {
        setVideoName(urlVOD?.lon[0].n);
        setVideoTitle(urlVOD?.lon[0].n);
        setIsVod(true);
      } else if (linearChannels?.length && XDeviceIDToken) {
        dispatch(getVODRequest(linearChannels[0].cid));
        playLiveChannel(linearChannels[0].cid, getLiveDuration());
        setIsVod(false);
      }
    } else if (urlVOD['epg/stations'] || urlVOD['live/teamCompetitions']) {
      setIsVod(false);
    }
    // eslint-disable-next-line
  }, [urlVOD, XDeviceIDToken, GetActiveSubscriptionsResponseMessage]);

  // get resource data from api based on id or load linear channel
  useEffect(() => {
    if (vodId) {
      setEntityId(vodId);
    } else if (linearChannels.length) {
      setEntityId(linearChannels[0].cid);
    }
    const requiredEntityType = (entityType && entityType[entityId]) ?? '';
    const videoIdValue =
      requiredEntityType === 'VOD'
        ? urlVOD?.id || ''
        : requiredEntityType === 'linear'
        ? linearChannels[0]?.pgm_id || ''
        : overflowChannels?.id || '';

    const seriesNameConvivaMetaData =
      requiredEntityType === 'VOD'
        ? null
        : requiredEntityType === 'linear'
        ? linearChannels[0]?.pgm?.lostl
          ? linearChannels[0]?.pgm?.lostl[0].n
          : null
        : null;

    ConvivaProxy.setAssetContext({
      id: videoIdValue,
      type: requiredEntityType ?? '',
      duration: entityDuration,
      source: liveSource,
      seriesName: seriesNameConvivaMetaData
    });
    // eslint-disable-next-line
  }, [vodId, linearChannels.length, refreshListing]);

  useEffect(() => {
    dispatch(getOverflowChannelsRequest());
    dispatch(getTVScheduleRequest());
    dispatch(getLinearChannelsRequest());
  }, [dispatch, refreshListing]);
  let OverflowTracker = null;
  useEffect(() => {
    if (isSubscribe) {
      if (OverflowTracker) {
        clearInterval(OverflowTracker);
      }
      OverflowTracker = setInterval(() => {
        dispatch(getOverflowChannelsRequest());
      }, 1 * 30 * 1000);
    }
    return () => {
      if (OverflowTracker) {
        clearInterval(OverflowTracker);
      }
    };
  }, [dispatch, isSubscribe]);

  let programSegmentProperty =
    upcomingSchedule !== null
      ? upcomingSchedule?.pgm?.loepn
        ? upcomingSchedule?.pgm?.loepn[0].n
        : upcomingSchedule?.pgm?.lon[0].n
      : '';
  let titleSegmentProperty =
    upcomingSchedule !== null
      ? upcomingSchedule?.pgm?.loepn
        ? upcomingSchedule?.pgm?.loepn[0].n
        : upcomingSchedule?.pgm?.lon[0].n
      : '';
  let descriptionSegmentProperty =
    upcomingSchedule !== null
      ? upcomingSchedule?.pgm?.lod
        ? upcomingSchedule?.pgm?.lod[0].n
        : ''
      : '';
  let livestreamSegmentProperty = upcomingSchedule !== null ? upcomingSchedule?.ev_live : '';
  let startDateSegmentProperty = upcomingSchedule?.sc_st_dt;
  let gameStartTime = moment(startDateSegmentProperty)
    .tz('America/New_York')
    .format('h:mma')
    .toUpperCase();

  const convivaLiveFlag =
    currentScheduleItem?.ev_live === 'true'
      ? 'true'
      : currentScheduleItem?.ev_replay === 'true'
      ? 'false'
      : '';
  const properties = {
    program: programSegmentProperty,
    player_name: process.env.REACT_APP_ANALYTICS_PLAYER_NAME,
    title: titleSegmentProperty,
    description: descriptionSegmentProperty,
    publisher: 'YES Network',
    asset_id: orbisId,
    session_id: getWebDeviceId(),
    livestream: livestreamSegmentProperty,
    ovp,
    channel: 'YES Network',
    genre: 'TV-G',
    startDate: startDateSegmentProperty,
    game_start_time: gameStartTime,
    liveFlag: convivaLiveFlag
  };

  useEffect(() => {
    if (
      currentChannel === 'linear' &&
      ((mvpdToken && liveSource && remoteConfig?.easeLive) || isSubscribe)
    ) {
      clearInterval(trackerId);
      let timeDifference = !entityIdChanges
        ? checkingBlackOutTime
          ? new Date(checkingBlackOutTime) - new Date() + 15000
          : 1250
        : 1250;

      if (timeDifference < 0) {
        timeDifference = 12500;
      }
      console.log(checkingBlackOutTime, timeDifference, 'blackout time difference');

      const id = setInterval(() => {
        const isBlackoutChecking = true;
        if (entityIdChanges) {
          setEntityIdChanges(false);
        }
        if (entityId === linearChannels[0]?.cid && timeDifference !== 1250) {
          dispatch(getTVScheduleRequest());
        }
        if (XDeviceIDToken && !isSelectedChannelLoading) {
          if (timeDifference !== 1250) {
            setConvivaSessionStart(false);
            segmentEvent(VideoContentCompleted, properties);
            ConvivaProxy.track(VideoPlaybackCompleted);
            if (upcomingSchedule?.pgm_id) {
              ConvivaProxy.track(VideoStartSession);
            }
          }
          dispatch(
            playLiveChannelRequest({
              contentId: entityId,
              XDeviceIDToken,
              isBlackoutChecking,
              currentChannel,
              selectedMVPD,
              catalogType,
              contentTypeId,
              registerDevice,
              ppid: account?.profile?.spAccountID || account?.profile?.cpCustomerID,
              isSubscribe
            })
          );
        }
      }, timeDifference);
      programSegmentProperty =
        upcomingSchedule !== null
          ? upcomingSchedule?.pgm?.loepn
            ? upcomingSchedule?.pgm?.loepn[0].n
            : upcomingSchedule?.pgm?.lon[0].n
          : '';
      titleSegmentProperty =
        upcomingSchedule !== null
          ? upcomingSchedule?.pgm?.loepn
            ? upcomingSchedule?.pgm?.loepn[0].n
            : upcomingSchedule?.pgm?.lon[0].n
          : '';
      descriptionSegmentProperty =
        upcomingSchedule !== null
          ? upcomingSchedule?.pgm?.lod
            ? upcomingSchedule?.pgm?.lod[0].n
            : ''
          : '';
      livestreamSegmentProperty = upcomingSchedule !== null ? upcomingSchedule?.ev_live : '';
      startDateSegmentProperty = upcomingSchedule?.sc_st_dt;
      gameStartTime = moment(startDateSegmentProperty)
        .tz('America/New_York')
        .format('h:mma')
        .toUpperCase();

      setTrackerId(id);
      setOnSearch(false);
    }
    return () => {
      if (trackerId) {
        clearInterval(trackerId);
      }
    };
  }, [
    mvpdToken,
    checkingBlackOutTime,
    entityIdChanges,
    currentChannel,
    liveSource,
    remoteConfig,
    XDeviceIDToken,
    isSelectedChannelLoading
  ]);

  useEffect(() => {
    if (!searchQuery && hasClicked) {
      setOnSearch(true);
    } else if (searchQuery) {
      setPrevQuery(searchQuery);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (geoLocation !== 'undefined') {
      dispatch(getPackages(geoLocation));
    }
  }, [geoLocation, dispatch]);
  const { GetPkgsAndProdsWithPromosResponseMessage } = useSelector(state => state.auth);
  const pkgWithSubscriptionDetails =
    GetPkgsAndProdsWithPromosResponseMessage &&
    GetPkgsAndProdsWithPromosResponseMessage?.pkgWithPromos;
  const monthlySubscriptionDetails =
    pkgWithSubscriptionDetails &&
    pkgWithSubscriptionDetails.filter(plan => plan?.pkgMsg?.period === 'NoOfMonths');
  const isAnyActivePromotions = plan => {
    const currentDate = moment(new Date()).format('MM/DD/YYYY');
    const result =
      plan &&
      plan[0]?.promoMsg?.map(item => {
        if (
          currentDate >= item.startDate.slice(0, 10) &&
          currentDate <= item?.endDate?.slice(0, 10)
        ) {
          return true;
        }
        return false;
      });
    const isActivePromtion = result?.includes(true);
    return isActivePromtion;
  };
  const monthlyDisplayPrice = isAnyActivePromotions(monthlySubscriptionDetails)
    ? monthlySubscriptionDetails && monthlySubscriptionDetails[0]?.promoMsg[0]?.promotionPrice
    : monthlySubscriptionDetails && monthlySubscriptionDetails[0]?.pkgMsg?.retailPrice;

  const handleSignout = () => {
    dispatch(signout());
    accessEnabler.logout();
    log('logout');
  };

  // highlight-starts
  const debouncedSave = useCallback(
    debounce(nextValue => {
      setSearchQuery(nextValue);
      setSeeMore(false);
    }, 2000),
    [] // will be created only once initially
  );

  // eslint-disable-next-line
  const handleChange = event => {
    const { value: nextValue } = event.target;
    setValue(nextValue);
    // Even though handleChange is created on each render and executed
    // it references the same debouncedSave that was created initially
    debouncedSave(nextValue);
  };

  const mvpdLogoClicked = mvpdObj => {
    if (mvpdObj.siteUrl) {
      window.open(mvpdObj.siteUrl, '_blank');
    }
  };

  if (addSubscriptionError) {
    const errorMsg =
      localization?.errors?.userAlreadyHasDTCSubscription?.en ||
      'Your account already has a YES subscription. Please login with your YES app account';
    return (
      <Model
        title="Sorry"
        body={errorMsg}
        show={addSubscriptionErrorMessage}
        isAddTVEError
        setIsShow={setAddSubscriptionErrorMessage}
      />
    );
  }

  if (
    (selectedMVPD !== null ||
      isSubscribe ||
      (userType === 'dtc' && GetActiveSubscriptionsResponseMessage === null)) &&
    (isSelectedChannelLoading ||
      islieanerChannelsLoading ||
      authLoading ||
      XDeviceIDToken === null ||
      loading)
  ) {
    return <PageLoading />;
  }

  const filterimages = ITEM => {
    if (ITEM?.ia && ITEM?.ia[0]) {
      return `${process.env.REACT_APP_QUICKPLAY_IMAGE_BASE_URL}/image/${ITEM.id}/${ITEM.ia[0]}.jpg?width=500`;
    }
    return overFlowImage;
  };
  const getChannels = () => {
    let list = [];
    list = overflowChannels.map((liveCompetition, index) => (
      <div key={`${liveCompetition.cid}-${index}`}>
        <Channel
          disabled={isChannelDisabled}
          title={liveCompetition.lon[0].n}
          startTime={liveCompetition.ev_st_dt}
          image={filterimages(liveCompetition)}
          onClick={() => {
            dispatch(setCurrentScheduleItem(''));
            setCurrentChannel(`overflow_${liveCompetition.cid}`);
            setVideoName(liveCompetition.lon[0].n);
            playLiveChannel(
              liveCompetition.id,
              (new Date(liveCompetition.ev_ed_dt) - new Date(liveCompetition.ev_st_dt)) / 1000,
              'OVERFLOW',
              liveCompetition.ent[0].sp.map(i => i.includes('Free')).includes(true),
              'liveevent',
              'live'
            );
          }}
        />
      </div>
    ));

    if (linearChannels.length) {
      list = [
        ...list,
        linearChannels.map(liveCompetition =>
          entityId !== liveCompetition.cid ? (
            <div key={liveCompetition.cid}>
              <Channel
                disabled={isChannelDisabled}
                title={
                  upcomingSchedule !== null
                    ? upcomingSchedule?.pgm?.loepn
                      ? upcomingSchedule?.pgm?.loepn[0].n
                      : upcomingSchedule?.pgm?.lon[0].n
                    : ''
                }
                startTime={upcomingSchedule !== null ? upcomingSchedule?.sc_st_dt : ''}
                image={filterimages(liveCompetition)}
                onClick={() => {
                  dispatch(setCurrentScheduleItem(''));
                  dispatch(getTVScheduleRequest());
                  setCurrentChannel('linear');
                  setVideoName(
                    upcomingSchedule?.pgm?.loepn
                      ? upcomingSchedule?.pgm?.loepn[0].n
                      : upcomingSchedule?.pgm?.lon[0].n
                  );
                  setOrbisId(upcomingSchedule?.cid);
                  playLiveChannel(liveCompetition.cid, getLiveDuration(), 'LINEAR', false);
                }}
              />
            </div>
          ) : null
        )
      ];
    }
    return list;
  };
  if (showSubscribeScreen) {
    if (remoteConfig?.isPhase1Enabled) {
      return <SubscribeToGotham />;
    }
    return (
      <Subscribe
        setIsSettingsOpen={setIsSettingsOpen}
        isSettingsOpen={isSettingsOpen}
        setShowSubscribeScreen={setShowSubscribeScreen}
        monthlyDisplayPrice={monthlyDisplayPrice}
      />
    );
  }

  if (isSettingsOpen) {
    return (
      <Account
        setIsSettingsOpen={setIsSettingsOpen}
        isSettingsOpen={isSettingsOpen}
        handleSignout={handleSignout}
      />
    );
  }

  return (
    <div className="watch-page">
      <div className="d-flex justify-content-between search-section">
        {!isVod ? (
          <div className="search-container">
            <div className="form-group has-search">
              <span className="fa fa-search form-control-feedback" />
              <input
                type="search"
                className="form-control"
                onChange={handleChange}
                onClick={() => setHasClicked(true)}
                placeholder="Search Videos"
                value={value}
              />
            </div>
            {searchQuery || onSearch ? (
              <p
                className="cancel-search"
                onClick={() => {
                  setHasClicked(false);
                  setOnSearch(false);
                  setSearchQuery('');
                  setValue('');
                  setPrevQuery('');
                }}
              >
                Cancel
              </p>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div />
        )}
        <div className="settings-icon" onClick={() => setIsSettingsOpen(!isSettingsOpen)}>
          <img src={AccountIcon} alt="Settings" />
        </div>
      </div>
      {seeMore ? (
        <VODCategory
          currentSection={detailedSections}
          setCurrentSection={setCurrentSection}
          sectionId={sectionId}
          playLiveChannel={(id, duration, isFreeVod, catalog, contentId) =>
            playLiveChannel(id, duration, 'VOD', isFreeVod, 'episode', 'vod')
          }
          setVideoName={name => setVideoName(name)}
          setVideoTitle={title => setVideoTitle(title)}
          linearId={linearChannels.length ? linearChannels[0].cid : ''}
          setIdVOD={setIsVod}
          mvpdObj={selectedMVPDObj}
          mvpdLogoClicked={mvpdObj => mvpdLogoClicked(mvpdObj)}
          setSeeMore={seeMore => setSeeMore(seeMore)}
          setShowSubscribeScreen={setShowSubscribeScreen}
          containerVodURL={containerVodURL}
        />
      ) : (
        <>
          {!isVod && (searchQuery || onSearch) ? (
            <>
              <VODSearchResults
                mvpdObj={selectedMVPDObj}
                mvpdLogoClicked={mvpdObj => mvpdLogoClicked(mvpdObj)}
                query={searchQuery}
                error={
                  errorType === watchErrorTypeConstants.OUT_OF_US_ERROR ||
                  errorType === watchErrorTypeConstants.COUCH_RIGHTS_EXPIRY_ERROR
                }
                playLiveChannel={(id, duration, isFreeVod, catalog, contentId) =>
                  playLiveChannel(id, duration, 'VOD', isFreeVod, 'episode', 'vod')
                }
                setVideoName={name => setVideoName(name)}
                setInput={setSearchQuery}
                setValue={setValue}
                prevQuery={prevQuery}
                setVideoTitle={setVideoTitle}
                search={search}
                setShowSubscribeScreen={setShowSubscribeScreen}
              />
            </>
          ) : (
            <>
              {isVod && signedUrls ? (
                <FullScreenPlayer
                  entityId={vodId}
                  entityDuration={entityDuration}
                  entityType={entityType}
                  source={vodSource}
                  autoPlay
                  videoName={videoName}
                  videoTitle={videoTitle}
                  mvpd={selectedMVPDObj !== undefined ? selectedMVPDObj.displayName : ''}
                  errorType={errorType}
                  linearId={linearChannels.length ? linearChannels[0].cid : ''}
                  setIdVOD={setIsVod}
                  search={search}
                  playLiveChannel={(id, duration, isFreeVod, catalog, contentId) =>
                    playLiveChannel(id, duration, 'VOD', isFreeVod, 'episode', 'vod')
                  }
                  setVideoName={name => setVideoName(name)}
                  setVideoTitle={title => setVideoTitle(title)}
                  currentSection={currentSection}
                  setCurrentSection={setCurrentSection}
                  isSubscribe={isSubscribe}
                  detailedSections={detailedSections}
                  selectedMVPD={selectedMVPD}
                  setIsVODReplayed={setIsVODReplayed}
                  isVODReplayed={isVODReplayed}
                />
              ) : (
                <>
                  <div className="player-container d-flex flex-column">
                    <div className="watch-header d-flex align-items-center justify-content-between mb-2">
                      <h3 className="tgd1-b-base-33 float-left">On Now</h3>
                      <div className="d-flex align-items-center">
                        {selectedMVPDObj && selectedMVPDObj.logoUrlWhite && (
                          <img
                            onClick={() => mvpdLogoClicked(selectedMVPDObj)}
                            className="mvpd-logo mr-4 mb-1"
                            src={selectedMVPDObj.logoUrlWhite}
                            alt="mvpd_logo"
                          />
                        )}
                      </div>
                    </div>
                    <Row>
                      <Col
                        md={overflowChannels.length ? 8 : 12}
                        className="pb-5 pb-md-0 height-fit-content"
                      >
                        {errorType
                          ? ErrorContent(
                              errorType,
                              setShowSubscribeScreen,
                              errorCode,
                              userType,
                              GetActiveSubscriptionsResponseMessage?.billingZipCode
                            )
                          : mvpdAuthenticated &&
                            ((mvpdToken || isSubscribe || isFreePackage) && liveSource ? (
                              <BitmovinPlayer
                                vod={vodId}
                                videoName={
                                  entityType[entityId] === 'OVERFLOW'
                                    ? videoName
                                    : upcomingSchedule !== null
                                    ? upcomingSchedule?.pgm?.loepn
                                      ? upcomingSchedule?.pgm?.loepn[0].n
                                      : upcomingSchedule?.pgm?.lon[0].n
                                    : ''
                                }
                                entityId={entityId}
                                orbisId={
                                  orbisId !== null ? orbisId : upcomingSchedule?.pgm_id ?? ''
                                }
                                entityType={entityType}
                                entityDuration={entityDuration}
                                source={liveSource}
                                autoPlay
                                mvpd={selectedMVPDObj?.displayName ?? ''}
                                startAnalytics={upcomingSchedule}
                                currentChannel={currentChannel}
                                sceenicId={sceenicId}
                                selectedMVPD={selectedMVPD}
                                isSubscribe={isSubscribe}
                                signedUrls={signedUrls}
                                XDeviceIDToken={XDeviceIDToken}
                                registerDevice={registerDevice}
                                catalogType={catalogType}
                                contentTypeId={contentTypeId}
                                startDate={new Date(upcomingSchedule?.sc_st_dt)}
                                isVod={isVod}
                                convivaSessionStart={convivaSessionStart}
                                isVODReplayed={isVODReplayed}
                                setIsVODReplayed={setIsVODReplayed}
                                convivaUserType={
                                  getUserType(getUser(), numberOfActivePackage).convivaUserType
                                }
                                userType={getUserType(getUser(), numberOfActivePackage).userType}
                              />
                            ) : (
                              ErrorContent(
                                userType === 'tve'
                                  ? watchErrorTypeConstants.BLACKOUT_ERROR
                                  : watchErrorTypeConstants.NO_SUBSCRIPTION_ERROR,
                                setShowSubscribeScreen
                              )
                            ))}
                        {errorType === null &&
                        upcomingSchedule &&
                        linearChannels.length &&
                        linearChannels[0].cid === entityId ? (
                          <div className="watch-description">
                            <div className="d-flex justify-content-between">
                              <span>
                                <h5 className="tglbc-b-db-25">
                                  {upcomingSchedule?.pgm?.loepn
                                    ? upcomingSchedule?.pgm?.loepn[0].n
                                    : upcomingSchedule?.pgm?.lon[0].n}
                                </h5>
                              </span>
                              <div className="tglbc-b-db-25">
                                {upcomingSchedule.qualifiers &&
                                  upcomingSchedule.qualifiers.includes('epgQualifierLive') && (
                                    <Button
                                      variant="outline-primary"
                                      className="float-right py-0 px-3"
                                    >
                                      LIVE
                                    </Button>
                                  )}
                              </div>
                            </div>
                            <p className="tglc-n-grey-19">
                              {upcomingSchedule?.pgm?.lod ? upcomingSchedule?.pgm?.lod[0].n : ''}
                            </p>
                            <p className="tglbc-b-db-20 mb-0 time-moment">
                              <Moment format="hh:mm A">{upcomingSchedule?.sc_st_dt}</Moment>
                              {' - '}
                              <Moment format="hh:mm A">{upcomingSchedule?.sc_ed_dt}</Moment>
                            </p>
                          </div>
                        ) : (
                          overflowChannels.map(liveCompetition =>
                            entityId === liveCompetition.id && errorType == null ? (
                              <div className="watch-description">
                                <h5 className="tglbc-b-db-25">{liveCompetition.lon[0].n}</h5>
                                <p className="tglc-n-grey-19">{liveCompetition.lod[0].n}</p>
                                <p className="tglbc-b-db-20 mb-0">
                                  <Moment format="hh:mm A">{liveCompetition.ev_st_dt}</Moment>
                                  {' - '}
                                  <Moment format="hh:mm A">{liveCompetition.ev_ed_dt}</Moment>
                                </p>
                              </div>
                            ) : null
                          )
                        )}
                      </Col>
                      {!!overflowChannels.length && (
                        <Col
                          md={4}
                          className={`${width > overflowCarousel ? 'overflow-parent' : ''}`}
                        >
                          <h3 className="tgd1-b-base-24">Also Live</h3>
                          <div className={`${width > overflowCarousel ? 'overflow-list' : ''}`}>
                            {width > overflowCarousel ? (
                              <>{getChannels()}</>
                            ) : (
                              <>
                                <div className="overflow--flex">
                                  <Carousel
                                    ssr
                                    responsive={responsive}
                                    infinite={false}
                                    beforeChange={() => {}}
                                    afterChange={() => {}}
                                    containerClass="first-carousel-container"
                                    customRightArrow={
                                      <CustomRightArrow length={overflowChannels.length} />
                                    }
                                    customLeftArrow={<CustomLeftArrow />}
                                  >
                                    {getChannels()}
                                  </Carousel>
                                </div>
                              </>
                            )}
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                  <br />
                  <VODList
                    error={
                      errorType === watchErrorTypeConstants.OUT_OF_US_ERROR ||
                      errorType === watchErrorTypeConstants.COUCH_RIGHTS_EXPIRY_ERROR
                    }
                    playLiveChannel={(id, duration, isFreeVod, catalog, contentId) =>
                      playLiveChannel(id, duration, 'VOD', isFreeVod, 'episode', 'vod')
                    }
                    setSeeMore={view => setSeeMore(view)}
                    setDetailedSections={section => setDetailedSections(section)}
                    setVideoName={name => setVideoName(name)}
                    setVideoTitle={title => setVideoTitle(title)}
                    setCurrentSection={setCurrentSection}
                    setSectionId={id => setSectionId(id)}
                    setShowSubscribeScreen={setShowSubscribeScreen}
                    setContainerVodURL={setContainerVodURL}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Watch;
