import React from 'react';
import { Route, Redirect, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { deivceAllowed, webAllowed } from 'config';
import Maintenance from 'pages/global/Maintenance';
import DeleteMyData from 'pages/app/DeleteMyData/DeleteMyData';
import Register from 'pages/auth/Register';
import Signout from './Signout';
import RegisterSuccess from './RegisterSuccess';
import WebRoutes from './WebRoutes';
import DeviceRoutes from './DeviceRoutes';
import 'components/FontawsomeIcons';
import CreditCardPayment from './CreditCardPayment';
import SubscriptionUnavailable from './SubscriptionUnavailable';
import SubscriptionPayment from './SubscriptionPayment';
import SubscriptionPlans from './SubscriptionPlans';
import CancelSubscription from './CancelSubscription';
import ManageSubscription from './ManageSubscription';

const AppRouter = ({ setHideHeaderFooter }) => {
  const { search } = useLocation();
  const { remoteConfig } = useSelector(state => state.app.watch);
  return (
    <>
      {webAllowed && (
        <WebRoute
          setHideHeaderFooter={setHideHeaderFooter}
          search={search}
          isPhase1Enabled={remoteConfig?.isPhase1Enabled}
          isPhase2Enabled={remoteConfig?.isPhase2Enabled}
        />
      )}
      {deivceAllowed && <DeviceRoute />}
    </>
  );
};

const DeviceRoute = () => (
  <Switch>
    <Route
      exact
      path="/authenticate/:deviceId/:platform?/:activationCode?"
      component={DeviceRoutes}
    />
    {/* <Route exact path="/authenticate/:deviceId?" component={DeviceMVPD} />  */}
    <Route exact path="/register-success" component={RegisterSuccess} />
    <Route exact path="/register" component={Register} />
    {!webAllowed && <Route to="/" component={Maintenance} />}
  </Switch>
);

const WebRoute = ({ setHideHeaderFooter, search, isPhase1Enabled, isPhase2Enabled }) => (
  <Switch>
    <Route exact path="/watch/:vodId?" render={props => <WebRoutes {...props} />} />
    <Route
      exact
      path="/delete"
      render={props => <DeleteMyData setHideHeaderFooter={setHideHeaderFooter} {...props} />}
    />
    {!isPhase1Enabled && !isPhase2Enabled && <Route exact path="/register" component={Register} />}
    <Route exact path="/signout" component={Signout} />
    {!isPhase1Enabled && !isPhase2Enabled && (
      <Route exact path="/CreditCardPayment" component={CreditCardPayment} />
    )}
    <Route exact path="/SubscriptionPayment" component={SubscriptionPayment} />
    <Route exact path="/SubscriptionPlans" component={SubscriptionPlans} />
    <Route exact path="/CancelSubscription" component={CancelSubscription} />
    <Route exact path="/SubscriptionUnavailable" component={SubscriptionUnavailable} />
    <Route exact path="/ManageSubscription" component={ManageSubscription} />
    <Redirect to={{ pathname: 'watch', search }} />
  </Switch>
);

export default AppRouter;
