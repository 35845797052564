import { useState, useCallback } from 'react';
import queryString from 'query-string';

const setQueryStringWithoutPageReload = qsValue => {
  const newurl =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname +
    qsValue;

  window.history.pushState({ path: newurl }, '', newurl);
};

const setQueryStringValue = (key, value, qString = window.location.search) => {
  const values = queryString.parse(qString);

  if (!value) delete values[key];
  const newQsValue = value
    ? queryString.stringify({ ...values, [key]: value })
    : queryString.stringify(values);
  setQueryStringWithoutPageReload(`${newQsValue ? '?' + newQsValue : ''}`);
};

const getQueryStringValue = (key, qString = window.location.search) => {
  const values = queryString.parse(qString);
  return values[key];
};

function useQueryString(key, initialValue) {
  const [value, setValue] = useState(getQueryStringValue(key) || initialValue);
  const onSetValue = useCallback(
    newValue => {
      setValue(newValue);
      setQueryStringValue(key, newValue);
    },
    [key]
  );

  return [value, onSetValue];
}

export default useQueryString;
