import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import '../Model/Model.scss';

const ErrorModal = ({ show, setIsShow }) => {
  const { isMac } = useSelector(state => state.auth);
  const { localization } = useSelector(state => state.app.watch);

  const handleClose = () => {
    setIsShow(false);
  };

  const modalTitle = localization?.errorModal?.modalTitle?.en || 'Sorry';
  const modalBody = localization?.errorModal?.modalBody?.en || 'Something went wrong and we couldn’t complete your request. We apologize for the inconvenience. Please try again.';
  const modalButton = localization?.errorModal?.modalButton?.en || 'OK';

  return (show) ? (
    <Modal className="modal-container" show={show} onHide={handleClose} backdrop="static" keyboard={false} centered>
      <Modal.Header>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{modalBody}</p>
        <button onClick={handleClose} className={`${isMac ? 'IOS' : ''} primary__button`}>
          {modalButton}
        </button>
      </Modal.Body>
    </Modal>
  )
    : null;
};

export default ErrorModal;
