import { all, fork } from 'redux-saga/effects';

import mvpdSelectSaga from '../MVPDSelect/redux/sagas';
import deviceMVPDSaga from '../DeviceMVPD/redux/sagas';
import teamSelectSaga from '../TeamSelect/redux/sagas';
import watchSaga from '../Watch/redux/sagas';

export default function* appSaga() {
  yield all([fork(mvpdSelectSaga)]);
  yield all([fork(deviceMVPDSaga)]);
  yield all([fork(teamSelectSaga)]);
  yield all([fork(watchSaga)]);
}
