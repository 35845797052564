import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { secondRegisterSchema } from 'helpers/validations';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import InputToast from 'components/InputToast';
import PasswordField from 'components/PasswordField';
import { updateProfileRequest } from '../redux/actions';
import PageWrapper from '../../layoutPage';
import NewLayout from '../../../containers/AuthLayout/NewLayout';
import CreditCardPayment from '../../app/CreditCardPayment';

const UpdateProfile = (deviceId, goBack) => {
  const dispatch = useDispatch();
  const {
    loading: isLoading,
    account
  } = useSelector(state => state.auth);
  const match = useRouteMatch('/watch:vodId?');
  const deviceMatch = useRouteMatch('/authenticate/:deviceId?');
  const { search } = useLocation();
  const { url } = useRouteMatch();
  const email = account?.profile?.contactMessage?.[0]?.email;
  const { errorMessage } = useSelector(state => state.auth);
  const [isSubmit, setIsSubmit] = useState(false);
  const newloading = useSelector(state => state.auth.loading);
  const [isAllowNewsletter, setIsAllowNewsletter] = useState(true);
  const [showCreditCard, setShowCreditCard] = useState(false);
  // const favoriteTeams = account?.customData?.favoriteTeams;


  const initialValues = {
    email,
    password: ''
  };

  const handleSubmit = values => {
    setIsSubmit(true);
    const isChangePassword = true;
    const { email, password } = values;
    const changePasswordRequest = {
      ChangePasswordRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        oldPassword: '',
        newPassword: password,
        confirmNewpassword: password
      }
    }
    const account = {
      UpdateProfileRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        customerUsername: email,
        password
      }
    };
    const authFlow = url.startsWith('/authenticate') ? 'device' : 'user';
    dispatch(
      updateProfileRequest(isChangePassword, changePasswordRequest, account, isAllowNewsletter, authFlow, deviceId || deviceMatch?.params?.deviceId, match?.params?.vodId, search, () => setShowCreditCard(true))
    );
  };
  if (showCreditCard) {
    return (
      <CreditCardPayment goBack={() => {
        setShowCreditCard(false);
      }}
      />
    );
  }
  return (
    <PageWrapper
      showHeader
      showBackIcon
      onBackClick={() => window.location.reload(false)}
      showTitle
      titleText="UPDATE PROFILE"
      titleSubText="IT’S QUICK AND EASY"
      showStepper
      activeStep={1}
      lengthStepper={2}
      stepperText="Step 1 of 2: Update Profile"
    >
      <NewLayout>
        <Formik
          initialValues={initialValues}
          validationSchema={secondRegisterSchema}
          onSubmit={handleSubmit}
        >
          {({ touched, errors, isValid }) => (
            <Form className="register">
              <div className="form-group">
                <label
                  className={
                    touched.email && errors.email ? 'is-invalid form-label' : 'form-label'
                  }
                  htmlFor="email"
                >
                  Email Address
                </label>
                <Field
                  type="email"
                  name="email"
                  disabled={!!email}
                  placeholder={email}
                  className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''
                    }`}
                />
                <ErrorMessage component="div" name="email" className="invalid-feedback" />
                {!newloading &&
                  errorMessage.type === 'UpdateProfile Error' &&
                  errorMessage.message && (
                    <InputToast
                      message={errorMessage.message}
                      isSubmit={isSubmit}
                      setIsSubmit={state => setIsSubmit(state)}
                    />
                  )}
              </div>
              <div className="form-group">
                <label
                  className={
                    touched.password && errors.password
                      ? 'is-invalid form-label'
                      : 'form-label'
                  }
                  htmlFor="password"
                >
                  Password
                </label>
                <Field
                  name="password"
                  component={PasswordField}
                  className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''
                    }`}
                />
                <ErrorMessage component="div" name="password" className="invalid-feedback" />
              </div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={isAllowNewsletter}
                  id="get-updates-checkbox"
                  required={false}
                  name="newsletter"
                  onClick={() => setIsAllowNewsletter(!isAllowNewsletter)}
                />
                <span />
                <div className="checkbox-label">
                  Never miss a moment! Share your email to receive our newsletter and
                  updates.
                </div>
              </label>
              <p className="terms-link w-75 text-center m-auto mb-3">
                By signing up, you confirm that you are 13 years or older and you accept our
                <a href="/terms-of-use.html" className="link-blue" target='_blank'>
                  {' '}
                  Terms of Use
                </a>
                {' '}
                and
                {' '}
                <a href="/privacy-policy.html" className="link-blue" target='_blank'>
                  Privacy Policy
                </a>
              </p>
              <div className="text-center">
                <button
                  type="submit"
                  className="primary__button w-100"
                  disabled={!isValid || isLoading}
                >
                  {isLoading ? 'Please wait...' : 'NEXT'}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </NewLayout>
    </PageWrapper>
  );
};

export default UpdateProfile;
