import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import request from 'utils/api';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import { checkValidToken } from 'utils/CheckTokenValidation';
import { segmentEvent } from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import ConvivaProxy from 'services/ConvivaProxy';
import { teamSelectRequest } from '../redux/actions';
import useAnalyticsScreenTracking from '../../../../hooks/useAnalyticsScreenTracking';
import UserSettings from '../../../../services/UserSettings';

const TeamPicker = ({ isOnboarding, goBack }) => {
  const deviceMatch = useRouteMatch('/authenticate/:deviceId?');
  const videoMatch = useRouteMatch('/watch/:vodId?');
  const { remoteConfig } = useSelector(state => state.app.watch);
  const { isMac } = useSelector(state => state.auth);
  // const [favoriteTeams, setFavoriteTeams] = useState(teamData || [] );
  const [userSelectedTeams, setUserSelectedTeams] = useState([]);
  const [apiFavTeams, setApiFavTeams] = useState([]);
  const [removeFavouriteTeams, setRemoveFavouriteTeams] = useState([]);
  const [addFavoriteTeams, setAddFavouriteTeams] = useState([]);
  const [unSelectedTeams, setUnSelectedTeams] = useState([]);
  const [activeTeam, setActiveTeam] = useState([]);
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const search = useLocation();
  const teamsData = useRef(false);
  const isScreenTracked = useRef(false);

  useAnalyticsScreenTracking(
    isOnboarding ? null : 'Account - Update Teams',
    'Team Select',
    '/teamSelect'
  );

  useEffect(() => {
    getFavoriteTeamData();
  }, []);

  const getFavoriteTeamData = async () => {
    const data = {
      GetFavouritesRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID
      }
    };
    try {
      const evergentAccessToken = await checkValidToken();
      const getFavoriteTeam = await request(
        'evergent',
        '/yesnetwork/getFavourites',
        'post',
        data,
        evergentAccessToken
      );
      setApiFavTeams(getFavoriteTeam.GetFavouritesResponseMessage?.favourites?.favoriteTeams);
      setActiveTeam(getFavoriteTeam.GetFavouritesResponseMessage?.favourites?.favoriteTeams);
    } catch (err) {
      console.log('getFavouriteTeam api error', err);
    }
  };

  /**
   * case 1
   * userSelectedTeam = ['team1','team2']
   * alreadySelectedTeam = ['team1','team3']
   *
   * addTeam = ['team2']
   * removeTeam = ['team3']
   *
   * case 2 // error
   * userSelectedTeam = ['team1','team2']
   * alreadySelectedTeam = ['team1','team2']
   *
   * addTeam = []
   * removeTeam = []
   *
   * case 3 // error
   * userSelectedTeam = ['team1','team2']
   * alreadySelectedTeam = ['team1','team3']
   *
   * addTeam = ['team1','team2']
   * removeTeam = []
   *
   * case 4 //error
   * userSelectedTeam = ['team1']
   * alreadySelectedTeam = ['team1','team3']
   *
   * addTeam = []
   * removeTeam = ['team4']
   */

  const selectTeam = selectedTeam => () => {
    if (activeTeam) {
      if (!activeTeam.includes(selectedTeam)) {
        activeTeam.push(selectedTeam);
      } else {
        const removeTeams = activeTeam.splice(activeTeam.indexOf(selectedTeam), 1);
        setUnSelectedTeams(removeTeams);
      }
    }
    if (userSelectedTeams.includes(selectedTeam)) {
      const favTeam = userSelectedTeams.filter(team => team !== selectedTeam);
      setUserSelectedTeams(favTeam);
    } else {
      const favTeam = [...userSelectedTeams, selectedTeam];
      setUserSelectedTeams(favTeam);
    }
  };

  useEffect(() => {
    if (apiFavTeams) {
      setAddFavouriteTeams(activeTeam);
      setRemoveFavouriteTeams(unSelectedTeams);
    } else {
      setAddFavouriteTeams(userSelectedTeams);
    }
  }, [userSelectedTeams, apiFavTeams, activeTeam, unSelectedTeams]);

  if (!teamsData.current) {
    const teamsMap = new Map();
    remoteConfig.yesTeams.forEach(team => {
      teamsMap.set(team.id, team);
    });
    teamsData.current = teamsMap;
  }

  const handleScreenTracking = () => {
    if (!isScreenTracked.current) {
      ConvivaProxy.screenTracking('Registration - Select Teams');
      isScreenTracked.current = true;
    }
  };

  const updateTags = async () => {
    const sdk = await window.UA;
    try {
      const teamTags = addFavoriteTeams.map(
        teamId => teamsData.current.get(Number(teamId)).airshipName
      );
      UserSettings.addFavTeamsTag(teamTags);
      sdk.channel.namedUser.tags.set(teamTags, 'favorite_teams');
    } catch (e) {
      console.log(e);
    }
  };

  const data = {
    AddOrRemoveFavouritesRequestMessage: {
      apiUser: APIUSER,
      apiPassword: APIPASSWORD,
      channelPartnerID: CHANNELPARTNERID,
      favouriteType: 'favoriteTeams',
      favoriteTeamsToAdd: addFavoriteTeams,
      favoriteTeamsToRemove: removeFavouriteTeams
    }
  };
  const handleSubmit = async () => {
    const authFlow = url.includes('/authenticate') ? 'device' : 'user';
    segmentEvent('Favorite Teams Picked', { teams_selected: addFavoriteTeams });
    dispatch(
      teamSelectRequest(
        data,
        authFlow,
        search,
        videoMatch?.params?.vodId,
        deviceMatch?.params?.deviceId
      )
    );
    if (isOnboarding) {
      handleScreenTracking();
    }

    await updateTags();
    if (!isOnboarding) {
      goBack();
    }
  };

  const sort = arr => {
    let temp;
    for (let i = 0; i < arr.length - 1; i++) {
      if (arr[i] > arr[i + 1]) {
        temp = arr[i];
        arr[i] = arr[i + 1];
        arr[i + 1] = temp;
      }
    }
    return arr;
  };

  const canUpdate = () => {
    const sortSavedTeams = apiFavTeams ? [apiFavTeams] : [];
    const sortList = [...userSelectedTeams];

    const sortedTeams = sort(sortList);
    const sortedSavedTeams = sort(sortSavedTeams);
    return (
      userSelectedTeams.length === 0 ||
      JSON.stringify(sortedSavedTeams) === JSON.stringify(sortedTeams)
    );
  };

  return (
    <>
      <div className={`team-picker mb-2 ${!isOnboarding ? 'team-picker-settings' : ''}`}>
        <button
          className={classNames('NewYork-Yankees', {
            YankeesSelected: activeTeam
              ? activeTeam.includes('1')
              : userSelectedTeams.includes('1')
          })}
          onClick={selectTeam('1')}
        />
        <button
          className={classNames('Brooklyn-Nets', {
            NetsSelected: activeTeam ? activeTeam.includes('2') : userSelectedTeams.includes('2')
          })}
          onClick={selectTeam('2')}
        />

        {/* <button
          className={classNames('NewYork-Liberty', {
            LibertySelected: activeTeam
              ? activeTeam.includes('4')
              : userSelectedTeams.includes('4')
          })}
          onClick={selectTeam('4')}
        /> */}

        <div className="update-button">
          <button
            type="submit"
            className={`${isMac ? 'IOS' : ''} primary__button`}
            disabled={canUpdate()}
            onClick={handleSubmit}
          >
            {isOnboarding ? 'NEXT' : 'UPDATE'}
          </button>
        </div>
      </div>
    </>
  );
};

export default TeamPicker;
