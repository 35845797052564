const MergeArraysWithoutDuplicates = ( arr1, arr2 ) => {
  // merging array
  const nextArr = [ ...arr2, ...arr1 ];
  // removing duplicates
  const dictArr = nextArr.reduce( ( acc, cv, index ) => {
    if ( !acc[ cv.id ] )
      return { ...acc, [cv.id]: { value: cv, position: index } };
    return { ...acc };
  }, {} );
  const newArray = Object.values( dictArr );

  // maintaining the order by sorting with indexes.
  return newArray.sort( ( a, b ) => a.position - b.position ).map( ( item ) => item.value );
};

export default MergeArraysWithoutDuplicates;