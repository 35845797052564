/* eslint-disable max-len */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from 'components/DropDown';
import classNames from 'classnames';
import isAnyActivePromotions from 'utils/promoUtils';
import { setSelectedPlan } from '../Watch/redux/actions';
import './CreditCardPayment.scss';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';


const SelectPlan = ({ ...props }) => {
  const baseUrl = process.env.PUBLIC_URL;

  const { isUpdateMode, activeSubscription } = props;
  const dispatch = useDispatch();
  const { selectedPlan, localization, remoteConfig } = useSelector(state => state.app.watch);
  const { GetPkgsAndProdsWithPromosResponseMessage } = useSelector(state => state.auth);
  const pkgWithSubscriptionDetails =
    GetPkgsAndProdsWithPromosResponseMessage &&
    GetPkgsAndProdsWithPromosResponseMessage?.pkgWithPromos;

  const [selectedPlanCSS, setSelectedPlanCSS] = useState('selectedMonthly');
  const [installmentValue, setInstallmentValue] = useState();
  const [noOfInstallments, setNoOfInstallment] = useState(0);
  useAnalyticsScreenTracking('Subscribe - Select Plan');

  const packageIDYearly =
    pkgWithSubscriptionDetails &&
    pkgWithSubscriptionDetails.find(plan => plan?.pkgMsg?.period === 'NoOfYears')?.pkgMsg
      ?.packageID;
  const packageIDMonthly =
    pkgWithSubscriptionDetails &&
    pkgWithSubscriptionDetails.find(plan => plan?.pkgMsg?.period === 'NoOfMonths')?.pkgMsg
      ?.packageID;

  const annualSubscriptionDetails =
    pkgWithSubscriptionDetails &&
    pkgWithSubscriptionDetails.filter(plan => plan?.pkgMsg?.period === 'NoOfYears');
  const monthlySubscriptionDetails =
    pkgWithSubscriptionDetails &&
    pkgWithSubscriptionDetails.filter(plan => plan?.pkgMsg?.period === 'NoOfMonths');

  const monthlyDisplayPrice = isAnyActivePromotions(monthlySubscriptionDetails)
    ? monthlySubscriptionDetails && monthlySubscriptionDetails[0]?.promoMsg[0]?.promotionPrice
    : monthlySubscriptionDetails && monthlySubscriptionDetails[0]?.pkgMsg?.retailPrice;
  const annualDisplayPrice = isAnyActivePromotions(annualSubscriptionDetails)
    ? annualSubscriptionDetails && annualSubscriptionDetails[0]?.promoMsg[0]?.promotionPrice
    : annualSubscriptionDetails && annualSubscriptionDetails[0]?.pkgMsg?.retailPrice;
  const annualOfferEndDate = isAnyActivePromotions(annualSubscriptionDetails)
    ? remoteConfig?.subscriptionScreens?.promoPricing?.yearlyOfferEndDate
    : null;


  const annualMontlyInfo = Math.round(annualDisplayPrice / 12);

  const pkgPriceMonthly =
    pkgWithSubscriptionDetails &&
    pkgWithSubscriptionDetails.find(plan => plan?.pkgMsg?.period === 'NoOfMonths')?.pkgMsg
      ?.retailPrice;

  const UpdateSelectPlan = useCallback((value) => {
    dispatch(setSelectedPlan(value));
  }, [dispatch]);

  if (selectedPlan?.period === undefined && activeSubscription) {
    const timePeriod = activeSubscription.split('.').at(-1);
    const price = timePeriod === 'monthly' ? monthlyDisplayPrice : annualDisplayPrice;
    const packageID = timePeriod === 'monthly' ? packageIDMonthly : packageIDYearly;
    const activePlan = { period: timePeriod, price, packageID };
    dispatch(setSelectedPlan(activePlan));
  }

  const paymentOption = [
    { value: 'full', label: 'Pay full amount' },
    { value: '3', label: 'Pay in 3 installments' },
    { value: '6', label: 'Pay in 6 installments' },
  ];

  const handlePaymentChange = (installmentPlan) => {
    setNoOfInstallment(installmentPlan);
    const paymentPlans = {
      'full': {
        price: annualDisplayPrice,
        noOfInstallment: installmentPlan
      },
      '3': {
        price: Math.round(annualDisplayPrice / 3),
        noOfInstallment: installmentPlan
      },
      '6': {
        price: Math.round(annualDisplayPrice / 6),
        noOfInstallment: installmentPlan
      }
    };

    const plan = paymentPlans[installmentPlan] || {
      price: annualDisplayPrice, // default case if selectedPayment is unexpected
    };
    setInstallmentValue(plan.price);
    UpdateSelectPlan({
      period: 'annual',
      ...plan,
      packageID: packageIDYearly,
    });
  };


  useEffect(() => {
    const plan = selectedPlanCSS === 'selectedMonthly' && pkgPriceMonthly ? {
      period: 'monthly',
      price: monthlyDisplayPrice,
      packageID: packageIDMonthly
    } : {
      period: 'annual',
      price: installmentValue || annualDisplayPrice,
      packageID: packageIDYearly,
      noOfInstallment: noOfInstallments
    };
    UpdateSelectPlan(plan);
  }, [selectedPlanCSS, monthlyDisplayPrice, annualDisplayPrice, packageIDMonthly, packageIDYearly, UpdateSelectPlan, pkgPriceMonthly, installmentValue, noOfInstallments]);


  return (
    <div>
      {!isUpdateMode && <div className="plans-section-title">SELECT A PLAN</div>}
      <div className="planImages">
        {/* Annual Card  */}
        <div
          className={classNames('annual-price-card', { 'annual-price-card--selected': selectedPlan === 'selectedAnnual' })}
          onClick={() => {
            setSelectedPlanCSS('selectedAnnual');
          }}
        >
          <div className={classNames('annual-plan-card', { 'annual-plan-card--selected': selectedPlanCSS === 'selectedAnnual' })}>
            <div className={classNames('plan-card__annual-offer', { 'plan-card__annual-offer--selected': selectedPlanCSS === 'selectedAnnual' })}>BEST VALUE</div>

            <div className={classNames('plan-card__price ', { 'plan-card__price--selected': selectedPlanCSS === 'selectedAnnual' })}>
              {`$${!installmentValue || installmentValue === annualDisplayPrice ? annualDisplayPrice : installmentValue} ${!installmentValue || installmentValue === annualDisplayPrice ? '/ year' : '/ installment'}`}
              <span className={classNames('plan-card__tax-text', { 'plan-card__tax-text--selected': selectedPlanCSS === 'selectedAnnual' })}>plus taxes</span>
            </div>
            {!installmentValue || installmentValue === annualDisplayPrice ? <div className={classNames('plan-card__per-month__text', { 'plan-card__per-month__text--selected': selectedPlanCSS === 'selectedAnnual' })}>{`(Only $${annualMontlyInfo} per month)`}</div> : ''}
            <div className="price-card-dropdown">
              <div className={classNames('price-card-installment__text', { 'price-card-installment__text--selected': selectedPlanCSS === 'selectedAnnual' })}>
                {(!installmentValue || installmentValue === annualDisplayPrice) ? 'Or pay in installments' : ' '}
                {' '}
                with 0% interest
              </div>
              <DropDown options={paymentOption} onChange={handlePaymentChange} />
            </div>
            {isAnyActivePromotions(annualSubscriptionDetails) && (
              <div className={classNames('plan-card__promo-text', { 'plan-card__promo-text--selected': selectedPlanCSS === 'selectedAnnual' })}>
                {`Offer ends ${annualOfferEndDate}. Promo price ends on renewal.`}
              </div>
            )}
          </div>
        </div>
        <br />

        {/* Monthly Card  */}
        <div
          className={classNames('monthly-price-card', { 'monthly-price-card--selected': selectedPlan === 'selectedMonthly' })}
          onClick={() => {
            setSelectedPlanCSS('selectedMonthly');
            UpdateSelectPlan({
              period: 'monthly',
              price: monthlyDisplayPrice,
              packageID: packageIDMonthly
            });
          }}
        >
          <div className={classNames('monthly-plan-card', { 'monthly-plan-card--selected': selectedPlanCSS === 'selectedMonthly' })}>
            <div className={classNames('', { 'plan-card__monthly-offer--selected': selectedPlanCSS === 'selectedMonthly' })} />
            <div className={classNames('monthly-plan-card__price ', { 'monthly-plan-card__price--selected': selectedPlanCSS === 'selectedMonthly' })}>
              <div>{`$${monthlyDisplayPrice} / month`}</div>
              <span className={classNames('plan-card__tax-text', { 'plan-card__tax-text--selected': selectedPlanCSS === 'selectedMonthly' })}>Plus taxes</span>
            </div>
            {isAnyActivePromotions(monthlySubscriptionDetails) && (
              <div className='promo'>
                <div className={classNames('plan-card__promo-text', { 'plan-card__promo-text--selected': selectedPlanCSS === 'selectedMonthly' })}>
                  {`Offer ends ${annualOfferEndDate}`}
                </div>
                <div className={classNames('plan-card__promo-text', { 'plan-card__promo-text--selected': selectedPlanCSS === 'selectedMonthly' })}>
                  Promo price ends on renewal.
                </div>
              </div>
            )}
          </div>
        </div>

      </div>
      <br />
      {!isUpdateMode && (
        <div className="includes">
          <p>
            {localization?.subscriptions['subscription-includes'].en || 'YOUR YES SUBSCRIPTION INCLUDES:'}
            {' '}
          </p>
          <ul>
            <li>
              <img
                className="check-mark"
                src={`${baseUrl}/Green check mark 1.png`}
                alt="check mark"
              />
              {localization?.subscriptions?.includes1.en || 'Live stream of the YES Network 24/7 including Yankees and Nets games across all your devices.'}
            </li>
            <li>
              <img
                className="check-mark"
                src={`${baseUrl}/Green check mark 1.png`}
                alt="check mark"
              />
              {localization?.subscriptions?.includes2.en || 'Exclusive original shows, video on demand, and game replays'}
            </li>
            <li>
              <img
                className="check-mark"
                src={`${baseUrl}/Green check mark 1.png`}
                alt="check mark"
              />
              {localization?.subscriptions?.includes3.en || 'Enhanced viewing experience with access to:'}
              <ul className="secondary-list">
                <li>{localization?.subscriptions?.includes4.en || 'Live game play-by-play stats in real time'}</li>
                <li>{localization?.subscriptions?.includes5.en || 'Pick-N-Play predictor game and prizes'}</li>
                <li>{localization?.subscriptions?.includes6.en || 'YES Rewards program where you can earn points to redeem for prizes'}</li>
              </ul>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default SelectPlan;
