const hasLowerCase = str => /[a-z]/.test(str);
const hasrUpperCase = str => /[A-Z]/.test(str);
const hasrDigit = str => /[0-9]/.test(str);
const hasSpecialCharactor = str =>
  // eslint-disable-next-line no-useless-escape
  /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str);
const checkPassword = str => {
  let mark = 0;

  if (hasLowerCase(str)) {
    mark += 1;
  }
  if (hasrUpperCase(str)) {
    mark += 1;
  }
  if (hasrDigit(str)) {
    mark += 1;
  }
  if (hasSpecialCharactor(str)) {
    mark += 1;
  }

  return mark;
};

export default checkPassword;
