import { put, takeLatest, call } from 'redux-saga/effects';
import request from 'utils/api';
import { toastErrorNotify } from 'pages/toast/redux/actions';
import { history } from 'redux/configureStore';
import { getAccessToken } from 'utils/localStorage';
import { getFavoriteTeamRequest, getNotificationRequest, getPlayerRequest } from 'pages/auth/redux/actions';
import eventAnalytics from 'utils/eventAnalytics';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import { checkValidToken } from 'utils/CheckTokenValidation';
import * as CONSTANTS from './constants';
import {
  teamSelectSuccess,
  teamSelectError,
  notificationSelectError,
  notificationSelectSuccess,
  loginDeviceSuccess,
  loginDeviceError,
  playerSelectError,
  playerSelectSuccess
} from './actions';

function* teamSelect(action) {


  const { authFlow, search, urlVODId } = action;
  try {
    const evergentAccessToken = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/addOrRemoveFavourites',
      'post',
      action.data,
      evergentAccessToken
    );
    yield put(teamSelectSuccess());
    if(data.AddOrRemoveFavouritesResponseMessage.responseCode === '1'){
      const
        favoriteTeams
      = action.data.AddOrRemoveFavouritesRequestMessage.favoriteTeamsToAdd;
      if (favoriteTeams.includes('1')) {
        eventAnalytics('event', 'New York Yankees');
      }
      if (favoriteTeams.includes('2')) {
        eventAnalytics('event', 'Brooklyn Nets');
      }
      if (favoriteTeams.includes('3')) {
        eventAnalytics('event', 'New York City Football Club');
      }
      if (favoriteTeams.includes('4')) {
        eventAnalytics('event', 'New York Liberty');
      }
      if (authFlow === 'user') {
        yield put(getFavoriteTeamRequest(
          {
            GetFavouritesRequestMessage: {
              apiUser: APIUSER,
              apiPassword: APIPASSWORD,
              channelPartnerID: CHANNELPARTNERID,
            }
          },
          true
        ));
      } else {
        yield put(
          getFavoriteTeamRequest(
            {
              GetFavouritesRequestMessage: {
                apiUser: APIUSER,
                apiPassword: APIPASSWORD,
                channelPartnerID: CHANNELPARTNERID,
              }
            },
            true
          )
        );
      }
    } else {
      switch(data.AddOrRemoveFavouritesResponseMessage.failureMessage[0].errorCode){
        case 'eV5558' :
          eventAnalytics('error', 'Cannot add this team as it is already associated to user');
          yield put(
            toastErrorNotify({ type: 'Team Select Error', message: data.AddOrRemoveFavouritesResponseMessage.failureMessage[0].errorMessage })
          );
          yield put(
            teamSelectError({
              type: 'Team Select Error',
              message: 'Cannot add this team as it is already associated to user.'
            })
          );
          history.push({
            pathname: urlVODId ? `/watch/${urlVODId}` : '/watch',
            search
          });
          break;
        default :
          eventAnalytics('error', data.AddOrRemoveFavouritesResponseMessage.failureMessage[0].errorMessage);
          yield put(
            toastErrorNotify({ type: 'Team Select Error', message: data.AddOrRemoveFavouritesResponseMessage.failureMessage[0].errorMessage })
          );
          yield put(
            teamSelectError({ type: 'Team Select Error', message: data.AddOrRemoveFavouritesResponseMessage.failureMessage[0].errorMessage })
          );
          history.push({
            pathname: urlVODId ? `/watch/${urlVODId}` : '/watch',
            search
          });
          break;
      }
    }
  } catch (err) {
    console.log('Team Select Error', err);
    yield put(teamSelectError());
    history.push({
      pathname: urlVODId ? `/watch/${urlVODId}` : '/watch',
      search
    });
  }
}

function* notifictionSelect(action) {
  try {
    const evergentAccessToken = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/addOrRemoveFavourites',
      'post',
      action.data,
      evergentAccessToken
    );
    yield put(notificationSelectSuccess());
    if(data.AddOrRemoveFavouritesResponseMessage.responseCode === '1'){
      yield put(getNotificationRequest( {
        GetFavouritesRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID,
        }
      }));
    }
    else {
      eventAnalytics('error', data.AddOrRemoveFavouritesResponseMessage.failureMessage[0].errorMessage);
      yield put(
        notificationSelectError({ type: 'Notification Error', message: data.GetOAuthAccessTokenv2ResponseMessage.failureMessage[0].errorMessage })
      );
    }
  }catch (err){
    console.log('Notification api error', err);
  }
}

function* PlayerSelect(action) {
  try {
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/addOrRemoveFavourites',
      'post',
      action.data,
      getAccessToken()
    );
    yield put(playerSelectSuccess());
    if(data.AddOrRemoveFavouritesResponseMessage.responseCode === '1'){
      yield put(getPlayerRequest( {
        GetFavouritesRequestMessage: {
          apiUser: APIUSER,
          apiPassword: APIPASSWORD,
          channelPartnerID: CHANNELPARTNERID,
        }
      }, true));
    }
    else {
      eventAnalytics('error', data.AddOrRemoveFavouritesResponseMessage.failureMessage[0].errorMessage);
      yield put(
        playerSelectError({ type: 'Player Error', message: data.GetOAuthAccessTokenv2ResponseMessage.failureMessage[0].errorMessage })
      );
    }
  }catch (err){
    console.log('NPlayer api error', err);
  }
}
function* loginDevice(action) {
  try {
    const evergentAccessToken = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/registerDevice',
      'post',
      action.data,
      evergentAccessToken
    );
    if (data.RegisterDeviceResponseMessage.responseCode === '1') {
      yield put(loginDeviceSuccess(data));
    //   history.push({
    //     pathname: '/register-success',
    //     search: action.data.search
    //   });
    // }else {
    //   history.push({
    //     pathname: `/authenticate/${action.data.deviceId}`,
    //     search: action.data.search
    //   });
    }
    else {
      yield put(loginDeviceError({ type: 'Device Error', message: 'Activation code is incorrect.' }));
    }
    // history.push({
    //   pathname: '/watch',    
    //   search: action.data.search   
    // });
  } catch (err) {
    history.push({
      pathname: `/authenticate/${action.data.deviceId}`,
      search: action.data.search
    });
    yield put(
      loginDeviceError({ type: 'Device Error', message: 'Activation code is incorrect.' })
    );
  }
}

export default function* teamSelectSaga() {
  yield takeLatest(CONSTANTS.TEAM_SELECT_REQUEST, teamSelect);
  yield takeLatest(CONSTANTS.NOTIFICATION_SELECT_REQUEST, notifictionSelect);
  yield takeLatest(CONSTANTS.PLAYER_SELECT_REQUEST, PlayerSelect);
  yield takeLatest(CONSTANTS.LOGIN_DEVICE_REQUEST, loginDevice);
}
