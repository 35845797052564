import produce from 'immer';
import * as CONSTANTS from './constants';

const initialState = {
  mvpdList: [],
  mvpdListLoading: false,
  mvpdSelectLoading: false,
  teamSelectLoading: false,
  notificationSelectLoading: false,
  playerSelectLoading: false,
  isDeviceLoginSuccess: false,
  errorMessage: {
    type: null,
    message: null
  }
};

const teamSelectReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CONSTANTS.TEAM_SELECT_REQUEST:
        draft.teamSelectLoading = true;
        break;
      case CONSTANTS.TEAM_SELECT_SUCCESS:
        draft.teamSelectLoading = false;
        break;
      case CONSTANTS.TEAM_SELECT_ERROR:
        draft.teamSelectLoading = false;
        break;

        case CONSTANTS.NOTIFICATION_SELECT_REQUEST:
          draft.notificationSelectLoading = true;
          break;
        case CONSTANTS.NOTIFICATION_SELECT_SUCCESS:
          draft.notificationSelectLoading = false;
          break;
        case CONSTANTS.NOTIFICATION_SELECT_ERROR:
          draft.notificationSelectLoading = false;
          break;

          case CONSTANTS.PLAYER_SELECT_REQUEST:
            draft.playerSelectLoading = true;
            break;
          case CONSTANTS.PLAYER_SELECT_SUCCESS:
            draft.playerSelectLoading = false;
            break;
          case CONSTANTS.PLAYER_SELECT_ERROR:
            draft.playerSelectLoading = false;
            break;

      case CONSTANTS.LOGIN_DEVICE_REQUEST:
        draft.isDeviceLogingin = true;
        break;
      case CONSTANTS.LOGIN_DEVICE_SUCCESS:
        draft.isDeviceLogingin = false;
        draft.account = action.payload.account;
        draft.isDeviceLoginSuccess = true;
        draft.errorMessage = { type: null,message: null };
        break;
      case CONSTANTS.LOGIN_DEVICE_ERROR:
        draft.isDeviceLogingin = false;
        draft.errorMessage = action.payload;
        break;
      default:
        break;
    }
  });

export default teamSelectReducer;
