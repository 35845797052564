import {
  CONVIVA_GATEWAY_URL,
  CONVIVA_CUSTOMER_KEY,
  CONVIVA_PLAYER_NAME,
  CHANNEL_NAME,
  APP_ENV
} from 'config';
import {
  Analytics,
  Constants
} from '../../node_modules/@convivainc/conviva-js-coresdk/conviva-core-sdk';
import {
  VideoPlaybackResumed,
  VideoPlaybackPaused,
  VideoPlaybackStarted,
  VideoPlaybackBufferStarted,
  VideoPlaybackBufferCompleted,
  VideoPlaybackSeekStarted,
  VideoPlaybackSeekCompleted,
  VideoPlaybackCompleted,
  VideoContentStarted,
  VideoContentCompleted,
  VideoContentPlaying,
  VideoPlaybackError,
  VideoQualityUpdated,
  VideoTimeChanged,
  ContentLoadError,
  StatsClicked,
  WatchPartyClicked,
  PickNPlayLiveClicked,
  VideoStartSession
} from '../components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import packageJson from '../../package.json';

// TODO: set accordingly for production
const options =
  APP_ENV === 'PROD'
    ? {}
    : {
      [Constants.GATEWAY_URL]: CONVIVA_GATEWAY_URL,
      [Constants.LOG_LEVEL]: Constants.LogLevel.DEBUG
    };
Analytics.init(CONVIVA_CUSTOMER_KEY, null, options);

window.apptracker('convivaAppTracker', {
  appId: 'YES WEB',
  convivaCustomerKey: CONVIVA_CUSTOMER_KEY,
  contexts: {
    performanceTiming: true
  },
});

window.apptracker('enableLinkClickTracking');
window.apptracker('enableButtonClickTracking');
window.apptracker('enableErrorTracking');

Analytics.setDeviceMetadata({
  [Constants.DeviceMetadata.CATEGORY]: Constants.DeviceCategory.WEB
});

let videoAnalytics = Analytics.buildVideoAnalytics();
let gameContext = null;
let assetContext = null;
let userContext = null;
let playerInfo = null;


const setPlayerInfo = (framework, version) => {
  playerInfo = {
    [Constants.FRAMEWORK_NAME]: framework,
    [Constants.FRAMEWORK_VERSION]: version
  };
};

const getAssetId = () => assetContext?.id || '';

const getAssetName = () =>
  assetContext?.assetName ? `[${assetContext.id}] ${assetContext.assetName}` : '';
const getStreamURL = () => assetContext?.source || '';
const getViewerId = () => userContext?.spAccountID || '';
const getDuration = () => assetContext?.duration || 0;

const getIsLive = () => {
  switch (assetContext?.type) {
    case 'linear':
      return Constants.StreamType.LIVE;
    case 'OVERFLOW':
      return Constants.StreamType.LIVE;
    case 'VOD':
      return Constants.StreamType.VOD;
    default:
      return 'UNKNOWN';
  }
};

const getContentType = () => {
  switch (assetContext?.type) {
    case 'linear':
      return 'Live-Linear';
    case 'OVERFLOW':
      return 'Live';
    case 'VOD':
      return 'VOD';
    default:
      return '';
  }
};
const getShowTitle = () => assetContext?.assetName ?? '';
const getUserType = () => userContext?.userType ?? '';
const getMVPDName = () => {
  console.log(userContext?.mvpdName, userContext,  "userContext");
  if (getUserType() === 'DTC') {
    return 'Null';
  }
  return userContext?.mvpdName;
};
const getSeriesName = () => (assetContext?.type === 'VOD' ? 'Null' : assetContext?.seriesName);
const getFullGameTitle = () => gameContext?.fullGameTitle || '';
const getHomeTeam = () => gameContext?.homeTeamName || '';
const getAwayTeam = () => gameContext?.awayTeamName || '';
const getGameLocation = () => gameContext?.gameLocation || '';
const getGameStartTime = () => gameContext?.gameStartTime || '';
const getLiveFlag = () => gameContext?.liveFlag;
const getVideoMinute = () => gameContext?.videoMinute || '';
const getVideoHour = () => gameContext?.videoHour || '';
const getVideoDay = () => gameContext?.dayOfWeek || '';
const getLeagueType = () => gameContext?.leagueType || '';

const hasActiveVideoSession = () =>
  videoAnalytics.getSessionId() !== null && videoAnalytics.getSessionId() !== -2;

const screenTracking = screenName => {
  window.apptracker('trackPageView', { 'title': screenName });
  window.apptracker('trackCustomEvent', {
    name: 'screen_opened',
    data: {
      screen_name: screenName
    }
  });
};

const actionTracking = actionName => {
  window.apptracker('trackCustomEvent', {
    name: 'custom_user_action',
    data: {
      action_name: actionName
    }
  });
};

const setGameContext = game => {
  gameContext = game;
};

const setAssetContext = asset => {
  assetContext = asset;
};

const setUserContext = user => {
  userContext = user;
  window.apptracker('setUserId', userContext?.spAccountID);
};

const getContentInfo = () => {
  const contentInfo = {
    [Constants.ASSET_NAME]: getAssetName(),
    [Constants.IS_LIVE]: getIsLive(),
    [Constants.PLAYER_NAME]: CONVIVA_PLAYER_NAME,
    [Constants.VIEWER_ID]: getViewerId(),
    [Constants.STREAM_URL]: getStreamURL(),
    [Constants.DEFAULT_RESOURCE]: 'AKAMAI',
    [Constants.DURATION]: getDuration(),
    [Constants.ENCODED_FRAMERATE]: 0, // BLOCKED: Need support from Bitmovin
    // content management metadata
    'c3.app.version': packageJson.version,
    'c3.cm.contentType': getContentType(),
    'c3.cm.channel': CHANNEL_NAME,
    'c3.cm.brand': 'Null',
    'c3.cm.affiliate': getMVPDName(),
    'c3.cm.categoryType': 'Sports',
    'c3.cm.name': 'QuickPlay',
    'c3.cm.id': getAssetId(),
    'c3.cm.seriesName': getSeriesName(),
    'c3.cm.seasonNumber': 'Null',
    'c3.cm.showTitle': getShowTitle(),
    'c3.cm.episodeNumber': 'Null',
    'c3.cm.genre': 'Sports',
    'c3.cm.genreList': 'Sports',
    'c3.cm.utmTrackingUrl': 'Null',
    // custom user metadata
    userType: getUserType(),
    // game information
    fillGameTitle: getFullGameTitle(),
    homeTeam: getHomeTeam(),
    awayTeam: getAwayTeam(),
    gameLocation: getGameLocation(),
    gameStartTime: getGameStartTime(),
    liveFlag: getLiveFlag(),
    videoMinute: getVideoMinute(),
    videoHour: getVideoHour(),
    videoDay: getVideoDay(),
    leagueType: getLeagueType()
  };
  return contentInfo;
};

const setContentInfo = () => {
  videoAnalytics.setContentInfo(getContentInfo());
};

const startSession = () => {
  endSession();
  videoAnalytics = Analytics.buildVideoAnalytics();
  setContentInfo();
  videoAnalytics.setPlayerInfo(playerInfo);
};

const endSession = () => {
  if (videoAnalytics) {
    videoAnalytics.release();
  }
};
const exit = () => {
  endSession();
  Analytics.release();
};

const reportPlaybackRequested = () => {
  videoAnalytics.reportPlaybackRequested(getContentInfo());
};

const track = (event, properties) => {
  console.debug('track triggered', event, JSON.stringify(assetContext, null, 2));
  switch (event) {
    case VideoStartSession:
      if (!hasActiveVideoSession()) {
        startSession();
      }
      reportPlaybackRequested();
      break;
    case VideoContentCompleted:
      break;
    case VideoContentPlaying:
      videoAnalytics.reportPlaybackMetric(
        Constants.Playback.PLAYER_STATE,
        Constants.PlayerState.PLAYING
      );
      break;
    case VideoContentStarted:
      setContentInfo();
      break;
    case VideoPlaybackBufferCompleted:
      // Conviva expects a PLAYING event to be triggered once buffering is completed.
      videoAnalytics.reportPlaybackMetric(
        Constants.Playback.PLAYER_STATE,
        Constants.PlayerState.PLAYING
      );
      break;
    case VideoPlaybackBufferStarted:
      videoAnalytics.reportPlaybackMetric(
        Constants.Playback.PLAYER_STATE,
        Constants.PlayerState.BUFFERING
      );
      break;
    case VideoPlaybackCompleted:
      videoAnalytics.reportPlaybackMetric(
        Constants.Playback.PLAYER_STATE,
        Constants.PlayerState.STOPPED
      );
      videoAnalytics.reportPlaybackEnded();
      endSession();
      break;
    case VideoPlaybackPaused:
      videoAnalytics.reportPlaybackMetric(
        Constants.Playback.PLAYER_STATE,
        Constants.PlayerState.PAUSED
      );
      break;
    case VideoPlaybackResumed:
      videoAnalytics.reportPlaybackMetric(
        Constants.Playback.PLAYER_STATE,
        Constants.PlayerState.PLAYING
      );
      break;
    case VideoPlaybackSeekCompleted:
      videoAnalytics.reportPlaybackMetric(Constants.Playback.SEEK_ENDED);
      break;
    case VideoPlaybackSeekStarted:
      videoAnalytics.reportPlaybackMetric(
        Constants.Playback.SEEK_STARTED,
        properties.seekPosition
      );
      break;
    case VideoPlaybackStarted:
      videoAnalytics.reportPlaybackMetric(
        Constants.Playback.PLAYER_STATE,
        Constants.PlayerState.PLAYING
      );
      break;
    case VideoTimeChanged:
      // convert to sec
      videoAnalytics.reportPlaybackMetric(
        Constants.Playback.PLAY_HEAD_TIME,
        properties.time * 1000
      );
      break;
    case VideoPlaybackError:
      videoAnalytics.reportPlaybackError(properties.error);
      break;
    case VideoQualityUpdated:
      console.debug(`bitrate ${properties.bitrate} bps framerate ${properties.framerate}`);
      // Convert to kbps
      videoAnalytics.reportPlaybackMetric(
        Constants.Playback.BITRATE,
        Math.round(properties.bitrate / 1000)
      );
      videoAnalytics.reportPlaybackMetric(
        Constants.Playback.RENDERED_FRAMERATE,
        properties.framerate
      );
      break;
    case ContentLoadError:
      videoAnalytics.reportPlaybackFailed(properties.error, getContentInfo());
      break;
    case StatsClicked:
      videoAnalytics.reportPlaybackEvent('stats_clicked');
      break;
    case WatchPartyClicked:
      videoAnalytics.reportPlaybackEvent('watch_party_clicked');
      break;
    case PickNPlayLiveClicked:
      videoAnalytics.reportPlaybackEvent('picknplay_clicked');
      break;
    default:
      console.warn(`Track event ${event} not implemented`);
  }
};

const ConvivaProxy = {
  setPlayerInfo,
  setContentInfo,
  setGameContext,
  setAssetContext,
  startSession,
  endSession,
  track,
  exit,
  setUserContext,
  screenTracking,
  actionTracking
};
export default ConvivaProxy;
