import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { deleteAccountSchema } from 'helpers/validations';
import { toastErrorNotify } from 'pages/toast/redux/actions';
import { Modal, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './DeleteMyData.scss';
import useAnalyticsScreenTracking from 'hooks/useAnalyticsScreenTracking';

const DeleteMyData = ({ setHideHeaderFooter }) => {
  setHideHeaderFooter(true);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showWhatHappens, setShowWhatHappens] = useState(false);
  const [xUserInfo, setXUserInfo] = useState();
  const [email, setEmail] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const deleteBoomAccountEndpoint = process.env.REACT_APP_BOOM_DELETE_ENDPOINT;
  const deleteRequestEndpoint = process.env.REACT_APP_SLS_DELETE_ACCOUNT;
  const deleteRequestApiKey = process.env.REACT_APP_SLS_DELETE_ACCOUNT_KEY;
  const dispatch = useDispatch();
  const { loading: isLoading } = useSelector(state => state.auth);
  const initialValues = {
    reason: 0,
    comment: ''
  };

  useAnalyticsScreenTracking(null, 'Delete', '/delete');

  const supportPath = 'https://www.yesnetwork.com/info/help-center';
  const cancellationResons = [
    "I'm cancelling because...",
    'TV provider subscription expired',
    'No longer interested in content',
    'Moving to a new area',
    'Concerned about my data',
    'Too many ads',
    'Too busy/distracting',
    'Have another account'
  ];
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setXUserInfo(queryParams.get('xUserInfo'));
    setEmail(queryParams.get('email'));
  }, [xUserInfo, email]);

  const handleBoom = async () => {
    try {
      const response = await axios.post(deleteBoomAccountEndpoint, null, {
        headers: {
          'X-User-Info': xUserInfo
        }
      });
      if (response.status !== 200) {
        throw new Error(response.data.message);
      }
      return true;
    } catch (err) {
      dispatch(
        toastErrorNotify({
          type: 'Boom Error',
          message: err.message
        })
      );
    }
    return false;
  };

  const handleDeleteEmail = async values => {
    const xUserInfoSLS = Buffer.from(
      JSON.stringify({
        email,
        apiKey: deleteRequestApiKey
      })
    ).toString('base64');
    const payload = {
      userEmailAddresses: email,
      apiKey: deleteRequestApiKey,
      reason: cancellationResons[values.reason],
      comment: values.comment
    };
    const headers = {
      headers: {
        'X-User-Info': xUserInfoSLS
      }
    };
    try {
      const response = await axios.post(deleteRequestEndpoint, payload, headers);
      const { data } = response;
      if (data.statusCode === 200) {
        setShowThankYou(true);
      }
    } catch (err) {
      dispatch(
        toastErrorNotify({
          type: 'Request Account Data Removal Error',
          message: 'Request Failed'
        })
      );
    }
  };

  const handleSubmit = async values => {
    setDisableButton(true);
    const boomComplete = await handleBoom();
    if (boomComplete) handleDeleteEmail(values);
  };

  return (
    <div className="account--settings__main">
      <Row>
        <Col md={{ span: 6, offset: 3 }}>
          <Card className="account">
            <Card.Header className="account--title text-center">
              <div className="content-center">
                <div>Are you sure?</div> <div />
              </div>
            </Card.Header>
            <div className="delete-account">
              <div className="da-heading">
                This action will permanently delete your account and this action CANNOT BE UNDONE.
              </div>
              <br />
              <div className="da-body">
                <div>
                  <strong>Help us improve the app. </strong>
                  Let us know why you&apos;re deleting your account from the dropdown and/or enter
                  a custom reply.
                </div>
                <br />
                <Formik
                  initialValues={initialValues}
                  validationSchema={deleteAccountSchema}
                  onSubmit={handleSubmit}
                >
                  {() => (
                    <Form className="d-flex flex-column justify-content-between">
                      <div>
                        <div className="form-group">
                          <Field
                            name="reason"
                            as="select"
                            className="form-control reason-dropdown"
                          >
                            {cancellationResons.map((reason, key) => (
                              <option key={key} value={key}>
                                {reason}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            component="div"
                            name="reason"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <Field
                            as="textarea"
                            rows="2"
                            name="comment"
                            placeholder="Enter comment."
                            className="form-control"
                          />
                          <ErrorMessage
                            component="div"
                            name="comment"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="delete-button-container">
                        <button
                          type="submit"
                          style={{
                            backgroundColor: '#AC0000',
                            color: '#FFF',
                            border: 0,
                            margin: '10px',
                            borderRadius: '8px'
                          }}
                          className="btn btn-primary btn-block w-100 m-auto"
                          onSubmit={values => handleSubmit(values)}
                          disabled={isLoading || disableButton}
                        >
                          {isLoading
                            ? 'Please wait...'
                            : disableButton
                            ? 'Request Submitted'
                            : "I'M SURE, DELETE MY ACCOUNT & DATA"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
                <br />
              </div>
            </div>
            <>
              <div style={{ backgroundColor: '#E6E8EB' }}>
                <div
                  style={{
                    textAlign: 'left',
                    marginLeft: '25px',
                    marginTop: '25px',
                    marginRight: '25px',
                    color: '#0A2240'
                  }}
                >
                  Cancelling from outside the U.S.?
                  <a
                    className="btn btn-secondary btn-support-center w-100"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#0A2240', fontSize: '17px' }}
                    // eslint-disable-next-line
                    href={supportPath}
                  >
                    Visit our support center for assistance
                  </a>
                </div>
                <div
                  style={{
                    marginLeft: '25px',
                    marginBottom: '25px',
                    marginTop: '5px',
                    color: '#0A2240'
                  }}
                >
                  What happens when I delete my account and data?
                  <button className="close-btn" onClick={() => setShowWhatHappens(true)}>
                    <FontAwesomeIcon
                      style={{ height: '17px', width: '17px' }}
                      className="mr-2"
                      icon={faQuestionCircle}
                    />
                  </button>
                </div>
              </div>
              <Modal show={showWhatHappens} backdrop="static" keyboard={false} centered>
                <Modal.Header>
                  <div className="close-modal">
                    <button
                      onClick={() => setShowWhatHappens(false)}
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <Modal.Title>What happens when I delete my account data?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>
                    YES Network will process account deletion within 5-7 business days. All
                    personal information and other data associated with your account will be
                    permanently deleted. Please contact deleteaccount@yesnetwork.com if you have
                    any additional questions. If you change your mind, you can always come back and
                    open a new account with us.
                  </p>
                  <br />
                </Modal.Body>
              </Modal>
            </>
            <Modal show={showThankYou} backdrop="static" keyboard={false} centered>
              <Modal.Header>
                <Modal.Title>
                  <span>Sorry to see you go.</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  We&apos;re processing your request. You&apos;ll receive an email in the coming
                  days time confirming your account and data have been deleted, at which point all
                  aceess will cease.
                </p>
                <br />
                <button
                  className="btn btn-primary close-delete-btn"
                  onClick={() => {
                    setShowThankYou(false);
                    setDisableButton(true);
                  }}
                >
                  CLOSE
                </button>
              </Modal.Body>
            </Modal>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default DeleteMyData;
