/* eslint-disable import/order */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import log from 'utils/log';
import PageLoading from 'components/PageLoading';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import ComponentLoading from 'components/ComponentLoading/ComponentLoading';
import {
  adobePassRequestorId,
  softwareStatement,
  setRequestorEndPoint,
  APIUSER,
  APIPASSWORD,
  CHANNELPARTNERID,
  getWebDeviceId,
  deviceType,
  deviceName
} from 'config';
import { toastErrorNotify } from 'pages/toast/redux/actions';
import eventAnalytics from 'utils/eventAnalytics';
import { getSubscriptionsRequest, signinRequest } from '../../../auth/redux/actions';
import {
  setAuthLoading,
  onAuthenticationStatus,
  selectedMvpdUpdated,
  setMetadata
} from '../redux/actions';
import '../MVPDSelect.scss';
import queryString from 'query-string';
import { history } from 'redux/configureStore';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { setUser } from 'utils/localStorage';
import useWindowDependency from 'hooks/useWindowDependency';
import { segmentEvent } from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import useAnalyticsScreenTracking from 'hooks/useAnalyticsScreenTracking';
import ConvivaProxy from 'services/ConvivaProxy';

const MVPDPicker = (isOnBoarding, match) => {
  const dispatch = useDispatch();
  const { authLoading } = useSelector(state => state.app.mvpdSelect);
  const { remoteConfig } = useSelector(state => state.app.watch);
  const { account, loading } = useSelector(state => state.auth);
  const { url } = useRouteMatch();
  const { search } = useLocation();
  const authFlow = url.startsWith('/authenticate') ? 'device' : 'user';
  const [isSelectedMVPD, setIsSelectedMVPD] = useState(false);
  const [accessEnabler, setAccessEnabler] = useState(null);
  const [mvpdList, setMvpdList] = useState([]);
  const areDependenciesReady = useWindowDependency(['Adobe', 'UA']);
  let selectedMVPD = null;
  const premiumProviders = remoteConfig?.tvProviders?.filter(provider => provider.isPremium);
  const restProviders = remoteConfig?.tvProviders?.filter(
    provider => provider.isPremium === false
  );
  const premiumProivderIds = premiumProviders?.map(provider => provider.id);
  const mvpdProviders = mvpdList?.filter(mvpd => !premiumProivderIds?.includes(mvpd.ID));
  if (mvpdProviders && mvpdProviders?.length) {
    ['RCN', 'www_websso_mybrctv_com', 'Cox'].forEach(mvpdId => {
      const index = mvpdProviders?.findIndex(item => item?.ID === mvpdId);
      const element = mvpdProviders[index];
      mvpdProviders.splice(index, 1);
      mvpdProviders.unshift(element);
    });
  }
  useAnalyticsScreenTracking('Login - TV Provider List');
  useEffect(() => {
    setAuthLoading(true);
    if (areDependenciesReady) {
      setAccessEnabler(new window.Adobe.AccessEnabler(softwareStatement));
      eventAnalytics('page', 'Registration - MVPD Select');
      dispatch(getSubscriptionsRequest(account?.profile?.cpCustomerID));
      window.UA.then(sdk => {
        sdk.getChannel().then(channel => {
          console.log('mvd channel', channel);
        });
      });
    }
    // eslint-disable-next-line
  }, [areDependenciesReady]);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const startAuthN = () => {
    /**
     * Triggered by: setProviderDialogURL(), getAuthentication(), getAuthorization()
     * @param {array} mvpds - An array of Objects representing the requested
     * MVPDs: ` var mvpd = {ID: "someprov",displayName: "Some Provider",logoURL: "http://www.someprov.com/images/logo.jpg"} `
     */
    window.displayProviderDialog = mvpds => {
      log('displayProviderDialog:', mvpds);
      setMvpdList(mvpds);
    };

    window.setAuthenticationStatus = () => { };
    accessEnabler.getAuthentication();
    log('getAuthentication');
  };

  const setMetaData = () => {
    /**
     * Trigger: getMetadata()
     */
    window.setMetadataStatus = (key, encrypted, data) => {
      log('setMetadataStatus');
      dispatch(setMetadata({ key, encrypted, data }));
      setUser('tve');

      if (data === null) {
        dispatch(
          toastErrorNotify({
            type: 'Updating UserMetadata Error',
            message: 'There is no zipcode'
          })
        );
      } else {
        if (key === 'userID') {
          dispatch(
            signinRequest(
              {
                GetOAuthAccessTokenv2RequestMessage: {
                  apiUser: APIUSER,
                  apiPassword: APIPASSWORD,
                  channelPartnerID: CHANNELPARTNERID,
                  cpCustomerID: decodeURIComponent(data),
                  sessionExpiryPeriod: '',
                  deviceMessage: {
                    deviceType,
                    deviceName,
                    modelNo: '04ries',
                    serialNo: getWebDeviceId(),
                    userAgent: 'USA'
                  }
                }
              },
              authFlow,
              match?.params?.deviceId,
              match?.params?.vodId,
              search,
              false,
              null
            )
          );
        }
        dispatch(onAuthenticationStatus(1));
      }
    };

    /**
     * Trigger: getSelectedProvider().
     * @param {object} result - provides information about the provider selected by the user.
     * The result parameter is an Object with these properties:
     * - MVPD The currently selected MVPD, or null if no MVPD was selected.
     * - AE_State The result of authentication for the current customer, one of "New User", "User Not Authenticated", or "User Authenticated
     */

    window.selectedProvider = mvpd => {
      log('selectedProvider', mvpd);
      setIsSelectedMVPD(false);
      dispatch(selectedMvpdUpdated(mvpd.MVPD));
      selectedMVPD = mvpd.MVPD;
      if (selectedMVPD === 'Comcast_SSO') {
        accessEnabler.getMetadata('encryptedZip');
      } else {
        accessEnabler.getMetadata('zip');
      }
      accessEnabler.getMetadata('userID');
    };

    accessEnabler.getSelectedProvider();
    log('getSelectedProvider');
  };

  useEffect(() => {
    if (accessEnabler) {
      /**
       * Callback triggered by setRequestor().  Delivers configuration information and MVPD list.
       * @param {string} configXML: xml object holding the configuration for the
       * current REQUESTOR including the MVPD list.
       */
      window.setConfig = () => {
        log('setConfig');
        accessEnabler.checkAuthentication();
        log('checkAuthentication');
      };

      /**
       * Triggered when the Access Enabler has completed initialization and is ready to receive requests.
       * Implement this callback to know when you can start the communication with the Access Enabler API.
       */
      log('entitlement loaded method is defined here');
      window.entitlementLoaded = () => {
        setTimeout(() => {
          log('setRequestor');
          accessEnabler.setRequestor(adobePassRequestorId, [setRequestorEndPoint]);
        }, 2000); // wait for 1 second before executing the callback
      };

      /**
       * Trigger: checkAuthentication(), getAuthentication(), checkAuthorization()
       *
       * Called upon completion of a checkAuthentication() request. Passes the authentication status (1=authenticated or 0=not authenticated)
       * @param {boolean} isAuthenticated - Provides authentication status: 1 (authenticated) or 0 (not authenticated).
       * @param {string} errorCode - Any error that occurred when determining authentication status. An empty string if none.
       */
      window.setAuthenticationStatus = isAuthenticated => {
        setAuthLoading(false);
        log('setAuthenticationStatus', isAuthenticated);
        if (isAuthenticated === 1) {
          // start AuthZ flow
          dispatch(onAuthenticationStatus(isAuthenticated));
          eventAnalytics('event', 'MVPD');
          setMetaData();
        } else {
          // start AuthN flow
          startAuthN();
        }
      };
      /**
       * Triggered by: checkAuthentication(), getAuthentication(), checkAuthorization(), getAuthorization()
       * Called to provide tracking data when specific events occur. You can use
       * this, for example, to keep track of how many users have logged in with the
       * same credentials. Tracking is not currently configurable.
       *
       * @param {string} trackingEventType
       * @param {array} data
       * @see http://tve.helpdocsonline.com/javascript-api-reference-v2$sendTracking
       */
      window.sendTrackingData = trackingEventType => {
        setAuthLoading(false);
        log('sendTrackingData', trackingEventType);
      };
      window.setConfig();
      if (queryString.parse(window.location.search)?.settings === 'watch') {
        dispatch(onAuthenticationStatus(1));
      }
    }
    // eslint-disable-next-line
  }, [accessEnabler]);

  const onMVPDChange = (mvpdId, displayName) => {
    ConvivaProxy.screenTracking('Login - TV Provider');
    const qs = queryString.parse(window.location.search);
    if (qs.settings) {
      delete qs.settings;
      history.push(`${window.location.pathname}?${queryString.stringify(qs)}`);
    }
    setIsSelectedMVPD(true);
    segmentEvent('Service Provider Selected', { service_provider: displayName });
    /**
     * This function is called if the selected provider is configured to display in an IFrame.
     * A provider is configured to render its authentication screen as either a redirect or in an iFrame, and the Programmer needs to account for both.
     * Trigger: setSelectedProvider(providerID)
     *
     * @param {number} width - the pixel width of the iframe
     * @param {number} height - the pixel height of the iframe
     *
     * @see http://tve.helpdocsonline.com/javascript-api-reference-v2$$getAuthZ
     */
    window.createIFrame = () => { };
    window.destroyIFrame = () => { };
    accessEnabler.setSelectedProvider(mvpdId);
  };

  if (authLoading || loading) {
    return <PageLoading />;
  }

  if (isSelectedMVPD) {
    return <PageLoading />;
  }
  return (
    <>
      {premiumProviders && mvpdProviders?.length ? (
        <>
          <Row className="suggested-mvpds">
            {premiumProviders.map(mvpd => (
              <Col
                md={4}
                sm={6}
                key={mvpd.id}
                onClick={() => onMVPDChange(mvpd.id, mvpd.displayName)}
              >
                <img src={mvpd.logoUrl} alt={mvpd.displayName} />
              </Col>
            ))}
            <div className={`${isOnBoarding ? 'border-none' : ''} top-border`} />
            <div className="border-none bottom-border" />
            <div className={`${isOnBoarding ? 'border-none' : ''} left-border `} />
            <div className={`${isOnBoarding ? 'border-none' : ''} right-border`} />
          </Row>
        </>
      ) : (
        <ComponentLoading />
      )}

      <p className={`${isOnBoarding ? 'text-center' : 'text-center'}`} htmlFor="otherMVPDList">
        {isOnBoarding ? (
          <>Can’t find the service provider you are subscribed to? Search below -</>
        ) : (
          <>Don’t see your TV provider above? Select from below.</>
        )}
      </p>
      {mvpdProviders && mvpdProviders?.length ? (
        <Form.Control
          className="service-provider-list"
          as="select"
          onChange={e => {
            const selectedValue = JSON.parse(e.target.value);
            onMVPDChange(selectedValue.id, selectedValue.displayName);
          }}
        >
          <option value="">Search Service Provider</option>
          {mvpdProviders.map(mvpd => {
            const matchedProviders = restProviders?.find(pId => pId.id === mvpd.ID);
            const displayName = matchedProviders ? matchedProviders.displayName : mvpd.displayName;
            const optionValue = { id: mvpd.ID, displayName };
            return (
              <option key={mvpd.ID} value={JSON.stringify(optionValue)}>
                {displayName}
              </option>
            );
          })}
        </Form.Control>
      ) : (
        <ComponentLoading />
      )}
      <iframe title="mvpdframe" id="mvpdframe" name="mvpdframe" />
    </>
  );
};

export default MVPDPicker;
