/* eslint-disable no-throw-literal */
/* eslint-disable prefer-destructuring */
/* eslint-disable max-len */
import axios from 'axios';
import { get } from 'lodash';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import { toastErrorNotify } from 'pages/toast/redux/actions';
import { baseUrl, getWebDeviceId, US, APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import {
  checkValidToken,
  checkQuickPlayOAuthValidToken,
  checkQuickPlayTVEFlatToken
} from 'utils/CheckTokenValidation';
import requestHeaderOption from 'utils/apiHeaderOption';
import requestNoToken from 'utils/apiWithnoToken';
import request from 'utils/api';
import jwt from 'jsonwebtoken';
import { getUser } from 'utils/localStorage';
import { SubscriptionCancelled } from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import * as CONSTANTS from './constants';
import {
  getLinearChannelsSuccess,
  getLinearChannelsError,
  playLiveChannelSuccess,
  getTVScheduleSuccess,
  getTVScheduleError,
  getOverflowChannelsSuccess,
  getOverflowChannelsError,
  playLiveChannelError,
  concurrencyError,
  outOfMarketPlaceError,
  trackStreamStartSuccess,
  trackStreamStartError,
  trackStreamStopError,
  trackStreamStopSuccess,
  getVODListSuccess,
  getVODListResponse,
  getVODListError,
  outOfUSError,
  blackoutError,
  getVODSearchResultsError,
  getVODSearchResultsSuccess,
  getVODSuccess,
  getVODError,
  setRemoteConfig,
  getRemoteConfigError,
  setCurrentScheduleItem,
  isCheckCouchRightsInProgress,
  couchRightsExpired,
  couchRightsExpireyError,
  cancelSubscriptionSuccess,
  cancelSubscriptionError,
  setLocalization,
  getLocalizationError,
  setPlayer,
  getPlayerError,
  setHeartbeatToken,
  abortNotEntitledError,
  abortRoamingError,
  gracefulStopError
} from './actions';
import { setCouchRightsDate, getCouchRightsDate } from '../../../../utils/localStorage';

const SUCCESS = 'Success';

function* getLinearChannels() {
  try {
    const reqparam = {
      reg: 'us',
      dt: 'web',
      client: 'yesn-yesn-web',
      pageNumber: 1,
      pageSize: 10
    };
    const data = yield call(
      requestNoToken,
      'quickplayDataStores',
      '/content/airing/live',
      'get',
      reqparam
    );
    if (data.header.message === SUCCESS) {
      yield put(getLinearChannelsSuccess(data?.data));
    } else {
      yield put(getLinearChannelsError());
      yield put(
        toastErrorNotify({ type: 'Live Channel Error', message: 'You are outside of US' })
      );
    }
  } catch (err) {
    const error = get(err, 'response', err);
    yield put(getLinearChannelsError());
    yield put(
      toastErrorNotify({ type: 'Live Channel Error', message: error?.data?.errorMessage })
    );
  }
}

function* playLiveChannel(action) {
  const {
    catalogType = 'channel',
    contentId,
    contentTypeId = 'live',
    delivery = 'streaming',
    deviceName = 'web',
    disableSsai = false,
    drm = 'widevine',
    mediaFormat = 'dash',
    isBlackoutChecking = false,
    currentChannel,
    registerDevice,
    playbackMode = 'live',
    mvpdToken,
    ppid,
    isSubscribe = false
  } = action.payload;
  const responseSecretKey = registerDevice.data.secret;
  const responseSecretExpiry = registerDevice.data.expiry;
  const currentTime = Date.now() / 1000;
  const expTime = currentTime + responseSecretExpiry;
  const payload = {
    deviceId: getWebDeviceId(),
    aud: 'playback-auth-service',
    iat: currentTime,
    exp: expTime
  };
  const token = jwt.sign(payload, Buffer.from(responseSecretKey, 'base64'));
  const urlParameters = {
    'ads.description_url': window.location.href,
    'ads.url': window.location.href,
    'ads.vid': contentId,
    'ads.ppid': ppid
  };
  const body = {
    catalogType,
    contentId,
    contentTypeId,
    delivery,
    deviceId: getWebDeviceId(),
    deviceName,
    disableSsai,
    drm,
    mediaFormat,
    playbackMode,
    urlParameters
  };
  const quickplayOauthToken = yield checkQuickPlayOAuthValidToken();
  const quickPlayTVEFlatToken = yield checkQuickPlayTVEFlatToken();
  const reqparam = {
    Authorization: quickplayOauthToken,
    'X-Authorization': quickPlayTVEFlatToken,
    'X-Client-Id': 'yesn-yesn-web',
    'X-Device-Id': token,
    'X-Adobe-Authorization': !isSubscribe ? mvpdToken : ''
  };
  const streamparam = {
    Authorization: quickplayOauthToken,
    'X-Authorization': quickPlayTVEFlatToken,
    'X-Client-Id': 'yesn-yesn-web'
  };
  const reqparams = {
    reg: 'us',
    dt: 'web',
    client: 'yesn-yesn-web',
    pageNumber: 1,
    pageSize: 10
  };
  try {
    if (!isBlackoutChecking) {
      const reqHeader = {
        'X-Client-Id': 'yesn-yesn-web'
      };
      const geoData = yield call(
        requestHeaderOption,
        'quickplayGeoLocation',
        '/device/location/lookup',
        'get',
        reqHeader,
        '',
        quickplayOauthToken
      );
      if (geoData.data.country !== US) {
        throw { code: 1040 };
      }
      const { GetActiveSubscriptionsResponseMessage } = yield select(state => state.auth);
      const isSubscribes = !!GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.length;

      // couch rights check start
      // if user is dtc user and if they are already subscribed
      if (getUser() === 'dtc' && isSubscribes) {
        const couchRightsDate = getCouchRightsDate();
        const evergentAccessToken = yield checkValidToken();
        if (geoData.data.zone === 'unavailable') {
          const currentDate = Date.now();
          // if no cached date
          if (
            couchRightsDate === null ||
            couchRightsDate === 'null' ||
            couchRightsDate === undefined ||
            couchRightsDate === 'undefined' ||
            couchRightsDate === ''
          ) {
            try {
              // call addOrUpdateCouchRights directly as there is some issue
              const addOrUpdateCouchRightsRequestBody = {
                AddOrUpdateCouchRightsRequestMessage: {
                  apiUser: APIUSER,
                  apiPassword: APIPASSWORD,
                  channelPartnerID: CHANNELPARTNERID,
                  deviceID: getWebDeviceId()
                }
              };
              const addUpdateResponse = yield call(
                requestHeaderOption,
                'evergent',
                '/yesnetwork/addOrUpdateCouchRights',
                'post',
                undefined,
                addOrUpdateCouchRightsRequestBody,
                evergentAccessToken
              );
              const response = addUpdateResponse?.AddOrUpdateCouchRightsResponseMessage;
              if (response?.responseCode !== '1') {
                console.log('addOrUpdateCouchRights service error', response);
                throw { code: 1041 };
              }
              // let in
              setCouchRightsDate(response.endDate);
              yield put(couchRightsExpired(false));
            } catch (err) {
              console.log(err);
              throw { code: 1041 };
            }
          }
          // cached date is in future
          else if (couchRightsDate >= currentDate) {
            yield put(couchRightsExpired(false));
          }
          // cached date in past
          else {
            const getCouchRightsRequest = {
              GetCouchRightsRequestMessage: {
                apiUser: APIUSER,
                apiPassword: APIPASSWORD,
                channelPartnerID: CHANNELPARTNERID,
                deviceID: getWebDeviceId()
              }
            };
            // call getCouchRights
            let response = yield call(
              requestHeaderOption,
              'evergent',
              '/yesnetwork/getCouchRights',
              'post',
              undefined,
              getCouchRightsRequest,
              evergentAccessToken
            );
            response = response?.GetCouchRightsResponseMessage;
            if (response?.responseCode !== '1') {
              console.log('getCouchRights service error', response);
              throw { code: 1041 };
            }
            // couch rights have expired
            if (response?.endDate < currentDate) {
              throw { code: 1041 };
            }
            // end date in future, need to cache the new date and let user in
            else if (response?.endDate >= currentDate) {
              setCouchRightsDate(response?.endDate);
              yield put(couchRightsExpired(false));
            }
          }
        }
        // user is in market
        else if (
          localStorage.getItem('CouchRightsDate') !== 'null' &&
          geoData.data.zone !== 'unavailable'
        ) {
          // reset couch rights in service
          const resetCouchRightsRequestBody = {
            ResetCouchRightsRequestMessage: {
              apiUser: APIUSER,
              apiPassword: APIPASSWORD,
              channelPartnerID: CHANNELPARTNERID,
              deviceID: getWebDeviceId()
            }
          };
          // call addOrUpdateCouchRights
          let response = yield call(
            requestHeaderOption,
            'evergent',
            '/yesnetwork/resetCouchRights',
            'post',
            undefined,
            resetCouchRightsRequestBody,
            evergentAccessToken
          );
          response = response?.ResetCouchRightsResponseMessage;
          setCouchRightsDate(null);
          if (response?.responseCode !== '1') {
            console.log('resetCouchRights service error', response);
            // not sure what to do when we have error while resetting couch rights.
          }
          yield put(couchRightsExpired(false));
        }
        yield put(isCheckCouchRightsInProgress(false));
        // couch rights end
      }

      const data = yield call(
        requestHeaderOption,
        'quickPlayAuthorization',
        '/media/content/authorize',
        'post',
        reqparam,
        body,
        quickplayOauthToken
      );
      if (data?.header.code === -1) {
        if (data?.header?.errors[0]?.code === '40060105') {
          throw { code: 1405 };
        } else {
          throw { code: 1409, errorCode: data?.header?.errors[0]?.code };
        }
      } else {
        yield put(playLiveChannelSuccess(data));
        yield put(setHeartbeatToken(data?.data?.heartbeatToken));
        const streamBody = {
          deviceId: getWebDeviceId()
        };
        const stream = yield call(
          requestHeaderOption,
          'quickPlayStream',
          '/user/stream/create',
          'put',
          streamparam,
          streamBody,
          quickplayOauthToken
        );
        if (stream?.header.code === -1) {
          if (stream.header?.errors[0]?.code === '40060105') {
            throw { code: 1405 };
          }
        }
      }
    } else {
      let currentEPG = null;
      let endTime = null;
      if (currentChannel.includes('overflow')) {
        const overflowData = yield call(
          requestNoToken,
          'quickplayOverflow',
          '/content/liveevent/live',
          'get',
          reqparams
        );
        currentEPG = overflowData?.data?.find(item => item.id === contentId);
        console.log(currentEPG, currentEPG.ev_ed_dt, 'overflow end date');
        endTime = '2023-01-12T19:05:45Z';
      } else {
        const data = yield call(
          requestNoToken,
          'quickplayDataStores',
          '/content/airing/live',
          'get',
          reqparams
        );
        currentEPG = data?.data[0];
        endTime = currentEPG.sc_ed_dt;
      }
      if (currentEPG) {
        yield put(setCurrentScheduleItem(currentEPG));
      }
      yield put(
        playLiveChannelSuccess({
          timeSchedule: endTime,
          isBlackoutChecking
        })
      );
    }
  } catch (err) {
    const error = get(err, 'response', err);
    switch (error?.code) {
      case 1405:
        yield put(concurrencyError());
        break;
      case 1404:
        yield put(outOfMarketPlaceError());
        break;
      case 1040:
        yield put(outOfUSError());
        break;
      case 1409:
        yield put(blackoutError(error?.errorCode));
        break;
      case 1041:
        yield put(couchRightsExpireyError());
        break;
      default:
        yield put(playLiveChannelError());
        yield put(
          toastErrorNotify({
            type: 'Play Live Channel Error',
            message: error?.data?.errorMessage
          })
        );
        break;
    }
  }
}

function* getOverflowChannels() {
  try {
    const reqparam = {
      reg: 'us',
      dt: 'web',
      client: 'yesn-yesn-web',
      pageNumber: 1,
      pageSize: 10
    };
    const data = yield call(
      requestNoToken,
      'quickplayOverflow',
      '/content/liveevent/live',
      'get',
      reqparam
    );
    if (data.header.message === SUCCESS) {
      yield put(getOverflowChannelsSuccess(data.data));
      yield put(getVODSuccess(data.data));
    } else {
      yield put(getOverflowChannelsSuccess([]));
    }
  } catch (err) {
    const error = get(err, 'response', err);
    yield put(
      toastErrorNotify({ type: 'Live Channel Error', message: error?.data?.errorMessage })
    );
    yield put(getOverflowChannelsError());
    yield put(getVODError());
  }
}

function* getTVSchedule() {
  try {
    const reqparam = {
      reg: 'us',
      dt: 'web',
      client: 'yesn-yesn-web',
      pageNumber: 1,
      pageSize: 10
    };
    const data = yield call(
      requestNoToken,
      'quickplayDataStores',
      '/content/airing/live',
      'get',
      reqparam
    );
    if (data.header.message === SUCCESS) {
      yield put(getTVScheduleSuccess(data.data[0]));
    }
  } catch (err) {
    const error = get(err, 'response', err);
    yield put(
      toastErrorNotify({
        type: 'Get TV Schedule Error',
        message: error?.data?.errorMessage
      })
    );
    yield put(getTVScheduleError());
  }
}

function* trackStreamStart(action) {
  const { entityId, heartbeatToken } = action.payload;
  const quickplayOauthToken = yield checkQuickPlayOAuthValidToken();
  const quickPlayTVEFlatToken = yield checkQuickPlayTVEFlatToken();
  const hearbeatHeaders = {
    Authorization: quickplayOauthToken,
    'X-Authorization': quickPlayTVEFlatToken,
    'X-Client-Id': 'yesn-yesn-web',
    'X-heartbeat': heartbeatToken
  };
  const streamBody = {
    deviceId: getWebDeviceId(),
    itemId: entityId
  };
  try {
    const data = yield call(
      requestHeaderOption,
      'quickPlayHeartbeat',
      '/user/heartbeat/update',
      'post',
      hearbeatHeaders,
      streamBody,
      quickplayOauthToken
    );
    if (data.header.code === -1) {
      if (
        data.header?.errors[0]?.code === '40070120' ||
        data.header?.errors[0]?.code === '40050146' ||
        data.header?.errors[0]?.code === '40050150' ||
        data.header?.errors[0]?.code === '40050160' ||
        data.header?.errors[0]?.code === '40050161' ||
        data.header?.errors[0]?.code === '40050162' ||
        data.header?.errors[0]?.code === '40050163'
      ) {
        throw { code: 1409, errorCode: data.header?.errors[0]?.code };
      } else if (data.header?.errors[0]?.code === '40060105') {
        throw { code: 1405 };
      } else {
        throw { code: 1000 };
      }
    } else {
      yield put(trackStreamStartSuccess());
      yield put(setHeartbeatToken(data?.data?.heartbeatToken));
    }
  } catch (err) {
    const error = get(err, 'response', err);
    switch (error?.code) {
      case 1409:
        yield put(blackoutError(error?.errorCode));
        break;
      case 1405:
        yield put(concurrencyError());
        break;
      case 1406:
        yield put(abortNotEntitledError());
        break;
      case 1407:
        yield put(abortRoamingError());
        break;
      case 1408:
        yield put(gracefulStopError());
        break;
      default:
        yield put(blackoutError());
        yield put(playLiveChannelError());
        yield put(
          toastErrorNotify({
            type: 'Tracking Stream Error',
            message: error?.data?.errorMessage
          })
        );
        yield put(trackStreamStartError());
        break;
    }
  }
}

function* trackStreamStop() {
  const quickplayOauthToken = yield checkQuickPlayOAuthValidToken();
  const quickPlayTVEFlatToken = yield checkQuickPlayTVEFlatToken();
  const streamparams = {
    Authorization: quickplayOauthToken,
    'X-Authorization': quickPlayTVEFlatToken,
    'X-Client-Id': 'yesn-yesn-web'
  };
  try {
    const deviceId = getWebDeviceId();
    yield call(
      requestHeaderOption,
      'quickPlayStream',
      `/user/stream/delete/${deviceId}`,
      'delete',
      streamparams,
      '',
      quickplayOauthToken
    );
    yield put(trackStreamStopSuccess());
  } catch (err) {
    const error = get(err, 'response', err);
    console.log(error);
    yield put(
      toastErrorNotify({
        type: 'Tracking Stream Error',
        message: error?.data?.errorMessage
      })
    );
    yield put(trackStreamStopError());
  }
}

function* getVODList(action) {
  const { sfId, tId } = action.payload;
  try {
    const reqparam = {
      reg: 'us',
      dt: 'web',
      client: 'yesn-yesn-web',
      pageNumber: action.offset === 1 ? 1 : action.offset,
      pageSize: 50
    };
    const sections = yield call(
      requestNoToken,
      'quickPlayStorefrontCatalogue',
      `/catalog/storefront/${sfId}/${tId}/containers`,
      'get',
      reqparam
    );
    const vodData = sections?.data.filter(container => container.con_tg !== 'live');
    yield put(getVODListSuccess(vodData));
    yield put(getVODListResponse(sections));
  } catch (err) {
    const error = get(err, 'response', err);
    console.log(error);
    yield put(toastErrorNotify({ type: 'VOD List Error', message: error?.data?.errorMessage }));
    yield put(getVODListError());
  }
}

function* getVODSearchResults(action) {
  try {
    const { query } = action.payload;
    const reqparam = {
      reg: 'us',
      dt: 'web',
      client: 'yesn-yesn-web',
      term: query,
      cty: 'episode',
      pageNumber: 1,
      pageSize: 10,
      st: 'published'
    };
    const data = yield call(
      requestNoToken,
      'quickplayDataStores',
      '/content/search',
      'get',
      reqparam
    );
    yield put(getVODSearchResultsSuccess(data));
  } catch (err) {
    yield put(getVODSearchResultsError());
  }
}

function* getRemoteConfig() {
  try {
    const data = yield axios.get(baseUrl.remoteConfig);
    yield put(setRemoteConfig(data.data));
  } catch (err) {
    const error = get(err, 'response', err);
    console.log(error);
    yield put(getRemoteConfigError(error));
  }
}
function* getLocalization() {
  try {
    const data = yield axios.get(baseUrl.localization);
    yield put(setLocalization(data.data));
  } catch (err) {
    const error = get(err, 'response', err);
    console.log(error);
    yield put(getLocalizationError(error));
  }
}
function* getPlayers() {
  try {
    const data = yield axios.get(baseUrl.players);
    yield put(setPlayer(data.data));
  } catch (err) {
    const error = get(err, 'response', err);
    console.log(error);
    yield put(getPlayerError(error));
  }
}

function* cancelSubscription(action) {
  const cancelSubscriptionData = {
    RemoveSubscriptionRequestMessage: {
      apiUser: APIUSER,
      apiPassword: APIPASSWORD,
      channelPartnerID: CHANNELPARTNERID,
      serviceID: action.payload,
      serviceType: 'PACKAGE',
      reasonCode: '001'
    }
  };
  try {
    const evergentAccessToken = yield checkValidToken();
    const data = yield call(
      request,
      'evergent',
      '/yesnetwork/removeSubscription',
      'post',
      cancelSubscriptionData,
      evergentAccessToken
    );
    if (data.RemoveSubscriptionResponseMessage.responseCode === '1') {
      SubscriptionCancelled(action.reason);
      yield put(cancelSubscriptionSuccess(data.GetPaymentsResponseMessage.message));
    } else {
      yield put(cancelSubscriptionError());
    }
  } catch (err) {
    yield put(cancelSubscriptionError());
  }
}

export default function* watchSaga() {
  yield takeLatest(CONSTANTS.GET_VOD_LIST_REQUEST, getVODList);
  yield takeLatest(CONSTANTS.GET_TV_SCHEDULE_REQUEST, getTVSchedule);
  yield takeLatest(CONSTANTS.TRACK_STREAM_STOP_REQUEST, trackStreamStop);
  yield takeLatest(CONSTANTS.PLAY_LIVE_CHANNEL_REQUEST, playLiveChannel);
  yield takeLatest(CONSTANTS.TRACK_STREAM_START_REQUEST, trackStreamStart);
  yield takeLatest(CONSTANTS.GET_LINEAR_CHANNELS_REQUEST, getLinearChannels);
  yield takeLatest(CONSTANTS.GET_OVERFLOW_CHANNELS_REQUEST, getOverflowChannels);
  yield takeLatest(CONSTANTS.GET_VOD_SEARCH_RESULTS_REQUEST, getVODSearchResults);
  yield takeLatest(CONSTANTS.GET_REMOTE_CONFIG_REQUEST, getRemoteConfig);
  yield takeLatest(CONSTANTS.GET_LOCALIZATION_REQUEST, getLocalization);
  yield takeLatest(CONSTANTS.GET_PLAYERS_REQUEST, getPlayers);
  yield takeLatest(CONSTANTS.CANCEL_SUBSCRIPTION_REQUEST, cancelSubscription);
}
