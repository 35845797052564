/* eslint-disable no-shadow */
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { setRegCode, setUser } from 'utils/localStorage';
import NewLayout from '../../../containers/AuthLayout/NewLayout';
import Signin from '../Signin';
import PageWrapper from '../../layoutPage';

const Winback = ({ match }) => {
  useEffect(() => {
    setUser('dtc');
    setRegCode('undefined');
  }, []);

  const {
    params: { deviceId }
  } = match;
  const { url } = useRouteMatch();

  return (
    <PageWrapper
      showHeader
      showBackIcon
      backUrl="/"
      showTitle
      titleText="LOGIN TO SUBSCRIBE "
      titleSubText="RENEWAL OFFER"
    >
      <NewLayout url={url}>
        <Signin deviceId={deviceId} winback />
      </NewLayout>
    </PageWrapper>
  );
};

export default Winback;
