// gets the type of browser
export function getBrowserName() {
  if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) !== -1) {
    return 'Opera';
  }
  if (navigator.userAgent.indexOf('Chrome') !== -1) {
    return 'Chrome';
  }
  if (navigator.userAgent.indexOf('Safari') !== -1) {
    return 'Safari';
  }
  if (navigator.userAgent.indexOf('Firefox') !== -1) {
    return 'Firefox';
  }
  if (navigator.userAgent.indexOf('MSIE') !== -1 || !!document.documentMode === true) {
    return 'Internet Explorer';
  }
  return '';
}

export function getBrowserInstructionsLink(name) {
  switch (name) {
    case 'Chrome':
      return 'https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform%3DDesktop';
    case 'Firefox':
      return 'https://support.mozilla.org/en-US/kb/push-notifications-firefox';
    case 'Safari':
      return 'https://support.apple.com/en-gb/guide/safari/sfri40734/mac';
    case 'Opera':
      return 'https://help.opera.com/en/latest/web-preferences/#notifications';
    default:
      return '';
  }
}

export const checkBrowserNotifications = () => {
  const browserName = getBrowserName();
  let message;
  let instructions;
  if (typeof Notification !== 'undefined') {
    switch (Notification?.permission) {
      case 'granted':
        break;
      case 'denied':
      case 'default':
        message = `Please enable notifications in your ${browserName} browser settings`;
        instructions = getBrowserInstructionsLink(browserName);
        break;
      default:
        message =
          'Your browser does not support notifications.  You need to use a browser that supports notifications to enable this feature';
        break;
    }

    return { status: Notification?.permission, message, instructions };
  }
  return { status: 'denied' };
};
