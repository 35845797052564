import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { signinSchema } from 'helpers/validations';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import {
  getWebDeviceId,
  APIUSER,
  APIPASSWORD,
  CHANNELPARTNERID,
  deviceType,
  deviceName,
  modelNo,
  userAgent
} from 'config';
import InputToast from 'components/InputToast';
import PasswordField from 'components/PasswordField';
import eventAnalytics from 'utils/eventAnalytics';
// import { getQuickPlayAnonymousFlatTokenRequest } from 'pages/app/DeviceMVPD/redux/actions';
import ConvivaProxy from 'services/ConvivaProxy';
import { signinRequest } from '../redux/actions';
import { onAuthenticationStatus } from '../../app/MVPDSelect/redux/actions';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';

const initialValues = {
  email: '',
  password: ''
};
const Signin = ({ deviceId, winback }) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const match = useRouteMatch('/watch/:vodId?');
  const { search } = useLocation();
  const isLoading = useSelector(state => state.auth.loading);
  const { errorMessage, isMac } = useSelector(state => state.auth);
  const { remoteConfig } = useSelector(state => state.app.watch);
  const authFlow = url.startsWith('/authenticate') ? 'device' : 'user';
  const [isSubmit, setIsSubmit] = useState(false);
  const promoPricing = remoteConfig?.subscriptionScreens?.promoPricing;
  const isMonthlyPromo = promoPricing?.monthly;
  const isYearlyPromo = promoPricing?.annually;
  const monthlyPrice = isMonthlyPromo ? promoPricing?.monthlyPromo : promoPricing?.monthlyRegular;
  const annuallyPrice = isYearlyPromo ? promoPricing?.annualPromo : promoPricing?.annualRegular;


  useAnalyticsScreenTracking('Login Select', 'Login', '/login');

  if (authFlow === 'device') {
    eventAnalytics('event', '2nd Screen');
  }
  const selectNoProvider = () => {
    dispatch(onAuthenticationStatus(1));
    // dispatch(getQuickPlayAnonymousFlatTokenRequest());
  };
  const handleSubmit = values => {
    setIsSubmit(true);
    eventAnalytics('event', 'Email');
    const { email, password } = values;
    dispatch(
      signinRequest(
        {
          GetOAuthAccessTokenv2RequestMessage: {
            apiUser: APIUSER,
            apiPassword: APIPASSWORD,
            channelPartnerID: CHANNELPARTNERID,
            contactUserName: email,
            contactPassword: password,
            spAccountID: '',
            cpCustomerID: '',
            sessionExpiryPeriod: '',
            deviceMessage: {
              deviceType,
              deviceName,
              modelNo,
              serialNo: getWebDeviceId(),
              userAgent
            }
          }
        },
        authFlow,
        deviceId,
        match?.params?.vodId,
        search,
        winback,
        selectNoProvider
      )
    );
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={signinSchema}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, isValid, values }) => (
          <Form>
            {winback && (
              <div style={{ fontSize: '23px', textAlign: 'center', marginBottom: '25px' }}>
                <div>{`Login to subscribe for $${monthlyPrice}/month or $${annuallyPrice}/year.`}</div>
              </div>
            )}
            <div className="form-group">
              <label
                className={touched.email && errors.email ? 'form-label is-invalid' : 'form-label'}
                htmlFor="email"
              >
                Email
              </label>
              <Field
                type="email"
                name="email"
                className={`${isMac ? 'IOS' : ''} form-control ${touched.email && errors.email ? 'is-invalid' : ''
                  }`}
              />
              <ErrorMessage component="div" name="email" className="invalid-feedback" />
            </div>
            <div className="form-group mb-55">
              <label
                className={
                  touched.password && errors.password ? 'is-invalid form-label' : 'form-label'
                }
                htmlFor="password"
              >
                Password
              </label>
              <Field
                name="password"
                component={PasswordField}
                className={`form-control ${touched.password && errors.password ? 'is-invalid' : ''
                  }`}
              />
              <ErrorMessage component="div" name="password" className="invalid-feedback" />
              {!isLoading && errorMessage.type === 'Signin Error' && errorMessage.message && (
                <InputToast
                  message={errorMessage.message}
                  isSubmit={isSubmit}
                  setIsSubmit={state => setIsSubmit(state)}
                />
              )}
              <Link
                to="/email-confirm"
                className="link-blue pts-b-db-16 float-right"
                style={{ padding: 10 }}
              >
                Forgot Password?
              </Link>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className={`${isMac ? 'IOS' : ''} primary__button`}
                disabled={!isValid || isLoading || !values?.email}
                onClick={() => {
                  ConvivaProxy.actionTracking('Login - Email');
                }}
              >
                {isLoading ? 'Please wait...' : 'LOGIN'}
              </button>
              {!winback && (
                <div className="or">
                  <span>OR</span>
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default Signin;
