import { useEffect } from 'react';
import { signout } from 'pages/auth/redux/actions';
import { useDispatch } from 'react-redux';
import { getQuickPlayTVEFlatTokenRequest } from 'pages/app/DeviceMVPD/redux/actions';

const Signout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(signout());
    const isSelectProvider = false;
    dispatch(getQuickPlayTVEFlatTokenRequest(isSelectProvider));
  }, [dispatch]);
  return null;
};

export default Signout;
