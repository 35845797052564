/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import eventAnalytics from 'utils/eventAnalytics';
import { setRegCode, setUser } from 'utils/localStorage';
import NewLayout from '../../containers/AuthLayout/NewLayout';
import SocialSignin from './SocialSignin';
import Signin from './Signin';
import PageWrapper from '../layoutPage';

const Auth = ({ match }) => {
  useEffect(() => {
    setUser('dtc');
    setRegCode('undefined');
  }, []);
  const {
    params: { deviceId }
  } = match;
  const { url } = useRouteMatch();
  const [isSignin, setIsSignin] = useState(true);

  if (isSignin) {
    eventAnalytics('page', 'Login Select');
  } else {
    eventAnalytics('page', 'Registration - Choose Options');
  }
  return (
    <PageWrapper
      showHeader
      showBackIcon
      backUrl="/"
      showTitle
      titleText="LOGIN IN LESS THAN"
      titleSubText="A NEW YORK MINUTE"
    >
      <NewLayout url={url} isSignin={isSignin} setIsSignin={setIsSignin}>
        <Signin deviceId={deviceId} winback={false}/>
        <SocialSignin deviceId={deviceId}/>
      </NewLayout>
    </PageWrapper>
  );
};
export default Auth;
