import * as braze from '@braze/web-sdk';

const brazeCustomUserAttribute = (attributeKey, attributeValue) => {
  braze.getUser().setCustomUserAttribute(attributeKey, attributeValue);
};

const BrazeProxy = {
  brazeCustomUserAttribute
};
export default BrazeProxy;
