import {
  segmentEvent,
  VideoPlaybackResumed,
  VideoPlaybackStarted,
  VideoContentStarted
} from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';
import moment from 'moment-timezone';
import { videoPlayer, ovp } from 'utils/consts';
import { TimeMode } from 'bitmovin-player';
import { getWebDeviceId } from '../../../config';
import ConvivaProxy from '../../../services/ConvivaProxy';

let segmentVideoPlaybackStartedflag = true;
let segmentVideoContentStartedFlag = true;
const playerReadyEventBinder = (
  entityTypeMap,
  entityId = null,
  orbisId = null,
  entityName = '',
  mvpdName = '',
  currentScheduleItem,
  remoteConfig,
  liveEvent,
  userType,
  player,
  videoSegmentPaused,
  linearChannels,
  startDate,
  entityDurationMap,
  source,
  convivaSessionStart
) => {
  const streamType = entityTypeMap[entityId].toUpperCase();
  const seriesNameConvivaMetaData =
    streamType === 'VOD'
      ? null
      : streamType === 'LINEAR'
        ? currentScheduleItem?.pgm?.lostl
          ? currentScheduleItem?.pgm?.lostl[0].n
          : null
        : null;

  const convivaLiveFlag =
    currentScheduleItem?.ev_live === 'true'
      ? 'true'
      : currentScheduleItem?.ev_replay === 'true'
        ? 'false'
        : '';

  const streamName = entityName.trim();
  const standardMediaMetadata = {};
  standardMediaMetadata['a.media.playerName'] = process.env.REACT_APP_ANALYTICS_PLAYER_NAME;
  standardMediaMetadata.userType = userType;

  // custome meta data for adobe analytics
  let fullGameTitle = '';
  let homeTeamName = '';
  let awayTeamName = '';
  let gameLocation = '';
  let gameStartTime = '';
  let liveFlag = false;
  let videoMinute = '';
  let videoHour = '';
  let dayOfWeek = '';
  let customDataNeeded = false;
  let leagueType = '';
  if (liveEvent) {
    customDataNeeded = true;
    const { eventTime, teams, league } = liveEvent;
    const liveGameTime = eventTime.replace(/[\\+\\-]\d{2}:\d{2}$/, '');
    console.log('eventTime', liveGameTime);
    const homeTeam = teams.find(team => team.teamLocationType === 'home');
    const awayTeam = teams.find(team => team.teamLocationType === 'away');
    homeTeamName = homeTeam.name;
    awayTeamName = awayTeam.name;
    fullGameTitle = `${moment(`${liveGameTime.includes('Z') ? liveGameTime : `${liveGameTime}Z`}`)
      .tz('America/New_York')
      .format('MM/DD/YYYY')} ${awayTeam.name.trim()} at ${homeTeam.name.trim()}`;

    gameLocation = '';
    const primaryId = remoteConfig?.analytics?.gameLocations.find(
      ({ tricode }) => tricode === homeTeam.abbreviation
    );
    const secondaryId = remoteConfig?.analytics?.gameLocations.find(
      ({ tricode }) => tricode === awayTeam.abbreviation
    );
    if (primaryId) {
      gameLocation = 'vs';
    } else if (secondaryId) {
      gameLocation = 'at';
    }
    gameStartTime = moment(`${liveGameTime}Z`)
      .tz('America/New_York')
      .format('h:mma')
      .toUpperCase();

    liveFlag = true;
    videoMinute = moment().format('HH:MM');
    videoHour = moment().format('HH');
    dayOfWeek = moment().format('dddd');
    leagueType = league.toUpperCase();

    console.log(
      fullGameTitle,
      homeTeamName,
      awayTeamName,
      gameLocation,
      gameStartTime,
      liveFlag,
      videoMinute,
      videoHour,
      dayOfWeek,
      leagueType,
      userType,
      'adobe custom data live Game'
    );
  } else if (
    currentScheduleItem?.ev_live &&
    currentScheduleItem?.pgm &&
    currentScheduleItem?.pgm?.tm?.length > 1
  ) {
    customDataNeeded = true;
    const startTime = currentScheduleItem?.sc_st_dt;
    const name = currentScheduleItem?.pgm?.loen[0].n;
    let leagueName = 'MLB';
    if (currentScheduleItem?.pgm?.spt_ty === 'basketball') {
      leagueName = 'NBA';
    }
    const homeTricode = currentScheduleItem?.pgm?.tm.find(team => team.ty === 'home')?.c;

    const awayTricode = currentScheduleItem?.pgm?.tm.find(team => team.ty === 'away')?.c;

    homeTeamName = homeTricode;
    awayTeamName = awayTricode;

    fullGameTitle = moment(startTime).tz('America/New_York').format('MM/DD/YYYY');
    fullGameTitle += ` ${name}`;
    console.log('fullGameTitle', fullGameTitle);
    gameStartTime = moment(startTime).tz('America/New_York').format('h:mma').toUpperCase();
    gameLocation = '';
    const primaryId = remoteConfig?.analytics?.gameLocations.find(
      ({ tricode }) => tricode === homeTricode
    );
    const secondaryId = remoteConfig?.analytics?.gameLocations.find(
      ({ tricode }) => tricode === awayTricode
    );
    if (primaryId) {
      gameLocation = 'vs';
    } else if (secondaryId) {
      gameLocation = 'at';
    }
    leagueType = leagueName.toUpperCase();
    liveFlag = false;
    videoMinute = moment().format('HH:mm');
    videoHour = moment().format('HH');
    dayOfWeek = moment().format('dddd');
    console.log(
      fullGameTitle,
      homeTeamName,
      awayTeamName,
      gameLocation,
      gameStartTime,
      liveFlag,
      videoMinute,
      videoHour,
      dayOfWeek,
      leagueType,
      userType,
      'adobe custom data'
    );
    // Custom metadata
  }
  if (customDataNeeded) {
    standardMediaMetadata.fullGameTitle = fullGameTitle;
    standardMediaMetadata.homeTeam = homeTeamName;
    standardMediaMetadata.awayTeam = awayTeamName;
    standardMediaMetadata.gameLocation = gameLocation;
    standardMediaMetadata.gameStartTime = gameStartTime;
    standardMediaMetadata.liveFlag = liveFlag;
    standardMediaMetadata.videoMinute = videoMinute;
    standardMediaMetadata.videoHour = videoHour;
    standardMediaMetadata.videoDay = dayOfWeek;
    standardMediaMetadata.leagueType = leagueType;
    standardMediaMetadata.userType = userType;
  }
  ConvivaProxy.setGameContext({
    fullGameTitle,
    homeTeamName,
    awayTeamName,
    gameLocation,
    gameStartTime,
    liveFlag: convivaLiveFlag,
    videoMinute,
    videoHour,
    dayOfWeek,
    leagueType
  });
  const propertiesContent = {
    channel: 'YES Network',
    player_name: process.env.REACT_APP_ANALYTICS_PLAYER_NAME,
    stream_type: entityTypeMap[entityId],
    title: streamName,
    session_id: getWebDeviceId(),
    asset_id: orbisId,
    total_length: entityDurationMap[entityId],
    livestream: linearChannels?.ev_live,
    sound: player.getVolume(),
    video_player: videoPlayer,
    ovp,
    publisher: 'YES Network',
    homeTeam: homeTeamName,
    leagueType,
    awayTeam: awayTeamName,
    bitrate: player?.videoQuality?.bitrate,
    framerate: player?.currentVideoFrameRate,
    game_location: gameLocation,
    game_start_time: gameStartTime,
    genre: 'TV-G',
    position: player?.getCurrentTime(TimeMode.RelativeTime),
    airdate: startDate,
    liveFlag: convivaLiveFlag
  };
  const propertiesPlayBack = {
    channel: 'YES Network',
    player_name: process.env.REACT_APP_ANALYTICS_PLAYER_NAME,
    stream_type: entityTypeMap[entityId],
    title: streamName,
    session_id: getWebDeviceId(),
    content_asset_id: orbisId,
    total_length: entityDurationMap[entityId],
    livestream: linearChannels?.ev_live,
    sound: player.getVolume(),
    video_player: videoPlayer,
    liveFlag: convivaLiveFlag,
    ovp,
    publisher: 'YES Network',
    homeTeam: homeTeamName,
    leagueType,
    awayTeam: awayTeamName,
    bitrate: player?.videoQuality?.bitrate,
    framerate: player?.currentVideoFrameRate,
    game_location: gameLocation,
    game_start_time: gameStartTime,
    genre: 'TV-G',
    position: player?.getCurrentTime(TimeMode.RelativeTime),
    airdate: startDate
  };

  if (standardMediaMetadata) {
    Object.keys(standardMediaMetadata).forEach(key => {
      propertiesContent[key] = standardMediaMetadata[key];
      propertiesPlayBack[key] = standardMediaMetadata[key];
    });
  }
  if (segmentVideoPlaybackStartedflag) {
    segmentEvent(VideoPlaybackStarted, propertiesPlayBack);
    ConvivaProxy.track(VideoPlaybackStarted, {
      channel: 'YES Network',
      ovp: 'Harmonics',
      asset_id: orbisId,
      title: streamName,
      total_length: entityDurationMap[entityId],
      fullGameTitle,
      homeTeamName,
      awayTeamName,
      gameLocation,
      gameStartTime,
      liveFlag: convivaLiveFlag,
      videoMinute,
      videoHour,
      dayOfWeek,
      leagueType
    });
    segmentVideoPlaybackStartedflag = false;
  }
  if (segmentVideoContentStartedFlag) {
    segmentEvent(VideoContentStarted, propertiesContent);
    segmentVideoContentStartedFlag = false;
  }
  if (convivaSessionStart === false) {
    ConvivaProxy.setAssetContext({
      id: orbisId,
      assetName: streamName,
      type: entityTypeMap[entityId],
      duration: entityDurationMap[entityId],
      source,
      seriesName: seriesNameConvivaMetaData
    });
  }

  ConvivaProxy.track(VideoContentStarted);
  if (videoSegmentPaused) {
    segmentEvent(VideoPlaybackResumed, propertiesPlayBack);
    ConvivaProxy.track(VideoPlaybackResumed);
  }
};

export default playerReadyEventBinder;
