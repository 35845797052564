import NewLayout from 'containers/AuthLayout/NewLayout';
import PageWrapper from 'pages/layoutPage/PageWrapper';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './CancelSubscription.scss';
import { maxBy } from 'lodash';
import moment from 'moment-timezone';
import { Field, Form, Formik } from 'formik';
import { getActiveSubscriptions } from 'pages/auth/redux/actions';
import { history } from '../../../redux/configureStore';
import { setSelectedPlan, cancelSubscriptionRequest } from '../Watch/redux/actions';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';

const CancelSubscription = () => {
  const initialValues = {
    reason: ''
  };
  const baseUrl = process.env.PUBLIC_URL;
  const { isMac, GetActiveSubscriptionsResponseMessage } = useSelector(state => state.auth);
  const { localization } = useSelector(state => state.app.watch);

  const [selectedValue, setSelectedValue] = useState('');
  const dispatch = useDispatch();
  const serviceID = GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.filter(
    sub => sub.status === 'Active'
  )[0]?.serviceID;
  const paymentMethod = GetActiveSubscriptionsResponseMessage?.AccountServiceMessage[0]?.paymentMethod;
  const backUrl = paymentMethod === 'Splitit' ? '/' : '/SubscriptionPlans';
  const backText = paymentMethod === 'Splitit' ? 'Account & Settings' : 'Change plan';


  const updatePlan = () => {
    dispatch(setSelectedPlan(null));
    dispatch(cancelSubscriptionRequest(serviceID, selectedValue));
    history.push({
      pathname: '/watch/?settings=true',
      search: window.location.search
    });
  };

  useAnalyticsScreenTracking(
    'Manage Subscription - Cancel Subscription',
    'CancelSubscription',
    '/cancelSubscription'
  );

  const handleSelectChange = event => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    dispatch(getActiveSubscriptions());
  }, [dispatch]);

  const maxObject = maxBy(
    GetActiveSubscriptionsResponseMessage?.AccountServiceMessage,
    'validityTill'
  );

  const RenewalDate = moment(maxObject?.validityTill).format('MMMM DD, YYYY');

  return (
    <PageWrapper
      className="background-light"
      showHeader
      backUrl={backUrl}
      showBackIcon
      backText={backText}
      showTitle
      titleText="CANCEL SUBSCRIPTION"
    >
      <NewLayout>
        <div className="cancel-body">
          <div className="sure-to-cancel">
            Are you sure you want to cancel your subscription? Cancellation will be effective
            <span> {RenewalDate} </span>
            You can continue to access your favourite games until then.
          </div>
          <div className="access">
            <p className="access-title">YOU WILL LOSE ACCESS TO:</p>
            <li>
              <img
                className="check-mark"
                src={`${baseUrl}/Green check mark 1.png`}
                alt="check mark"
              />
              {localization?.subscriptions?.includes1.en || 'Live stream of the YES Network 24/7 including Yankees and Nets games across all your devices.'}

            </li>
            <li>
              <img
                className="check-mark"
                src={`${baseUrl}/Green check mark 1.png`}
                alt="check mark"
              />
              {localization?.subscriptions?.includes2.en || 'Exclusive original shows, video on demand, and game replays'}
            </li>
            <li>
              <img
                className="check-mark"
                src={`${baseUrl}/Green check mark 1.png`}
                alt="check mark"
              />
              {localization?.subscriptions?.includes3.en || 'Enhanced viewing experience with access to:'}
              <ul className="secondary-list">
                <li>{localization?.subscriptions?.includes4.en || 'Live game play-by-play stats in real time'}</li>
                <li>{localization?.subscriptions?.includes5.en || 'Pick-N-Play predictor game and prizes'}</li>
                <li>{localization?.subscriptions?.includes6.en || 'YES Rewards program where you can earn points to redeem for prizes'}</li>
              </ul>
            </li>
          </div>
          <Formik
            initialValues={initialValues}
          // validationSchema={registerSchema}
          // onSubmit={handleSubmit}
          >
            {({ touched, errors }) => (
              <Form className="reason">
                <div className="form-group">
                  <label
                    className={
                      touched.reason && errors.reason ? 'is-invalid form-label' : 'form-label'
                    }
                    htmlFor="reason"
                  >
                    Reason for subscription cancellation
                  </label>
                  <Field
                    name="reason"
                    as="select"
                    className="form-control gender-dropdown"
                    onChange={handleSelectChange}
                    value={selectedValue}
                  >
                    <option value={0}>Select</option>
                    <option value="Season is over">Season is over</option>
                    <option value="Can’t afford price"> Can’t afford price</option>
                    <option value="Going on vacation">Going on vacation</option>
                    <option value="Don’t have enough time">Don’t have enough time</option>
                    <option value="on’t use the app enough">Don’t use the app enough</option>
                  </Field>
                </div>
              </Form>
            )}
          </Formik>
          <div className="buttons">
            <p>
              By cancelling your subscription you accept our
              <span className="link-blue">Terms of Cancellation</span>.
            </p>
            <button className={`${isMac ? 'IOS' : ''} primary__button`} onClick={updatePlan}>
              CANCEL SUBSCRIPTION
            </button>
            {paymentMethod && paymentMethod !== 'Splitit' && (
              <button
                className={`${isMac ? 'IOS' : ''} secondary__button`}
                onClick={() =>
                  history.push({
                    pathname: '/SubscriptionPlans',
                    search: window.location.search
                  })}
              >
                CHANGE PLAN
              </button>
            )}
          </div>
        </div>
      </NewLayout>
    </PageWrapper >
  );
};
export default CancelSubscription;
