/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import NewLayout from 'containers/AuthLayout/NewLayout';
import PageWrapper from 'pages/layoutPage/PageWrapper';
import { useSelector, useDispatch } from 'react-redux';
import './ManageSubscription.scss';
import { minBy, maxBy } from 'lodash';
import moment from 'moment-timezone';
import { getPaymentsRequest } from 'pages/auth/redux/actions';
import PageLoading from 'components/PageLoading';
import CancelSubscriptionInstruction from './CancelSubscriptionInstruction';
import { history } from '../../../redux/configureStore';
import subscriptionPaymentMethod from './config';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';

const ManageSubscription = ({ goBack }) => {
  const dispatch = useDispatch();
  const baseUrl = process.env.PUBLIC_URL;

  useAnalyticsScreenTracking('Account - Manage Subscription');

  const { GetActiveSubscriptionsResponseMessage, getPayment, isGetPaymentLoading } = useSelector(
    state => state.auth
  );

  useEffect(() => {
    dispatch(getPaymentsRequest());
  }, [dispatch]);

  const minObject = minBy(
    GetActiveSubscriptionsResponseMessage?.AccountServiceMessage,
    'orderDateTimeInMillis'
  );
  const maxObject = maxBy(
    GetActiveSubscriptionsResponseMessage?.AccountServiceMessage,
    'validityTill'
  );

  // eslint-disable-next-line max-len
  // const isAnyActivePromotions = plan => {
  //   const currentDate = moment(new Date()).format('YYYY-MM-DD');
  //   const result = plan?.promotions?.map(item => {
  //     if (currentDate >= item.startDate.slice(0, 10) && currentDate <= item.endDate.slice(0, 10)) {
  //       return true;
  //     }
  //     return false;
  //   });
  //   const isActivePromtion = result?.includes(true);
  //   return isActivePromtion;
  // };

  // const planPrice = isAnyActivePromotions(maxObject)
  //   ? maxObject?.promotions[0]?.amount
  //   : maxObject?.retailPrice;
  const planPrice = maxObject?.retailPrice;
  const planPeriod = maxObject?.subscriptionType === 'Yearly' ? 'year' : 'month';
  const RenewalDate = moment(maxObject?.validityTill).format('MMMM DD, YYYY');
  const SubscribedDate = moment(minObject?.orderDateTimeInMillis).format('MMMM DD, YYYY');
  const currentObject = subscriptionPaymentMethod?.methods.find(
    item => item.id === maxObject?.paymentMethod
  );
  return (
    <>
      {isGetPaymentLoading ? <PageLoading /> : null}
      <PageWrapper
        className="background-light"
        showHeader
        showBackIcon
        backText="Account & Settings"
        onBackClick={() => {
          if (history.location.pathname === '/ManageSubscription') {
            history.push({
              pathname: '/watch',
              search: window.location.search
            });
          } else {
            goBack();
          }
        }}
      >
        <NewLayout>
          <div>
            <div className="manage-title"> MANAGE SUBSCRIPTION</div>
            <div className="manage-body">
              {maxObject?.paymentMethod !== 'Credit/Debit Card' &&
                maxObject?.paymentMethod !== 'Splitit' && (
                  <div className="manage-device">
                    <img src={currentObject?.logo} alt="logo" />
                    <div>
                      Please go to YES App on the &nbsp;
                      {currentObject?.platform}
                      &nbsp; to manage your payment and plans.
                    </div>
                  </div>
                )}
              <div className="manage-item">
                <img
                  className="icon"
                  src={`${baseUrl}/Credit card icon.png`}
                  alt="Credit card icon"
                />
                <p>
                  {`$ ${planPrice}`} / {planPeriod}
                </p>
              </div>
              <div className="manage-item">
                <img className="icon" src={`${baseUrl}/Calendar icon.png`} alt="Calendar icon" />
                <p>
                  {maxObject?.status === 'Final Bill' ? 'Expires' : 'Renews'} on {RenewalDate}
                </p>
              </div>
              <div className="manage-item">
                <img className="icon" src={`${baseUrl}/Calendar icon.png`} alt="Calendar icon" />
                <p>{`Subscribed on ${SubscribedDate}`}</p>
              </div>
              {maxObject?.paymentMethod === 'Credit/Debit Card' && (
                <div className="manage-item">
                  <div className="manage-item-buttons">
                    <button
                      onClick={() =>
                        history.push({
                          pathname: '/SubscriptionPlans',
                          search: window.location.search
                        })
                      }
                    >
                      CHANGE PLAN
                    </button>
                    <img
                      className="feather-chevron"
                      src={`${baseUrl}/Icon feather-chevron-right.png`}
                      alt="Credit card icon"
                    />
                  </div>
                </div>
              )}
              {maxObject?.paymentMethod === 'Credit/Debit Card' && (
                <div className="manage-item-empty" />
              )}
              {maxObject?.paymentMethod === 'Credit/Debit Card' && (
                <div className="manage-item">
                  <div className="manage-item-buttons">
                    <button
                      style={{ marginTop: '-10px' }}
                      onClick={() =>
                        history.push({
                          pathname: '/SubscriptionPayment',
                          search: window.location.search
                        })
                      }
                    >
                      UPDATE CREDIT CARD
                    </button>
                    <span style={{ top: '28px' }}>
                      Credit Card ending in{' '}
                      {getPayment?.CardInfoMessage?.cardNumber.replaceAll('x', '')}
                    </span>
                    <img
                      className="feather-chevron"
                      src={`${baseUrl}/Icon feather-chevron-right.png`}
                      alt="Credit card icon"
                    />
                  </div>
                </div>
              )}
              {maxObject?.paymentMethod === 'Splitit' && (
                <div className="manage-item">
                  <div className="manage-item-buttons">
                    <button
                      onClick={() =>
                        history.push({
                          pathname: '/CancelSubscription',
                          search: window.location.search
                        })
                      }
                    >
                      Cancel Subscription
                    </button>
                    <img
                      className="feather-chevron"
                      src={`${baseUrl}/Icon feather-chevron-right.png`}
                      alt="Credit card icon"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="help-with-subscription">
              <p>
                Need help with your subscription?
                <a
                  href="https://yesnetworkhelp.zendesk.com/hc/en-us"
                  className="link-blue"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </p>
              <CancelSubscriptionInstruction platform={currentObject?.platform} />
            </div>
          </div>
        </NewLayout>
      </PageWrapper>
    </>
  );
};
export default ManageSubscription;
