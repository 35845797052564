
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { history } from 'redux/configureStore';
import { setRegCode, setUser } from 'utils/localStorage';
import { useSelector } from 'react-redux';
import FacebookIcon from 'assets/images/FacebookLogo.png';
import GoogleIcon from 'assets/images/GoogleLogo.png';
import { ReactComponent as AppleIcon } from 'assets/svgs/AppleIcon.svg';
import './ResetPasswordNotification.scss';

function ResetPasswordNotification() {
  const [show, setShow] = useState(true);
  const { isMac } = useSelector(state => state.auth);
  const handleClose = () => {
    localStorage.setItem('reset_password_notified', true);
    setShow(false);
  };

  const handleResetEmail = () => {
    history.push('/email-confirm');
  };

  const tvProviderSelect = () => {
    setUser('tve');
    setRegCode('undefined');
    history.push({
      pathname: '/watch',
      search: window.location.search
    });
  };

  const onSubscribeSelect = () => {
    setUser('dtc');
    setRegCode('undefined');
    history.push({
      pathname: '/register',
      search: window.location.search
    });
  };

  return (
    <div id="resetPasswordNotification">
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} centered size="lg">
        <Modal.Header style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#e6e8eb' }}>
          <img src='https://yes-app-config-main.s3.us-east-2.amazonaws.com/images/web/yes.png' height={40} width={70} alt="yes_logo" />
        </Modal.Header>
        <Modal.Header style={{ backgroundColor: '#F2F3F5' }}>
          <Modal.Title style={{ fontSize: '15px' }}> You're almost home! We have recently made upgrades to improve your experince on the YES App. Please click the appropiate button below to reset password or to dismiss this notification: </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: '#F2F3F5' }}>
          <div>
            <div className='content-header'>
              I have a TV subscription
            </div>
            <div className='content-body-upper'>
              <div className='content-body-below-subpart-title'>
                Simply login with your TV provider credentials
              </div>
              <div>
                <button style={{ width: '250px' }} className={`${isMac ? 'IOS' : ''} primary__button`} onClick={tvProviderSelect}>
                  LOGIN WITH TV PROVIDER
                </button>
              </div>
            </div>
            <div>
              <div />
            </div>

            <div className='content-header'>
              I do not have a TV subscription, and:
            </div>
            <div className='content-body-below-mainpart'>
              <div className='content-body-below-subpart'>
                <div className='content-body-below-subpart-title'>
                  I want to subscribe to the YES App
                </div>
                <div>
                  <button
                    style={{ width: '250px' }}
                    className={`${isMac ? 'IOS' : ''} primary__button`}
                    onClick={onSubscribeSelect}
                  >
                    SUBSCRIBE NOW!
                  </button>
                </div>
              </div>
              <div className='content-body-below-subpart'>
                <div className='content-body-below-subpart-title'>
                  I use social account to login or have recently reset my password
                  <img src={GoogleIcon} alt="Google_Icon" style={{ padding: 4 }} />
                  <img src={FacebookIcon} alt="Facebook_Icon" style={{ padding: 4 }} />
                  <AppleIcon style={{ padding : 2 }} />
                </div>
                <div className='content-body-below-subpart-dismiss-btn'>
                  <button
                    style={{ width: '250px' }}
                    onClick={handleClose}
                    className={`${isMac ? 'IOS' : ''} primary__button`}
                  >
                    DISMISS
                  </button>
                </div>
              </div>
              <div className='content-body-below-subpart-withoutbottomborder'>
                <div className='content-body-below-subpart-title'>
                  I have not recently reset my password
                </div>
                <div>
                  <button
                    style={{ width: '250px' }}
                    onClick={handleResetEmail}
                    className={`${isMac ? 'IOS' : ''} primary__button`}
                  >
                    RESET PASSWORD
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ResetPasswordNotification;
