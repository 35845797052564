import * as CONSTANTS from './constants';

export const mvpdListRequest = () => ({
  type: CONSTANTS.MVPD_LIST_REQUEST
});
export const mvpdListSuccess = data => ({
  type: CONSTANTS.MVPD_LIST_SUCCESS,
  payload: data
});
export const mvpdListError = () => ({
  type: CONSTANTS.MVPD_LIST_ERROR
});

// user metadata
export const getUserMetadataRequest = data => ({
  type: CONSTANTS.GET_USER_METADATA_REQUEST,
  payload: data
});
export const getUserMetadataSuccess = data => ({
  type: CONSTANTS.GET_USER_METADATA_SUCCESS,
  payload: data
});
export const getUserMetadataError = () => ({
  type: CONSTANTS.GET_USER_METADATA_ERROR
});

// check authentication
export const checkAuthenticationRequest = data => ({
  type: CONSTANTS.CHECK_AUTHENTICATION_REQUEST,
  payload: data
});
export const checkAuthenticationSuccess = data => ({
  type: CONSTANTS.CHECK_AUTHENTICATION_SUCCESS,
  payload: data
});
export const checkAuthenticationError = () => ({
  type: CONSTANTS.CHECK_AUTHENTICATION_ERROR
});
export const regCodeError = () => ({
  type: CONSTANTS.CHECK_REG_CODE_ERROR
});
export const updateSelectMvpdDevice = data => ({
  type: CONSTANTS.SELECTED_MVPD_DEVICE_UPDATED,
  payload: data
});
// userMetaData Zip

export const getUserMetadataZipSuccess = data => ({
  type: CONSTANTS.GET_USERMETADATA_ZIP_SUCCESS,
  payload: data
});

// geoLocation

export const getGeoLocationRequest = () => ({
  type: CONSTANTS.GET_QUICKPLAY_GEO_LOCATION_REQUEST
});

export const getGeoLocationSuccess = data => ({
  type: CONSTANTS.GET_QUICKPLAY_GEO_LOCATION_SUCCESS,
  payload: data
});

export const getGeoLocationError = () => ({
  type: CONSTANTS.GET_QUICKPLAY_GEO_LOCATION_ERROR
});

// quickPlay TVE flattoken
export const getQuickPlayTVEFlatTokenRequest = (data, isSelectProvider) => ({
  type: CONSTANTS.GET_QUICKPLAY_TVE_FLATTOKEN_REQUEST,
  data,
  isSelectProvider
});

export const getQuickPlayTVEFlatTokenSuccess = data => ({
  type: CONSTANTS.GET_QUICKPLAY_TVE_FLATTOKEN_SUCCESS,
  payload: data
});

export const setXDeviceIDToken = data => ({
  type: CONSTANTS.SET_XDEVICEID_TOKEN,
  payload: data
});

export const getQuickPlayTVEFlatTokenError = () => ({
  type: CONSTANTS.GET_QUICKPLAY_TVE_FLATTOKEN_ERROR
});

// register device
export const registerDeviceSuccess = data => ({
  type: CONSTANTS.REGISTER_DEVICE_SUCCESS,
  payload: data
});

export const registerDeviceError = () => ({
  type: CONSTANTS.REGISTER_DEVICE_ERROR
});

export const addSubscriptionSuccess = data => ({
  type: CONSTANTS.ADD_SUBSCRIPTION_SUCCESS,
  payload: data
});

export const addSubscriptionError = data => ({
  type: CONSTANTS.ADD_SUBSCRIPTION_ERROR,
  payload: data
});
