import { useEffect, useState } from 'react';

const useWindowDependency = (initialDependencies) => {
  const [areDependenciesReady, setDependenciesReady] = useState(false);

  useEffect(() => {
    const checkDependencies = () => {
      if (initialDependencies.every(dep => window[dep])) {
        setDependenciesReady(true);
      } else {
        requestAnimationFrame(checkDependencies);
      }
    };

    checkDependencies();
  }, [initialDependencies]);

  return areDependenciesReady;
};

export default useWindowDependency;