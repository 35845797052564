import mCrypto from 'crypto';
import { easeLiveConstants } from 'helpers/constants';

export default function install(easeLive, config) {

  const publicKey = process.env.REACT_APP_SIMPLEBETS_PUBLIC_KEY;

  console.log('pk', publicKey);

  const message = {
    'uid': config.uid,
    'nickname': config.nickname,
    'match': config.match
  };
  const binaryData = Buffer.from(JSON.stringify(message));

  const encryptedToken = mCrypto.publicEncrypt({
    key: publicKey,
    padding: mCrypto.constants.RSA_PKCS1_PADDING
  }, binaryData).toString('base64');

  easeLive.on(easeLiveConstants.BRIDGE_READY, () => {
    easeLive.emit(easeLiveConstants.APP_MESSAGE, {
      event: easeLiveConstants.YES_SIMPLEBET,
      metadata: {
        token: encodeURIComponent(encryptedToken)
      }
    });
    easeLive.emit(easeLiveConstants.APP_MESSAGE, {
      event: easeLiveConstants.YES_SIMPLEBET_DEVICE_INFO,
      metadata: {
        app_version: process.env.REACT_APP_SIMPLEBETS_APP_VERSION,
        platform: 'web',
      }
    });
  });
}
