import React from 'react';
import './Maintenance.scss';

const Maintenance = () => (
  <div className="maintenance">
    <div className="maintenance_title">
      <p className="tglc-24 pb-1">We are currently undergoing a </p>
      <h1 className="tglb-27">scheduled maintenance</h1>
      <p className="tglc-24 pt-1">and will return shortly. Thanks for your patience.</p>
    </div>
  </div>
);

export default Maintenance;
