export const TEAM_SELECT_REQUEST = 'TEAM_SELECT_REQUEST';
export const TEAM_SELECT_SUCCESS = 'TEAM_SELECT_SUCCESS';
export const TEAM_SELECT_ERROR = 'TEAM_SELECT_ERROR';

export const NOTIFICATION_SELECT_REQUEST = 'NOTIFICATION_SELECT_REQUEST';
export const NOTIFICATION_SELECT_SUCCESS = 'NOTIFICATION_SELECT_SUCCESS';
export const NOTIFICATION_SELECT_ERROR = 'NOTIFICATION_SELECT_ERROR';

export const PLAYER_SELECT_REQUEST = 'PLAYER_SELECT_REQUEST';
export const PLAYER_SELECT_SUCCESS = 'PLAYER_SELECT_SUCCESS';
export const PLAYER_SELECT_ERROR = 'PLAYER_SELECT_ERROR';

export const LOGIN_DEVICE_REQUEST = 'LOGIN_DEVICE_REQUEST';
export const LOGIN_DEVICE_SUCCESS = 'LOGIN_DEVICE_SUCCESS';
export const LOGIN_DEVICE_ERROR = 'LOGIN_DEVICE_ERROR';
