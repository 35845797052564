import React, { useEffect, useState, useRef } from 'react';
import PlaylistCard from 'components/PlaylistCard/PlaylistCard';
import { history } from 'redux/configureStore';
import VODimage from 'assets/images/vod_item_image.png';
import { useDispatch, useSelector } from 'react-redux';
import PageLoading from 'components/PageLoading';
import { getVODSearchResultsRequest, getVODSuccess } from '../redux/actions';
import '../VODList/VODList.scss';
import './VODSearchResults.scss';
import useAnalyticsScreenTracking from '../../../../hooks/useAnalyticsScreenTracking';

const VODSearchResults = ({
  mvpdObj,
  mvpdLogoClicked,
  query,
  playLiveChannel,
  setVideoName,
  setInput,
  prevQuery,
  setValue,
  setVideoTitle,
  setShowSubscribeScreen
}) => {
  const dispatch = useDispatch();
  const { vodSearchResults, isVODSearchLoading, remoteConfig } = useSelector(
    state => state.app.watch
  );
  const { selectedMVPD } = useSelector(state => state.app.mvpdSelect);
  const { GetActiveSubscriptionsResponseMessage } = useSelector(state => state.auth);
  const isSubscribe = !!GetActiveSubscriptionsResponseMessage?.AccountServiceMessage?.length;
  const [offset, setOffset] = useState(0);
  const myRef = useRef(null);

  useAnalyticsScreenTracking('Search');

  const playItem = (assetId, name, duration, title, item) => {
    setInput('');
    setValue('');
    const inFreePackage = item.ent[0].sp.map(i => i.includes('Free')).includes(true);
    if (!selectedMVPD && !inFreePackage && !isSubscribe) {
      dispatch(getVODSuccess(item)); // sets url to enter mvpd select route
      history.push({
        pathname: `/watch/${assetId}`,
        search: window.location.search
      }); // sets url to enter mvpd select route
    } else {
      setVideoName(name);
      setVideoTitle('');
      dispatch(getVODSuccess(item));
      playLiveChannel(assetId, duration, inFreePackage);
    }
  };

  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  useEffect(() => {
    const i = setInterval(() => {
      if (myRef.current) {
        const isVisible = isInViewport(myRef.current);
        if (
          isVisible &&
          vodSearchResults.results &&
          vodSearchResults.metadata.totalCount > offset + vodSearchResults.metadata.count
        ) {
          setOffset(offset + vodSearchResults.metadata.count);
        }
      }
    }, 2000);
    return () => {
      if (i) {
        clearInterval(i);
      }
    };
    // eslint-disable-next-line
  }, [vodSearchResults]);

  const pusblishTime = duration => {
    const durInSec = duration / 1000;
    const hrs = Math.floor(durInSec / 3600)
      .toString()
      .padStart(2, '0');
    const mins = Math.floor((durInSec % 3600) / 60)
      .toString()
      .padStart(2, '0');
    const secs = Math.floor(durInSec % 60)
      .toString()
      .padStart(2, '0');

    return `${hrs}:${mins}:${secs}`;
  };

  const filterimages = ITEM => {
    if (ITEM && ITEM?.ia && ITEM?.ia[0]) {
      return `${process.env.REACT_APP_QUICKPLAY_IMAGE_BASE_URL}/image/${ITEM?.id}/${ITEM?.ia[0]}.jpg?width=500`;
    }
    if (ITEM && ITEM?.ia && ITEM?.ia[1]) {
      return `${process.env.REACT_APP_QUICKPLAY_IMAGE_BASE_URL}/image/${ITEM?.id}/${ITEM?.ia[1]}.jpg?width=500`;
    }
    return VODimage;
  };

  useEffect(() => {
    if (query) {
      setOffset(0);
      // setResults([]);
    }
  }, [query]);

  useEffect(() => {
    if (query) {
      const queryData = { query, offset, search: remoteConfig?.search };
      dispatch(getVODSearchResultsRequest(queryData));
    }
    // eslint-disable-next-line
  }, [query, offset]);

  return (
    <div className="player-container">
      {isVODSearchLoading ? (
        <PageLoading />
      ) : (
        <>
          <div className="watch-header d-flex align-items-center justify-content-between mb-2">
            <h3 className="tgd1-b-base-33 float-left search-title">
              {`SHOWING RESULTS FOR "${prevQuery}"`}
            </h3>
            <div className="d-flex align-items-center">
              {mvpdObj && mvpdObj.logoUrlWhite && (
                <img
                  onClick={() => mvpdLogoClicked(mvpdObj)}
                  className="mvpd-logo mr-4 mb-1"
                  src={mvpdObj.logoUrlWhite}
                  alt="mvpd_logo"
                />
              )}
            </div>
          </div>
          <div className="video-container">
            {vodSearchResults && vodSearchResults.data ? (
              <>
                <div className="search-video-container d-flex flex-wrap">
                  {vodSearchResults.data.map(item => {
                    const isLockedVOD = !!(
                      !selectedMVPD &&
                      !isSubscribe &&
                      !item.ent[0].sp.map(i => i.includes('Free')).includes(true)
                    );
                    return (
                      <div className="search-result-card" key={item?.id}>
                        <PlaylistCard
                          title={item?.lon[0].n}
                          onClick={
                            isLockedVOD
                              ? undefined
                              : () =>
                                playItem(item.id, item.name, item?.vrt / 1000, item?.lon[0].n, item)
                          }
                          responsive={1}
                          videolength={pusblishTime(item?.vrt)}
                          publishdate={item?.st_dt}
                          image={filterimages(item)}
                          lockIcon={isLockedVOD}
                          setShowSubscribeScreen={setShowSubscribeScreen}
                        />
                      </div>
                    );
                  })}
                </div>
                <div ref={myRef} />
              </>
            ) : isVODSearchLoading ? (
              <></>
            ) : (
              <>
                <div className="no-result-found">
                  <h3 className="tglc-n-grey-18">No matching results</h3>
                </div>
                <div>&nbsp;</div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default VODSearchResults;
