import React from 'react';

import YesCard from 'components/YesCard';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PasswordResetSuccess.scss';
import useAnalyticsScreenTracking from 'hooks/useAnalyticsScreenTracking';

const PasswordResetSuccess = () => {
  useAnalyticsScreenTracking(null, 'PasswordResetSuccess', '/passwordResetSuccess');

  return (
    <div style={{ marginTop: '200px' }}>
      <YesCard title="Success!">
        <div className="pt-5">
          <p className="pts-n-db-18 mb-3 text-center">
            Your password has been updated! <br />
            You can now log in using your new password.
          </p>
        </div>
        <div className="mt-5 d-flex align-items-center flex-column">
          <FontAwesomeIcon className="mr-2 check-success d-block" icon="check-circle" />
          <Link to="/" className="btn btn-primary btn-block w-50 mt-5">
            Back to Log in
          </Link>
        </div>
      </YesCard>
    </div>
  );
};

export default PasswordResetSuccess;
