import produce from 'immer';
import * as CONSTANTS from './constants';

const initialState = {
  mvpdList: [],
  mvpdListLoading: false,
  selectedMVPD: null,
  mvpdAuthenticated: false,
  authLoading: false,
  mvpdSelectLoading: false,
  isDeviceAuthorized: false,
  userMetadata: null,
  activationError: false,
  isGettingFlatToken: false,
  isGettingTVEFlatToken: false,
  quickplayAnonymousFlatToken: null,
  quickplayTVEFlatToken: null,
  registerDevice: null,
  getRegisterDevice: false,
  XDeviceIDToken: null,
  isGettingGeoLocation: false,
  geoLocation: null,
  userMetadataZip: null,
  addSubscriptionSuccess: false,
  addSubscriptionError: false,
};

const deviceMVPDReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CONSTANTS.MVPD_LIST_REQUEST:
        draft.mvpdListLoading = true;
        break;
      case CONSTANTS.MVPD_LIST_SUCCESS:
        draft.mvpdListLoading = false;
        draft.mvpdList = action.payload;
        break;
      case CONSTANTS.MVPD_LIST_ERROR:
        draft.mvpdListLoading = false;
        draft.mvpdList = [];
        break;

      case CONSTANTS.CHECK_AUTHENTICATION_REQUEST:
        draft.activationError = false;
        draft.authLoading = true;
        break;
      case CONSTANTS.CHECK_AUTHENTICATION_SUCCESS:
        draft.authLoading = false;
        draft.isDeviceAuthorized = true;
        break;
      case CONSTANTS.CHECK_AUTHENTICATION_ERROR:
        draft.authLoading = false;
        draft.isDeviceAuthorized = false;
        break;
      case CONSTANTS.SELECTED_MVPD_DEVICE_UPDATED:
        draft.selectedMVPD = action.payload;
        break;
      case CONSTANTS.CHECK_REG_CODE_ERROR:
        draft.activationError = true;
        break;
      case CONSTANTS.GET_USER_METADATA_REQUEST:
        draft.authLoading = true;
        break;
      case CONSTANTS.GET_USER_METADATA_SUCCESS:
        draft.authLoading = false;
        draft.userMetadata = action.payload;
        break;
      case CONSTANTS.GET_USER_METADATA_ERROR:
        draft.authLoading = false;
        break;

      case CONSTANTS.GET_USERMETADATA_ZIP_SUCCESS:
        draft.userMetadataZip = action.payload;
        break;

      case CONSTANTS.GET_QUICKPLAY_GEO_LOCATION_REQUEST:
        draft.isGettingGeoLocation = true;
        break;
      case CONSTANTS.GET_QUICKPLAY_GEO_LOCATION_SUCCESS:
        draft.isGettingGeoLocation = false;
        draft.geoLocation = action.payload;
        break;
      case CONSTANTS.GET_QUICKPLAY_GEO_LOCATION_ERROR:
        draft.isGettingGeoLocation = false;
        break;

      case CONSTANTS.GET_QUICKPLAY_TVE_FLATTOKEN_REQUEST:
        draft.isGettingTVEFlatToken = true;
        break;
      case CONSTANTS.GET_QUICKPLAY_TVE_FLATTOKEN_SUCCESS:
        draft.isGettingTVEFlatToken = true;
        draft.quickplayTVEFlatToken = action.payload;
        break;
      case CONSTANTS.GET_QUICKPLAY_TVE_FLATTOKEN_ERROR:
        draft.isGettingTVEFlatToken = false;
        break;
      case CONSTANTS.REGISTER_DEVICE_SUCCESS:
        draft.getRegisterDevice = true;
        draft.registerDevice = action.payload;
        break;
      case CONSTANTS.REGISTER_DEVICE_ERROR:
        draft.getRegisterDevice = false;
        break;
      case CONSTANTS.SET_XDEVICEID_TOKEN:
        draft.XDeviceIDToken = action.payload;
        break;
      case CONSTANTS.ADD_SUBSCRIPTION_SUCCESS:
        draft.addSubscriptionSuccess = action.payload;
        break;
      case CONSTANTS.ADD_SUBSCRIPTION_ERROR:
        draft.addSubscriptionError = action.payload;
        break;
      default:
        break;
    }
  });

export default deviceMVPDReducer;
