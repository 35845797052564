import PageWrapper from 'pages/layoutPage/PageWrapper';
import React, { useEffect } from 'react';
import ChangePlan from 'pages/app/CreditCardPayment/ChangePlan';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'react-bootstrap';
import { getActiveSubscriptions } from '../../auth/redux/actions';
import './SubscriptionPlans.scss';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';

const SubscriptionPlans = () => {
  const dispatch = useDispatch();
  const { GetActiveSubscriptionsResponseMessage } = useSelector(state => state.auth);
  const activeSubscriptions = GetActiveSubscriptionsResponseMessage?.AccountServiceMessage || null;

  useAnalyticsScreenTracking(null, 'SubscriptionPlan', '/subscriptionPlan');

  useEffect(() => {
    if (GetActiveSubscriptionsResponseMessage === null) {
      dispatch(getActiveSubscriptions());
    }
  }, [GetActiveSubscriptionsResponseMessage, dispatch]);

  return (
    <PageWrapper
      className="background-light"
      showHeader
      backUrl="/ManageSubscription"
      showBackIcon
      backText="Manage subscription"
      showTitle
      titleText="CHANGE PLAN"
    >
      <Row className="credit-card-screen">
        <div className="plans-section is-update-mode">
          <ChangePlan isUpdateMode activeSubscription={activeSubscriptions} />
        </div>
      </Row>
    </PageWrapper>
  );
};
export default SubscriptionPlans;
