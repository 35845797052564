import * as CONSTANTS from './constants';

/**
 * User Register
 * @param {object} data
 * @param {boolean} authFlow
 */
export const registerRequest = (data, isAllowNewsletter, authFlow, deviceId, urlVODId, search, callback) => ({
  type: CONSTANTS.REGISTER_REQUEST,
  payload: data,
  isAllowNewsletter,
  authFlow,
  deviceId,
  urlVODId,
  search,
  callback
});

export const registerSuccess = data => ({
  type: CONSTANTS.REGISTER_SUCCESS,
  payload: data
});

export const registerError = data => ({
  type: CONSTANTS.REGISTER_ERROR,
  payload: data
});

/**
 * quickplay OAuth Token
 */
export const getQuickplayOAuthTokenRequest = data => ({
  type: CONSTANTS.GET_QUICKPLAY_OAUTH_TOKEN_REQUEST,
  data
});
/**
 * User Register (Updating User Profile)
 * @param {object} data
 * @param {boolean} authFlow
 */
export const updateProfileRequest = (isChangePassword, changePasswordRequest, data, isAllowNewsletter, authFlow, deviceId, urlVODId, search, callback) => ({
  type: CONSTANTS.UPDATE_PROFILE_REQUEST,
  isChangePassword,
  changePasswordRequest,
  data,
  isAllowNewsletter,
  authFlow,
  deviceId,
  urlVODId,
  search,
  callback
});

export const updateProfileSuccess = data => ({
  type: CONSTANTS.UPDATE_PROFILE_SUCCESS,
  payload: data
});

export const updateProfileError = data => ({
  type: CONSTANTS.UPDATE_PROFILE_ERROR,
  payload: data
});

/**
 * User Delete Data
 * @param {object} data
 * @param {boolean} authFlow
 */
export const deleteDataRequest = (data, authFlow, deviceId, urlVODId, search) => ({
  type: CONSTANTS.DELETE_DATA_REQUEST,
  data,
  authFlow,
  deviceId,
  urlVODId,
  search
});

export const deleteDataSuccess = data => ({
  type: CONSTANTS.DELETE_DATA_SUCCESS,
  payload: data
});

export const deleteDataError = () => ({
  type: CONSTANTS.DELETE_DATA_ERROR
});

/**
 * User Signin
 * @param {object} data
 * @param {boolean} authFlow
 */
export const signinRequest = (data, authFlow, deviceId, urlVODId, search, winback, callback = null) => ({
  type: CONSTANTS.SIGNIN_REQUEST,
  data,
  authFlow,
  deviceId,
  urlVODId,
  search,
  winback,
  callback,
});

export const signinSuccess = data => ({
  type: CONSTANTS.SIGNIN_SUCCESS,
  payload: data
});

export const signinError = data => ({
  type: CONSTANTS.SIGNIN_ERROR,
  payload: data
});

/**
 * session token getting
 * @param {object} data
 */
export const getAccountRequest = (data, isRegisterReq, socialSignInProvider) => ({
  type: CONSTANTS.GET_ACCOUNT_REQUEST,
  data,
  isRegisterReq,
  socialSignInProvider
});

export const getAccountSuccess = data => ({
  type: CONSTANTS.GET_ACCOUNT_SUCCESS,
  payload: data
});

export const getAccountError = () => ({
  type: CONSTANTS.GET_ACCOUNT_ERROR
});

/**
 * favorite team
 */

export const getFavoriteTeamRequest = (data, isUpdatedTeam) => ({
  type: CONSTANTS.GET_FAVORITE_REQUEST,
  data,
  isUpdatedTeam
});

export const getFavoriteSuccess = data => ({
  type: CONSTANTS.GET_FAVORITE_SUCCESS,
  payload: data
});

export const getFavoriteError = () => ({
  type: CONSTANTS.GET_FAVORITE_ERROR,
});

/**
 * notification
 */
export const getNotificationRequest = data => ({
  type: CONSTANTS.GET_NOTIFICATION_REQUEST,
  data
});

export const getNotificationSuccess = data => ({
  type: CONSTANTS.GET_NOTIFICATION_SUCCESS,
  payload: data
});

export const getNotificationError = () => ({
  type: CONSTANTS.GET_NOTIFICATION_ERROR,
});

/**
 * players
 */

export const getPlayerRequest = (data, isUpdatedPlayer) => ({
  type: CONSTANTS.GET_PLAYER_REQUEST,
  data,
  isUpdatedPlayer
});

export const getPlayerSuccess = data => ({
  type: CONSTANTS.GET_PLAYER_SUCCESS,
  payload: data
});

export const getPlayerError = () => ({
  type: CONSTANTS.GET_PLAYER_ERROR,
});

/**
 * Google Auth
 * @param {object} data
 */

// eslint-disable-next-line max-len
export const socialSigninRequest = (data, authFlow, deviceId, urlVODId, search, response, provider, callback) => ({
  type: CONSTANTS.SOCIAL_SIGNIN_REQUEST,
  data,
  authFlow,
  deviceId,
  urlVODId,
  search,
  response,
  provider,
  callback
});

export const socialSigninSuccess = data => ({
  type: CONSTANTS.SOCIAL_SIGNIN_SUCCESS,
  payload: data
});

export const socialSigninError = () => ({
  type: CONSTANTS.SOCIAL_SIGNIN_ERROR
});

export const signout = () => ({
  type: CONSTANTS.SIGN_OUT
});

/**
 * Email confirm for password reset
 * @param {email} data - The data required for email confirmation
 * @param {Function} errorCallback - A callback function to execute when there is an error.
 */
export const emailConfirmRequest = (data, errorCallback) => ({
  type: CONSTANTS.EMAIL_CONFIRM_REQUEST,
  payload: data,
  errorCallback
});

export const emailConfirmSuccess = data => ({
  type: CONSTANTS.EMAIL_CONFIRM_SUCCESS,
  payload: data
});

export const emailConfirmError = () => ({
  type: CONSTANTS.EMAIL_CONFIRM_ERROR
});

/**
 * Reset Password
 * @param {resetToken, newPassword} data - The data required for reset password
 * @param {Function} errorCallback - A callback function to execute when there is an error.
 */
export const passwordResetRequest = (data, errorCallback) => ({
  type: CONSTANTS.PASSWORD_RESET_REQUEST,
  payload: data,
  errorCallback
});

export const passwordResetSuccess = data => ({
  type: CONSTANTS.PASSWORD_RESET_SUCCESS,
  payload: data
});

export const passwordResetError = () => ({
  type: CONSTANTS.PASSWORD_RESET_ERROR
});

export const getSubscriptionsRequest = data => ({
  type: CONSTANTS.GET_SUBSCRIPTIONS_REQUEST,
  payload: data
});

export const getSubscriptionsSuccess = () => ({
  type: CONSTANTS.GET_SUBSCRIPTIONS_SUCCESS
});

export const getSubscriptionsError = () => ({
  type: CONSTANTS.GET_SUBSCRIPTIONS_ERROR
});

export const getContinueFromThePlayers = data => ({
  type: CONSTANTS.GET_CONTINUE_FROM_THE_PLAYERS,
  payload: data
});

export const searchAccountV2Request = (data, authFlow, deviceId, urlVODId, search, callback) => ({
  type: CONSTANTS.SEARCH_ACCOUNT_REQUEST,
  data,
  authFlow,
  deviceId,
  urlVODId,
  search,
  callback
});

export const setSearchAccountV2ResponseMessage = () => ({
  type: CONSTANTS.SEARCH_ACCOUNT_SUCCESS
});

export const setSearchAccountV2Error = () => ({
  type: CONSTANTS.SEARCH_ACCOUNT_ERROR
});

export const setIsMac = data => ({
  type: CONSTANTS.SET_IS_MAC,
  payload: data
});

export const getActiveSubscriptions = (callback) => ({
  type: CONSTANTS.GET_ACTIVE_SUBSCRIPTIONS,
  callback
});

export const setActiveSubscriptionsSuccess = data => ({
  type: CONSTANTS.SET_ACTIVE_SUBSCRIPTIONS_SUCCESS,
  payload: data
});

export const setActiveSubscriptionsError = () => ({
  type: CONSTANTS.SET_ACTIVE_SUBSCRIPTIONS_ERROR
});

export const updateAccountDetailsSuccess = data => ({
  type: CONSTANTS.UPDATE_ACCOUNT_DETAILS_SUCCESS,
  payload: data
});

export const getPackages = data => ({
  type: CONSTANTS.GET_PACKAGES,
  payload: data
});

export const getPackagesSuccess = data => ({
  type: CONSTANTS.GET_PACKAGES_SUCCESS,
  payload: data
});

export const getPackagesError = () => ({
  type: CONSTANTS.GET_PACKAGES_ERROR
});

export const setLinkToTVSucess = () => ({
  type: CONSTANTS.LINK_TO_TV_SUCESS
});

export const getPaymentsRequest = () => ({
  type: CONSTANTS.GET_PAYMENTS_REQUEST
});

export const getPaymentsSuccess = data => ({
  type: CONSTANTS.GET_PAYMENTS_SUCCESS,
  payload: data
});

export const getPaymentsError = () => ({
  type: CONSTANTS.GET_PAYMENTS_ERROR
});

export const changeServiceRequest = data => ({
  type: CONSTANTS.CHANGE_SERVICE_REQUEST,
  payload: data
});

export const changeServiceSuccess = data => ({
  type: CONSTANTS.CHANGE_SERVICE_SUCCESS,
  payload: data
});

export const changeServiceError = () => ({
  type: CONSTANTS.CHANGE_SERVICE_ERROR
});

export const setSubscriptionType = data => ({
  type: CONSTANTS.SET_SUBSCRIPTION_TYPE,
  payload: data
});
