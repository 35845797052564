const subscriptionPaymentMethod = {
    "methods": [
        {
            "index": 0,
            "platform": "Web",
            "id": "Credit/Debit Card",
            "logo": "",
            "tvLogo": ""
        },
        {
            "index": 0,
            "platform": "WebSplitit",
            "id": "Splitit",
            "logo": "",
            "tvLogo": ""
        },
        {
            "index": 1,
            "platform": "Apple",
            "id": "App Store Billing",
            "logo": "https://d22v42wv50qeh4.cloudfront.net/images/paymentLogos/AppleLogo_Colour.png",
            "tvLogo": "https://d22v42wv50qeh4.cloudfront.net/images/paymentLogos/AppleLogo_White.png"
        },
        {
            "index": 2,
            "platform": "Roku",
            "id": "Roku Payment",
            "logo": "https://d22v42wv50qeh4.cloudfront.net/images/paymentLogos/RokuPay_Colour.png",
            "tvLogo": "https://d22v42wv50qeh4.cloudfront.net/images/paymentLogos/RokuPay_White.png"
        },
        {
            "index": 3,
            "platform": "Android",
            "id": "Google Wallet",
            "logo": "https://d22v42wv50qeh4.cloudfront.net/images/paymentLogos/GooglePay_Colour.png",
            "tvLogo": "https://d22v42wv50qeh4.cloudfront.net/images/paymentLogos/GooglePay_White.png"
        },
        {
            "index": 4,
            "platform": "FireTV",
            "id": "Amazon App Store Billing",
            "logo": "https://d22v42wv50qeh4.cloudfront.net/images/paymentLogos/AmazonPay_Colour.png",
            "tvLogo": "https://d22v42wv50qeh4.cloudfront.net/images/paymentLogos/AmazonPay_White.png"
        }
    ]
}

export default subscriptionPaymentMethod;