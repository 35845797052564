import React, { useState, useRef } from 'react';
import { FormControl } from 'react-bootstrap';
import VisibilityOnIcon from 'assets/icons/VisibilityOnIcon';
import VisibilityOffIcon from 'assets/icons/VisibilityOffIcon';
import './PasswordField.scss';

const PasswordField = ({ field, form, ...props }) => {
  const [type, setType] = useState('password');
  const inputRef = useRef(null);

  const showHide = e => {
    e.preventDefault();
    e.stopPropagation();
    if (type === 'password') {
      setType('text');
    } else {
      setType('password');
    }
    inputRef.current.focus();
  };
  return (
    <div className="icon-input">
      <FormControl type={type} {...field} {...props} ref={inputRef} />
      {type === 'text' ? (
        <VisibilityOnIcon onClick={showHide} color="#adadad" />
      ) : (
        <VisibilityOffIcon onClick={showHide} color="#adadad" />
      )}
    </div>
  );
};

export default PasswordField;
