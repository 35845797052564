export const gender = {
  male: 'male',
  Female: 'female'
};

export const background = {
  auth: 'auth-background',
  app: 'app-background',
  favPlayer: 'favorite-player-background'
};

export const adobPassAuthConstants = {
  ENTITLEMENT_LOADED: 'entitlementLoaded',
  ON_AUTHENTICATION_STATUS: 'onAuthenticationStatus',
  ON_SET_CONFIG: 'onSetConfig',
  ON_SET_SELECTED_PROVIDER: 'onSetSelectedProvider',
  ON_SET_TOKEN: 'onSetToken',

  // status strings
  AUTHENTICATED: 'authenticated',
  LOGGING_OUT_ADOBE_PASS: 'loggingOutAdobePass',
  NOT_AUTHENTICATED: 'notAuthenticated',
  TEMP_PASS: 'tempPass'
};

export const adobPassUserConstants = {
  AUTH_BTN_SELECTED: 'authBtnSelected',
  SELECTED_MVPD_UPDATED: 'selectedMvpdUpdated',
  SIGN_IN_BTN_SELECTED: 'signInBtnSelected',
  WATCH_BTN_SELECTED: 'watchBtnSelected'
};

export const watchErrorTypeConstants = {
  NO_SUBSCRIPTION_ERROR: 'NO_SUBSCRIPTION_ERROR',
  CONCURRENCY_ERROR: 'CONCURRENCY_ERROR',
  BLACKOUT_ERROR: 'BLACKOUT_ERROR',
  OUT_OF_MARKETPLACE_ERROR: 'OUT_OF_MARKETPLACE_ERROR',
  OUT_OF_US_ERROR: 'OUT_OF_US_ERROR',
  COUCH_RIGHTS_EXPIRY_ERROR: 'COUCH_RIGHTS_EXPIRY_ERROR',
  ABORT_ROAMING: 'ABORT_ROAMING',
  ABORT_NOT_ENTITLED: 'ABORT_NOT_ENTITLED',
  GRACEFUL_STOP: 'GRACEFUL_STOP'
};

export const CEHCKING_BLACKOUT_SECOND = 2 * 60; // polling api every 2min

export const easeLiveConstants = {
  BRIDGE_READY: 'bridge.ready',
  BRIDGE_MESSAGE: 'yes.menu',
  APP_READY: 'app.ready',
  APP_MESSAGE: 'appMessage',
  YES_SIMPLEBET: 'yes.simplebet',
  STAGE_CLICKED: 'stage.clicked',
  PLAYER_SPEED: 'player.speed',
  PLAYER_VOLUME: 'player.volume',
  PLAYER_STATE: 'player.state',
  PLAYER_TIME: 'player.time',
  PLAYER_READY: 'player.ready',
  VIEW_ERROR: 'view.error',
  ANY_ERROR: '*.error',
  DESTROY: 'destroy',
  YES_SIMPLEBET_DEVICE_INFO: 'yes.deviceInfo'
};