import { useSelector } from 'react-redux';
import React, { useRef, useState } from 'react';
import PageWrapper from '../../layoutPage/PageWrapper';
import './Subscribe.scss';
import UpdateProfile from '../../auth/UpdateProfile';
import CreditCardPayment from '../CreditCardPayment';
import useAnalyticsScreenTracking from '../../../hooks/useAnalyticsScreenTracking';

const Subscribe = ({
  isSettingsOpen,
  setIsSettingsOpen,
  setShowSubscribeScreen,
  monthlyDisplayPrice
}) => {
  const videoRef = useRef(null);
  const baseUrl = process.env.PUBLIC_URL;
  const { account } = useSelector(state => state.auth);
  const { remoteConfig } = useSelector(state => state.app.watch);
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);
  const [showCreditCard, setShowCreditCard] = useState(false);

  useAnalyticsScreenTracking('Watch - Subscribe');

  const checkIsSocial = () => {
    if (
      ['Google', 'Facebook', 'Apple'].indexOf(
        account?.profile?.contactMessage?.[0]?.socialLoginType
      ) !== -1 &&
      account?.profile?.contactMessage?.[0]?.userName === undefined
    ) {
      setShowUpdateProfile(true);
    } else {
      setShowCreditCard(true);
    }
  };
  if (showUpdateProfile) {
    return (
      <UpdateProfile
        goBack={() => {
          setShowUpdateProfile(false);
        }}
      />
    );
  }
  if (showCreditCard) {
    return (
      <CreditCardPayment
        goBack={() => {
          setShowCreditCard(false);
        }}
        isReload
      />
    );
  }
  return (
    <PageWrapper
      showHeader
      showBackIcon
      onBackClick={() => setShowSubscribeScreen(false)}
      showAccountIcon
      isSettingsOpen={isSettingsOpen}
      setIsSettingsOpen={setIsSettingsOpen}
      className="subscriber-page"
    >
      <div className="subscribe-page">
        <video
          autoPlay
          muted
          id="landingPageVideo"
          poster={`${baseUrl}/BG.png`}
          ref={videoRef}
          onEnded={() => {
            videoRef.current.play();
          }}
        >
          <source src={remoteConfig?.dtcLaunchScreen?.videoURL} type="video/mp4" />
        </video>
        <img className="darkMode" src={`${baseUrl}/Gradient.png`} alt="DarkMode" />
        <div className="subscribe-content">
          <div className="subscribe-title">
            The video you are trying to watch requires a YES subscription
          </div>
          <img className="team-icons" src={`${baseUrl}/Team icons.png`} alt="Team icons" />
          <div className="subscribe-desc">{`Subscribe now for just $${monthlyDisplayPrice}`}</div>
          <div className="subscribe-sub-desc">Subject to territorial restrictions.</div>
          <button className="primary__button" onClick={() => checkIsSocial()}>
            SUBSCRIBE
          </button>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Subscribe;
