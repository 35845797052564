import React from 'react';

import YesBackground from 'components/YesBackground';
import YesCard from 'components/YesCard';
import ScreenImage from 'assets/images/Screens_icon.png';
import { background } from 'helpers/constants';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useAnalyticsScreenTracking from 'hooks/useAnalyticsScreenTracking';

const EmailSent = () => {
  const { resetEmail } = useSelector(state => state.auth);

  useAnalyticsScreenTracking(null, 'EmailSent', '/emailSent');

  return (
    <>
      <YesBackground
        logoIcon={ScreenImage}
        bgClassName={background.auth}
        title="Watch Yes"
        description="<span>Catch your favorite games live on your <b>phone</b>, <b>computer</b>, <b>tablet</b>, or <b>TV</b>.</span>"
        subDescription="Available to customers on participating TV providers."
      />
      <YesCard title="Email Sent">
        <div className="pt-4 text-center pts-n-db-20">
          A password link has been sent to
          <a href={`mailto: ${resetEmail}`} className="link-normal my-2 d-block">
            {resetEmail}
          </a>
          <b>Note: Be sure to also check your spam.</b>
        </div>
        <div className="text-center mt-5">
          <Link to="/" className="link pts-b-db-16">
            Back to login
          </Link>
        </div>
      </YesCard>
    </>
  );
};

export default EmailSent;
