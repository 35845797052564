/* eslint-disable max-len */
import { useEffect, useRef } from 'react';
import ConvivaProxy from 'services/ConvivaProxy';
import { segmentEvent } from 'components/BitmovinPlayer/SegmentAnalytics/analyticsFunction';

const useAnalyticsScreenTracking = (convivaScreenName, segmentScreenName, segmentPath, segmentEventName, segmentEventProperties) => {
  const isScreenTracked = useRef(false);
  useEffect(() => {
    if (!isScreenTracked.current) {
      if (segmentScreenName) {
        window.analytics.page(segmentScreenName, {
          path: segmentPath,
          url: window.location.href
        });
      }
      if (convivaScreenName) {
        ConvivaProxy.screenTracking(convivaScreenName);
      }
      if (segmentEventName) {
        segmentEvent(segmentEventName, segmentEventProperties);
      }
      isScreenTracked.current = true;
    }
  }, [convivaScreenName, segmentScreenName, segmentPath, segmentEventName, segmentEventProperties]);
};

export default useAnalyticsScreenTracking;
