/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  checkValidToken
} from 'utils/CheckTokenValidation';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';


const API_BASE_URL = process.env.REACT_APP_EVERGENT_API;

export const generateReferralCode = async (referralProgram) => {
  try {
    const evergentAccessToken = await checkValidToken();
    const headers = {
      headers: {
        Authorization: `Bearer ${evergentAccessToken}`,
        'Content-Type': 'application/json',
      },
    };
    const payload = {
      GenerateReferralCodeRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        initiatedBy: 'evWeb',
        referralProgram,
      },
    };
    const response = await axios.post(`${API_BASE_URL}/yesnetwork/generateReferralCode`, payload, headers);
    let referralCode = null;
    if (response?.data?.GenerateReferralCodeResponseMessage?.responseCode === '1') {
      referralCode = response.data.GenerateReferralCodeResponseMessage.referralCode;
    }
    else if (response?.data?.GenerateReferralCodeResponseMessage?.responseCode === '0') {
      throw new Error(response?.data?.GenerateReferralCodeResponseMessage?.failureMessage[0]?.errorMessage);
    }
    return referralCode;
  } catch (error) {
    throw new Error(error.message);
  }
};
