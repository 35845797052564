import queryString from 'qs';
import axios from 'axios';
import { baseUrl } from '../config';

/**
 * API request module
 *
 * @param {*} endpoint
 * @param {string} [method='GET']
 * @param {*} [payload={}]
 * @param {boolean} [isToken=false]
 * @returns
 */
async function requestHeaderOption(
  baseUrlType,
  endpoint,
  method = 'get',
  header = {},
  payload = {},
  token = '',
  isFormData = false
) {
  return new Promise((resolve, reject) => {
    let qs = '';
    let body;

    if (['get', 'delete'].includes(method)) {
      qs = `?${queryString.stringify(payload, { arrayFormat: 'bracket' })}`;
    } else {
      body = isFormData ? payload : JSON.stringify(payload);
    }

    const options = {
      url: `${baseUrl[baseUrlType]}${endpoint}${qs}`,
      method: method.toLowerCase(),
      headers: header ? header : {},
      data: body
    };

    if (!isFormData) {
      options.headers['Content-Type'] = 'application/json';
    }
    else {
      options.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    }

    if (token) {
      if (baseUrlType === 'adobeAuth') {
        options.headers.Authorization = token;
      } else {
        options.headers.Authorization = `Bearer ${token}`;
      }
    }

    axios
      .request(options)
      .then(result => resolve(result.data))
      .catch(err => reject(err));
  });
}

export default requestHeaderOption;
