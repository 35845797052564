import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { manageAccountSchema } from 'helpers/validations';
import { useRouteMatch, useLocation } from 'react-router-dom';
import { updateProfileRequest } from '../../auth/redux/actions';
import './ManageAccount.scss';
import { APIUSER, APIPASSWORD, CHANNELPARTNERID } from 'config';
import PageWrapper from 'pages/layoutPage/PageWrapper';
import NewLayout from 'containers/AuthLayout/NewLayout';
import useAnalyticsScreenTracking from 'hooks/useAnalyticsScreenTracking';

const ManageAccount = ({ goBack }) => {
  const dispatch = useDispatch();
  const { loading: isLoading, account, isMac } = useSelector(state => state.auth);
  const match = useRouteMatch('/watch:vodId?');
  const deviceMatch = useRouteMatch('/authenticate/:deviceId?');
  const { url } = useRouteMatch();
  const authFlow = url.includes('/authenticate') ? 'device' : 'user';
  const { search } = useLocation();
  let firstName = account?.profile?.contactMessage?.[0]?.firstName;
  let lastName = account?.profile?.contactMessage?.[0]?.lastName;
  const dateOfBirth = account?.profile?.contactMessage?.[0]?.dateOfBirth;
  const gender = account?.profile?.contactMessage?.[0]?.gender;
  const email = account?.profile?.contactMessage?.[0]?.email;
  const nickName = account?.profile?.contactMessage?.[0]?.nickName;

  useAnalyticsScreenTracking('Account - Manage YES Account');
  const initialValues = {
    name: nickName || `${firstName} ${lastName}`,
    gender: gender || ''
  };
  const handleSubmit = values => {
    const { name: nickname, gender } = values;
    const nameParts = nickname.split(' ');
    if (nameParts.length > 1) {
      // eslint-disable-next-line prefer-destructuring
      firstName = nameParts[0];
      lastName = nameParts.slice(1).join(' ');
    } else {
      firstName = nickname;
      lastName = '';
    }

    const account = {
      UpdateProfileRequestMessage: {
        apiUser: APIUSER,
        apiPassword: APIPASSWORD,
        channelPartnerID: CHANNELPARTNERID,
        email,
        firstName,
        lastName,
        nickName: nickname,
        dateOfBirth,
        gender,
        addressMsg: {
          address1: '',
          address2: '',
          city: '',
          state: '',
          country: '',
          zipCode: ''
        },
        homePhone: '',
        mobileNumber: '',
        socialProfilePic: '',
        profilePic: ''
      }
    };
    dispatch(
      updateProfileRequest(
        false,
        null,
        account,
        undefined,
        authFlow,
        deviceMatch?.params?.deviceId,
        match?.params?.vodId,
        search
      )
    );
    goBack();
  };
  return (
    <PageWrapper
      showHeader
      showBackIcon
      onBackClick={() => goBack()}
      showTitle
      titleText="MANAGE ACCOUNT"
    >
      <NewLayout>
        <div className="manage-account">
          <Formik
            initialValues={initialValues}
            validationSchema={manageAccountSchema}
            onSubmit={handleSubmit}
          >
            {({ touched, errors, isValid }) => (
              <Form className="d-flex flex-column justify-content-between manage-account-form">
                <div>
                  <div className="form-group">
                    <label
                      className={
                        touched.name && errors.name ? 'is-invalid form-label' : 'form-label'
                      }
                      htmlFor="name"
                    >
                      Name
                    </label>
                    <Field
                      type="text"
                      name="name"
                      placeholder="Enter your name."
                      className={`form-control ${touched.name && errors.name ? 'is-invalid' : ''}`}
                    />
                    <ErrorMessage component="div" name="name" className="invalid-feedback" />
                  </div>

                  <div className="form-group">
                    <label className="form-label" htmlFor="gender">
                      Gender
                    </label>
                    <Field name="gender" as="select" className="form-control gender-dropdown">
                      <option value="Optional">Optional</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Other</option>
                      <option value="Prefer not to say">Prefer not to answer</option>
                    </Field>
                  </div>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    // className="btn btn-primary btn-block w-50 m-auto"
                    className={`${isMac ? 'IOS' : ''} primary__button`}
                    disabled={!isValid}
                  >
                    {isLoading ? 'Please wait...' : 'UPDATE'}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </NewLayout>
    </PageWrapper>
  );
};

export default ManageAccount;
