import React from 'react';

const PageTitle = ({ text = '', subText = '', newSubText = '' }) => {

  return (
    <>
      <div className='title'>
        <div className='title-wrapper'>
          {text && <div className='title-wrapper-text'>
            {text}
          </div>}
          {subText && <div className='title-wrapper-subText'>
            {subText}
          </div>}
          {newSubText && <div className='title-wrapper-newSubText'>
            {newSubText}
          </div>}
        </div>
      </div>
    </>
  );
};

export default PageTitle;
