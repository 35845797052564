import React, { useEffect, useState, useRef } from 'react';
import 'react-multi-carousel/lib/styles.css';
import './VODList.scss';
import { useDispatch, useSelector } from 'react-redux';
import ErrorScreen from 'components/ErrorScreen';
import { getVODListRequest } from '../redux/actions';
import VODSection from '../VODSection';
import MergeArraysWithoutDuplicates from '../../../../utils/MergeArraysWithoutDuplicates';

const STANDARD_INTERVAL = 10800000; // 3 hours in milliseconds
const LIVE_GAME_INTERVAL = 1800000; // 30 minutes in milliseconds

const VODList = ({
  playLiveChannel,
  setSeeMore,
  setDetailedSections,
  setVideoName,
  setVideoTitle,
  setSectionId,
  error,
  setCurrentSection,
  setShowSubscribeScreen,
  setContainerVodURL
}) => {
  const dispatch = useDispatch();
  const { vodList, remoteConfig, vodListResponse, currentScheduleItem } = useSelector(state => state.app.watch);
  const { account } = useSelector(state => state.auth);
  // eslint-disable-next-line max-len, eqeqeq
  const favTeams = remoteConfig?.yesTeams?.filter(element => account?.favoriteTeams?.some(element2 => element.id == element2)).map(team => team.name.toLowerCase());
  const teamPlaylistArray = [];
  const vodListarray = [];
  const [offset, setOffset] = useState(1);
  const myRef = useRef(null);
  const [allVodList, setAllVodList] = useState([]);


  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (

      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  useEffect(() => {
    const i = setInterval(() => {
      if (myRef.current) {
        const isVisible = isInViewport(myRef.current);
        if (
          isVisible &&
          vodListResponse?.data &&
          vodListResponse?.header?.count > vodListResponse?.header?.start + vodListResponse?.header?.rows
        ) {
          setOffset(offset + 1);
        }
      }
    }, 2000);
    return () => {
      if (i) {
        clearInterval(i);
      }
    };
    // eslint-disable-next-line
  }, [vodListResponse]);

  useEffect(() => {
    // Function to fetch the catalog
    const fetchCatalog = () => {
      if (remoteConfig?.sfId) {
        dispatch(getVODListRequest(remoteConfig, offset));
      }
    };
    // Calculate the interval duration based on the live status
    const intervalDuration = currentScheduleItem?.ev_live === 'true' ? LIVE_GAME_INTERVAL : STANDARD_INTERVAL;
    // Call fetchCatalog on mount and at the interval's end
    fetchCatalog();
    const intervalId = setInterval(fetchCatalog, intervalDuration);
    // Cleanup function to clear the interval when the component unmounts or dependencies change
    return () => {
      clearInterval(intervalId);
    };
    // Only reset the interval when the intervalDuration changes
  }, [dispatch, offset, remoteConfig, currentScheduleItem]);



  useEffect(() => {
    const result = MergeArraysWithoutDuplicates(vodList, allVodList);
    setAllVodList(result);
    // eslint-disable-next-line
  }, [vodList]);

  // eslint-disable-next-line consistent-return
  const playlistFilter = (ID, position, sectionItem) => {
    // Featured Playlist
    if (position === 0) {
      return (
        <VODSection
          sectionName={sectionItem?.lon[0].n}
          detailSections={sectionItem.cd}
          key={ID}
          listLength={position}
          sectionId={ID}
          playLiveChannel={playLiveChannel}
          setDetailedSections={section => setDetailedSections(section)}
          setSeeMore={view => setSeeMore(view)}
          setVideoName={name => setVideoName(name)}
          setVideoTitle={title => setVideoTitle(title)}
          setCurrentSection={setCurrentSection}
          setSectionId={id => setSectionId(id)}
          position={0}
          setShowSubscribeScreen={setShowSubscribeScreen}
          setContainerVodURL={setContainerVodURL}
          containerUrl={sectionItem.i[0]?.cu}
        />
      );
    }
    // Team Playlist
    if (
      favTeams.includes(sectionItem.con_tg.split('_')[1])
    ) {
      teamPlaylistArray.push(sectionItem);
    }
    // Generic Playlist and Not Selected
    else {
      vodListarray.push(sectionItem);
    }

  };

  const teamPlaylistFilter = teamPlaylistArr => (
    teamPlaylistArr &&
    teamPlaylistArr.map((_section, _index) => (
      <VODSection
        sectionName={_section?.lon[0].n}
        detailSections={_section.cd}
        key={_section.id}
        sectionId={_section.id}
        listLength={+_index + 1}
        setSeeMore={view => setSeeMore(view)}
        playLiveChannel={playLiveChannel}
        setDetailedSections={section => setDetailedSections(section)}
        setVideoName={name => setVideoName(name)}
        setVideoTitle={title => setVideoTitle(title)}
        setCurrentSection={setCurrentSection}
        setSectionId={id => setSectionId(id)}
        setShowSubscribeScreen={setShowSubscribeScreen}
        setContainerVodURL={setContainerVodURL}
        containerUrl={_section.i[0]?.cu}
      />
    ))
  );

  const playlistRemainder = playlistArray =>
    playlistArray &&
    playlistArray.map((_section, _index) => (
      <VODSection
        sectionName={_section?.lon[0].n}
        detailSections={_section.cd}
        key={_section.id}
        sectionId={_section.id}
        listLength={+_index + 1}
        setSeeMore={view => setSeeMore(view)}
        playLiveChannel={playLiveChannel}
        setDetailedSections={section => setDetailedSections(section)}
        setVideoName={name => setVideoName(name)}
        setVideoTitle={title => setVideoTitle(title)}
        setCurrentSection={setCurrentSection}
        setSectionId={id => setSectionId(id)}
        setShowSubscribeScreen={setShowSubscribeScreen}
        setContainerVodURL={setContainerVodURL}
        containerUrl={_section.i[0]?.cu}
      />
    ));

  return vodList.length ? (
    <div className="playlist-container">
      <div className="watch-header d-flex align-items-center justify-content-between mb-2">
        <h3 className="tgd1-b-base-33 float-left">MORE VIDEO</h3>
      </div>
      <div className="video-container">
        {error ? (
          <ErrorScreen
            title="Videos unavailable"
            content="You’re outside USA. In order to adhere to our rights we broadcast only in USA at the moment."
            vodList
          />
        ) : (
          allVodList.map((section, index) => playlistFilter(section.id, index, section)
          )
        )}
        {teamPlaylistFilter(teamPlaylistArray)}
        {playlistRemainder(vodListarray)}
      </div>
      <div ref={myRef} />
    </div>
  ) : null;
};

export default VODList;
